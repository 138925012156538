import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getUrl } from '../constants/urls'
import { BroadcastMsgUIAdapter, CancellationUIAdapter, ChatBotsUIAdapter, ClientsUIAdapter, ContactUSUIAdapter, ConversationalEngagementUIAdapter, CustomerSupportAppUIAdapter, DummyUIAdapter, ECommerceSectorUIAdapter, EducationalSectorUIAdapter, HealthSectorUIAdapter, HomeUIAdapter, IntegerationUIAdapter, MarketingAppUIAdapter, MultiAgentUIAdapter, PowerBusinessUIAdapter, PartnershipPageUIAdapter, PricingUIAdapter, PrivacyPolicyUIAdapter, QuickGuideUIAdapter, SalesAppUIAdapter, SupportUIAdapter, TeamUIAdapter, TermsOfServiceUIAdapter, TourTravelSectorUIAdapter, CaseStudyUIAdapter, HotelsHospitalitySectorUIAdaptor, WhatstoolTemplatesUIAdapter, CompareWatiUIAdapter } from '../UICaller/StaticUI'
import { FeaturedChatBotTemplateUIAdapter, UrlShortners, WTDUIAdapter, WhatsappChatButtonUIAdapter, WhatsappLinkGeneratorAdapter, WhatsappQRGeneratorUIAdapter, WhatstoolProcongCalulator } from '../UICaller/DynamicUI'
import { getString } from '../constants/strings'
import { getSeo } from '../constants/seo'
import { Maintenance } from '../view/Static/Maintenance'


export const RouteController = () => {
  const [pageURL, setPageURL] = useState();
  useEffect(() => { setPageURL(window.location.href) }, [])
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Helmet>
              <title>WhatsApp Api Solutions for your business.</title>
              <meta name="description" content={getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={'WhatsApp Api Solutions for your business.'} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={'WhatsApp Api Solutions for your business.'} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <HomeUIAdapter />
          </Route>
          {/* Business Panel Routes */}


          <Route exact path={getUrl.whastsappchatbuttonUrl}><WhatsappChatButtonUIAdapter /></Route>
          <Route exact path={getUrl.whatsappLinkGeneratorUrl}>
            <Helmet>
              <title>Free WhatsApp Link Generator</title>
              <meta name="description" content='Create custom WhatsApp links with free Whatsapp link generator.' />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={'Free WhatsApp Link Generator'} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content='Create custom WhatsApp links with free Whatsapp link generator.' />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={'Free WhatsApp Link Generator'} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content='Create custom WhatsApp links with free Whatsapp link generator.' />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <WhatsappLinkGeneratorAdapter />
          </Route>
          <Route exact path={getUrl.whatsappQRGeneratorUrl}><WhatsappQRGeneratorUIAdapter /></Route>
          <Route exact path={getUrl.WhatsToolPricingCalcultorUrl}><WhatstoolProcongCalulator /></Route>

          <Route exact path={getUrl.whatsappBusinessApiUrl}><DummyUIAdapter /></Route>

          <Route exact path={getUrl.crmUrl}><DummyUIAdapter /></Route>
          <Route exact path={getUrl.intergrationUrl}><IntegerationUIAdapter /></Route>
          <Route exact path={getUrl.ecommerceSectorUrl}><ECommerceSectorUIAdapter /></Route>
          <Route exact path={getUrl.educationalSectorUrl}><EducationalSectorUIAdapter /></Route>
          <Route exact path={getUrl.healthSectorUrl}><HealthSectorUIAdapter /></Route>
          <Route exact path={getUrl.tourTravelSectorUrl}><TourTravelSectorUIAdapter /></Route>
          <Route exact path={getUrl.HotelsHospitalitySectorUrl}><HotelsHospitalitySectorUIAdaptor /></Route>

          <Route exact path={getUrl.powerBusinessUrl}><PowerBusinessUIAdapter /></Route>
          <Route exact path={getUrl.CaseStudyUrl}>< CaseStudyUIAdapter /></Route>
          <Route exact path={getUrl.CompareWatiUrl}>< CompareWatiUIAdapter /></Route>
          <Route exact path={getUrl.PartnershipPageUrl}><PartnershipPageUIAdapter /></Route>
          <Route exact path={getUrl.MaintenanceUrl}>< Maintenance /></Route>
          <Route exact path={getUrl.UrlShortnerLink}><UrlShortners /></Route>


          <Route exact path={getUrl.pricingUrl}>
            <Helmet>
              <title>WhatsApp API Pricing | Whatstool Pricing</title>
              <meta name="description" content="WhatsTool pricing starts at 899/month. WhatsTool offers the most reliable and affordable WhatsApp API solution." />
              <meta name="keywords" content="whatsapp api pricing, whatsapp api pricing India, whatsapp api pricing in India, whatsapp api cost, whatsapp api business pricing, whatsapp api price, whatsapp api prices, WhatsApp business pricing, WhatsApp business API pricing" />
              <meta property="og:title" content="WhatsApp API Pricing | Whatstool Pricing" />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content="WhatsTool pricing starts at 899/month. WhatsTool offers the most reliable and affordable WhatsApp API solution." />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content="WhatsApp API Pricing | Whatstool Pricing" />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content="WhatsTool pricing starts at 899/month. WhatsTool offers the most reliable and affordable WhatsApp API solution." />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <PricingUIAdapter />
          </Route>
          <Route exact path={getUrl.quickGuideUrl}>
            <Helmet>
              <title>{getSeo.quickGuideTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.quickGuideTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.quickGuideTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.quickGuideTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.quickGuideTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.quickGuideTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <QuickGuideUIAdapter />
          </Route>
          <Route exact path={getUrl.chatbotsUrl}>
            <Helmet>
              <title>{getSeo.chatbotTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.chatbotTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.chatbotTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.chatbotTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.chatbotTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.chatbotTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <ChatBotsUIAdapter />
          </Route>
          <Route exact path={getUrl.multiAgentsUrl}>
            <Helmet>
              <title>WhatsApp multi-Agent Teambox - Whatstool Business</title>
              <meta name="description" content="WhatsApp multi-agent feature can be utilized by using WhatsApp API. WhatsApp multi-agent feature allows multiple team members to use a single WhatsApp number." />
              <meta name="keywords" content='whatsapp multiagent, whatsapp multi agent, whatsapp business multi agent, whatsapp multi agent chat, whatsapp multi agent platform, whatsapp multi agent chat, WhatsApp team inbox' />
              <meta property="og:title" content={'WhatsApp multi-Agent Teambox - Whatstool Business'} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content="WhatsApp multi-agent feature can be utilized by using WhatsApp API. WhatsApp multi-agent feature allows multiple team members to use a single WhatsApp number." />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={'WhatsApp multi-Agent Teambox - Whatstool Business'} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content="WhatsApp multi-agent feature can be utilized by using WhatsApp API. WhatsApp multi-agent feature allows multiple team members to use a single WhatsApp number." />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <MultiAgentUIAdapter />
          </Route>

          <Route exact path={getUrl.WhatstoolTemplatesUrl}>
            <Helmet>
              <title>50+ Best WhatsApp Message Templates - WhatsTool</title>
              <meta name="description" content={'Get access to WhatsApp Message templates from the top brands across sectors for free.'} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={'50+ Best WhatsApp Message Templates - WhatsTool'} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={'Get access to WhatsApp Message templates from the top brands across sectors for free.'} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={'50+ Best WhatsApp Message Templates - WhatsTool'} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={'Get access to WhatsApp Message templates from the top brands across sectors for free.'} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            < WhatstoolTemplatesUIAdapter />
          </Route>


          <Route exact path={getUrl.broadcastMessageUrl}>
            <Helmet>
              <title>{getSeo.broadcastMsgTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.broadcastMsgTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.broadcastMsgTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.broadcastMsgTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.broadcastMsgTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.broadcastMsgTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <BroadcastMsgUIAdapter />
          </Route>
          <Route exact path={getUrl.clientsUrl}>
            <Helmet>
              <title>{getSeo.clientsTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.clientsTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.clientsTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.clientsTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.clientsTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.clientsTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <ClientsUIAdapter />
          </Route>
          <Route exact path={getUrl.supportUrl}>
            <Helmet>
              <title>{getSeo.supportTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.supportTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.supportTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.supportTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.supportTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.supportTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <SupportUIAdapter />
          </Route>
          <Route exact path={getUrl.contactUsUrl}>
            <Helmet>
              <title>{getString.contactUsTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getString.contactUsTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getString.contactUsTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getString.contactUsTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getString.contactUsTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getString.contactUsTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <ContactUSUIAdapter />
          </Route>
          <Route exact path={getUrl.termsOfServiceUrl}>
            <Helmet>
              <title>{getSeo.termsOfServiceTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.termsOfServiceTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.termsOfServiceTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.termsOfServiceTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.termsOfServiceTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.termsOfServiceTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <TermsOfServiceUIAdapter />
          </Route>
          <Route exact path={getUrl.privacyPolicyUrl}>
            <Helmet>
              <title>{getSeo.privacyPolicyTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.privacyPolicyTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.privacyPolicyTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.privacyPolicyTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.privacyPolicyTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.privacyPolicyTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <PrivacyPolicyUIAdapter />
          </Route>
          <Route exact path={getUrl.cancellationUrl}>
            <Helmet>
              <title>{getSeo.cancellationTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.cancellationTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.cancellationTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.cancellationTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.cancellationTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.cancellationTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <CancellationUIAdapter />
          </Route>
          <Route exact path={getUrl.teamPageUrl}>
            <Helmet>
              <title>{getSeo.teamPageTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.teamPageTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.teamPageTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.teamPageTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.teamPageTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.teamPageTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <TeamUIAdapter />
          </Route>
          <Route exact path={getUrl.conversationalEngagementUrl}>
            <Helmet>
              <title>{getSeo.conversationalEngagementTitle + " - " + getString.SiteName}</title>
              <meta name="description" content={getSeo.conversationalEngagementTitle + ". " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={getSeo.conversationalEngagementTitle + " - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={getSeo.conversationalEngagementTitle + ". " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={getSeo.conversationalEngagementTitle + " - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={getSeo.conversationalEngagementTitle + ". " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <ConversationalEngagementUIAdapter />
          </Route>
          <Route exact path={getUrl.featuredTemplatesUrl}>
            <Helmet>
              <title>WhatsApp Chatbot Templates - WhatsTool Business</title>
              <meta name="description" content="Choose from the library of free no-code whatsapp template. Setup on your website within minutes. Lots of option for different industries and use cases." />
              <meta name="keywords" content="Whatsapp chatbot templates, chatbot templates, free chatbot templates, dialogflow chatbot templates, chatbot templates free download" />
              <meta property="og:title" content={'WhatsApp Chatbot Templates - WhatsTool Business'} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content="Choose from the library of free no-code whatsapp template. Setup on your website within minutes. Lots of option for different industries and use cases." />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={'WhatsApp Chatbot Templates - WhatsTool Business'} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content="Choose from the library of free no-code whatsapp template. Setup on your website within minutes. Lots of option for different industries and use cases." />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <FeaturedChatBotTemplateUIAdapter />
          </Route>
          <Route exact path={getUrl.marketingPageUrl}>
            <Helmet>
              <title>{"Marketing - " + getString.SiteName}</title>
              <meta name="description" content={"Marketing. " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={"Marketing - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={"Marketing. " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={"Marketing - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={"Marketing. " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <MarketingAppUIAdapter />
          </Route>
          <Route exact path={getUrl.salesPageUrl}>
            <Helmet>
              <title>{"Sales - " + getString.SiteName}</title>
              <meta name="description" content={"Sales. " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={"Sales - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={"Sales. " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={"Sales - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={"Sales. " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <SalesAppUIAdapter />
          </Route>
          <Route exact path={getUrl.customerSupportPageUrl}>
            <Helmet>
              <title>{"Customer Support - " + getString.SiteName}</title>
              <meta name="description" content={"Customer Support. " + getString.SiteDescription} />
              <meta name="keywords" content={getSeo.commonKeywords} />
              <meta property="og:title" content={"Customer Support - " + getString.SiteName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={"Customer Support. " + getString.SiteDescription} />
              <meta property="og:locale" content="en_GB" />

              <meta property="twitter:title" content={"Customer Support - " + getString.SiteName} />
              <meta name="twitter:url" content={pageURL} />
              <meta name="twitter:description" content={"Customer Support. " + getString.SiteDescription} />
              <meta name="twitter:site" content="@ToolsWhats" />
              <meta name="twitter:creator" content="@ToolsWhats" />

              <link rel="canonical" href={pageURL} />
            </Helmet>
            <CustomerSupportAppUIAdapter />
          </Route>
          {/* End of Static Pages Routes */}

          <Route exact path={getUrl.wtdPaymentUrl}><WTDUIAdapter /></Route>
        </Switch>
      </Router>
    </>
  )
}


