import React, { useEffect, useContext } from 'react'
import { Footer, Header } from '../controller/BaseToolUI'
import { Client, Home } from '../view/home/ui'
import { Pricing } from '../view/Static/PricingUI'
import { BroadcastMsg, Chatbots, ConversationalEngagement, CustomerSupportApp, ECommerceSector, EducationalSector, HealthSector, HotelsHospitalitySector, Integeration, MarketingApp, MultiAgent, QuickGuide, SalesApp, TeamPage, TourTravelSector } from '../view/Static/InfoUIs'
import { Cancellation, ContactUs, FAQ, PrivacyPolicy, Support, TermsOfService } from '../view/Static/ui'
import globalContext from '../context/global/globalContext'
import { PartnershipPage, WhatstoolPowerBusiness, CaseStudy, WhatstoolTemplates, CompareWati } from '../view/Static/StaticApp'

export const HomeUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Home />
        </div>
    )
}
export const PricingUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.location.href = 'https://whatstool.business/pricing' }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <Pricing />
            <Footer />
        </div>
    )
}
export const ChatBotsUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <Chatbots />
            <Footer />
        </div>
    )
}
export const MultiAgentUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <MultiAgent />
            <Footer />
        </div>
    )
}

export const BroadcastMsgUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <BroadcastMsg />
            <Footer />
        </div>
    )
}
export const ClientsUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <Client ClientPage={true} />
            <Footer />
        </div>
    )
}
export const QuickGuideUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <QuickGuide />
            <Footer />
        </div>
    )
}

// Integeration UI
export const IntegerationUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <Integeration />
            <Footer />
        </div>
    )
}
// End of Integeration UI


// Industry UI
export const EducationalSectorUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <EducationalSector />
            <Footer />
        </div>
    )
}

export const ECommerceSectorUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <ECommerceSector />
            <Footer />
        </div>
    )
}
export const HealthSectorUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <HealthSector />
            <Footer />
        </div>
    )
}
export const TourTravelSectorUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <TourTravelSector />
            <Footer />
        </div>
    )
}
// End of Industry UI

export const HotelsHospitalitySectorUIAdaptor = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <HotelsHospitalitySector />
            <Footer />
        </div>
    )
}



export const MarketingAppUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <MarketingApp />
            <Footer />
        </div>
    )
}
export const SalesAppUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <SalesApp />
            <Footer />
        </div>
    )
}
export const CustomerSupportAppUIAdapter = () => {
    const setOfferImgVisivility = useContext(globalContext)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 200) setOfferImgVisivility.setOfferImgVisivility(false)
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
            <Header />
            <CustomerSupportApp />
            <Footer />
        </div>
    )
}


export const SupportUIAdapter = () => {
    return (
        <>
            <Header />
            <Support />
            <Footer />
        </>
    )
}
export const ContactUSUIAdapter = () => {
    return (
        <>
            <Header />
            <ContactUs />
            <Footer />
        </>
    )
}
export const TermsOfServiceUIAdapter = () => {
    return (
        <>
            <Header />
            <TermsOfService />
            <Footer />
        </>
    )
}
export const PrivacyPolicyUIAdapter = () => {
    return (
        <>
            <Header />
            <PrivacyPolicy />
            <Footer />
        </>
    )
}
export const CancellationUIAdapter = () => {
    return (
        <>
            <Header />
            <Cancellation />
            <Footer />
        </>
    )
}
export const TeamUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

    return (
        <>
            <Header />
            <TeamPage />
            <Footer />
        </>
    )
}
export const ConversationalEngagementUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Header />
            <ConversationalEngagement />
            <Footer />
        </>
    )
}
export const FAQUIAdapter = () => {
    return (
        <>
            <Header />
            <FAQ />
            <Footer />
        </>
    )
}

export const PowerBusinessUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

    return (
        <>
            <Header />
            <WhatstoolPowerBusiness />
            <Footer />
        </>
    )
}
export const PartnershipPageUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

    return (
        <>
            <Header />
            <PartnershipPage />
            <Footer />
        </>
    )
}



export const CaseStudyUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

    return (
        <>
            <Header />
            <CaseStudy />
            <Footer />
        </>
    )
}

export const WhatstoolTemplatesUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

    return (
        <section>
            <Header />
            <WhatstoolTemplates />
        </section>
    )
}

export const CompareWatiUIAdapter = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

    return (
        <section>
            < CompareWati />
            <Footer />
        </section>
    )
}
export const DummyUIAdapter = () => {
    return (
        <>
            <Header />
            <h1 className="text-center mt-5 mb-5 pt-5 pb-5">Coming Soon...<br />Under development...</h1>
            <Footer />
        </>
    )
}


