/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Grow, Slider, Switch } from "@mui/material"
import { useState, useRef } from "react"
import { Col, Container, Row } from "react-bootstrap"
import './Calculatorindex.css'
import { isMobile } from "react-device-detect"
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui/icons-material"
import { useEffect } from "react"
import { fetchPlanListOpen } from "../../apis/APIs"
import { Player } from "@lottiefiles/react-lottie-player"

export const PricingCalculator = () => {
    // const s1 = { fontSize: '32px' }
    const s2 = { fontSize: '20px', marginBottom: '4px' }
    // const s4 = { fontSize: '12px' }


    const playerRef = useRef(null)

    const s3 = { fontSize: '12px', color: 'gray' }
    const s5 = { fontSize: '10px', color: 'gray', flex: "1" }
    const s6 = { fontSize: '10px', color: 'gray' }
    const s7 = { fontSize: '20px', marginBottom: '4px' }

    let durationMinVal = 1
    let conversationMinVal = 1000
    let agentMinVal = 5
    let bicMinVal = 1000
    let bicMaxVal = 100000
    let uicMinVal = 1000
    let uicMaxVal = 100000
    let uicPrice = 0.29
    let bicPrice = 0.49

    let durationMaxVal = 12
    let conversationMaxVal = 100000
    let agentMaxVal = 20

    const [price, setPrice] = useState("00")
    const [agentPrice, setAgentPrice] = useState(0)
    const [durationVal, setDurationVal] = useState(durationMinVal)
    const [bic, setbic] = useState(1000)
    const [uic, setuic] = useState(1000)
    const [conversationVal, setConversationVal] = useState(conversationMinVal)
    const [agentVal, setAgentVal] = useState(agentMinVal)
    const [discountVal, setdiscountVal] = useState(0)
    const [active, setActive] = useState("1");
    const [activeName, setActiveName] = useState("Growth");
    const [planList, setPlanList] = useState([])
    const [isLottiePlay, setLottiePlay] = useState(true)
    const [growthAgent, setGrowthAgent] = useState(0)
    const [proAgent, setProAgent] = useState(0)
    const [enterprisehAgent, setEnterpriseAgent] = useState(0)
    const [totalcount, setotalcount] = useState(0)

    const handlePriceCalc = (key, value) => {
        console.log(key, value, activeName)
        if (key === 'duration') {
            setDurationVal(value)
            const index = planList.findIndex(obj => obj.name === activeName)
            console.log("valueIndes: ", index)
            if (value === 1) {
                setdiscountVal(planList[index].percentageM)
                setLottiePlay(true)
                playLottie()
            } else if (value === 3) {
                setdiscountVal(planList[index].percentageQ)
                setLottiePlay(true)
                playLottie()
            } else if (value === 12) {
                setdiscountVal(planList[index].percentageY)
                setLottiePlay(true)
                playLottie()
            } else if (value > 3 && value < 12) {
                setdiscountVal(planList[index].percentageQ)
                setLottiePlay(false)
            } else if (value < 3) {
                setdiscountVal(planList[index].percentageM)
                setLottiePlay(false)
            }
        }
        if (key === 'conversation') {
            setConversationVal(value)
        }
        if (key === 'agent') setAgentVal(value)
    }

    const hadlemetaPrice = (key, value) => {
        if (key === 'bic') {
            const total = uic + value
            setotalcount(total)
            setbic(value)
        }
        if (key === 'uic') {
            const total = bic + value
            setotalcount(total)
            setuic(value)
        }


    }

    // Handle Dropdown button
    const [breakdownbutton, setBreakdownbutton] = useState(true);
    const handleBreakdown = (e) => {
        console.log("brekdown==", e)
        setBreakdownbutton(!breakdownbutton)
    }

    // Handle Rupees Switch Button
    const [currentVal, setCurrencyVal] = useState(false)
    const handleCurrencyVal = (e) => {
        console.log('===', e)
        setCurrencyVal(e)
    }

    // Handle Button-Group onclick set
    const handleClick = (event, name) => {
        setActive(event.target.id);
        console.log("key: ", name)
        setActiveName(name)
        const index = planList.findIndex(obj => obj.name === name)
        setConversationVal(planList[index].messages)
        setdiscountVal(planList[index].percentageM)
        setAgentVal(planList[index].agents)
        setPrice(planList[index].price)
        if (name === "Growth") setAgentPrice(growthAgent)
        if (name === "Pro") setAgentPrice(proAgent)
        if (name === "Enterprise") setAgentPrice(enterprisehAgent)
        setDurationVal(durationMinVal)
        setConversationVal(conversationMinVal)
        setAgentVal(agentMinVal)

    }




    const getPlan = async () => {
        const response = await fetchPlanListOpen()
        if (response.status === 200) {
            response.data.data?.map((v) => {
                if (v._id === "Primary") {
                    setPlanList(v.plans)
                    const index = v.plans.findIndex(obj => obj.name === activeName)
                    console.log("index: ", v.plans + ", " + v.plans[index].price)
                    setPrice(v.plans[index].price)
                    setConversationVal(v.plans[index].messages)
                    setLottiePlay(true)
                    setdiscountVal(v.plans[index].percentageM)
                    setAgentVal(v.plans[index].agents)
                }
                if (v._id === "BuyAgent") {
                    setAgentPrice(v.plans[0].growth)
                    setEnterpriseAgent(v.plans[0].enterprise)
                    setGrowthAgent(v.plans[0].growth)
                    setProAgent(v.plans[0].pro)
                }
            })
        }
    }


    const playLottie = () => {
        playerRef.current.play()
    }

    const totalPrice = () => {
        const planPrice = parseFloat((price * durationVal) - (price * durationVal) * (discountVal / 100))
        const addonConversation = parseFloat((conversationVal - 1000) * 0.1)
        const addonAgent = parseFloat((agentVal - 5) * agentPrice)
        console.log(`Total: ${planPrice}, ${addonConversation}, ${addonAgent}`)
        const totalPrice = (planPrice + addonConversation + addonAgent)
        console.log(`TotalPrice: ${totalPrice}`)
        return totalPrice

    }

    useEffect(() => {
        getPlan()
    }, [])

    return (
        <>
            <div>
                <Container>
                    <Row>
                        <div className="fw-semibold text-center mt-5 mb-3"><p style={{ fontSize: '36px', marginBottom: '0px' }}>WTB Plan & Pricing Calculator</p>
                            <p>Use our pricing calculator to get an estimate for your plan pricing based on monthly billing.</p></div>


                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                            <Container >
                                <div className="him_BOX-SHADOW mb-5">
                                    <div className="mb-5 ms-5 me-2 pt-2 pb-3">
                                        <p style={s2} className="fw-semibold pt-3">Plans</p>

                                        <div className="btn-group" style={{ maxWidth: "100%", width: "100%" }}>
                                            {/* <button key={1} id={"1"} onClick={(e) => handleClick(e, "Free")} style={{ width: "25%", backgroundColor: `${active === "1" ? "#efffed" : "#fff"}`, fontSize: `${isMobile ? "12px" : "16px"}`, justifyContent: "center", display: "flex" }} >Free</button> */}
                                            <button key={1} id={"1"} onClick={(e) => handleClick(e, "Growth")} style={{ width: "25%", backgroundColor: `${active === "1" ? "#efffed" : "#fff"}`, fontSize: `${isMobile ? "12px" : "16px"}`, justifyContent: "center", display: "flex" }} >Growth</button>
                                            <button key={2} id={"2"} onClick={(e) => handleClick(e, "Pro")} style={{ width: "25%", backgroundColor: `${active === "2" ? "#efffed" : "#fff"}`, fontSize: `${isMobile ? "12px" : "16px"}`, justifyContent: "center", display: "flex" }} >Pro</button>
                                            <button key={3} id={"3"} onClick={(e) => handleClick(e, "Enterprise")} style={{ width: "26%", backgroundColor: `${active === "3" ? "#efffed" : "#fff"}`, fontSize: `${isMobile ? "12px" : "16px"}`, justifyContent: "center", display: "flex" }} >Enterprise</button>
                                        </div>

                                        <div key="duration" style={{ marginBottom: "20px" }}>
                                            {/* <PriceCalcSliderUI v={v} handlePriceCalc={handlePriceCalc} plan={active} index={i} /> */}
                                            <div style={{ width: '70%' }}>
                                                <p style={s7} className="fw-semibold pt-4">Duration</p>
                                                <small style={s3}>Select the time period of the plan</small>
                                                <div style={{ marginTop: "10px" }}>
                                                    <Slider
                                                        id="durationId"
                                                        onChange={(e) => handlePriceCalc("duration", e.target.value)}
                                                        valueLabelDisplay="auto"
                                                        value={durationVal}
                                                        style={{ color: "#01a82d" }}
                                                        min={durationMinVal}
                                                        max={durationMaxVal}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={s5}>1 Month (30 Days)</p>
                                                    <p style={s6}>12 Month (365 Days)</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div key="conversation" style={{ marginBottom: "20px" }}>
                                            {/* <PriceCalcSliderUI v={v} handlePriceCalc={handlePriceCalc} plan={active} index={i} /> */}
                                            <div style={{ width: '70%' }}>
                                                <p style={s7} className="fw-semibold pt-4">Conversation</p>
                                                <small style={s3}>Select the number of conversation you want. 0.10/conversation {`(Monthly Unique)`}</small>
                                                <div style={{ marginTop: "10px" }}>
                                                    <Slider
                                                        id="conversationId"
                                                        onChange={(e) => handlePriceCalc("conversation", e.target.value)}
                                                        valueLabelDisplay="auto"
                                                        value={conversationVal}
                                                        style={{ color: "#01a82d" }}
                                                        min={conversationMinVal}
                                                        max={conversationMaxVal}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={s5}>{conversationMinVal}</p>
                                                    <p style={s6}>{conversationMaxVal}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div key="agent" style={{ marginBottom: "20px" }}>
                                            {/* <PriceCalcSliderUI v={v} handlePriceCalc={handlePriceCalc} plan={active} index={i} /> */}
                                            <div style={{ width: '70%' }}>
                                                <p style={s7} className="fw-semibold pt-4">Agent</p>
                                                <small style={s3}>Select the number of Agents you want. 300/Agent</small>
                                                <div style={{ marginTop: "10px" }}>
                                                    <Slider
                                                        id="agentId"
                                                        onChange={(e) => handlePriceCalc("agent", e.target.value)}
                                                        valueLabelDisplay="auto"
                                                        value={agentVal}
                                                        style={{ color: "#01a82d" }}
                                                        min={agentMinVal}
                                                        max={agentMaxVal}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={s5}>{agentMinVal}</p>
                                                    <p style={s6}>{agentMaxVal}</p>
                                                </div>
                                            </div>
                                        </div>



                                        {
                                            // priceUICalcSchema?.map((v, i) => {
                                            //     return (

                                            //     )
                                            // })
                                        }

                                    </div>
                                </div>

                                <div className="him_BOX-SHADOW mb-5">
                                    <div className="mb-5 ms-5 me-2 pt-3 pb-3">
                                        <h5>Charged by WhatsApp</h5>

                                        <div style={{ display: 'flex', marginTop: '10px' }}>
                                            <div style={{ flex: '1' }}><strong>Country :</strong></div>
                                            <select style={{ width: '10rem', marginRight: '3rem' }}>
                                                <option>India</option>
                                            </select>
                                        </div>

                                        <div>
                                            <p><strong>Total Count:{" "}</strong><strong style={{color:"#0669df"}}>{totalcount}</strong></p>
                                        </div>

                                        <div key="Bic" style={{ marginBottom: "20px" }}>
                                            {/* <PriceCalcSliderUI v={v} handlePriceCalc={handlePriceCalc} plan={active} index={i} /> */}
                                            <div style={{ width: '70%' }}>
                                                <p style={s7} className="fw-semibold pt-4">Business Initiated Conversation {" (BIC) "}</p>
                                                <small style={s3}>Select the number of expected BIC conversation</small>
                                                <div style={{ marginTop: "10px" }}>
                                                    <Slider
                                                        id="durationId"
                                                        onChange={(e) => hadlemetaPrice("bic", e.target.value)}
                                                        valueLabelDisplay="auto"
                                                        value={bic}
                                                        style={{ color: "#0669df" }}
                                                        min={bicMinVal}
                                                        max={bicMaxVal}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={s5}>{bicMinVal}</p>
                                                    <p style={s6}>{bicMaxVal}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div key="uic" style={{ marginBottom: "20px" }}>
                                            {/* <PriceCalcSliderUI v={v} handlePriceCalc={handlePriceCalc} plan={active} index={i} /> */}
                                            <div style={{ width: '70%' }}>
                                                <p style={s7} className="fw-semibold pt-4">User Initiated Conversation   {" (UIC) "}</p>
                                                <small style={s3}>Select the number of expected UIC conversation</small>
                                                <div style={{ marginTop: "10px" }}>
                                                    <Slider
                                                        id="durationId"
                                                        onChange={(e) => hadlemetaPrice("uic", e.target.value)}
                                                        valueLabelDisplay="auto"
                                                        value={uic}
                                                        style={{ color: "#0669df" }}
                                                        min={uicMinVal}
                                                        max={uicMaxVal}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={s5}>{uicMinVal}</p>
                                                    <p style={s6}>{uicMaxVal}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </Container>

                        </Col>

                        <Col className='price_view' xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                            <div>
                                <div style={{ border: '1px solid #01a82d', background: '#01a82d', paddingBottom: '40px', color: "white", justifyItems: "center", display: "flex", flexDirection: "column", borderRadius: "10px", alignItems: "center", maxWidth: '100%', width: '100%' }}>
                                    {<div style={{ position: "absolute", display: !isLottiePlay && "none" }}>
                                        <Player autoplay="true" ref={playerRef} onEvent={(e) => {
                                            if (e === 'load') playLottie()
                                            if (e === "complete") setLottiePlay(false)
                                        }} loop="false" src="https://assets4.lottiefiles.com/packages/lf20_poy1vcfr.json" className="items-lottie" />
                                    </div>}

                                    <div style={{ fontWeight: 'bold', position: 'relative', top: '0px' }} className='pt-5 pb-4'>
                                        <span >INR (₹) </span>
                                        <Switch onChange={(e) => handleCurrencyVal(e.target.checked)} color="warning" />
                                        <span>USD ($)</span>
                                    </div>
                                    <small>Estimated Cost</small>

                                    {discountVal > 0 && <p style={{ marginBottom: '5px' }}>{"🎉 "}Discount: {" " + discountVal + "% 🎊"}</p>}

                                    <div style={{ maxWidth: "60%", width: "60%", border: '1px solid #01a82d', borderRadius: "5px", background: '#ffffff', color: 'green', display: "flex", justifyContent: 'center', marginBottom: "3rem", alignItems: "center", alignContent: "center", marginTop: "5px", flexDirection: "column" }}  >
                                        <p style={{ marginBottom: "0px", fontSize: '28px', marginTop: "5px" }}> {!currentVal ? "₹" : "$"} {!currentVal ? ((price * durationVal) - (price * durationVal) * (discountVal / 100)) : (((price * durationVal) - (price * durationVal) * (discountVal / 100)) / 82).toFixed(2)} </p>
                                        <p style={{ marginBottom: "5px" }}><s>{!currentVal ? "₹" : "$"} {!currentVal ? ` ${(price * durationVal)}` : Math.floor((price * durationVal) / 82)}</s></p>
                                    </div>
                                    <small className='hand' onClick={() => handleBreakdown(!breakdownbutton)}>view price breakdown {breakdownbutton ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />} </small>
                                    {
                                        breakdownbutton &&

                                        <Grow direction='up' in={true} timeout={1000} unmountOnExit>
                                            <table style={{ width: '100%', lineHeight: 3 }} className='text-center mt-3'>
                                                <tr className="fw-semibold mt-3">
                                                    <td>Duration</td>
                                                    <td>:</td>
                                                    <td>{durationVal > 1 ? `${durationVal} Months` : `${durationVal} Month`}</td>
                                                </tr>

                                                <tr>
                                                    <td>{`${conversationVal} Conversation/Month`}</td>
                                                    <td>-</td>
                                                    <td>{`${!currentVal ? "₹" : "$"} ${!currentVal ? conversationVal > 1000 ? parseFloat((conversationVal - 1000) * 0.1).toFixed(2) : "0.00" : (((conversationVal - 1000) * 0.1) / 82).toFixed(2)}`}</td>
                                                </tr>

                                                {/* <tr>
                                                    <td>{`${broadcastVal} Broadcast`}</td>
                                                    <td>-</td>
                                                    <td>₹1,000<span style={s4}>(200/month)</span></td>
                                                </tr>

                                                <tr>
                                                    <td>{`${chatbotVal} Chatbots`}</td>
                                                    <td>-</td>
                                                    <td>₹75,00<span style={s4}>(1500/month)</span></td>
                                                </tr> */}

                                                <tr style={{ borderBottom: '1px solid white' }}>
                                                    <td>{`${agentVal} Agents/Month`}</td>
                                                    <td>-</td>
                                                    <td>{`${!currentVal ? "₹" : "$"} ${!currentVal ? agentVal > 5 ? ((agentVal - 5) * agentPrice) : "0.00" : (((agentVal - 5) * agentPrice) / 82).toFixed(2)}`}</td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Total</strong></td>
                                                    <td>-</td>
                                                    <td><strong>{`${!currentVal ? "₹" : "$"} ${!currentVal ? totalPrice().toFixed(2) : (totalPrice() / 82).toFixed(2)}`}</strong></td>
                                                </tr>

                                            </table>
                                        </Grow>
                                    }

                                </div>
                                <p style={{ fontWeight: '400', fontSize: '14px', color: 'gray' }} className='mt-3'>We have estimated your cost based on the options you have chosen. Any applicable taxes are not included, and your fees are subject to increase with additional purchases.</p>
                            </div>
                            <div>
                                <div style={{ border: '1px solid #0669df', background: '#0669df', paddingBottom: '40px', color: "white", justifyItems: "center", display: "flex", flexDirection: "column", borderRadius: "10px", alignItems: "center", maxWidth: '100%', width: '100%', marginTop: '3.5rem' }}>
                                    <p className="text-center" style={{ marginTop: '2rem', fontSize: '20px', fontWeight: 'bold' }}>Monthly <br></br>Conversation charges</p>

                                    <div style={{ maxWidth: "60%", width: "60%", border: '1px solid #0669df', borderRadius: "5px", background: '#ffffff', color: '#0669df', display: "flex", justifyContent: 'center', marginBottom: "3rem", alignItems: "center", alignContent: "center", marginTop: "5px", flexDirection: "column", height: '5rem' }}  >
                                        <p style={{ marginBottom: "0px", fontSize: '28px', marginTop: "5px" }}> {!currentVal ? "₹" : "$"} {!currentVal ? ((bic > bicMinVal ?  ((bic - bicMinVal) * bicPrice): 0) + (uic > uicMinVal ? ((uic - uicMinVal) * uicPrice) : 0)).toFixed(2) : (((bic > bicMinVal ?  ((bic - bicMinVal) * bicPrice): 0) + (uic > uicMinVal ? ((uic - uicMinVal) * uicPrice) : 0)) / 82).toFixed(2)} </p>


                                    </div>

                                    <Grow direction='up' in={true} timeout={1000} unmountOnExit>
                                        <table style={{ width: '100%', lineHeight: 3 }} className='text-center mt-3'>
                                            <tr><td>BIC{" " + bic + " X ₹ " + bicPrice}</td>
                                                <td>-</td>
                                                <td>{`${!currentVal ? "₹" : "$"} ${!currentVal ? bic > bicMinVal ? ((bic - bicMinVal) * bicPrice).toFixed(2) : "0.00" : bic > bicMinVal ? (((bic - bicMinVal) * bicPrice) / 82).toFixed(2) : "0.00"}`}</td>
                                            </tr>
                                            <tr style={{ borderBottom: '1px solid white' }}>
                                                <td>UIC{" " + uic + " X ₹ "+ uicPrice}</td>
                                                <td>-</td>
                                                <td>{`${!currentVal ? "₹" : "$"} ${!currentVal ? (uic > uicMinVal ? ((uic - 1000) * uicPrice).toFixed(2) : '0.00') : uic > uicMinVal ? (((uic - uicMinVal) * uicPrice) / 82).toFixed(2) : "0.00"}`}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Total</strong></td>
                                                <td>-</td>
                                                <td><strong>{`${!currentVal ? "₹" : "$"} ${!currentVal ? ((bic > bicMinVal ? ((bic - bicMinVal) * bicPrice) : 0) + (uic > uicMinVal ? ((uic - uicMinVal) * uicPrice) : 0)).toFixed(2) : ((((bic > bicMinVal ? (bic - bicMinVal) * bicPrice : 0) + (uic > uicMinVal ? ((uic - uicMinVal) * uicPrice) : 0))) / 82).toFixed(2)}`}</strong></td>
                                            </tr>
                                        </table>
                                    </Grow>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )

}

