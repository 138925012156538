import React from 'react'
import { Container } from 'react-bootstrap'
import { getUrl } from '../../constants/urls'

export const Maintenance = () => {
	return (
		<section style={{ background: '#a6ddda', width: '100%', height: '100%', minHeight: '100vh'}} >
			<Container fluid>
				<img src={getUrl.imageBaseUrl+'/wtb_green_round_images.webp'} style={{ width: '40px', height: '40px' }} alt='App maintenance' className='img-fluid mt-4 ms-4' />
				<p className='text-center'>
					<img src={getUrl.imageBaseUrl+'/media/maintenance_app.png'} style={{ height: '420px' }} alt='App maintenance' className='img-fluid' />
				</p>
				<div className='text-center'>
					<p className='fw-semibold' style={{ fontSize: '32px' }}>IT Team is Under Maintenance</p>
					<p style={{ fontSize: '24px', color: '#616874', fontFamily: 'roboto' }}>We are working on some major issue, we will get back to you in an hour.</p>
					<p style={{ fontSize: '20px' }}>Kindly check our website after an hour. </p>
				</div>
			</Container>
		</section>
	)
}
