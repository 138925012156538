/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import GlobalContext from './globalContext'
import { assignAgentAPI, assignAttributesAPI, assignEditAttributesAPI, assignedLabelsAPI, fetchAllAttributesAPI, fetchAllLabelsAPI, fetchAllMediaAPI, fetchAllTemplatesAPI, fetchChannelLsAPI, fetchEmployeeListAPI, fetchUserInfoAPI, removeAttributeAPI, removeLabelsAPI, templateSync } from '../../apis/APIs'

const GlobalState = (props) => {
    // Page Loading Speed handling
    const [pageLoadingStatus, setPageLoadingStatus] = useState(true)
    const handlePageLoadingStatus = (val) => setPageLoadingStatus(val)
    // End of Page Loading Speed handling



    // Loader Handling
    const [isLoading, setIsLoading] = useState(undefined)
    const handleWTLoader = (val) => setIsLoading(val)
    // End of Loader Handling
    const [params, setParams] = useState(undefined)

    // Snackbar Code
    const [openSnackbar, setOpenSnackbar] = useState({ open: false, errorMsg: '', errorMsgBg: '' })
    const snackbarShow = (errorMsg, errorMsgBg) => setOpenSnackbar({ open: true, errorMsg: errorMsg, errorMsgBg: errorMsgBg })
    const snackbarClose = () => setOpenSnackbar(false)
    // End of Snackbar Code


    const [channelLs, setChannelLs] = useState([])

    const [channelLsCount, setChannelLsCount] = useState([])
    const getChannelLs = async () => {
        try {
            const response = await fetchChannelLsAPI()
            if (response.status === 200) {
                setChannelLs(response.data?.data)
                setChannelLsCount(response.data?.data?.length)
                handleWTLoader(false)
            } else { handleWTLoader(false) }
        } catch (error) { handleWTLoader(false) }
    }


    // User Info State Management
    const [userInfo, setUserInfo] = useState([])
    const [agent, setAgent] = useState(undefined)
    const [labels, setLabels] = useState([])
    const [cusAttr, setCusAttr] = useState([])
    const getUserInfomation = async (channel, whatsapp) => {
        try {
            let data = { "extChannelId": channel, "extUserId": whatsapp }
            const response = await fetchUserInfoAPI(data)
            if (response.status === 200) {
                response.data?.data?.details ? setUserInfo(response.data?.data?.details) : setUserInfo([])
                response.data?.data?.details?.emp ? setAgent(response.data?.data?.details?.emp) : setAgent(undefined)
                response.data?.data?.details?.labels ? setLabels(response.data?.data?.details?.labels) : setLabels([])
                response.data?.data?.details?.contactField ? setCusAttr(response.data?.data?.details?.contactField) : setCusAttr([])
            }
        } catch (error) { }
    }

    const [agentLs, setAgentLs] = useState([])
    const getAgents = async () => {
        try {
            const response = await fetchEmployeeListAPI()
            if (response.status === 200) {
                setAgentLs(response.data.data)
                handleWTLoader(false)
            }
        } catch (error) { }
    }
    const assignedAgent = async (channel, whatsapp, id, chatId) => {
        try {
            let data = { 'empId': id, 'chatId': chatId, 'extUserId': whatsapp, 'extChannelId': channel }
            await assignAgentAPI(data)
            setAgent(undefined)
            await getUserInfomation(channel, whatsapp)
            // await getChats("inbox",localStorage.getItem("ch_filterInbox_key"), localStorage.getItem("ch_filterInbox_chatSkip"), 20, "fetch_more")
        } catch (error) { }
    }

    const [lableLs, setLabelLs] = useState([])
    const getLabelLs = async () => {
        try {
            const response = await fetchAllLabelsAPI()
            if (response.status === 200) {
                setLabelLs(response.data.data)
                handleWTLoader(false)
            }
        } catch (error) { }
    }
    const assignLabel = async (channel, whatsapp, id) => {
        try {
            let data = { "extChannelId": channel, "extUserId": whatsapp, "labelId": id }
            await assignedLabelsAPI(data)
            setLabels([])
            await getUserInfomation(channel, whatsapp)
        } catch (error) { }
    }
    const removeLabels = async (channel, whatsapp, id) => {
        try {

            let data = { "extChannelId": channel, "extUserId": whatsapp, "labelId": id }
            await removeLabelsAPI(data)
            setLabels([])
            await getUserInfomation(channel, whatsapp)
        } catch (error) { }
    }


    const [attributeLs, setAttributeLs] = useState([])
    const getAttributeLs = async () => {
        try {
            const response = await fetchAllAttributesAPI()
            if (response.status === 200) {
                setAttributeLs(response.data.data)
                handleWTLoader(false)
            }
        } catch (error) { }
    }
    const assignAttribute = async (channel, whatsapp, id, inputData, key) => {
        try {
            let data = {
                "extUserId": whatsapp,
                "extChannelId": channel,
                "fieldId": id,
                "inputData": inputData,
                "fieldKey": key
            }
            await assignAttributesAPI(data)
            setCusAttr([])
            await getUserInfomation(channel, whatsapp)
        } catch (error) { }
    }
    const assignEditAttribute = async (channel, whatsapp, id, inputData, key) => {
        try {
            let data = {
                "extUserId": whatsapp,
                "extChannelId": channel,
                "fieldId": id,
                "inputData": inputData,
                "fieldKey": key
            }
            await assignEditAttributesAPI(data)
            setCusAttr([])
            await getUserInfomation(channel, whatsapp)
        } catch (error) { }
    }
    const removeAttribute = async (channel, whatsapp, id, key) => {
        try {
            let data = { "extChannelId": channel, "extUserId": whatsapp, "fieldId": id, "fieldKey": key }
            await removeAttributeAPI(data)
            setCusAttr([])
            await getUserInfomation(channel, whatsapp)
        } catch (error) { }
    }
    // End of User Info State Management  




    // Offer Image
    const [offerImgVisibility, setOfferImgVisivility] = useState(true)
    const [offerImg, setOfferImg] = useState(false)
    const [offerImgUrl, setOfferImgUrl] = useState(undefined)
    // End of Offer Image



    // Template Work
    const [templateLs, setTemplateLs] = useState([])
    const getTemplatesUI = async (ch) => {
        handleWTLoader(true)
        try {
            let data = { 'extChannelId': ch }
            const response = await fetchAllTemplatesAPI(data)
            if (response.status === 200 && response.data.data.templates) {
                setTemplateLs(response.data.data.templates)
                handleWTLoader(false)
            } else { handleWTLoader(false) }
        } catch (error) { handleWTLoader(false) }
    }
    const setTemplateSync = async (ch) => {
        handleWTLoader(true)
        try {
            let data = { 'extChannelId': ch }
            const response = await templateSync(data)
            if (response.status === 200) {
                handleWTLoader(false)
                getTemplatesUI(ch)
            } else { handleWTLoader(false) }
        } catch (error) { handleWTLoader(false) }
    }
    const [templateUIView, setTemplateUIView] = useState('normal') // normal | grid

    // End of Template Work


    // Media Work
    const [mediaLs, setMediaLs] = useState([])
    const getMediaLs = async () => {
        try {
            const response = await fetchAllMediaAPI()
            if (response.status === 200) {
                setMediaLs(response.data.data)
                handleWTLoader(false)
            }
        } catch (error) { }
    }
    // End of Media Work


    // Notification Top Bar Work
    const [notificationBarStatus, setNotificationBarStatus] = useState(false)
    const handleNotificationBarStatus = (v) => setNotificationBarStatus(v)

    const [notificationBarData, setNotificationBarData] = useState(undefined)
    const handleNotificationBarData = (info) => setNotificationBarData(info)
    // End of Notification Top Bar Work


    // Plan Details
    const [activePlanName, setActivePlanName] = useState(localStorage.getItem('activePlan'))
    const handleActivePlanName = (val) => setActivePlanName(val)
    // End of Plan Details


    // Renew AuthToken
    const [renewAuthToken, setRenewAuthToken] = useState(true)
    const handleRenewAuthToken = (status) => setRenewAuthToken(status)
    // End of Renew AuthToken


    // Ecommerce
    const [ecomUI, setEcomUI] = useState('summary') // product | summary
    const handleEcomUI = (ui) => setEcomUI(ui)

    const [catalogueId, setCatalogueId] = useState('')
    const handleCatalogueId = (id) => setCatalogueId(id)

    const [catalogueRes, setCatalogueRes] = useState('')
    const handleCatalogueRes = (res) => setCatalogueRes(res)

    const [productLs, setProductLs] = useState([])
    const handleProductLs = (ls) => setProductLs(ls)
    const getProductLs = () => { }

    const [cartLs, setCartLs] = useState([])
    const addToCart = (itemObj) => {
        if (itemObj) {
            let data = [...cartLs, itemObj]
            setCartLs(data)
        }
    }
    const removeFromCart = (id) => {
        var data = [...cartLs]
        data.splice(data.findIndex(function (i) { return i.id === id }), 1)
        setCartLs(data)
    }

    const getProductQtyCount = (id) => { return cartLs.filter(item => item.id === id).length || 0 }
    const getTotalCartCount = () => { return cartLs?.length }

    const getCartLs = () => {
        return cartLs.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
    }



    // End of Ecommerce
    return (
        <GlobalContext.Provider value={{
            pageLoadingStatus, handlePageLoadingStatus,
            isLoading, handleWTLoader,
            openSnackbar, snackbarShow, snackbarClose,
            channelLs, channelLsCount, getChannelLs, setChannelLs,
            userInfo, setUserInfo, getUserInfomation,
            agentLs, getAgents, assignedAgent, agent,
            getLabelLs, lableLs, assignLabel, labels, removeLabels,
            getAttributeLs, attributeLs, assignAttribute, assignEditAttribute, cusAttr, removeAttribute,
            offerImgVisibility, setOfferImgVisivility, offerImg, setOfferImg, offerImgUrl, setOfferImgUrl,
            templateLs, setTemplateLs, getTemplatesUI, setTemplateSync, templateUIView, setTemplateUIView,
            mediaLs, getMediaLs,
            notificationBarStatus, handleNotificationBarStatus,
            activePlanName, handleActivePlanName,
            notificationBarData, handleNotificationBarData,

            renewAuthToken, handleRenewAuthToken,

            // Ecommerce
            ecomUI, handleEcomUI,
            catalogueId, handleCatalogueId,
            catalogueRes, handleCatalogueRes,
            getProductLs, productLs, handleProductLs,
            cartLs, setCartLs, addToCart, removeFromCart,
            getProductQtyCount, getTotalCartCount,
            getCartLs,
            // End of Ecommerce
            params, setParams
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}
export default GlobalState