/* eslint-disable no-unused-vars */
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, styled, tooltipClasses, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Close } from "@mui/icons-material"
import { Container } from "@mui/system"
import { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import QRCode from "react-qr-code"
import html2canvas from "html2canvas"
import './WhatsappLink.css'
import axios from "axios"
import { Env } from "../../controller/constant"
import { WtLoader } from "../../controller/BaseTool"
import { getColour } from "../../constants/colours"
import SearchIcon from '@mui/icons-material/Search';
import { isDesktop, isMobile } from "react-device-detect"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import { getUrl } from "../../constants/urls"
import { Link } from "react-router-dom"



export const UrlShortner = () => {

  const params = new URL(window.location.href);
  console.log("UrlShortner", params)
  const path = params.pathname.split("/")
  console.log("newUrl", path)
  if (path[1] === "tiny") {
    const id = path[2]
    console.log("id", id)
    sendShortId(id)
  }

  async function sendShortId(id) {
    try {
      const url = `${Env.baseApiCallURL}/wame/${id}`
      console.log('url', url)
      window.location.replace(url)
    } catch (error) {
      console.log("redirect: ", error)
    }
  }
  return (
    <></>
  )
}

export const WhatsappLinkGenerator = () => {
  return (
    <section>
      <WhatsappLinkGenerators />
      <BoostingLinkEngagement />
      <WhatsAppChatLink />
      <WhatsAppLinkFAQs />
    </section>
  )
}

export const WhatsappLinkGenerators = () => {
  const [mobileNumber, setMobileNumber] = useState("+91")
  const [customeMessage, setCustomeMessage] = useState("")
  const [ismsgshow, setismsgShow] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [forWardisHover, setForWardIsHover] = useState(false)
  const [chatIsHover, setChatIsHover] = useState(false)
  const [open, setOpen] = useState(false)
  const [qrValue, setQrValue] = useState("")
  const [errorText, setError] = useState("")
  const [randomID, setRandomID] = useState("")
  var QRCodeRandom = Math.random().toString(18).slice(8)



  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const handleCustomeMsg = (e) => {
    setCustomeMessage(e.target.value)
    setismsgShow(false)
  }

  const handleClickToOpen = () => {
    setOpen(true)
  }

  const handleToClose = () => {
    setOpen(false)
  }

  const copyToClipboard = (evt) => {
    navigator.clipboard.writeText(evt)
    setopenToolTip(true)
  }
  const [isShowProgress, setIsShowProgress] = useState(false)
  const downloadQR = () => {
    const input = document.getElementById('qr_code_div')
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        // const pdf = new jsPDF()
        // pdf.addImage(imgData, 'PNG', 0, 0)
        // pdf.save("downloadQrCode.pdf")
        var link = document.createElement("a")
        link.download = "whatsappQrCode.png"
        link.href = "data:" + imgData
        link.click()
      })
  }

  const [isMobileRequire, setIsMobileRequire] = useState(false)
  const handleGenerateQr = (element) => {

    setRandomID(QRCodeRandom)
    console.log("handleGenerateQr: ", mobileNumber)
    if (mobileNumber && mobileNumber !== "+91" | !isMobileRequire) {
      const urlencodedText = encodeURIComponent(customeMessage)
      var link = isMobileRequire ?
        `https://api.whatsapp.com/send?phone=${mobileNumber}&text=${urlencodedText}` :
        `https://api.whatsapp.com/send/?text=${urlencodedText}`

      linkShortner(link)
    } else {
      setError("Check your phone number (maybe it's wrong) and try again.")
      document.getElementById("")
    }
  }

  async function linkShortner(link) {
    setIsShowProgress(true)
    try {
      const payload = { longURL: link }
      const response = await axios.post(Env.baseApiCallURL + "/wame/generate", payload)
      if (response.status === 200) {
        console.log("linkShortner", response)
        const short_link = response.data.data.shorten_url
        setQrValue(short_link)
        handleClickToOpen()
        setIsShowProgress(false)
      }
    } catch (error) {
      setIsShowProgress(false)
    }
  }
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const [openToolTip, setopenToolTip] = useState(false);
  const handleClose = () => {
    setopenToolTip(false);
  };


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(customeMessage)
      setismsgShow(true)
      // Send Axios request here
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [customeMessage])

  return (
    <section style={{ marginTop: "40px" }} className="wtd mb-5">
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px",
            paddingBottom: "40px",
            backgroundColor: "#00a82d",
            borderRadius: '12px'
          }}
        >
          <div
            style={{ flexDirection: "column", flex: 1, textAlign: "center" }}
          >
            <p style={{ color: "white", marginBottom: "5px" }}>
              <strong>GROW YOUR BUSINESS WITH WHATSTOOL BUSINESS</strong>
            </p>
            <div style={{ marginTop: "0px", color: "white" }} className="h1">
              Free WhatsApp
              {/* <span>
                <img
                  src="https://storage.googleapis.com/wt_resources/wtb_media/whatsapp-circle.png"
                  alt="whatstool img"
                  style={{
                    height: "36px",
                    width: "36px",
                    display: "inline-block",
                    marginBottom: "3px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
              </span> */}
              &nbsp;Link {<br></br>}
              Generator
            </div>
          </div>
        </div>

        <div style={{ marginTop: "70px" }}>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "50px",
                }}
              >
                <div>
                  <h5>
                    Create your free WhatsApp link here
                  </h5>
                  <Card
                    style={{
                      marginTop: "40px",
                      maxWidth: "25rem",
                      padding: "15px 12px 15px 12px",
                    }}
                  >
                    <div>
                      <p style={{ marginBottom: "8px", marginTop: "20px" }}>
                        <strong>Enter Your Whatsapp number</strong>
                      </p>
                      <input style={{ padding: "7px", width: '100%' }}
                        id="numberInputText"
                        type="number"
                        className="removespinner"
                        placeholder="Your phone number with country code"
                        onChange={(e) => {
                          setError("")
                          setMobileNumber(e.target.value)
                        }}
                      />
                      <p
                        style={{
                          fontSize: "14px",
                          color: errorText ? "red" : "black",
                        }}
                      >
                        {errorText
                          ? errorText
                          : "Note: enter number with country code"}
                      </p>
                      <p style={{ marginBottom: "8px", marginTop: "20px" }}>
                        <strong>Add Your Custom Message</strong>
                      </p>
                      <textarea
                        id="messageInputText"
                        type="text"
                        placeholder="Add custom message that user send to you"
                        style={{
                          padding: "7px",
                          width: "100%",
                          height: "90px",
                        }}
                        onChange={(e) => {
                          handleCustomeMsg(e)
                        }}
                      />
                      <p style={{ fontSize: "14px" }}>
                        Example: “Hello, I am interested to know more about your
                        business”.
                      </p>
                      <div style={{ marginTop: "20px" }}>
                        <Card
                          style={{
                            padding: "8px 6px 8px 6px",
                            display: "flex",
                            color: isHover ? "#fff" : "#acacac",
                            backgroundColor: isHover ? "#00a82d" : "#fff",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          role="button"
                          onClick={() => {
                            if (mobileNumber.length > 3) {
                              var link = `https://api.whatsapp.com/send?phone=${mobileNumber}&text=${customeMessage}`;
                              window.open(link);
                            } else {
                              setError("Check your phone number (maybe it's wrong) and try again.")

                            }
                          }}
                        >
                          Preview
                        </Card>
                      </div>

                      <Card id="forwardLink" role="button" disabled={isShowProgress} style={{
                        width: "100%",
                        color: forWardisHover ? "#fff" : "#acacac",
                        background: forWardisHover ? "#00a82d" : "#fff",
                        marginBottom: "0px",
                        marginTop: "5px",
                        outline: 'none',
                        padding: "10px",
                        borderRadius: "8px",
                        textAlign: "center",
                        fontWeight: "400",
                      }} onMouseEnter={() => {
                        setForWardIsHover(true)
                        setIsMobileRequire(false)
                      }} onMouseLeave={() => { setForWardIsHover(false) }} onClick={() => {
                        setIsMobileRequire(false)
                        handleGenerateQr('forwardLink')
                      }}>Generate Forward Link</Card>
                      <Card id="chatLink" disabled={isShowProgress} role="button"
                        style={{
                          width: "100%",
                          color: chatIsHover ? "#fff" : "#acacac",
                          background: chatIsHover ? "#00a82d" : "#fff",
                          marginBottom: "20px",
                          marginTop: "5px",
                          padding: "10px",
                          borderRadius: "8px",
                          textAlign: "center",
                          fontWeight: "400",
                        }} onMouseEnter={() => {
                          setChatIsHover(true)
                          setIsMobileRequire(true)
                        }} onMouseLeave={() => { setChatIsHover(false) }}
                        onClick={() => {
                          setIsMobileRequire(true)
                          handleGenerateQr('chatLink')
                        }}
                      >
                        Generate Chat Link
                      </Card>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="text-center">
                This is how your message will be displayed
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  class="border-steel-light"
                  style={{
                    minWidth: "18rem",
                    width: "20rem",
                    height: "560px",
                    paddingTop: "2.5rem",
                    paddingLeft: "0.75rem",
                    paddingBottom: "2.5rem",
                    paddingRight: "0.75rem",
                    borderRadius: "12px",
                    border: "solid",
                    background: "#272727",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      backgroundImage:
                        `url("https://assets.gallabox.com/gb-home/wa-background.png")`,
                      backgroundSize: "100%",
                    }}
                  >
                    <div style={{ display: "flex", height: "2.9rem", alignItems: "center", paddingLeft: 2.5, background: "#128c7e" }}>
                      <img
                        src="https://gallabox.com/emptyprofilpic.svg"
                        style={{
                          height: "2.5rem",
                          width: "2.5rem",
                          borderRadius: "1.25rem",
                        }}
                        alt="whatsapp-link-generator"
                      />
                      <div class="text-white" style={{ paddingLeft: 5 }}>
                        {mobileNumber}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {customeMessage && ismsgshow &&
                          (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                width: "100%",
                                paddingRight: "10px",
                              }}
                            >
                              <p
                                style={{
                                  backgroundColor: "#dcf8c6",
                                  padding: "8px",
                                  borderRadius: "12px",
                                  maxWidth: "13rem",
                                  wordBreak: "break-all",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {customeMessage}
                              </p>
                            </div>
                          )}
                        <div
                          class="flex gap-x-2.5 h-12 p-2 w-full"
                          style={{
                            display: "flex",
                            gap: "10px",
                            height: "48px",
                            padding: 2,
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              background: "#fff",
                              width: "83.333333%",
                              borderRadius: "12px",
                              height: "91.666667%",
                            }}
                          >
                            <p
                              class="px-2 py-1 my-0 mx-3.5"
                              style={{
                                color: "black",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                fontSize: "0.75rem",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {!ismsgshow && customeMessage}
                            </p>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#128c7e",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "white",
                              borderRadius: "16px",
                              height: "32px",
                              width: "32px",
                              boxShadow: "revert",
                            }}
                          >
                            <i class="flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{ marginBottom: "3px" }}
                              >
                                <path
                                  fill="currentColor"
                                  d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                                >
                                </path>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={() => {
              handleToClose()
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Close
                role="button"
                style={{ margin: "8px" }}
                onClick={() => {
                  handleToClose()
                }}
              />
            </div>
            <DialogTitle>
              <h3>
                <strong>
                  Your WhatsApp link and QR code has been generated!
                </strong>
              </h3>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>
                  Copy it and share it via emails, messaging, your website or
                  even social media. Your desired users can contact you in just
                  one click. Download the QR code to share it anywhere.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <a
                    lass="text-xs text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                    href={qrValue}
                  >
                    {qrValue}
                  </a>
                </div>
                {qrValue &&
                  (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        id="qr_code_div"
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 180,
                          width: "100%",
                          padding: "12px"
                        }}
                      >
                        <QRCode
                          id="qr-code"
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={qrValue}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                  )}
              </DialogContentText>
            </DialogContent>
            <div
              style={{
                backgroundColor: "gray",
                height: "0.5px",
                margin: "0px 20px 0px 20px",
              }}
            >
            </div>
            <DialogActions style={{ margin: "5px 0px 5px 0px" }}>

              {/* <div className="ripple-btn"></div> */}

              <BootstrapTooltip
                open={openToolTip}
                onClose={handleClose}
                title={"Copied"}
                leaveDelay={500}
                placement="top"

              >
                <button id="copyLinkBtn" className="btns"
                  onClick={() => {
                    copyToClipboard(qrValue)

                  }}>
                  Copy Link
                </button>
              </BootstrapTooltip>


              <button type="button"
                className="btns"
                onClick={() => {
                  downloadQR()
                }}>Download QR</button>

            </DialogActions>
          </Dialog>
        </div>
        {isShowProgress && <WtLoader open={isShowProgress} />}
      </Container>
    </section>
  )
}

export const BoostingLinkEngagement = () => {
  const BoostingEngagement = [
    { title: 'Why Link Codes?', desc: 'Customers contact you via Click to Chat WhatsApp link codes on your website and landing pages - they do not need to add your phone number to their contacts.' },
    { title: 'How these Link codes work?', desc: 'This free tool works for all your WhatsApp business numbers by generating wa.me WhatsApp Click to Chat links in the form of link codes.' },
    { title: 'Using the Link generator', desc: 'Create WhatsApp Click to Chat link codes containing your number and a welcome message so you can set the tone of the conversation.' }
  ]
  return (
    <section className="mb-5">
      <Container>
        <div className="text-center">
          <h2 className="fw-semibold pt-4">Boosting engagement with WhatsApp</h2>
          <h6 className="fw-semibold pt-4" style={{ color: getColour.HeadingTextColour }}>Click to Chat Link</h6>
        </div>
        <div style={{ padding: '50px' }}>
          <Row>
            {
              BoostingEngagement?.map((v, i) => {
                return (
                  <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                    <div style={{ padding: '30px 8px 18px 20px', minHeight: '229px', border: '2px solid' + getColour.DescriptionTextColour, borderRadius: '8px' }} className="bg-white mb-4">

                      <h5 className="fw-semibold text-center pb-2">{v.title}</h5>

                      <p className="ms-3" style={{ color: getColour.DescriptionTextColour }}>{v.desc}</p>

                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </div>
      </Container>
    </section>
  )
}

export const WhatsAppChatLink = () => {
  return (
    <section>
      <Container>
        <h2 className="text-center mb-5" style={{ color: getColour.DescriptionTextColour }}>Create WhatsApp link in 3 easy steps with WhatsApp Link Generator</h2>
        <div className="pt-4">
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section>
                <div>
                  <img src={getUrl.templateimgBaseUrl + '/QR_icon_01.svg'} alt="whatsapp-link-generator" className="img-fluid float-end" />
                </div>
                <section style={{ boxShadow: getColour.BoxShadow, borderRadius: '8px', padding: '35px 15px 35px 15px' }} className="text-center">
                  <img src={getUrl.templateimgBaseUrl + '/Qr_code_01.png'} style={{ width: '300px' }} alt="whatsapp-link-generator" className="img-fluid" />
                </section>
              </section>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section className="ms-4">
                <h4 style={{ marginTop: '30px' }}><KeyboardBackspaceIcon className="me-3" />Step #1</h4>
                <p style={{ paddingTop: '5px' }}>Enter your country code without the + symbol and your WhatsApp Business number.</p>
              </section>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '75px' }}>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section>
                <h4 style={{ marginTop: '30px' }}>Step #2<EastIcon className="ms-3" /></h4>
                <p style={{ paddingTop: '5px' }}>You can add a pre-filled welcome message if you wish.</p>
              </section>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section>
                <div>
                  <img src={getUrl.templateimgBaseUrl + '/QR_icon_02.svg'} alt="whatsapp-link-generator" className="img-fluid float-end" />
                </div>
                <section style={{ boxShadow: getColour.BoxShadow, borderRadius: '8px', padding: '35px 15px 35px 15px' }} className="text-center">
                  <img src={getUrl.templateimgBaseUrl + '/Qr_code_02.png'} style={{ width: '300px' }} alt="whatsapp-link-generator" className="img-fluid" />
                </section>
              </section>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '75px' }}>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section>
                <div>
                  <img src={getUrl.templateimgBaseUrl + '/QR_icon_03.svg'} alt="whatsapp-link-generator" className="img-fluid float-end" />
                </div>
                <section style={{ boxShadow: getColour.BoxShadow, borderRadius: '8px', padding: '35px 15px 35px 15px' }} className="text-center">
                  <img src={getUrl.templateimgBaseUrl + '/Qr_code_03.png'} style={{ width: '300px' }} alt="whatsapp-link-generator" className="img-fluid" />
                </section>
              </section>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section className="ms-4">
                <h4 style={{ marginTop: '45px' }}><KeyboardBackspaceIcon className="me-3" />Step #3</h4>
                <p style={{ paddingTop: '5px' }}>Finally, click on the ‘Generate WhatsApp Chat Link’ button. It’s that easy!</p>
              </section>
            </Col>
          </Row>
        </div>
        <div style={{ display: 'flex', padding: '20px', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
          <div className="mt-5 ">
            <Link to={getUrl.whastsappchatbuttonUrl}><button style={{ color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '8px 25px', fontSize: '14px' }} className="btn btn-sm btngallery">WhatsApp Chat Widget</button></Link>
            <Link to={getUrl.WhatstoolTemplatesUrl}><button style={{ color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '8px 25px', fontSize: '14px', margin: isMobile ? '12px 0' : '0 14px' }} className="btn btn-sm btngallery">WhatsApp Message Templates</button></Link>
            <button onClick={() => window.open(getUrl.wpCasestudyUrl, '_self')} style={{ color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '8px 25px', fontSize: '14px' }} className="btn btn-sm btngallery">Case Study</button>
            <Link to={getUrl.featuredTemplatesUrl}><button style={{ color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '8px 25px', fontSize: '14px', margin: isMobile ? '12px 0' : '0 14px' }} className="btn btn-sm btngallery">WhatsApp Chatbot Templates</button></Link>
            <button onClick={() => window.open(getUrl.wpWhatsappapiUrl, '_self')} style={{ color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '8px 30px', fontSize: '14px' }} className="btn btn-sm btngallery">WhatsApp API</button>
          </div>
        </div>
      </Container>
    </section>
  )
}

export const WhatsAppLinkFAQs = () => {

  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const FAQ_Data_01 = [
    { "t": "How do I create a Link for WhatsApp?", "a": "In short, to create a WhatsApp QR Code: Copy and paste this link https://wa.me/<number> onto the QR Code generator above and replace <number> with your full phone number in international format. Omit any zeroes, brackets, or dashes when adding the phone number in international format." },
    { "t": "How do I link a Link to WhatsApp?", "a": "You can scan a WhatsApp QR code to add a friend or family member's contact information to WhatsApp. Tap More options > Settings. Tap the QR Code displayed next to your name. Tap Scan code." },
    { "t": "Can I create my own Link?", "a": "Absolutely. The Adobe Express app is quick and easy to use on smartphones and other mobile devices. Simply open the QR code generator, add your desired link, choose a style and color, download the file, and you're done. With Adobe Express, there's no need to create an account or sign in before you start." },
    { "t": "What is WhatsApp Link used for?", "a": "Your friends and family can add you as a contact on WhatsApp by scanning your WhatsApp QR code. Your QR code won't expire unless you reset it or delete your WhatsApp account. Only share your WhatsApp QR code with trusted individuals." }
  ]

  // Search Handling

  const [searchLs, setSearchLs] = useState(FAQ_Data_01)
  const handleSearchLs = (sv) => {
    const filteredData = FAQ_Data_01?.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
    setSearchLs(filteredData)
  }
  // End of Search Handling
  return (
    <section style={{ margin: '80px 0' }}>
      <Container>
        <div>
          <div className="text-center">
            <p className="fw-semibold" style={{ fontSize: '32px' }}>Frequently Asked Questions.</p>
            <p>Here are some frequently asked questions</p>
          </div>
          <center>
            <div style={{ display: 'flex', border: '1px solid #B8BDCC', borderRadius: '5px', padding: '0 15px', width: isDesktop ? '65%' : 'auto', height: '12mm', margin: '55px 0' }}>
              <span style={{ padding: '8px' }}><SearchIcon /></span>
              <input type="search" onChange={(e) => handleSearchLs(e.target.value)} placeholder="Type your question here" style={{ border: 'none', outline: 'none', width: '100%' }} />
            </div>
          </center>
          {
            searchLs?.map((v, i) => {
              return (
                <Accordion style={{ border: "none", boxShadow: "none" }} expanded={expanded === 'panel-l-' + i} onChange={handleChange('panel-l-' + i)} key={i}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon className="bRound" style={{ color: getColour.BaseColor, background: getColour.BorderLineColour, }} />}>
                    <Typography className="fw-bold" sx={{ fontSize: "17px", width: '90%' }}>{v.t}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography dangerouslySetInnerHTML={{ __html: v.a }} style={{ fontSize: '16px', color: getColour.DescriptionTextColour }}></Typography>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </div>
      </Container>
    </section >
  )
}

export const WhatsappQRGenerator = () => {
  return (
    <section>
      <WhatsappQRGenerators />
      <BoostingEngagement />
      <WhatsAppChatQR />
      <WhatsAppQRFAQs />
    </section>
  )
}

export const WhatsappQRGenerators = () => {
  const [mobileNumber, setMobileNumber] = useState("+91")
  const [customeMessage, setCustomeMessage] = useState("")
  const [ismsgshow, setismsgShow] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [forWardisHover, setForWardIsHover] = useState(false)
  const [chatIsHover, setChatIsHover] = useState(false)
  const [open, setOpen] = useState(false)
  const [qrValue, setQrValue] = useState("")
  const [errorText, setError] = useState("")
  const [randomID, setRandomID] = useState("")
  var QRCodeRandom = Math.random().toString(18).slice(8)



  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const handleCustomeMsg = (e) => {
    setCustomeMessage(e.target.value)
    setismsgShow(false)
  }

  const handleClickToOpen = () => {
    setOpen(true)
  }

  const handleToClose = () => {
    setOpen(false)
  }

  const copyToClipboard = (evt) => {
    navigator.clipboard.writeText(evt)
    setopenToolTip(true)
  }
  const [isShowProgress, setIsShowProgress] = useState(false)
  const downloadQR = () => {
    const input = document.getElementById('qr_code_div')
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        // const pdf = new jsPDF()
        // pdf.addImage(imgData, 'PNG', 0, 0)
        // pdf.save("downloadQrCode.pdf")
        var link = document.createElement("a")
        link.download = "whatsappQrCode.png"
        link.href = "data:" + imgData
        link.click()
      })
  }

  const [isMobileRequire, setIsMobileRequire] = useState(false)
  const handleGenerateQr = (element) => {

    setRandomID(QRCodeRandom)
    console.log("handleGenerateQr: ", mobileNumber)
    if (mobileNumber && mobileNumber !== "+91" | !isMobileRequire) {
      const urlencodedText = encodeURIComponent(customeMessage)
      var link = isMobileRequire ?
        `https://api.whatsapp.com/send?phone=${mobileNumber}&text=${urlencodedText}` :
        `https://api.whatsapp.com/send/?text=${urlencodedText}&type=custom_url&app_absent=0`

      linkShortner(link)
    } else {
      setError("Check your phone number (maybe it's wrong) and try again.")
      document.getElementById("")
    }
  }

  async function linkShortner(link) {
    setIsShowProgress(true)
    try {
      const payload = { longURL: link }
      const response = await axios.post(Env.baseApiCallURL + "/wame/generate", payload)
      if (response.status === 200) {
        console.log("linkShortner", response)
        const short_link = response.data.data.shorten_url
        setQrValue(short_link)
        handleClickToOpen()
        setIsShowProgress(false)
      }
    } catch (error) {
      setIsShowProgress(false)
    }
  }
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const [openToolTip, setopenToolTip] = useState(false);
  const handleClose = () => {
    setopenToolTip(false);
  };


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(customeMessage)
      setismsgShow(true)
      // Send Axios request here
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [customeMessage])

  return (
    <section style={{ marginTop: "40px" }} className="wtd mb-5">
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px",
            paddingBottom: "40px",
            backgroundColor: "#00a82d",
            borderRadius: '12px'
          }}
        >
          <div
            style={{ flexDirection: "column", flex: 1, textAlign: "center" }}>
            <p style={{ color: "white", marginBottom: "5px" }}>
              <strong>GROW YOUR BUSINESS WITH WHATSTOOL BUSINESS</strong>
            </p>
            <div style={{ marginTop: "0px", color: "white" }} className="h1">
              Free WhatsApp
              <span>
                <img
                  src="https://storage.googleapis.com/wt_resources/wtb_media/whatsapp-circle.png"
                  alt="whatsapp-link-generator"
                  style={{
                    height: "36px",
                    width: "36px",
                    display: "inline-block",
                    marginBottom: "3px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
              </span>
              QR {<br></br>}
              Generator
            </div>
          </div>
        </div>

        <div style={{ marginTop: "70px" }}>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "50px",
                }}
              >
                <div>
                  <h5>
                    Create your free WhatsApp QR Here
                  </h5>
                  <Card
                    style={{
                      marginTop: "40px",
                      maxWidth: "25rem",
                      padding: "15px 12px 15px 12px",
                    }}
                  >
                    <div>
                      <p style={{ marginBottom: "8px", marginTop: "20px" }}>
                        <strong>Enter Your Whatsapp number</strong>
                      </p>
                      <input style={{ padding: "7px", width: '100%' }}
                        id="numberInputText"
                        type="number"
                        className="removespinner"
                        placeholder="Your phone number with country code"
                        onChange={(e) => {
                          setError("")
                          setMobileNumber(e.target.value)
                        }}
                      />
                      <p
                        style={{
                          fontSize: "14px",
                          color: errorText ? "red" : "black",
                        }}
                      >
                        {errorText
                          ? errorText
                          : "Note: enter number with country code"}
                      </p>
                      <p style={{ marginBottom: "8px", marginTop: "20px" }}>
                        <strong>Add Your Custom Message</strong>
                      </p>
                      <textarea
                        id="messageInputText"
                        type="text"
                        placeholder="Add custom message that user send to you"
                        style={{
                          padding: "7px",
                          width: "100%",
                          height: "90px",
                        }}
                        onChange={(e) => {
                          handleCustomeMsg(e)
                        }}
                      />
                      <p style={{ fontSize: "14px" }}>
                        Example: “Hello, I am interested to know more about your business”.</p>
                      <div style={{ marginTop: "20px" }}>
                        <Card
                          style={{
                            padding: "8px 6px 8px 6px",
                            display: "flex",
                            color: isHover ? "#fff" : "#acacac",
                            backgroundColor: isHover ? "#00a82d" : "#fff",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          role="button"
                          onClick={() => {
                            if (mobileNumber.length > 3) {
                              var link = `https://api.whatsapp.com/send?phone=${mobileNumber}&text=${customeMessage}`;
                              window.open(link);
                            } else {
                              setError("Check your phone number (maybe it's wrong) and try again.")

                            }
                          }}
                        >
                          Preview
                        </Card>
                      </div>

                      <Card id="forwardLink" role="button" disabled={isShowProgress} style={{
                        width: "100%",
                        color: forWardisHover ? "#fff" : "#acacac",
                        background: forWardisHover ? "#00a82d" : "#fff",
                        marginBottom: "0px",
                        marginTop: "5px",
                        outline: 'none',
                        padding: "10px",
                        borderRadius: "8px",
                        textAlign: "center",
                        fontWeight: "400",
                      }} onMouseEnter={() => {
                        setForWardIsHover(true)
                        setIsMobileRequire(false)
                      }} onMouseLeave={() => { setForWardIsHover(false) }} onClick={() => {
                        setIsMobileRequire(false)
                        handleGenerateQr('forwardLink')
                      }}>Generate Forward QR</Card>
                      <Card id="chatLink" disabled={isShowProgress} role="button"
                        style={{
                          width: "100%",
                          color: chatIsHover ? "#fff" : "#acacac",
                          background: chatIsHover ? "#00a82d" : "#fff",
                          marginBottom: "20px",
                          marginTop: "5px",
                          padding: "10px",
                          borderRadius: "8px",
                          textAlign: "center",
                          fontWeight: "400",
                        }} onMouseEnter={() => {
                          setChatIsHover(true)
                          setIsMobileRequire(true)
                        }} onMouseLeave={() => { setChatIsHover(false) }}
                        onClick={() => {
                          setIsMobileRequire(true)
                          handleGenerateQr('chatLink')
                        }}
                      >
                        Generate Chat QR
                      </Card>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="text-center">
                This is how your message will be displayed
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  class="border-steel-light"
                  style={{
                    minWidth: "18rem",
                    width: "20rem",
                    height: "560px",
                    paddingTop: "2.5rem",
                    paddingLeft: "0.75rem",
                    paddingBottom: "2.5rem",
                    paddingRight: "0.75rem",
                    borderRadius: "12px",
                    border: "solid",
                    background: "#272727",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      backgroundImage:
                        `url("https://assets.gallabox.com/gb-home/wa-background.png")`,
                      backgroundSize: "100%",
                    }}
                  >
                    <div style={{ display: "flex", height: "2.9rem", alignItems: "center", paddingLeft: 2.5, background: "#128c7e" }}>
                      <img
                        src="https://gallabox.com/emptyprofilpic.svg"
                        style={{
                          height: "2.5rem",
                          width: "2.5rem",
                          borderRadius: "1.25rem",
                        }}
                        alt="whatsapp-link-generator"
                      />
                      <div class="text-white" style={{ paddingLeft: 5 }}>
                        {mobileNumber}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {customeMessage && ismsgshow &&
                          (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                width: "100%",
                                paddingRight: "10px",
                              }}
                            >
                              <p
                                style={{
                                  backgroundColor: "#dcf8c6",
                                  padding: "8px",
                                  borderRadius: "12px",
                                  maxWidth: "13rem",
                                  wordBreak: "break-all",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {customeMessage}
                              </p>
                            </div>
                          )}
                        <div
                          class="flex gap-x-2.5 h-12 p-2 w-full"
                          style={{
                            display: "flex",
                            gap: "10px",
                            height: "48px",
                            padding: 2,
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              background: "#fff",
                              width: "83.333333%",
                              borderRadius: "12px",
                              height: "91.666667%",
                            }}
                          >
                            <p
                              class="px-2 py-1 my-0 mx-3.5"
                              style={{
                                color: "black",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                fontSize: "0.75rem",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {!ismsgshow && customeMessage}
                            </p>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#128c7e",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "white",
                              borderRadius: "16px",
                              height: "32px",
                              width: "32px",
                              boxShadow: "revert",
                            }}
                          >
                            <i class="flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{ marginBottom: "3px" }}
                              >
                                <path
                                  fill="currentColor"
                                  d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                                >
                                </path>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={() => {
              handleToClose()
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Close
                role="button"
                style={{ margin: "8px" }}
                onClick={() => {
                  handleToClose()
                }}
              />
            </div>
            <DialogTitle>
              <h3>
                <strong>
                  Your WhatsApp link and QR code has been generated!
                </strong>
              </h3>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>
                  Copy it and share it via emails, messaging, your website or
                  even social media. Your desired users can contact you in just
                  one click. Download the QR code to share it anywhere.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <a
                    lass="text-xs text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                    href={qrValue}
                  >
                    {qrValue}
                  </a>
                </div>
                {qrValue &&
                  (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        id="qr_code_div"
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 180,
                          width: "100%",
                          padding: "12px"
                        }}
                      >
                        <QRCode
                          id="qr-code"
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={qrValue}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                  )}
              </DialogContentText>
            </DialogContent>
            <div
              style={{
                backgroundColor: "gray",
                height: "0.5px",
                margin: "0px 20px 0px 20px",
              }}
            >
            </div>
            <DialogActions style={{ margin: "5px 0px 5px 0px" }}>

              {/* <div className="ripple-btn"></div> */}

              <BootstrapTooltip
                open={openToolTip}
                onClose={handleClose}
                title={"Copied"}
                leaveDelay={500}
                placement="top"

              >
                <button id="copyLinkBtn" className="btns"
                  onClick={() => {
                    copyToClipboard(qrValue)
                  }}>
                  Copy Link
                </button>
              </BootstrapTooltip>

              <button type="button"
                className="btns"
                onClick={() => {
                  downloadQR()
                }}>Download QR</button>

            </DialogActions>
          </Dialog>
        </div>
        {isShowProgress && <WtLoader open={isShowProgress} />}
      </Container>
    </section>
  )
}

export const BoostingEngagement = () => {
  const BoostingEngagement = [
    { title: 'Why QR Codes?', desc: 'Customers contact you via Click to Chat WhatsApp QR codes on your website and landing pages - they do not need to add your phone number to their contacts.' },
    { title: 'How these QR codes work?', desc: 'This free tool works for all your WhatsApp business numbers by generating wa.me WhatsApp Click to Chat links in the form of QR codes.' },
    { title: 'Using the QR generator', desc: 'Create WhatsApp Click to Chat QR codes containing your number and a welcome message so you can set the tone of the conversation.' }
  ]
  return (
    <section className="mb-5">
      <Container>
        <div className="text-center">
          <h2 className="fw-semibold pt-4">Boosting engagement with WhatsApp</h2>
          <h6 className="fw-semibold pt-4" style={{ color: getColour.HeadingTextColour }}>Click to Chat QR codes</h6>
        </div>
        <div style={{ padding: '50px' }}>
          <Row>
            {
              BoostingEngagement?.map((v, i) => {
                return (
                  <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                    <div style={{ padding: '30px 8px 18px 20px', minHeight: '229px', border: '2px solid' + getColour.DescriptionTextColour, borderRadius: '8px' }} className="bg-white mb-4">

                      <h5 className="fw-semibold text-center pb-2">{v.title}</h5>

                      <p className="ms-3" style={{ color: getColour.DescriptionTextColour }}>{v.desc}</p>

                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </div>
      </Container>
    </section>
  )
}

export const WhatsAppChatQR = () => {
  return (
    <section>
      <Container>
        <h3 className="text-center mb-5" style={{ color: getColour.DescriptionTextColour }}>3 simple steps to creating a WhatsApp chat QR</h3>
        <div className="pt-4">
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <div>
                <div>
                  <img src={getUrl.templateimgBaseUrl + '/QR_icon_01.svg'} alt="whatsapp-link-generator" className="img-fluid float-end" />
                </div>
                <section style={{ boxShadow: getColour.BoxShadow, borderRadius: '8px', padding: '35px 15px 35px 15px' }} className="text-center">
                  <img src={getUrl.templateimgBaseUrl + '/Qr_code_01.png'} style={{ width: '300px' }} alt="whatsapp-link-generator" className="img-fluid" />
                </section>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section className="ms-4">
                <h4 style={{ marginTop: '30px' }}><KeyboardBackspaceIcon className="me-3" />Step #1</h4>
                <p style={{ paddingTop: '5px', marginLeft: '40px' }}>Enter your country code without the + symbol and your WhatsApp Business number.</p>
              </section>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '75px' }}>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section>
                <h4 style={{ marginTop: '30px' }}>Step #2<EastIcon className="ms-3" /></h4>
                <p style={{ paddingTop: '5px' }}>You can add a pre-filled welcome message if you wish.</p>
              </section>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section>
                <div>
                  <img src={getUrl.templateimgBaseUrl + '/QR_icon_02.svg'} alt="whatsapp-link-generator" className="img-fluid float-end" />
                </div>
                <section style={{ boxShadow: getColour.BoxShadow, borderRadius: '8px', padding: '35px 15px 35px 15px' }} className="text-center">
                  <img src={getUrl.templateimgBaseUrl + '/Qr_code_02.png'} style={{ width: '300px' }} alt="whatsapp-link-generator" className="img-fluid" />
                </section>
              </section>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '75px' }}>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section>
                <div>
                  <img src={getUrl.templateimgBaseUrl + '/QR_icon_03.svg'} alt="whatsapp-link-generator" className="img-fluid float-end" />
                </div>
                <section style={{ boxShadow: getColour.BoxShadow, borderRadius: '8px', padding: '35px 15px 35px 15px' }} className="text-center">
                  <img src={getUrl.templateimgBaseUrl + '/QR_Code_04.png'} style={{ width: '300px' }} alt="whatsapp-link-generator" className="img-fluid" />
                </section>
              </section>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <section className="ms-4">
                <h4 style={{ marginTop: '45px' }}><KeyboardBackspaceIcon className="me-3" />Step #3</h4>
                <p style={{ paddingTop: '5px', marginLeft: '40px' }}>Finally, click on the ‘Generate WhatsApp Chat Link’ button. It’s that easy!</p>
              </section>
            </Col>
          </Row>
        </div>
      </Container>
    </section >
  )
}

export const WhatsAppQRFAQs = () => {

  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const FAQ_Data_01 = [
    { "t": "How do I create a QR code for WhatsApp?", "a": "In short, to create a WhatsApp QR Code: Copy and paste this link https://wa.me/<number> onto the QR Code generator above and replace <number> with your full phone number in international format. Omit any zeroes, brackets, or dashes when adding the phone number in international format." },
    { "t": "How do I link a QR code to WhatsApp?", "a": "You can scan a WhatsApp QR code to add a friend or family member's contact information to WhatsApp. Tap More options > Settings. Tap the QR Code displayed next to your name. Tap Scan code." },
    { "t": "Can I create my own QR?", "a": "Absolutely. The Adobe Express app is quick and easy to use on smartphones and other mobile devices. Simply open the QR code generator, add your desired link, choose a style and color, download the file, and you're done. With Adobe Express, there's no need to create an account or sign in before you start." },
    { "t": "What is WhatsApp QR code used for?", "a": "Your friends and family can add you as a contact on WhatsApp by scanning your WhatsApp QR code. Your QR code won't expire unless you reset it or delete your WhatsApp account. Only share your WhatsApp QR code with trusted individuals." }
  ]

  // Search Handling

  const [searchLs, setSearchLs] = useState(FAQ_Data_01)
  const handleSearchLs = (sv) => {
    const filteredData = FAQ_Data_01?.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
    setSearchLs(filteredData)
  }
  // End of Search Handling
  return (
    <section style={{ margin: '80px 0' }}>
      <Container>
        <div>
          <div className="text-center">
            <p className="fw-semibold" style={{ fontSize: '32px' }}>Frequently Asked Questions.</p>
            <p>Here are some frequently asked questions</p>
          </div>
          <center>
            <div style={{ display: 'flex', border: '1px solid #B8BDCC', borderRadius: '5px', padding: '0 15px', width: isDesktop ? '65%' : 'auto', height: '12mm', margin: '55px 0' }}>
              <span style={{ padding: '8px' }}><SearchIcon /></span>
              <input type="search" onChange={(e) => handleSearchLs(e.target.value)} placeholder="Type your question here" style={{ border: 'none', outline: 'none', width: '100%' }} />
            </div>
          </center>
          {
            searchLs?.map((v, i) => {
              return (
                <Accordion style={{ border: "none", boxShadow: "none" }} expanded={expanded === 'panel-l-' + i} onChange={handleChange('panel-l-' + i)} key={i}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon className="bRound" style={{ color: getColour.BaseColor, background: getColour.BorderLineColour, }} />}>
                    <Typography className="fw-bold" sx={{ fontSize: "17px", width: '90%' }}>{v.t}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography dangerouslySetInnerHTML={{ __html: v.a }} style={{ fontSize: '16px', color: getColour.DescriptionTextColour }}></Typography>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </div>
      </Container>
    </section >
  )
}
