/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import './index.css'
import { Container, Row, Col } from 'react-bootstrap'
import { getColour } from '../constants/colours'
import { Link } from 'react-router-dom'
import { getUrl } from '../constants/urls'
import { getString } from '../constants/strings'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { getUiEnvUrl } from '../basetool/methods'
import { Call, WhatsApp } from '@mui/icons-material';


// Header UI
// export const Header = () => {
//     const offerImgVisibility = useContext(globalContext)
//     const params = useContext(globalContext)
//     const setOfferImgVisivility = useContext(globalContext)
//     const offerImg = useContext(globalContext)
//     const setOfferImg = useContext(globalContext)
//     const setOfferImgUrl = useContext(globalContext)
//     const handleOfferUI = async () => {
//         try {
//             const response = await offerLiveAPI()
//             if (response.status === 200) {
//                 setOfferImg.setOfferImg(response.data?.data?.isActive)
//                 setOfferImgUrl.setOfferImgUrl(response.data?.data?.imageLink)
//             }
//         } catch (error) {
//             setOfferImgVisivility.setOfferImgVisivility(false)
//         }
//     }
//     useEffect(() => { handleOfferUI() }, [])
//     const handleOutsideOfferUIReOpen = () => setOfferImgVisivility.setOfferImgVisivility(true)

//     const [industryVisibility, setIndustryVisibility] = useState(false)
//     const [toolsVisibility, setToolsVisibility] = useState(false)

//     const handleIndustryVisibility = () => {
//         setToolsVisibility(false)
//         setIndustryVisibility(!industryVisibility)
//     }

//     const handleToolsVisibility = () => {
//         setIndustryVisibility(false)
//         setToolsVisibility(!toolsVisibility)
//     }

//     function getUrls() {
//         if (params.params) {
//             return isTestEnv() ? `${getUrl.staticLink}?${params.params}` : `${getUrl.productionLink}?${params.params}`
//         }
//         return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
//     }
//     return (
//         <>
//             <div className='wttrial' style={{ display: 'none' }}>
//                 <Nav.Item className="pb-2 pt-2 wttrial text-center" style={{ borderBottom: '1px solid' + getColour.BaseColor }}><Link to={{ pathname: getUiEnvUrl() }} target='_blank' className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 fw-bold button_shiny" style={{ whiteSpace: 'nowrap', color: getColour.BaseColor }}>Start 15-days trial</Link></Nav.Item>
//             </div>

//             {offerImgVisibility.offerImgVisibility && offerImg.offerImg && <div><OutsideOfferUI /></div>}
//             {!offerImgVisibility.offerImgVisibility && offerImg.offerImg &&
//                 <div style={{ display: 'flex', marginRight: '20px' }}>
//                     <div style={{ flex: 1 }}></div>
//                     <div><button onClick={() => handleOutsideOfferUIReOpen()} className="btn btn-sm pt-1 pb-1 ps-4 pe-4" style={{ color: '#fff', background: getColour.BaseColor }}>Offer 🎉</button></div>
//                 </div>
//             }

//             <Container>
//                 <Navbar bg="white" className="headerMenu" expand="lg">

//                     <div className='wttrial' style={{ display: 'none' }}>
//                         <div className='wt_headerbtn wt_headerbtn2 mt-2' >

//                             <Navbar.Toggle className='border-0'><MenuIcon /></Navbar.Toggle>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

//                             <Navbar.Brand><p onClick={() => window.open(getUrl.wpHomeUrl, '_self')} style={{ display: "flex" }}><img src={getUrl.imageBaseUrl + "/wtb_green_round_images.webp"} width="auto" height="40px" alt={getString.SiteName} />&nbsp;<img src={getUrl.imageBaseUrl + "/logo_text_dark.webp"} width="auto" height="35px" className="mt-1" alt={getString.SiteName} /></p></Navbar.Brand>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

//                             <Nav.Item className="onSmallBtn"><Link to={{ pathname: getUrls() }} target='_blank' className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 fw-bold " style={{ background: getColour.BaseColor, whiteSpace: 'nowrap', color: '#fff', border: "2px solid " + getColour.BaseColor }}>Get Started</Link></Nav.Item>

//                         </div>
//                     </div>

//                     <Container>

//                         <Navbar.Brand ><p onClick={() => window.open(getUrl.wpHomeUrl, '_self')} style={{ display: "flex" }} className='wt_header'><img src={getUrl.imageBaseUrl + "/wtb_green_round_images.webp"} width="40px" height="40px" alt={getString.SiteName} />&nbsp; <img src={getUrl.imageBaseUrl + "/logo_text_dark.webp"} width="auto" height="35px" className="mt-1" alt={getString.SiteName} /></p></Navbar.Brand>
//                         <Navbar.Toggle className='wt_header' aria-controls="header" />
//                         <Navbar.Collapse id="header">
//                             <Nav className="mx-auto">
//                                 <NavDropdown title="Solutions" show={industryVisibility} onMouseEnter={handleIndustryVisibility} onMouseLeave={handleIndustryVisibility} onBlur={handleIndustryVisibility} className="me-5 fw-bold" style={{ color: getColour.HeadingTextColour }} id="industry-dropdown">

//                                     <section style={{ margin: isMobile ? '4px' : '8px', width: isMobile ? '' : '18vw' }}>

//                                         <p onClick={() => window.open(getUrl.wpEducationUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><SchoolOutlined fontSize="small" /></ListItemIcon>Educational Sector</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpEcommerceUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><ShoppingCartOutlined fontSize="small" /></ListItemIcon>E-Commerce Sector</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpHealthUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><HealthAndSafetyOutlined fontSize="small" /></ListItemIcon>Health Sector</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpTourtravelUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><FlightTakeoffOutlined fontSize="small" /></ListItemIcon>Tour & Travel Sector</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpMarketingUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><Storefront fontSize="small" /></ListItemIcon>Marketing</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpSalesUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><Sell fontSize="small" /></ListItemIcon>Sales</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpCustomersupportUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><SupportAgent fontSize="small" /></ListItemIcon>Customer Support</MenuItem></p>
//                                         <hr />
//                                         <div className="mb-2" style={{ textAlign: 'center' }}>
//                                             <ListItemIcon className='text-center'>Works for every industry</ListItemIcon>
//                                             <ListItemIcon className='text-center'>&nbsp;for sales, marketing & support</ListItemIcon>
//                                         </div>
//                                     </section>
//                                 </NavDropdown>
//                                 <NavDropdown title="Tools" show={toolsVisibility} onMouseEnter={handleToolsVisibility} onMouseLeave={handleToolsVisibility} onBlur={handleToolsVisibility} className="me-5 fw-bold" style={{ color: getColour.HeadingTextColour }} id="tools-dropdown">

//                                     <section style={{ margin: isMobile ? '4px' : '8px', width: isMobile ? '' : '18vw' }}>

//                                         <p onClick={() => window.open(getUrl.wpChatwidgetUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><ChatBubbleOutlineOutlined fontSize="small" /></ListItemIcon>Chat Widget</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpQrgeneratorUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><QrCodeOutlined fontSize="small" /></ListItemIcon>WhatsApp QR Generator</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpLinkgeneratorUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><InsertLinkOutlined fontSize="small" /></ListItemIcon>WhatsApp Link Generator</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpchatbottemplateUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><SmartToy fontSize="small" /></ListItemIcon>Whatsapp Chatbot Templates</MenuItem><Divider /></p>

//                                         <p onClick={() => window.open(getUrl.wpWhatsapptemplateUrl, '_self')} style={{ color: getColour.HeadingTextColour }}><MenuItem ><ListItemIcon><MmsOutlinedIcon fontSize="small" /></ListItemIcon>WhatsApp Message Templates</MenuItem></p>

//                                     </section>

//                                 </NavDropdown>
//                                 <Nav.Item className="mt-2 me-5 hand"><p onClick={() => window.open(getUrl.wpIntegrationUrl, '_self')} style={{ color: getColour.HeadingTextColour }} className="fw-bold">Integration</p></Nav.Item>
//                                 <Nav.Item className="mt-2 me-5 hand"><p onClick={() => window.open(getUrl.wpPricingUrl, '_self')} style={{ color: getColour.HeadingTextColour }} className="fw-bold">Pricing</p></Nav.Item>
//                                 <Nav.Item className="mt-2 me-5 hand"><p onClick={() => window.open(getUrl.wpQuickguideUrl, '_self')} style={{ color: getColour.HeadingTextColour }} className="fw-bold">Quick Guide</p></Nav.Item>
//                             </Nav>
//                             <Nav className="ms-auto">

//                                 <Nav.Item className="mt-2 me-3 onSmallBtn wt_header "><Link to={{ pathname: getUrls() }} target='_blank' className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 fw-bold button_shiny" style={{ background: '#fff', whiteSpace: 'nowrap', color: getColour.BaseColor, border: "2px solid " + getColour.BaseColor }}>Start 15-days trial</Link></Nav.Item>

//                                 <Nav.Item className="mt-2 me-3 onSmallBtn wt_header "><Link to={{ pathname: getUrls() }} target='_blank' className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 fw-bold button_shiny" style={{ background: getColour.BaseColor, whiteSpace: 'nowrap', color: '#fff', border: "2px solid " + getColour.BaseColor }}>Get Started</Link></Nav.Item>
//                             </Nav>
//                         </Navbar.Collapse>
//                     </Container>
//                 </Navbar >
//             </Container >
//         </>
//     )
// }

export const Header = () => {
    return (
        <section>
            <Container fluid>
                {/* <div onClick={() => window.open(getUrl.wpFestiveSaleUrl, '_target')} style={{ margin: '0 -12px 0 -12px', cursor: 'pointer' }}>
                    <img src="https://whatstool.business/wp-content/uploads/2023/09/banner_off.png" className='img-fluid' alt={getString.SiteName} />
                </div> */}
                <Container style={{ display: 'flex' }}>
                    <section className='mt-4 hand' style={{ flex: 1 }}><p onClick={() => window.open(getUrl.wpHomeUrl, '_self')}><img src={getUrl.imageBaseUrl + "/logo.png"} width="auto" height="45px" alt={getString.SiteName} /></p></section>
                    <section className='mt-4 text-center' style={{ flex: 6, display: 'flex', justifyContent: 'center' }}>
                        {window.location.href?.includes(getUrl.pricingUrl) && <p style={{ margin: 0, fontSize: '24px', color: getColour.HeadingTextColour }} className='fw-semibold'>Pricing</p>}
                    </section>
                    <section className='mt-4' style={{ flex: 1 }}></section>
                </Container>
            </Container>
        </section>
    )
}
// End of Header UI


// Footer UI
export const Footer = () => {

    return (
        <section style={{ marginBottom: isMobile ? '80px' : "0" }}>
            <Container className="footer" fluid>

                <Container style={{ display: 'flex' }} className="in p-5">
                    <div style={{ flex: 1 }}>
                        <h5 className="fw-bold mb-3 mt-4" style={{ color: getColour.BaseColor }}>WTB</h5>
                        <div style={{ lineHeight: "1" }}>
                            <p><p onClick={() => window.open(getUrl.wpWhatsappcrmUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">WhatsApp CRM</p></p>
                            <p><p onClick={() => window.open(getUrl.wpWhatsappapiUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">WhatsApp API</p></p>
                            <p><p onClick={() => window.open(getUrl.wpAnalyticsUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Analytics</p></p>
                            <p><p onClick={() => window.open(getUrl.wpChatbotUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">ChatBot</p></p>
                            <p><p onClick={() => window.open(getUrl.wpMultiagentUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold"> WhatsApp Multi Agent</p></p>
                            <p><p onClick={() => window.open(getUrl.wpPowerbusinessUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold"> WhatsApp Power Business</p></p>
                            <p><p onClick={() => window.open(getUrl.wpBroadcastmsgUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">WhatsApp Broadcast</p></p>
                            <p><p onClick={() => window.open(getUrl.wpIntegrationUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Integration</p></p>
                            <p><p onClick={() => window.open(getUrl.wpPricingUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Pricing</p></p>
                            <p><p onClick={() => window.open(getUrl.wpPricingcalculatorUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Pricing Calculator</p></p>
                            <p><Link to={getUrl.wpLearnUrl} target='_blank' style={{ color: getColour.HeadingTextColour }} className="fw-bold">Learning Centre</Link></p>
                        </div>
                    </div>

                    <div style={{ flex: 1 }}>
                        <h5 className="fw-bold mb-3 mt-4" style={{ color: getColour.BaseColor }}>Industry</h5>
                        <div style={{ lineHeight: "1" }}>
                            <p><p onClick={() => window.open(getUrl.wpEducationUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Educational</p></p>
                            <p><p onClick={() => window.open(getUrl.wpEcommerceUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">E-Commerece</p></p>
                            <p><p onClick={() => window.open(getUrl.wpHealthUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Health</p></p>
                            <p><p onClick={() => window.open(getUrl.wpTourtravelUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Tour & Travel</p></p>
                            <p><p onClick={() => window.open(getUrl.wpMarketingUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Marketing</p></p>
                            <p><p onClick={() => window.open(getUrl.wpSalesUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Sales</p></p>
                            <p><p onClick={() => window.open(getUrl.wpCustomersupportUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Customer Support</p></p>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h5 className="fw-bold mb-3 mt-4" style={{ color: getColour.BaseColor }}>Tools</h5>
                        <div style={{ lineHeight: "1" }}>
                            <p><p onClick={() => window.open(getUrl.wpChatwidgetUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Chat Widget</p></p>
                            <p><p onClick={() => window.open(getUrl.wpQrgeneratorUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">WhatsApp QR Generator</p></p>
                            <p><p onClick={() => window.open(getUrl.wpLinkgeneratorUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">WhatsApp Link Generator</p></p>
                            <p><p onClick={() => window.open(getUrl.wpchatbottemplateUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Whatsapp Chatbot Templates</p></p>
                            <p><p onClick={() => window.open(getUrl.wpWhatsapptemplateUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">WhatsApp Message Templates </p></p>
                        </div>
                        <div>
                            <h5 className="fw-bold mb-3 mt-4" style={{ color: getColour.BaseColor }}>Contact Us</h5>
                            <div style={{ lineHeight: "1" }}>
                                <Link to="tel:8501946127"><p style={{ color: getColour.HeadingTextColour }} className="fw-bold">Call us: +9185019-46127 <Call style={{ fontSize: '18px' }} /></p></Link>
                                <Link to={{ pathname: `https://wa.me/918217564965?text=${"Hi I want to know mare"}` }} target="_blank"><p style={{ color: getColour.HeadingTextColour }} className="fw-bold">Chat on WhatsApp <WhatsApp style={{ fontSize: '18px' }} /></p></Link>
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h5 className="fw-bold mb-3 mt-4" style={{ color: getColour.BaseColor }}>Support</h5>
                        <div style={{ lineHeight: "1" }}>
                            <p><p onClick={() => window.open(getUrl.wpRequestCallUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Request a Call</p></p>
                            <p><Link to={{ pathname: "mailto:support@whatstool.in" }} target="_blank" style={{ color: getColour.HeadingTextColour }} className="fw-bold">Email Us</Link></p>
                            <p><Link to={{ pathname: "https://wa.me/917353902312" }} target="_blank" style={{ color: getColour.HeadingTextColour }} className="fw-bold">WhatsApp Us</Link></p>
                            <p><p onClick={() => window.open(getUrl.wpContactusUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Contact Us</p></p>
                            <p><p onClick={() => window.open(getUrl.wpTermsconditionUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Terms &amp; Condition</p></p>
                            <p><p onClick={() => window.open(getUrl.wpPrivacypolicyUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Privacy Policy</p></p>
                            <p><p onClick={() => window.open(getUrl.wpCancellationUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Cancellation</p></p>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h5 className="fw-bold mb-3 mt-4" style={{ color: getColour.BaseColor }}>About</h5>
                        <div style={{ lineHeight: "1" }}>
                            <p><p onClick={() => window.open(getUrl.wpTeampageUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Team</p></p>
                            <p><p onClick={() => window.open(getUrl.wpClientpageUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Clients</p></p>
                            <p><Link to={{ pathname: getUrl.blogUrl }} target="_blank" style={{ color: getColour.HeadingTextColour }} className="fw-bold">Blog</Link></p>
                            <p><p onClick={() => window.open(getUrl.wpQuickguideUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Quick Guide</p></p>
                            <p><p onClick={() => window.open(getUrl.wpCasestudyUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Case Study</p></p>
                            <p><p onClick={() => window.open(getUrl.wpPartnershipUrl, '_self')} style={{ color: getColour.HeadingTextColour, cursor: 'pointer' }} className="fw-bold">Partnership</p></p>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h5 className="fw-bold mb-3 mt-4" style={{ color: getColour.BaseColor }}>Download</h5>
                        <div>
                            <Link to={{ pathname: getUrl.whatstoolBusinessAppUrl }} target="_blank"><img src={getUrl.imageBaseUrl + "/download_app.png"} className="mb-2 dImg shadow-lg" width="160px" alt={getString.SiteName} /></Link>
                        </div>
                        <div className="mt-3">
                            <img src={getUrl.imageBaseUrl + "/performer_icon.png"} alt={getString.SiteName} style={{ width: "160px" }} className="img-fluid" />
                        </div>
                        <div className="mt-3">
                            <img src="https://whatstool.business/wp-content/uploads/2023/08/Meta_partner.png" alt={getString.SiteName} style={{ width: "160px", borderRadius: '12px' }} className="img-fluid" />
                        </div>
                    </div>
                </Container>
                <hr style={{ backgroundColor: "#9FF2A7", height: "2px" }} />
                <Container className="pt-2 pb-2 pe-5 ps-5 in social">
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}><p className="float-start fw-bold" style={{ fontSize: "14px", color: getColour.HeadingTextColour }}>© {getString.SiteName} {moment().format('YYYY')}</p></Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <p className="float-end">
                                <Link to={{ pathname: "https://www.facebook.com/whatstooltech" }} target="_blank"><i className="fab fa-facebook-f fb" style={{ paddingLeft: "12px", paddingRight: "12px" }}></i></Link>
                                <Link to={{ pathname: "https://www.instagram.com/wt_business/" }} target="_blank"><i className="fab fa-instagram"></i></Link>
                                <Link to={{ pathname: "https://twitter.com/ToolsWhats" }} target="_blank"><i className="fab fa-twitter"></i></Link>
                                <Link to={{ pathname: "https://www.linkedin.com/company/whatstool" }} target="_blank"><i className="fab fa-linkedin-in"></i></Link>
                                <Link to={{ pathname: "https://www.youtube.com/c/WhatsToolTech" }} target="_blank"><i className="fab fa-youtube"></i></Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid>
                <div id="LandingButtons" className='wttrial' style={{ display: 'none' }}>
                    <Link to={{ pathname: getUiEnvUrl() }} target='_blank'><button className="btn-md fw-semibold" style={{ color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', padding: '22px 0', fontSize: '18px', background: getColour.BaseColor, width: '100%', border: '1px solid' + getColour.BaseColor }}>Get Started</button></Link>
                </div>
            </Container>
        </section>
    )
}
// End of Footer UI

// Start of Whatstool FooterUI
export const WtFooter = () => {
    return (
        <section>
            <Container fluid className="footer" style={{ position: 'fixed', left: 0, bottom: 0, width: '100%', textAlign: 'center', paddingTop: '18px' }}>
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <p className="float-start fw-bold" style={{ fontSize: "14px", color: getColour.HeadingTextColour }}>© {getString.SiteName}</p>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <p className="float-end">
                                <Link to={{ pathname: "https://www.facebook.com/whatstooltech" }} target="_blank"><i className="fab fa-facebook-f fb" style={{ paddingLeft: "12px", paddingRight: "12px" }}></i></Link>
                                <Link to={{ pathname: "https://www.instagram.com/whatstool/" }} target="_blank"><i className="fab fa-instagram"></i></Link>
                                <Link to={{ pathname: "https://twitter.com/ToolsWhats" }} target="_blank"><i className="fab fa-twitter"></i></Link>
                                <Link to={{ pathname: "https://www.linkedin.com/company/whatstool" }} target="_blank"><i className="fab fa-linkedin-in"></i></Link>
                                <Link to={{ pathname: "https://www.youtube.com/c/WhatsToolTech" }} target="_blank"><i className="fab fa-youtube"></i></Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section >
    )
}

// End of Whatstool FooterUI

