
class WtStrings {
    constructor() {
        // Site Information
        this.SiteName = 'WhatsTool Business'
        this.ShortSiteName = 'WT Business'
        this.SiteDescription = 'Convert your users into happy customers with WhatsApp Tools'
        this.SiteEmail = 'support@whatstool.in'
        this.EducationTemplate = 'Education'
        this.AllTemplate = 'All Templates'

        this.SiteContact = '+917353902312'
        this.SiteAddress = 'Prisha Cowork 1st Floor, 6th E Main Road,Behind Brigade Gardenia RBI Layout, Gaurav Nagar, JP Nagar 7th Phase, J. P. Nagar, Bengaluru, Karnataka 560078'

        // End of Site Information


        this.contactUsTitle = 'Contact Us'

        this.nonLoggedInUserText = 'Non-Logged User'
    }
}
export const getString = new WtStrings()

