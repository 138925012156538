import React, { useEffect } from 'react'
import { Footer, Header, WtFooter } from '../controller/BaseToolUI'
import { Wtd } from '../view/Wtd/App'
import { WhatsAppChatButton } from '../view/WhatsappChatButton/App'
import { UrlShortner, WhatsappLinkGenerator, WhatsappQRGenerator } from '../view/WhatsappLinkGenerator/App'
import { PricingCalculator } from '../view/Calculator/PricingCalculator'
import { FeaturedChatbotTemplates } from '../view/Static/InfoUIs'


// Featured Chatbot Template
export const FeaturedChatBotTemplateUIAdapter = () => {
  useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
  return (
    <>
      <Header />
      <FeaturedChatbotTemplates />
    </>
  )
}
// End of Featured Chatbot Template


export const WTDUIAdapter = () => {
  useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
  return (
    <>
      <Wtd />
      <WtFooter />
    </>
  )
}

export const UrlShortners = () => {
  return (
    <>
      <UrlShortner />
    </>
  )
}

export const WhatsappChatButtonUIAdapter = () => {
  useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
  return (
    <>
      <Header />
      <WhatsAppChatButton />
    </>
  )
}

export const WhatsappLinkGeneratorAdapter = () => {
  useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
  return (
    <>
      <Header />
      <WhatsappLinkGenerator />
    </>
  )
}

export const WhatsappQRGeneratorUIAdapter = () => {
  useEffect(() => { window.scrollTo({ top: 0, left: 0, behaiver: 'smooth' }) }, [])
  return (
    <section>
      <Header />
      <WhatsappQRGenerator />
    </section>
  )
}


export const WhatstoolProcongCalulator = () => {
  useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
  return (
    <>
      <Header />
      <PricingCalculator />
    </>
  )
}



