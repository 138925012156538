
import React from 'react'
import './index.css'
import { Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, IconButton, Typography, Slide } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Env } from './constant'
import 'react-circular-progressbar/dist/styles.css'
import ReactGA from 'react-ga'
import { getUrl } from '../constants/urls'
import { getColour } from '../constants/colours'
import { getString } from '../constants/strings'
import { getUiEnvUrl } from '../basetool/methods'
var moment = require('moment')

ReactGA.initialize(Env.analyticsTrackID)

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })

export function analyticsEvent(category, action, label) { ReactGA.event({ 'category': category, 'action': action, 'label': label }) }

export const ShowTime = (val) => {
    return moment.unix((val) / 1000).format("h:mm")
}
export const ShowDateTime = (val) => {
    return moment.unix((val) / 1000).format("DD MMM, YYYY h:mm a")
}
export const ShowDate = (val) => {
    return moment.unix((val) / 1000).format("DD MMM, YYYY")
}
export const ShowDateFilter = (val) => {
    return moment.unix((val) / 1000).format("YYYY-MM-DD")
}
export const ShowMonth = (val) => {
    return moment.unix((val) / 1000).format("MMM")
}
export const ShowNextMonth = (val) => {
    return moment.unix((val) / 1000).add(1, 'months').format("MMM")
}
export const ShowYear = (val) => {
    return moment.unix((val) / 1000).format("YYYY")
}
export const ShowNextYear = (val) => {
    return moment.unix((val) / 1000).add(1, 'years').format("YYYY")
}

export const copyToClipboard = (evt) => {
    navigator.clipboard.writeText(evt)
}


function TransitionDown(props) { return <Slide {...props} direction="down" /> }
export const ShowSnackbar = ({ open, errorMsg, errorMsgBg, close }) => {
    return (
        <Snackbar open={open} autoHideDuration={2000} TransitionComponent={TransitionDown} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={close}>
            <div className="text-white ps-5 pt-2 pb-1" style={{ background: errorMsgBg, display: "flex", borderRadius: "6px" }} >
                <img src={getUrl.imageBaseUrl + "/wtb_green_round_images.png"} style={{ width: "24px", height: '24px', marginLeft: "-36px", marginTop: "3px", borderRadius: '50%' }} alt={getString.SiteName} />
                <Typography style={{ marginTop: "3px" }} className="ms-3">{errorMsg}</Typography>
                <IconButton className="ms-5 me-2" size="small" aria-label="close" color="inherit" onClick={close}><Close fontSize="small" /></IconButton>
            </div>
        </Snackbar>
    )
}

export function LoginSignupDialog({ open, purpose, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const ValidButtonStyle = { background: getColour.BaseColor, color: '#fff', fontWeight: "bold", textTransform: "none", boxShadow: "none" }
    let title = ''
    let message = 'Please Login to Buy this Plan'

    if (purpose === 'free') title = 'Get started with Free Plan'
    if (purpose === 'growth') title = 'Buy Growth Plan'
    if (purpose === 'pro') title = 'Buy Pro Plan'
    if (purpose === 'enterprise') title = 'Buy Enterprise Plan'
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>
                {title}<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: getColour.DescriptionTextColour, fontWeight: "18px" }} dangerouslySetInnerHTML={{ __html: purpose === 'free' ? 'Please Login to continue with Free Plan' : message }}></DialogContentText>
                <br />
                <hr />
                <button style={ValidButtonStyle} onClick={() => window.open(getUiEnvUrl(), '_blank')} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 text-white">Login</button>
            </DialogContent>
        </Dialog>
    )
}
//login Dailog


// WT Loader Work
export function WtLoader({ open }) {
    return (
        <Dialog className="wt_loader" open={open}>
            <p className='text-center'><img src={getUrl.logoUrl} style={{ width: '120px', height: '120px' }} alt={getString.SiteName} /></p>
        </Dialog>
    )
}
// End of WT Loader Work