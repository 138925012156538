class WtAnalytics {
    constructor() {
        // Left Navigation
        this.dashboardBtnClicked = 'Dashboard Button Clicked'
        this.chatBtnClicked = 'Chat Button Clicked'
        this.conctactsBtnClicked = 'Contacts Button Clicked'
        this.broadcastBtnClicked = 'Broadcast Button Clicked'
        this.analyticsBtnClicked = 'Analytics Button Clicked'
        this.chatbotsBtnClicked = 'ChatBots Button Clicked'
        this.ecommerceBtnClicked = 'Ecommerce Button Clicked'
        this.settingsBtnClicked = 'Settings Button Clicked'
        this.profileBtnClicked = 'Profile Button Clicked'
        this.upgradePlanBtnClicked = 'Upgrade Plan Button Clicked'
        this.joinLiveDemoClicked = 'Join Live Demo Button Clicked'
        this.CalendlyLiveDemo = 'Live Partnership Calen Clicked'
        this.FacebookMetaPricing = 'Facebook Meta Pricing Clicked'
        this.buyNowButtonClicked = 'Buy Now Button of Pricing Page Clicked'
        // End of Left Navigation


        // User Related Flow
        this.nonLoggedInUser = 'Non Logged-in User'
        // End of User Related Flow


        // Login Flow
        this.loginSuccess = "Login Successfull"
        this.loginFailed = "Login Failed"
        // End of Login Flow


        // Offer 
        this.topFullWidthOfferBannerClicked = 'Top Offer Banner Img Clicked'
        // End of Offer 


        // Others
        this.conversationalEngagement = 'Conversational Engagement - Request Button Clicked'
        // End of Others

        //auth login
        this.googleLoginSuccess = "Google Login Success"

        this.loginwitholdmethod = "clicked Login with old method"

        this.sendresendemail = "clicked resend email"

        this.loginwithcustomemail = "Login with custome email"

        this.registerwithcustomemail = "Register with custome email"

        this.loginWithWhatsapp = "Login with whatsapp button clicked"

        this.registerDialogContinueBtn = "Register dialog continue button clicked"

        this.registerDialogContinueBtn = "Register dialog continue button clicked"

        this.sendWhatsappOptBtn = "Whats OTP Button clicked"

        this.loginwithWhatsapp = "Successfully Login with Whatsapp OTP "

        this.onboardingFirsstStep = "Onboarding first step completed"

        this.onboardingSecondStep = "Onboading second step completed"

        this.onboardingSecondStepSkip = "Onboarding second step Skip"

        this.introSlideNext = "Intro slide next button clicked"

        this.exprienceSkip = "Exprince UI Skip Button Clicked"

        this.exprienceNext = "Exprince UI Next Button Clicked"

        this.exprienceMessage = "Exprince UI SendMessage Button Clicked"

        this.exprienceBroadcastMessage = "Exprince UI Braodcast message Button Clicked"

        this.exprienceSuccessMessage = "Exprince UI Successfully message"
        this.exprienceFailerMessage = "Exprince UI failer Message"
        this.expriencebroadcastSuccessMessage = "Exprince UI Successfully broadcast message send"
        this.exprienceBroadcastFailerMessage = "Exprince UI failer broadcast message"
        this.lastpageOnboardingYoutub = "Last page onbarding youtub button clicked"
        this.lastpageOnboardingpdfDownload = "Last page onbarding PDF download button clicked"
        this.lastpageOnboardingDemo = "Last page onbarding Demo button clicked"



    }
}
export const getAnalyticsKey = new WtAnalytics()