/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import './basetool.style.css'
import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Slide } from '@mui/material'
import { getColour } from '../constants/colours'
import { Link, useHistory } from 'react-router-dom'
import { getUrl } from '../constants/urls'
import { getString } from '../constants/strings'
import { Player } from '@lottiefiles/react-lottie-player'
import CountryCode from '../third-party/CountryCode.json'
import { isMobile } from 'react-device-detect'
import EastIcon from '@mui/icons-material/East';
import { isTestEnv } from './methods'
import { getAnalyticsKey } from '../constants/analyticsKeys'
import { analyticsEvent } from '../controller/BaseTool'


const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })
const CancelButtonStyle = { color: getColour.DescriptionTextColour, fontWeight: "bold" }

// Pricing - HomePage
export function PricingFeaturesDialog({ open, title, message, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>
                {title}<Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-3" style={{ color: getColour.DescriptionTextColour, fontWeight: "18px" }} dangerouslySetInnerHTML={{ __html: message }}></DialogContentText>
                <br />
                <hr />
                <button style={CancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm me-3 rounded-3 ps-3 pe-3 pb-1 bg-white">Close</button>
            </DialogContent>
        </Dialog>
    )
}
// End of Pricing - HomePage


// Featured Chatbot Template Dialog
export function FeaturedChatBotDialog({ open, v, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>{v?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-4" style={{ color: '#162c6f' }}>
                    Real Estate companies can make use of this chatbot template to let their buyers/propects know about payment schedule once they decide on the property. This bot is designed in a way, to even collect certain payments.
                </DialogContentText>
                <button className="btn btn-sm mt-3" style={{ background: '#cbd5e0', fontSize: '12px', borderRadius: '4px', paddingTop: '2px 8px 4px 8px' }}>Booking / Scheduling</button><br />
                {v?.btnActionUrl !== '' && <Link to={{ pathname: `https://wa.me/919844344170?text=${v?.btnActionUrl}` }} target="_blank" className="btn fw-bold mt-3" style={{ background: getColour.BaseColor, color: 'white', fontSize: '17px', borderRadius: '6px', padding: '0.5em 2em' }}>Try on WhatsApp</Link>}
            </DialogContent>
        </Dialog>
    )
}
// End of Featured Chatbot Template Dialog

// WT Loader Work
export function WtLoader({ open }) {
    return (
        <Dialog className="wt_loader" open={open}>
            <div>
                <p className="text-center"><Player autoplay loop src={getUrl.imageBaseUrl + '/wtb_loading.json'} className="items-lottie"></Player></p>
            </div>
        </Dialog>
    )
}
// End of WT Loader Work


// Conversational Engagement Dialog
export function ConversationalEngagementDialog({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: '#fff', color: getColour.DescriptionTextColour }}>
                <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <img src={getUrl.imageBaseUrl + '/conver_img.svg'} className='img-fluid' alt={getString.SiteName} />
                <p className="ms-5 me-5 text-center" style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }}>You'll receive a call from our WhatsTool Business Expert Team within 2 - 3 days.</p>
                <br />
            </DialogContent>
        </Dialog>
    )
}
// End of Conversational Engagement Dialog


// Join LIVE Demo Dialog
export function JoinLIVEDemoDialog({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"md"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <div>
                <div style={{ background: getColour.BorderLineColour, padding: '8px 0px 8px 0px' }}>
                    <p style={{ color: getColour.DescriptionTextColour, margin: '0px', fontSize: '20px' }} className='text-center'>Join Live Demo</p>
                </div>
                <div style={{ padding: '20px' }}>
                    <p className='text-center'><img src={getUrl.imageBaseUrl + '/demo_icon.png'} alt={getString.SiteName} className='img-fluid' /></p>
                    <div style={{ padding: isMobile ? '0' : '40px 40px 0px 40px' }}>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }}>Join Live Demo to understand how WhatsTool Business  works and to know detailed features of it.</p>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }}>Know how it helps to push your Business growth.</p>
                    </div>
                    <div>
                        <p className='text-center'>
                            <p onClick={() => window.open(getUrl.wpdemobookingUrl, '_self')} style={{ color: '#fff', background: getColour.BaseColor, padding: '8px 30px 10px 30px', fontSize: '20px' }} className='btn btn-sm'>Join Live Demo</p>
                        </p>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
// End of Join LIVE Demo Dialog

// Partnership Feature input Dialog
export function CalendlyLiveDemo({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <div>
                <div style={{ background: getColour.BorderLineColour, padding: '8px 0px 8px 0px' }}>
                    <p style={{ color: getColour.DescriptionTextColour, margin: '0px', fontSize: '20px' }} className='text-center'>Application Submitted Successfully🚀</p>
                </div>
                <div style={{ padding: '20px' }}>
                    <div style={{ padding: '40px 40px 0px 40px' }}>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }}>Thanks for applying to partnership. Our team will reach to you with 1-3 working days.</p>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }}>You can book a meeting with our team from below Calendar..</p>
                    </div>
                    <div>
                        <p className='text-center'>
                            <Link to={{ pathname: 'https://whatstool.ldmk.io/t/whatstoolbusinessdemobooking/demo' }} target='_blank' style={{ color: '#fff', background: getColour.BaseColor, padding: '8px 30px 10px 30px', fontSize: '18px' }} className='btn btn-sm'>Book Now</Link>
                        </p>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
// End of Partnership Webpage Feature input Dialog


// Restrict Feature Dialog
export function UpgradeToPremium({ open, bodyText, btnText, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    const history = useHistory()
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} TransitionComponent={Transition}>
            <div>
                <div style={{ background: getColour.BorderLineColour, padding: '8px 0px 8px 0px' }}>
                    <p style={{ color: getColour.DescriptionTextColour, margin: '0px', fontSize: '20px' }} className='text-center'>Upgrade Required</p>
                </div>
                <div style={{ padding: '20px' }}>
                    <div style={{ padding: '40px 40px 0px 40px' }}>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }}>{bodyText}</p>
                    </div>
                </div>
                <div style={{ display: 'flex', padding: '20px', borderTop: '1px solid ' + getColour.BorderLineColour }}>
                    <div style={{ flex: 1 }}>
                        <button onClick={() => onCloseHere(false)} style={{ color: getColour.DescriptionTextColour, background: getColour.LightTextColour, padding: '4px 20px 6px 20px' }} className='btn btn-sm'>Cancel</button>
                    </div>

                    <button onClick={() => history.push(getUrl.paymentPlanSubscriptionUrl)} style={{ color: '#fff', background: getColour.BaseColor, padding: '4px 20px 6px 20px' }} className='btn btn-sm'>{btnText}</button>
                </div>
            </div>
        </Dialog>
    )
}
// End of Restrict Feature Dialog

// Pricing Facebook Meta Pricing Dialog
export function FacebookMetaPricing({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <div>
                <div style={{ background: getColour.BorderLineColour, padding: '8px 0px 8px 0px' }}>
                    <p style={{ color: getColour.HeadingTextColour, margin: '8px', fontSize: '20px', paddingLeft: '45px' }}>Rate Cards</p>
                </div>
                <div style={{ padding: '20px' }}>
                    <div style={{ padding: '25px', lineHeight: '1.9' }}>
                        <ul>
                            <li><Link to={{ pathname: "https://drive.google.com/file/d/1ZN_xFEEtLrjLGytY-SGM5_aAuZRCvpyy/view?usp=drive_link" }} target='_blank'>INR Rate Card</Link></li>
                            <li><Link to={{ pathname: "https://drive.google.com/file/d/1VCaaVz0S8hfKfnT1QPB80ZR1GUHCXG9e/view?usp=drive_link" }} target='_blank'>USD Rate Card</Link></li>
                            <li><Link to={{ pathname: "https://drive.google.com/file/d/1atd2puRNLCc29abc-Jgp8whVOJCCE4Jw/view?usp=drive_link" }} target='_blank'>AUD Rate Card</Link></li>
                            <li><Link to={{ pathname: "https://drive.google.com/file/d/1NpUgRMp-o2GJNxtphWyGV3W9eMIMbFl5/view?usp=drive_link" }} target='_blank'>EUR Rate Card</Link></li>
                            <li><Link to={{ pathname: "https://drive.google.com/file/d/1hD3FNehP2R1Caoo0cKo3c6f_8UrOS-B-/view?usp=drive_link" }} target='_blank'>GBP Rate Card</Link></li>
                            <li><Link to={{ pathname: "https://drive.google.com/file/d/1zL166fW-uSeHRDFsNZMTpJI5UqF6k4cw/view?usp=drive_link" }} target='_blank'>IDR Rate Card</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
// End of Facebook Meta Pricing Dialog

// Country Code Dialog
export const CountryCodeDialog = ({ open, onClose }) => {
    const onCloseHere = (confirm, countryCode) => { if (onClose) onClose(confirm, countryCode) }
    const handleCountryCode = (val) => {
        onCloseHere(true, val?.split('+')[1])
    }

    // Search Handling
    const [searchValue, setSearchValue] = useState('')
    const [searchLs, setSearchLs] = useState(CountryCode)
    const handleSearchLs = (sv) => {
        setSearchValue(sv)
        const filteredData = CountryCode?.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filteredData)
    }
    // End of Search Handling

    const cancelButtonStyle = { color: getColour.HeadingTextColour, background: getColour.BorderLineColour, padding: '4px 20px' }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>
                Select country code <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <section style={{ margin: '20px' }}>
                <div>
                    <input value={searchValue} onChange={(e) => handleSearchLs(e.target.value)} type='search' style={{ width: '100%', outline: 'none', padding: '10px 20px', border: '1px solid ' + getColour.BorderColour, borderRadius: '30px' }} placeholder={getString.searchPlaceholderText} />
                </div>
                <div style={{ height: '60vh', overflowY: 'auto' }}>
                    {
                        searchLs?.map((v, i) => {
                            return (
                                <div className='hand' onClick={() => handleCountryCode(v.dial_code)} style={{ padding: '6px', margin: '10px', border: '1px solid ' + getColour.LineColor, borderRadius: '4px' }} key={i}>
                                    <p style={{ margin: '0px 10px' }}><span style={{ fontWeight: 'bold' }}>{v.dial_code}</span>&nbsp;&nbsp;{v.name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
            <br />
            <hr />
            <section className='dialog_footer' style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <button style={cancelButtonStyle} onClick={() => onCloseHere(false)} className="btn btn-sm">{getString.cancelTitleText}</button>
                </div>
                <div>&nbsp;</div>
            </section>
        </Dialog>
    )
}
// End of Country Code Dialog


// WhatsApp Templates Dialog
export function WhatsAppTemplates({ open, onClose, v }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    // const queryParams = encodeURIComponent(JSON.stringify(v));

    // function getlink() {
    //     if (queryParams) {
    //         return isTestEnv() ? `${getUrl.appTestingTemplateLink}${queryParams}` : `${getUrl.productionTemplateLink}${queryParams}`
    //     }
    // }
    return (
        <Dialog fullWidth={true} open={open} TransitionComponent={Transition} onClose={() => onCloseHere(false)} className='Templatesfullimg'>

            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>&nbsp;</div>
                <div><IconButton onClick={() => onCloseHere(false)}><Close style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }} /></IconButton></div>
            </div>
            <DialogContent className='text-center mt-4'>
                {v?.ImageFull !== '' && <img src={getUrl.templateimgBaseUrl + v?.ImageFull} style={{ height: '480px', background: getColour.BoxShadow }} alt="WhatsTool Templates" className="img-fluid rounded-2" />}
            </DialogContent>
            <div onClick={() => onCloseHere(false)} className='text-center mb-5 mt-2'>
                <Link to={{ pathname: 'https://app.whatstool.business/auth' }} target='_blank'><button style={{ color: '#fff', background: getColour.BaseColor, padding: '8px 50px', fontSize: '18px' }} className='btn btn-sm shadow-lg'>Do Login & Apply<EastIcon className='ms-2 shadow-lg' /></button></Link>
                <p className='text-center ps-3 pe-3 mt-3' style={{ margin: 0 }}>Note: Need to login and select this particular template from <b>"Template Gallery"</b> to create.</p> 
            </div>
        </Dialog>
    )
}
// End of WhatsApp Templates Dialog


// start of Apply Now Dialog
export function ApplyNowTemplate({ open, onClose, v }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    // const queryParams = encodeURIComponent(JSON.stringify(v));

    // function getlink() {
    //     if (queryParams) {
    //         return isTestEnv() ? `${getUrl.appTestingTemplateLink}${queryParams}` : `${getUrl.productionTemplateLink}${queryParams}`
    //     }
    // }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"md"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour, fontSize: '18px' }}>{v?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText className="mt-4" style={{ color: '#162c6f' }}>{v.Describe}</DialogContentText>
                <p className='mt-4 mb-4'>For Creating this Template. You have to be Login</p>
            </DialogContent>
            <div onClick={() => onCloseHere(false)} style={{ display: 'flex', padding: '20px', borderTop: '1px solid ' + getColour.BorderLineColour }}>
                <div style={{ flex: 1 }}>
                    <p style={{ margin: 0 }}>Note: Need to login and select this particular template from <b>"Template Gallery"</b> to create.</p>
                </div>
                <div>
                    <Link to={{ pathname: isTestEnv() ? getUrl.staticLink : getUrl.productionLink }} target='_blank' ><button className='btn btn-sm' style={{ color: getColour.HeadingTextColour, background: getColour.MainBgColour, padding: '4px 20px', fontSize: '16px' }}>Do Login & Apply</button></Link>
                </div>
                {/* <Link to={{ pathname: getlink() }} target='_blank'><button style={{ color: '#fff', background: getColour.BaseColor, padding: '4px 20px 6px 20px', fontSize: '16px' }} className='btn btn-sm'>Apply Now</button></Link> */}
            </div>
        </Dialog>
    )
}
// End of Apply Now Dialog


export const BuyNowDialog = ({ open, onClose }) => {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle >
                <div>
                    <Player style={{ width: '30%' }} autoplay loop src="https://lottie.host/68a24798-3c32-429b-aded-7b029d2f5e2b/puVXYe8r4W.json"></Player>
                </div>
            </DialogTitle>

            <section style={{ padding: '20px' }}>
                <p className='text-center' style={{ color: getColour.HeadingTextColour, fontSize: '24px' }}>Please do Login/Signup to buy this plan</p>
            </section>
            <section style={{ padding: '10px 20px', borderTop: '1px solid ' + getColour.BorderLineColour, display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <button onClick={() => onCloseHere(false)} className='btn btn-sm' style={{ color: getColour.HeadingTextColour, background: getColour.BorderLineColour, padding: '4px 20px', borderRadius: '4px' }}>Cancel</button>
                </div>
                <div>
                    <button onClick={() => {
                        analyticsEvent('Non Loggedin User', getAnalyticsKey.buyNowButtonClicked, window.location.search)
                        window.open('https://app.whatstool.business/auth', '_blank')
                    }} className='btn btn-sm' style={{ color: '#fff', background: getColour.BaseColor, padding: '4px 20px', borderRadius: '4px' }}>Login / Signup</button>
                </div>
            </section>
        </Dialog>
    )
}