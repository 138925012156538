import { Container } from "@mui/system"
import { useRef, useState } from "react"
import { Card, Col, Collapse, Row } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import "./ChatBubble.css"
import moment from "moment"
import CodeEditor from "@uiw/react-textarea-code-editor"
import { ContentCopy } from "@mui/icons-material"
import { generateWidgets } from "../../apis/APIs"
import { showConsole } from "../../basetool/methods"
import { getColour } from "../../constants/colours"

export const WhatsAppChatButton = () => {
  const [selectColor, setSelectColor] = useState("#00a82d")
  const [buttonNameinput, setbuttonNameinput] = useState("")
  const [waId, setwaId] = useState(undefined)
  const [brandurl, setBrandUrl] = useState("https://storage.googleapis.com/wtx_public_bucket_prod/1675345643947.png")
  const ref = useRef(null)
  const [brandSubTitle, setBrandSubTitle] = useState("Usually reply in 4 Minutes")
  const [brandTitle, setBrandTitle] = useState("WT Business")
  const [widgetTopBgColor, setWidgetTopBgColor] = useState("#198754")
  const [welcometext, setWelcometext] = useState("Hi there! How may I help you?")
  const [defaulUserMsg, setdefaulUserMsg] = useState("I have a question about {{page_link}}")
  const [chatBtnRadius, setChatBtnRadius] = useState("25")
  const [chatBtnMarginLeft, setChatBtnMarginLeft] = useState("0")
  const [chatBtnMarginRight, setchatBtnMarginRight] = useState("50")
  const [chatBtnMarginBottom, setchatBtnMarginBottom] = useState("50")
  const [ctaText, setctaText] = useState("Start Chat")
  const [widgetborderRadius, setWidgetBorderRadius] = useState("25")
  const [isWidgetOpen, setIsWidgetOpen] = useState("False")
  const [isBottomLeft, setIsBottomLeft] = useState("RIGHT")
  const [isCopyBtnShow, setIsCopyBtnShow] = useState(true)
  const [linksId, setLinksId] = useState("ID")


  const copyToClipboard = (evt) => {
    setIsCopyBtnShow(false)
    startCount()
    navigator.clipboard.writeText(evt)
  }

  const [showCode, setShowCode] = useState(false)
  const codetitle = `Copy and paste this code before the </Body> tag on every page of your website.`
  const code =
    `<script>
        var url = 'https://storage.googleapis.com/wt_resources/wtb_media/whatsappWidget.js'
        var s = document.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = url
        var options = {
      "enabled":true,
      "chatButtonSetting":{
          "backgroundColor":"${selectColor}",
          "ctaText":"${buttonNameinput}",
          "borderRadius":"${chatBtnRadius}",
          "marginLeft":"${chatBtnMarginLeft}",
          "marginBottom":"${chatBtnMarginBottom}",
          "marginRight":"${chatBtnMarginRight}",
          "position":"${isBottomLeft.toLocaleLowerCase()}"
      },
      "brandSetting":{
          "brandName":"${brandTitle}",
          "brandSubTitle":"${brandSubTitle}",
          "brandImg":"${brandurl}",
          "welcomeText":"${welcometext}",
          "messageText":"${defaulUserMsg}",
          "backgroundColor":"${widgetTopBgColor}",
          "ctaText":"${ctaText}",
          "borderRadius":"${widgetborderRadius}",
          "autoShow":${isWidgetOpen.toLocaleLowerCase()},
          "phoneNumber":"${waId}",
          "id":"${linksId}"
      }
    }
      s.onload = function() {
          CreateWhatsappChatWidget(options)
      }
      var x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    </script>`

  var pageLink = "{{page_link}}"
  var pageTitle = "{{page_title}}"

  const handlebackgroundColorChnage = (e) => setWidgetTopBgColor(e.target.value)

  const showCodeFunction = async () => {
    if (waId) {
      var linkId = Math.random().toString(18).slice(8)
      var saveData = { linkId: linkId, brandName: brandTitle, phoneNumber: waId }
      setShowCode(true)
      setLinksId(linkId)
      await generateWidgets(saveData)
    } else {
      alert("Phone number should not be empty")
      focusInput()
    }
  }

  const startCount = () => setInterval(() => { setIsCopyBtnShow(true) }, 3000)

  const focusInput = () => {
    const section = document.querySelector("#brandNameInput")
    section.scrollIntoView({ behavior: "smooth", block: "start" })
    ref.current.focus()
  }

  const pageLinkBtnclicked = (val) => {
    showConsole(val)
    var input = document.getElementById("defaultUserMsginput")
    let cursorPosition = input.selectionStart
    let textBeforeCursorPosition = input.value.substring(0, cursorPosition)
    let textAfterCursorPosition = input.value.substring(cursorPosition, input.value.length)
    const newText = textBeforeCursorPosition + (val === "link" ? pageLink : pageTitle) + textAfterCursorPosition
    input.value = newText
    setdefaulUserMsg(newText)
    showConsole(cursorPosition + ", " + textBeforeCursorPosition + ", " + textAfterCursorPosition)
  }

  const handleButtonNameInput = (e) => setbuttonNameinput(e.target.value)

  const handleColorChnage = (e) => {
    setSelectColor(e.target.value)
    showConsole("SelectColor: " + JSON.stringify(e.target.value))
    document.getElementById("colorInput").value = e.target.value
  }

  return (
    <section style={{ marginTop: "40px" }} className="wtd mb-5">
      <Container>
        <p align="center" style={{ marginBottom: "50px" }}><h1>Generate WhatsApp Live Chat Widget</h1></p>
        <Row className="align-items-center">
          <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <h4>Chat Button Settings</h4>
            <p style={{ marginBottom: "0px" }}>Customise chat button settings - Choose</p>
            <p style={{ marginBottom: "0px" }}>chat button design, Set color</p> & change CTA text.
            <p style={{ marginTop: "20px" }}><strong>Buttom Style:</strong>All possible & attractive button designs.</p>
            <p style={{ marginTop: "20px" }}><strong>BackGround Color:</strong>Choose any color to get personalize WhatsApp Chat button.</p>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
            <div>
              <Card>
                <section style={{ marginTop: "30px", marginBottom: "30px", paddingLeft: "20px", paddingRight: "20px" }}>
                  <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      Button Color
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <Form.Control type="color" id="exampleColorInput" defaultValue="#00a82d" title="Choose your color" onChange={(e) => { handleColorChnage(e) }} />
                        <input type="text" style={{ marginLeft: "5px", paddingLeft: "5px" }} defaultValue="#00a82d" id="colorInput" />
                      </div>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      Button Name
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <input type="text" style={{ height: "37px", paddingLeft: "5px" }} id="buttonNameinput" onChange={(e) => handleButtonNameInput(e)} />
                      </div>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      Rounded Corner(px)
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <input type="text" style={{ height: "37px", paddingLeft: "5px" }} id="cornerInput" defaultValue="25" onChange={(e) => { setChatBtnRadius(e.target.value) }} />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      Margin Bottom(px)
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <input type="text" style={{ height: "37px", paddingLeft: "5px" }} defaultValue="50" id="marginbottomInput" onChange={(e) => { setchatBtnMarginBottom(e.target.value) }} />
                      </div>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      Margin Left(px)
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <input type="text" style={{ height: "37px", paddingLeft: "5px" }} defaultValue="0" id="marginLeftInput" onChange={(e) => { setChatBtnMarginLeft(e.target.value) }} />
                      </div>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      Margin Right(px)
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <input type="text" style={{ height: "37px", paddingLeft: "5px" }} id="marginRightInput" defaultValue="50" onChange={(e) => { setchatBtnMarginRight(e.target.value) }} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      Position
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <Form style={{ display: "flex" }}>
                          <Form.Check inline type="radio" id="bottomLeftId" name="bottomLeft" label="Bottom-Left" value="LEFT" checked={isBottomLeft === "LEFT"} onChange={(e) => { setIsBottomLeft(e.target.value) }}></Form.Check>
                          <Form.Check inline type="radio" id="bottomRightId" name="bottomRight" label="Bottom-Right" value="RIGHT" checked={isBottomLeft === "RIGHT"} onChange={(e) => { setIsBottomLeft(e.target.value) }}></Form.Check>
                        </Form>
                      </div>
                    </Col>

                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                      Chat Button Preview
                      <div style={{ display: "flex", marginTop: "10px", justifyContent: "center" }}>
                        <div id="whatsAppChatBtn" style={{ height: "50px", minWidth: "50px", borderRadius: "25px", backgroundColor: selectColor, boxShadow: "rgba(0, 0, 0, 0.4) 2px 2px 6px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: buttonNameinput ? "15px" : "0px", paddingRight: buttonNameinput ? "15px" : "0px" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="wa-messenger-svg-whatsapp wh-svg-icon" style={{ width: "46px", height: "46px", fill: "rgb(255, 255, 255)", zIndex: "1" }}>
                            <path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fill-rule="evenodd"></path>
                          </svg>
                          <div id="whatsappChatCTATextPreview" style={{ color: "white", fontSize: "18px" }}>{buttonNameinput}</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </section>
              </Card>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xxl={4} xl={4} lg={4} md={5} sm={12} xs={12}>
            <h4>Chat Widget settings</h4>
            <p>Change your brand name, subtitle, welcome text and default user message</p>

            <div className="mt-5" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "30px" }}>
              <strong>Chat Widget Preview</strong>
              <div style={{ borderRadius: "10px", display: "block", boxShadow: widgetTopBgColor, maxWidth: "350px", marginTop: "10px" }}>
                <div id="whatsappChatWidgetHeaderPreview" style={{ height: "65px", maxHeight: "100px", minHeight: "65px", backgroundColor: widgetTopBgColor, color: "white", borderRadius: "10px 10px 0px 0px", display: "flex", alignItems: "center", }}>
                  <img src={brandurl} id="whatsappChatWidgetBrandIml̥agePreview" style={{ marginLeft: "20px", width: "40px", height: "40px", borderRadius: "25px" }} alt="true" />
                  <div style={{ marginLeft: "20px" }}>
                    <div id="whatsappChatWidgetBrandPreview" style={{ fontSize: "16px", fontWeight: "700" }}>{brandTitle}</div>
                    <div id="whatsappChatWidgetBrandSubTitlePreview" style={{ fontSize: "12px", lineHeight: "18px", marginTop: "0px", }}>{brandSubTitle}</div>
                  </div>
                </div>

                <div class="conversation_messagescontainer__vGqzb" style={{ backgroundColor: "#D9D9D9", display: 'flex', flexDirection: 'column' }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", justifyContent: "center" }} >
                      <p style={{ position: "absolute", justifyContent: "center", alignItems: "center", fontSize: "10px", marginTop: "10px", textAlign: "center", width: "300px", padding: "10px", background: "#D9D9D9", opacity: "0.6" }}>⚡Powered by <font color={getColour.BaseColor}>WhatsTool Business</font></p>
                    </div>

                    <div class="conversation_messagebubble__18PAE">
                      <div class="conversation_triright__3MXBw">
                        <div class="conversation_messagetext__2xcva">
                          <p style={{ marginBottom: "5px", whiteSpace: "pre-wrap" }}>{welcometext}</p>
                          <p style={{ fontSize: "10px", marginBottom: "0px", textAlign: "right", color: getColour.LightTextColour }}>{moment().format("h:mm a")}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ justifyContent: "center", alignItems: "center", width: "350px", display: "flex" }}>
                    <div style={{ width: "290px", backgroundColor: "white", display: "flex", margin: "6px", padding: "8px 12px 8px 12px", borderRadius: "25px" }}>{defaulUserMsg}</div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        id="whatsAppChatBtn"
                        style={{ height: "40px", minWidth: "40px", borderRadius: "25px", backgroundColor: widgetTopBgColor, boxShadow: "rgba(0, 0, 0, 0.4) 2px 2px 6px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: buttonNameinput ? "15px" : "0px", paddingRight: buttonNameinput ? "15px" : "0px", }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                        >
                          <path
                            fill="white"
                            d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                          >
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xxl={8} xl={8} lg={8} md={7} sm={12} xs={12}>
            <Card>
              <section
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <Row>
                  <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    Brand Name
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <input
                        type="text"
                        style={{
                          height: "37px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        id="brandNameInput"
                        defaultValue="WT Business"
                        onChange={(e) => setBrandTitle(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
                    Brand Sub Title
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <input
                        type="text"
                        style={{
                          height: "37px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        id="brandSubTitleInput"
                        defaultValue="Usually reply in 4 Minutes"
                        onChange={(e) => setBrandSubTitle(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    Phone number with country code
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <input
                        type="number"
                        style={{
                          height: "37px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        ref={ref}
                        id="phonenumberInput"
                        onChange={(e) => setwaId(e.target.value)}
                      />
                    </div>
                    <p>Example: 91XXXXXXXXXX</p>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <div>
                    Welcome Text
                  </div>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <textarea
                      id="welcomeTextinput"
                      style={{
                        lineHeight: "1.5",
                        marginBottom: "0px",
                        paddingLeft: "3px",
                        height: "100px",
                        width: "100%",
                        border: "1px solid lightgray",
                      }}
                      onChange={(e) => setWelcometext(e.target.value)}
                      spellCheck="false"
                    >
                      Hi there! How may I help you?
                    </textarea>
                  </div>
                </Row>

                <Row className="mt-4">
                  <div>
                    Default User Message
                  </div>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <textarea
                      id="defaultUserMsginput"
                      style={{
                        lineHeight: "1.5",
                        marginBottom: "0px",
                        paddingLeft: "3px",
                        height: "100px",
                        width: "100%",
                        border: "1px solid lightgray",
                      }}
                      onChange={(e) => {
                        setdefaulUserMsg(e.target.value)
                      }}
                      spellCheck="false"
                    >
                      {defaulUserMsg}
                    </textarea>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span
                      id="btn_add_pageLink"
                      class="btn btn-success btn-sm"
                      onClick={() => pageLinkBtnclicked("link")}
                    >
                      Page Link
                    </span>
                    <span
                      style={{ marginLeft: "5px" }}
                      id="btn_add_pageTitle"
                      class="btn btn-success btn-sm"
                      onClick={() => pageLinkBtnclicked("title")}
                    >
                      Page Title
                    </span>
                  </div>
                </Row>

                <Row className="mt-4">
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    Brand Image url
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <input
                        type="text"
                        style={{
                          height: "37px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        id="brandUrlInput"
                        defaultValue="https://storage.googleapis.com/wtx_public_bucket_prod/1675345643947.png"
                        onChange={(e) => {
                          setBrandUrl(e.target.value)
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    Background Color
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <Form.Control
                        type="color"
                        id="backgroundColorInput"
                        defaultValue="#198754"
                        title="Choose your color"
                        onChange={(e) => {
                          handlebackgroundColorChnage(e)
                        }}
                      />
                      <input
                        type="text"
                        style={{ marginLeft: "5px", paddingLeft: "5px" }}
                        defaultValue="#198754"
                        id="backgroundcolorInputEd"
                      />
                    </div>
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    CTA Text
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <input
                        type="text"
                        style={{ height: "37px", paddingLeft: "5px" }}
                        id="buttonNameinput"
                        defaultValue="Start Chat"
                        onChange={(e) => {
                          setctaText(e.target.value)
                        }}
                      />
                    </div>
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    Border Radius(px)
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <input
                        type="text"
                        style={{ height: "37px", paddingLeft: "5px" }}
                        id="cornerInput"
                        defaultValue="25"
                        onChange={(e) => {
                          setWidgetBorderRadius(e.target.value)
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    Open widget by default
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <Form style={{ display: "flex" }}>
                        <Form.Check
                          inline
                          type="radio"
                          id="widgetTrue"
                          name="true"
                          label="True"
                          value="True"
                          checked={isWidgetOpen === "True"}
                          onChange={(e) => {
                            setIsWidgetOpen(e.target.value)
                          }}
                        >
                        </Form.Check>
                        <Form.Check
                          inline
                          type="radio"
                          id="widgetFalse"
                          name="false"
                          label="False"
                          value="False"
                          checked={isWidgetOpen === "False"}
                          onChange={(e) => {
                            setIsWidgetOpen(e.target.value)
                          }}
                        >
                        </Form.Check>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </section>
            </Card>
          </Col>
        </Row>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: "#00a82d",
              textAlign: "center",
              padding: "10px",
              width: "300px",
              margin: "60px 0px 30px 0px",
              borderRadius: "10px",
              alignContent: "center",
            }}
            alignItems="center"
            role="button"
            onClick={() => {
              showCodeFunction()
            }}
          >
            <span
              class="elementor-button-text"
              role="button"
              style={{ color: "white" }}
            >
              Generate Widget Code
            </span>
          </div>
        </div>

        <Collapse in={showCode}>
          <Card style={{ marginTop: "20px" }}>
            <div>
              <div style={{ marginLeft: 20, marginRight: 20, display: "flex" }}>
                <div style={{ flex: "1" }}>
                  <p style={{ margin: "5px" }}>{codetitle}</p>
                </div>
                <div>
                  {isCopyBtnShow
                    ? (
                      <ContentCopy
                        style={{
                          margin: "5px",
                          right: "0px",
                          position: "relative",
                        }}
                        role="button"
                        onClick={() => {
                          copyToClipboard(code)
                        }}
                      />
                    )
                    : <p textAlign="centre">Copied</p>}
                </div>
              </div>
              <CodeEditor
                value={code}
                data-color-mode="dark"
                language="js"
                contentEditable="false"
                placeholder="Please enter JS code."
                padding={15}
                style={{ fontSize: 12, fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace", }}
              />
            </div>
          </Card>
        </Collapse>
      </Container>
    </section>
  )
}
