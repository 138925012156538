/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import './index.css'
import { useHistory, Link } from 'react-router-dom'
import { Slide } from '@mui/material'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { BlogViewHome, VideoIframe, WTBTools } from '../Dashboard/App'
import { FAQ, FeaturesNavbar, Testimonial } from '../Static/ui'
import { PricingCTA } from '../Static/PricingUI'
import { Footer, Header } from '../../controller/BaseToolUI'
import { getString } from '../../constants/strings'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { isMobile } from 'react-device-detect'
import { JoinLIVEDemoDialog } from '../../basetool/dialogs'
import { WtEvent } from '../../basetool/analytics'
import { getAnalyticsKey } from '../../constants/analyticsKeys'
import { getColour } from '../../constants/colours'
import { getUrl } from '../../constants/urls'
import { useContext } from 'react'
import globalContext from '../../context/global/globalContext'
import { isTestEnv } from '../../basetool/methods'

// Home Page UI
export const Home = () => {
    // const urlSearch = new URLSearchParams(window.location.href)
    const setParams = useContext(globalContext)
    const urlSearch = window.location.href
    if (urlSearch.includes("?")) {
        console.log("urlSearch: ", urlSearch.split("?"))
        const parmas = urlSearch.split("?")[1]
        setParams.setParams(parmas)

    }
    return (
        <section className="home">
            <Header />
            <Slide direction="up" in={true} timeout={400} unmountOnExit>
                <div>
                    <Container className="hOne"><HomeOne /></Container>
                    <Container className="clients" fluid><Client /></Container>
                    {/* <Container className="clients" fluid><ECommerceHomepage /></Container> */}
                    {/* <Container style={{ background: 'rgba(97, 129, 119, 0.15)', padding: '75px 0' }} fluid>< PricingComp /></Container> */}
                    <Container className="homeindustry" fluid><HomeIndustry /></Container>
                    <Container style={{ background: 'rgba(97, 129, 119, 0.15)' }} fluid><FeaturesNavbar /></Container>
                    <Container className="hTwo homeTwo" fluid><Container><HomeTwo /></Container></Container>
                    <Container className="hThree" fluid><Container><HomeThree /></Container></Container>
                    <Container className="hFour homeFour" fluid><Container><HomeFour /></Container></Container>
                    <Container className="hFive" fluid><Container><HomeFive /></Container></Container>
                    <Container className="hSix homeFour" fluid><Container><HomeSix /></Container></Container>
                    <Container className="clients" fluid>
                        <Container className="pt-5">
                            <p className='text-center mb-5' style={{ fontSize: '24px' }}><span className="fw-bold" style={{ color: getColour.BaseColor }}>Tools for Businesses</span><span> to start chat on WhatsApp</span></p>
                            <WTBTools />
                        </Container>
                    </Container>
                    <Container className=""><VideoIframe /></Container>
                    <Container className="clients" fluid><Container><BlogViewHome /></Container></Container>
                    <Container><Testimonial /></Container>
                    <Container><FAQ /></Container>
                    <Container><PricingCTA /></Container>
                </div>
            </Slide>
            <Footer />
        </section>
    )
}
export const Client = ({ ClientPage }) => {
    const params = useContext(globalContext)

    function getUrls() {
        if (params.params) {
            console.log("objectParam: ", params.params)
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }

    return (
        <>
            {
                (ClientPage && ClientPage === true) ? (
                    <Container className="pt-5 pb-5 mt-5 mb-5">
                        <h3 className="text-center">Thousands of Business has trusted us in their growth</h3>
                        <p className="text-center mb-5">Join the journey of Growth on WhatsApp</p>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <p className="text-center">
                                    <LazyLoadImage src={getUrl.imageBaseUrl + "/clients.webp"} className="img-fluid w-75" alt={getString.SiteName} />
                                </p>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container className="pt-5 pb-5">
                        <h3 className="text-center">Thousands of Business has trusted us in their growth</h3>
                        <p className="text-center mb-5">Join the journey of Growth on WhatsApp</p>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <p className="text-center"><img src={getUrl.imageBaseUrl + "/clients.webp"} className="img-fluid w-75" alt={getString.SiteName} /></p>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Link to={{ pathname: getUrls() }} target='_blank' ><h4 className="text-center hand" style={{ marginTop: "12px" }}><span className="fw-bold" style={{ color: getColour.BaseColor }}>Join the list now</span></h4></Link>
                            </Col>
                        </Row>
                    </Container>
                )
            }
        </>
    )
}
export const HomeOne = () => {
    const [homeImg, setHomeImg] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setHomeImg(true)
        }, 5000);
    }, [])

    const [openDialog, setOpenDialog] = useState({ open: false, onClose: null })
    const openDialogBox = () => {
        WtEvent(getAnalyticsKey.joinLiveDemoClicked)
        setOpenDialog({ open: true, onClose: () => setOpenDialog(false) })
    }
    return (
        <Container className="pb-5 mt-5" fluid>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                <div style={{ flex: 1, marginTop: isMobile ? '' : '100px' }}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <p style={{ fontSize: '12px' }} className="tx1">Powered by WhatsApp Official Business API &nbsp;</p>
                        </div>
                        <div>
                            <p><img src={getUrl.imageBaseUrl + '/green_tick.svg'} style={{ width: "15px", height: "15px", marginTop: '-10px' }} alt={getString.SiteName} /></p>
                        </div>
                    </div>
                    <p style={{ fontSize: '25px' }} className="tx2 mt-4">WT Business <b>solutions will</b><br />grow your business with WhatsApp</p>
                    <button onClick={() => openDialogBox()} style={{ display: 'flex', color: getColour.HeadingTextColour, background: '#fff', border: '2px solid ' + getColour.HeadingTextColour, padding: '6px 30px 8px 30px', fontSize: '20px', fontWeight: '500', margin: '10px 0px 0px 0px' }} className='btn btn-sm'>
                        <div><p style={{ margin: '4px 0px 0px 0px' }}>Join Live Demo</p></div> &nbsp;&nbsp;&nbsp;
                        <div>
                            <Carousel style={{ width: '40px' }}>
                                <Carousel.Item interval={500}>
                                    <img src={getUrl.imageBaseUrl + '/demo1.jpg'} style={{ width: '40px', height: '40px', borderRadius: '30px' }} alt={getString.SiteName} />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img src={getUrl.imageBaseUrl + '/demo2.jpg'} style={{ width: '40px', height: '40px', borderRadius: '30px' }} alt={getString.SiteName} />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img src={getUrl.imageBaseUrl + '/demo3.jpg'} style={{ width: '40px', height: '40px', borderRadius: '30px' }} alt={getString.SiteName} />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img src={getUrl.imageBaseUrl + '/demo4.jpg'} style={{ width: '40px', height: '40px', borderRadius: '30px' }} alt={getString.SiteName} />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </button>
                </div>
                <div style={{ flex: 2 }}>
                    <LazyLoadImage alt={getString.SiteName} src={homeImg ? getUrl.imageBaseUrl + "/wtb_home-top_banner_01_img.webp" : getUrl.imageBaseUrl + "/wtb_home_top_banner_01.gif"} className="img-fluid w-100" />
                </div>
            </div>
            {openDialog.open && <JoinLIVEDemoDialog open={openDialog.open} onClose={openDialog.onClose} />}

        </Container>
    )
}
export const HomeTwo = () => {
    return (
        <Container className="p-5 pTwo" fluid>
            <Row className="pt-5 pTwo2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ color: getColour.BaseColor, fontSize: '30px' }}>WhatsApp Power for your Business</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>WhatsApp Business API helps reach customers on the most<br />trusted app using the WhatsTool platform on the web and mobile.</p>
                    <button onClick={() => window.open(getUrl.wpPowerbusinessUrl, '_self')} className="btn btn-sm text-white shadow-lg mt-5 fw-bold" style={{ background: getColour.BaseColor }}>Learn More</button>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end">
                        <LazyLoadImage alt={getString.SiteName} src={getUrl.imageBaseUrl + "/wtb_02.webp"} className="img-fluid homeRImg" />
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

// style={{ background: 'rgba(97, 129, 119, 0.15)' }}
export const HomeThree = () => {
    return (
        <Container className="p-5 pThree" fluid>
            <Row className="pt-5 pThree2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ color: getColour.BaseColor, fontSize: '30px' }}>Work Together with<br />Multiple Agents on 1 number</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Your customers are on WhatsApp. All the time. So when they’re<br />looking to buy, and they message you, they’re at their moment<br />of highest intent. Engaging these visitors, get their attention<br />and move them along - on 1 number.</p>
                    <button onClick={() => window.open(getUrl.wpMultiagentUrl, '_self')} className="btn btn-sm text-white shadow-lg mt-5 fw-bold" style={{ background: getColour.BaseColor }}>Learn More</button>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end">
                        <LazyLoadImage alt={getString.SiteName} src={getUrl.imageBaseUrl + "/wtb_03.webp"} className="img-fluid homeRImg" />
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeFour = () => {
    return (
        <Container className="p-5 pFour" fluid>
            <Row className="pt-5 pFour2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ color: getColour.BaseColor, fontSize: '30px' }}>Communicate, at scale<br />Broadcast Messages</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Customers who message you on WhatsApp are your most<br />engaged customers and prospects. Have personalized<br />conversations with them at scale – so you can engage, qualify,<br />and convert them in real time, day or night.</p>
                    <button onClick={() => window.open(getUrl.wpBroadcastmsgUrl, '_self')} className="btn btn-sm text-white shadow-lg mt-5 fw-bold" style={{ background: getColour.BaseColor }}>Learn More</button>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="text-center">
                        <LazyLoadImage alt={getString.SiteName} src={getUrl.imageBaseUrl + "/try_wtb_api.webp"} className="img-fluid homeRImg" />
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeFive = () => {
    return (
        <Container className="p-5 pFive" fluid>
            <Row className="pt-5 pFive2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ color: getColour.BaseColor, fontSize: '30px' }}>Build your Bot within a matter of minutes.<br />No codes required</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Setup your Chatbot to interact with users just like any agent<br />would. Create, test, and run your no-code Chatbot. Set a<br />standard with the quality of customer support, engagement,<br />and interaction.</p>
                    <button onClick={() => window.open(getUrl.wpChatbotUrl, '_self')} className="btn btn-sm text-white shadow-lg mt-5 fw-bold" style={{ background: getColour.BaseColor }}>Learn More</button>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end">
                        <LazyLoadImage alt={getString.SiteName} src={getUrl.imageBaseUrl + "/chatbotHomePageImg.webp"} style={{ boxShadow: '0px 0px 6px ' + getColour.BaseColor, borderRadius: '8px' }} className="img-fluid homeRImg" />
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeSix = () => {
    return (
        <Container className="p-5 pSix" fluid>
            <Row className="pt-5 pSix2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ color: getColour.BaseColor, fontSize: '30px' }}>Integration with other apps<br />and system of your need </h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Easily integrate with your existing CRM databases,<br />ecommerce stores, payment &amp; logistic partners.</p>
                    <button onClick={() => window.open(getUrl.wpIntegrationUrl, '_self')} className="btn btn-sm text-white shadow-lg mt-5 fw-bold" style={{ background: getColour.BaseColor }}>Learn More</button>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end">
                        <LazyLoadImage alt={getString.SiteName} src={getUrl.imageBaseUrl + "/wtb_06.webp"} className="img-fluid homeRImg" />
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export const ECommerceHomepage = () => {
    const s1 = { color: getColour.DescriptionTextColour, fontSize: "16px" }
    const s2 = { color: getColour.LightTextColour, ontSize: '20px', fontWeight: '500' }
    const history = useHistory()
    return (
        <Container className="him_ecom_homepage pt-5 pb-5">
            <Row>
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                    <div>
                        <p className="text-center">
                            <LazyLoadImage src={getUrl.imageBaseUrl + '/ecomm_home_anim.gif'} className='img-fluid' alt={getString.SiteName} />
                        </p>
                    </div>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >
                    <div className="ms-4" style={{ marginTop: '-10px' }}>
                        <p className="mt-5" style={{ fontSize: '24px', fontWeight: '500' }}>Sell, Promote and Grow Your E-Commerce Store Online </p>
                        <p style={s2} className="mt-2">Boost Your Sales Connecting with us</p>

                        <p style={s1} className="mt-4">Perform complete Process using single platform. Show your catalog, Get orders, Payment process, Sales update, Customised discount message, Recover abandoned cart, provide customer service, promote your sales and many more.</p>
                        <button onClick={() => history.push(getUrl.ecommerceSectorUrl)} className="btn btn-sm text-white ps-3 pe-3 rounded-3 fw-bold mt-4" style={{ background: getColour.BaseColor }}>Try Now</button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export const HomeIndustry = () => {

    const allIndustryDescriptions = [
        { images: 'https://user-images.githubusercontent.com/117907145/229727984-43300c77-ffc8-45fa-a3af-3d972fa05886.png', url: getUrl.ecommerceSectorUrl, info: 'E-Commerce Industry', title: 'Sell, Promote and Grow your E-Commerce Store Online', description: 'Perform the complete process using a single platform. Show your catalog, Get orders, Payment process, Sales update, Customised discount messages, Recover abandoned carts, Provide customer service, Promote your sales, and many more.', btn: 'Try Now' },
        { images: 'https://user-images.githubusercontent.com/117907145/229728087-2ecc11d5-d670-403f-a0a2-340c6a9348dd.png', url: getUrl.healthSectorUrl, info: 'Healthcare Industry', title: 'Admit, Remind, send notification, and provide customer service 24*7', description: 'Perform the complete process using a single platform. Take appointments online, Payment process, Offer updates, Report notifications, Post consultation follow-up, Provide customer service, and Increase your patient rate.', btn: 'Try Now' },
        { images: getUrl.imageBaseUrl + '/media/educ_cationwt.png', url: getUrl.educationalSectorUrl, info: 'Education Industry', title: 'Admit, Onboard, Remind, send notification, solve queries and provide customer service 24*7', description: 'Perform the complete process using a single platform. Take admission online, Online parents & teachers meetings, Important updates & notifications, Solve student queries, Provide customer service, and Increase your student rate.', btn: 'Try Now' },
        { images: 'https://user-images.githubusercontent.com/117907145/229728180-43f6bb98-668a-4528-b7f1-dcb782504ee4.png', url: getUrl.tourTravelSectorUrl, info: 'Tours & Travels Industry', title: 'Assist, coordinate,recommend and provide customer service 24*7', description: 'Perform the complete process using a single platform. Take bookings online, Payment process, Booking remainder, Make repeat customers, Share exciting deals and offers, Provide customer service, and Increase your sales rate.', btn: 'Try Now' },
    ]
    const history = useHistory()

    return (
        <section style={{ margin: '0 -12px' }} className='pt-5'>
            <Container fluid>
                <Container>
                    <div className='mb-4'>
                        <Row>
                            <div className="text-center" style={{ marginBottom: '60px' }}>
                                <h3>Solve your Industry problem and Increase the sales</h3>
                                <p style={{ color: '#9EA4B5' }}>We solve the problems of all types of Industry. Which Industry do you have?</p>
                            </div>
                            {
                                allIndustryDescriptions?.map((v, i) => {
                                    return (
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} key={i}>
                                            <div className="Indboxshadow" style={{ margin: isMobile ? '0px 0px 65px 0px' : '0 20px 65px 20px', minHeight: isMobile ? '230px' : '300px' }}>
                                                <div style={{ display: 'flex', alignItems: 'end' }}>
                                                    <div>
                                                        <img src={v.images} style={{ height: '140px', width: '250px' }} alt="Shopping" className="img-fluid" />
                                                    </div>
                                                    <div>
                                                        <h4 style={{ color: '#273041' }}>{v.info}</h4>
                                                        <p style={{ color: '#9EA4B5' }}>{v.title}</p>
                                                    </div>
                                                </div>
                                                <div style={{ color: '#616874', padding: '20px 20px 0 38px' }}>
                                                    <p dangerouslySetInnerHTML={{ __html: v.description }}></p>
                                                </div>
                                                <div className="text-center" style={{ position: 'relative', top: '15px' }}>
                                                    <button onClick={() => history.push(v.url)} className="text-white btn btn-sm ps-4 pe-4 pt-1 pb-1 rounded-3" style={{ background: '#00A82D' }}>{v.btn}</button>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Container >
            </Container >
        </section >
    )
}

export const PricingComp = () => {
    return (
        <section >
            <Container>
                <div>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p className="text-center"><img src="./templatesimg/pricing_03.png" roundedCircle alt={getString.SiteName} style={{ borderRadius: '4px' }} className="img-fluid "></img></p>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="pt-4" style={{ marginLeft: isMobile ? '0' : '40px' }}>
                                <h4 className="fw-bold" style={{ color: getColour.HeadingTextColour }}>WhatsTool Business Pricing </h4>
                                <p className="text-muted mt-3">Grow your Business with WhatsTool Business and increase your sales and marketing.</p>
                                <p className="text-muted mt-3 mb-5">WhatsTool Business pricing starts from Rs. 999/ month and also you can customise your plans according to your requirements.</p>
                                <Link to={getUrl.pricingUrl} ><button className="text-white btn btn-sm ps-3 pe-3 pt-2 pb-2 fw-semibold" style={{ background: getColour.BaseColor, borderRadius: '8px', border: '1px solid' + getColour.BaseColor }}>Explore Pricing</button></Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
// End of Home Page UI

