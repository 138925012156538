import React, { useState } from 'react'
import './terms.css'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Container, Row, Col, Carousel, Tab, Nav } from 'react-bootstrap'
import { getUrl } from '../../constants/urls'
import { getColour } from '../../constants/colours'
import { getString } from '../../constants/strings'
import SearchIcon from '@mui/icons-material/Search';
import { isDesktop, isMobile } from 'react-device-detect'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'


export const Support = () => {
    return (
        <Container >
            <h4 className="text-center mt-5 mb-5" style={{ color: getColour.DescriptionTextColour }}>Customer Support</h4>
            <div>
                <h6><span style={{ color: getColour.HeadingTextColour }}>Official WhatsApp No.:</span> <span style={{ color: getColour.DescriptionTextColour }}>+91 73539 02312</span></h6>
                <h6><span style={{ color: getColour.HeadingTextColour }}>Email:</span> <span style={{ color: getColour.DescriptionTextColour }}>support@whatstool.in</span></h6>
            </div>
        </Container>
    )
}
export const TermsOfService = () => {
    return (
        <Container className="terms mt-5 mb-5  pb-5">
            <article id="960bc714-ba92-457c-a62e-614deee596a2" class="page sans">
                <header>
                    <h1 class="page-title">Terms of Service </h1>
                </header>
                <div class="page-body">
                    <p id="45f1f433-bf2e-499d-a69a-d053e5c2ea58" class="">WhatsTool Tech </p>
                    <p id="6af3e58c-19cb-4eee-8675-d85916846c07" class="">This Privacy Policy (hereinafter referred to
                        as“Policy” ), helps the users accessing the Website/App (“User”/ “You”/ “Your”) and the Service therein
                        to understand the type of information collected, the purpose for which the information is collected; how
                        the information is used; the intended recipients of such information; and how the information is
                        protected. We take protection and proper use of Your information seriously and are committed to
                        protecting such information in Our possession. We advise You to read this Policy carefully prior to Your
                        access of Website/App, use of Services, or creation of an Account on the Website/App. If You do not
                        agree to this Policy, please do not access Our Website/App, use Our Services, or create an Account in
                        Our Website/App.</p>
                    <p id="772db4ed-4218-4453-bc88-1dc9b7698510" class="">If We learn that We have collected the Personal
                        Information (defined below) from a User who is under the age of 18 that was not provided under the
                        supervision and consent of the minor’s parents or guardians, We will promptly delete such information.
                        If You believe that We have collected Personal Information from someone under the age of 18, please
                        contact us at support@whatstool.in.</p>
                    <p id="cb7b2e0e-f941-4eae-97d9-97edd691dfcb" class="">Any capitalized terms used herein without definition
                        shall have the meanings assigned to them in Our Terms of Use.</p>
                    <p id="cde3837f-d8a6-4b0f-897f-a39df4b51d56" class="">
                    </p>
                    <p id="d498db78-7f55-410c-ad5f-67c751b9c2d3" class=""><strong>INFORMATION COLLECTED BY US</strong></p>
                    <p id="e018ed3a-01f9-4c00-a16b-6976dabdc38c" class="">When You access our Website/App and/or choose to avail
                        the demo of the Product and/or sign up on the Website/App for a free trial of the Product provided by Us
                        through the Website/App, We shall collect certain information from You, the details of which are
                        provided hereinafter. The categories and sources of the Information collected by Us are as follows:</p>
                    <p id="b908e790-187d-4c42-aa02-7cdc8073d18c" class="">Personal Information</p>
                    <p id="04445eaa-1674-470c-bd5e-ad49d6d501b5" class="">Any Information that relates to You as a natural
                        person, which, either directly or indirectly, in combination with other information available or likely
                        to be available to a body corporate, is capable of identifying You and any further information,
                        including, without limitation, provided by You to Us while signing up for availing the free trial such
                        as:</p>
                    <p id="4aec7414-290f-4b50-9cb4-d083209ee929" class="">Your name,</p>
                    <p id="1e0a2193-fc74-478f-a543-b59c7e3d3722" class="">Phone number associated with the Whatsapp,</p>
                    <p id="ec845d30-d29d-4d55-8557-a8bfa9661f26" class="">Company name and address</p>
                    <p id="74189c81-ae31-4def-9175-8b3de6a0338b" class="">Your designation</p>
                    <p id="725fcc1e-b272-4b75-a68a-233437d73aee" class="">Web address of Your company</p>
                    <p id="30ba9e86-9bd9-4c87-9a7c-aff1d5f7c396" class="">Social media login credentials,</p>
                    <p id="6ff0582c-7840-4ef3-9a40-ac5939102516" class="">Any other identifiers and any other sensitive/personal
                        information as defined under the Information Technology (Reasonable Security Practices and Procedures
                        and Sensitive Personal Data of Information) Rules, 2011 as amended from time to time and such other data
                        privacy laws whether now in force or in future(“Personal Information”).</p>
                    <p id="4ee96e58-c1c8-4097-bcd1-4b1af045bdab" class="">Usage Information:</p>
                    <p id="84ba2674-e0ed-4c4f-8757-18328bf1d300" class="">Any information that is not Personal Information, but
                        information collected in relation to Your use of the Website/App or information required for the proper
                        functioning and development of Website/App, including, without limitation:</p>
                    <p id="854ef39f-42a3-44e8-a152-b95f90077d1f" class="">time, date and extent of Your usage of the
                        Website/App;</p>
                    <p id="d4aeeced-19cb-4e06-a5f3-78cc17cab6fa" class="">Your usage and search history within the Website/App,
                    </p>
                    <p id="8a87b2bc-255c-49f7-ba09-cf47447cd10d" class="">device keyboard settings;</p>
                    <p id="c885022a-ff2d-4829-b829-3ea58f5c2776" class="">time zone, language, screen resolution, and other
                        usage preferences You select when using the Website/App;</p>
                    <p id="c431c3f8-cd97-4428-a532-1373a29267a6" class="">the URL or advertisement that may have directed You to
                        the Website/App;</p>
                    <p id="5a090535-6b25-4221-83e7-c0d04f9b4007" class="">other device and Website/App access information viz
                        Your IP address, operating system, browser type, referring/exit pages, and other unique device
                        identifiers, as well as Your push notification token.</p>
                    <p id="c6193a53-ab19-4ed8-9814-781a35408172" class="">Behavioral Information:</p>
                    <p id="22d4cc6f-77c6-49a1-bd3b-83d83c3a0c1f" class="">We shall collect information which is connected with
                        Your activity on the Website/App across all Your devices using Your email or social media log-in
                        details, as well as Services that You search, and opt-ins and communication preferences.</p>
                    <p id="6bc9f094-6b1d-47d5-9933-6519b54a3fd2" class="">Location Data:</p>
                    <p id="235ea6f6-9287-49a0-a905-f81a1c683503" class="">Information about Your location when accessing and/or
                        using the Website/App if You chose to opt-in. With Your permission, We may collect Global Positioning
                        System (GPS) data and mobile device location information. If You do not wish to share Your precise
                        location with Us, You can switch off location services via the settings on Your mobile device.</p>
                    <p id="d449f628-a1f9-4fb6-b3b4-4e688ad1b6ee" class="">Contact List and Files:</p>
                    <p id="0386b36c-1d38-490d-a921-d5acb337c98c" class="">We shall allow you to upload your existing contact
                        list that contains name, mobile number, email and other data fields to initiate conversations from our
                        Website/App. We collect the information related to your device&#x27;s audio, video and other rich media
                        files such as PDF documents, and enable you to share rich media files to your customers through our
                        Website/App.</p>
                    <p id="c48fdfbe-21ba-40e7-86eb-040531afc137" class="fw-bold">Cookies:</p>
                    <p id="de4178f6-4618-4367-998e-b13d61b37f5a" class="">We and Our service providers and business partners use
                        cookies and other similar technologies (e.g. web beacons, flash cookies, etc.) (“Cookies”) to
                        automatically collect information, measure and analyze which web pages and advertisements You click on
                        and how You use the Website/App, enhance Your experience using the Website/App, improve the Website/App,
                        provide You with advertising on the Website/App and elsewhere across Your devices, and measure the
                        effectiveness of advertisements. Cookies enable the Website/App to provide certain features and
                        functionality. Web beacons are very small images or small pieces of data embedded in images, also known
                        as “pixel tags” or “clear GIFs,” that can recognize Cookies, the time and date a page is viewed, a
                        description of the page where the pixel tag is placed, and similar information from Your computer or
                        device.</p>
                    <p id="d876e6ae-a946-4e90-8b09-1d30f376daf5" class="fw-bold">Device Information:</p>
                    <p id="ddaf3eb9-b28b-4ad0-ab56-174dfb9e4492" class="">We collect information about computers, phones, and
                        other connected devices with a web connection and the presence of Our Website/App and Services and
                        integrate this information with the various devices You use. This information includes: device features
                        such as information about operating system, hardware and software versions, battery status, signal
                        availability, available storage space, browser type, app or file names and types, and plug-ins, device
                        activities such as information about the activity and behavior of the device, for example in the
                        background or in front of a window or with mouse movements (which help to distinguish humans from
                        boats), unique identifiers, device ID, games, apps, or accounts You use, Bluetooth signals and
                        information about nearby WiFi access points, beacons and cell towers, Information that We are permitted
                        to collect by turning on access in device configuration, as well as providing access to GPS location,
                        camera or photo, information such as Your mobile operator&#x27;s name or ISP, language, time zone,
                        mobile phone number, IP address, connection speed, and other devices near Your device or on the network
                        so that We can help You with the streaming of the Services on Your device.</p>
                    <p id="7b70b569-8abd-4eb0-a717-6e2711c3db42" class="fw-bold">Third party Information</p>
                    <p id="0f2ca1be-6566-4362-8761-a010e5549ca7" class="">Information about You that We may receive from third
                        parties services, such as advertising partners, data providers and analytics providers.</p>
                    <p id="eab3a3cd-ffd5-4fee-96bc-922d04fa2b35" class="">We may collect Your third-party social media networks
                        information including Your contact lists for these services and information relating to your use of the
                        Website/App in relation to these services, if You choose to link or sign up using a third-party social
                        network or login service (such as Facebook, Twitter, Instagram, or Google). If you link your Account in
                        our Website/App to another service, We may receive information about Your use of that service.</p>
                    <p id="6e5027d6-1875-4435-93f7-3bdcd49a47f1" class="">
                    </p>
                    <p id="6ab5bcff-7496-46e8-afff-cd4a0084e351" class="fw-bold"><strong>USAGE OF YOUR INFORMATION</strong></p>
                    <p id="00a53ca3-1d23-4419-8bc5-3c4f01cd982b" class="">As explained below, We use Your information to
                        improve, support and administer the Website/App, to allow You to use its functionalities, and to fulfill
                        and enforce Our Terms of Use. We may also use Your information to, among other things, show You
                        suggestions, promote the Website/App, and customize Your ad experience.</p>
                    <p id="815aee96-83bf-4f24-aa76-0d5c4c9a46b0" class="">We generally use the information We collect before
                        signing up on the Website/App:</p>
                    <ul>
                        <li>to structure the Services provided to the users through the Website/App</li>
                        <li>to fulfill requests for services, Website/App functionality, support and information for internal operations, including troubleshooting, data analysis, testing, research, statistical, and survey purposes;</li>
                        <li>to communicate with You, including to notify You about changes in Our services;</li>
                        <li>quality assurance purposes</li>
                        <li>to improve and develop Our Website/App and conduct service development;</li>
                        <li>to customize the Service, You see when You use the Website/App; to enforce Our terms, conditions, and policies;</li>
                        <li>to prove Your identity in order to use certain features,</li>
                        <li>to combine all the information, We collect or receive about You for any of the foregoing purposes;</li>
                        <li>to maintain security and help Us detect abuse, fraud, and illegal activity on the Website/App;</li>
                        <li>to understand how You use the Website/App, including across Your devices;</li>
                        <li>to support the social functions of the Website/App;</li>
                        <li>to make suggestions and provide a customized ad experience;</li>
                        <li>to send promotional materials from Us or on behalf of Our affiliates and trusted third parties;</li>
                        <li>to measure and understand the effectiveness of the advertising We serve to You and others and to deliver advertising;</li>
                        <li>consistent with Your permissions, to provide You with location-based services, such as advertising and other personalized content;</li>
                        <li>for any other purposes disclosed to You at the time We collect Your information or pursuant to Your consent.</li>
                    </ul>
                    <p id="61174238-73c2-4bb7-bc6a-6d348c8f407d" class="">We shall not sell or rent Your Personal Information to
                        anyone, for any reason, at any time. However, We will be sharing Your Personal Information with Our
                        affiliates and business partners. We assure that We will always seek Your consent to process certain
                        types of information where it is legally required to do so.</p>
                    <p id="26d5450c-9c00-44b2-ab3f-57bfd85e6653" class="">
                    </p>
                    <p id="27107a16-54ed-44d8-b2fe-137d1a3220d7" class="fw-bold"><strong>SHARING YOUR INFORMATION</strong></p>
                    <p id="98a2a4f0-d227-4d37-86fa-7ff2b314e718" class="">We want You to understand when and with whom We may
                        share the information We collect for business purposes. Your information is shared with others in the
                        following ways:</p>
                    <p id="11e62b34-4b0d-4027-935b-c76540ebb8b2" class="fw-bold">Public Information:</p>
                    <p id="97d6fedd-4bff-412c-a54f-6e300df41564" class="">Personal Information that You upload to the
                        Website/App (such as certain Account profile information), certain other information of You, will
                        automatically, or upon Your selection, be made publicly available to other Users on the Website/App.</p>
                    <p id="5dfef693-9bf2-48d9-8990-69b35c2dfde0" class="fw-bold">Legal Reasons:</p>
                    <p id="2f5100c5-0bb0-4a9f-b312-f0392f5a0d1d" class="">We may disclose any of the information We collect to
                        respond to summons, court orders, legal process, law enforcement requests, legal claims, or government
                        inquiries, and to protect and defend the rights, interests, safety, and security of Us, the Website/App,
                        Our affiliates, Users, or the public. We may also share any of the information We collect to enforce any
                        terms applicable to the Website/App, to exercise or defend any legal claims, and comply with any
                        applicable law.</p>
                    <p id="621a2fb1-2a4d-4708-8f59-987dbd3d35fc" class="fw-bold">Sale, Merger, or Other Business Transfer:</p>
                    <p id="02432305-30aa-42f1-90dc-d2411062b847" class="">We may share all of the information We collect in
                        connection with a substantial corporate transaction, such as the sale of a website/app, a merger,
                        consolidation, asset sales, or in the unlikely event of bankruptcy.</p>
                    <p id="2da5b191-0dcf-4470-aaf9-6bcd11877d98" class="fw-bold">Service Providers, Business Partners:</p>
                    <p id="9cc96f03-f94b-4cf2-918d-9257235c467c" class="">We share the categories of Personal information listed
                        above with service providers and business partners to help Us perform business operations and for
                        business purposes, including research, database maintenance, administering contests and special offers,
                        technology services, deliveries, sending communications, advertising, analytics, measurement, data
                        storage and hosting, disaster recovery, search engine optimization, marketing, and data processing.
                        These service providers and business partners may include:</p>
                    <p id="0fa681f2-a0cd-40ac-8f14-657e0163b79e" class="">advertising, marketing, and analytics vendors, who may
                        receive the information You choose to provide, the information We obtain from other sources, and the
                        information We collect automatically.</p>
                    <p id="b7a87dde-e788-4645-9886-534a18b7f232" class="">Cloud providers, who may receive the information You
                        choose to provide, the information We obtain from other sources, and the information We collect
                        automatically.</p>
                    <p id="21549d8f-ae81-44da-aa95-d698f2604ca8" class="">Research providers, who may receive the information
                        You choose to provide, the information We obtain from other sources, and the information We collect
                        automatically.</p>
                    <p id="f991dc91-ccd0-45c1-8e40-2e83f30426c0" class="">Customer and technical support providers, who may
                        receive the information You choose to provide, the information We obtain from other sources, and the
                        information We collect automatically.</p>
                    <p id="ef92cdc8-2a64-45dd-9a0b-98ef21788f5d" class="fw-bold">Third Parties.</p>
                    <p id="65ed149c-7ada-4be5-8866-f978da955f6c" class="">We may share aggregated usage information and may
                        otherwise disclose non-Personal Information that We collect to third parties. However, absent Your prior
                        consent, We will share Your Personal Information with third parties only in the ways that are described
                        in this Policy, including as set forth below.</p>
                    <p id="2c33a300-5cab-4571-adfb-a2288c55e467" class="">We may use third parties to outsource one or more
                        aspects of Our business and/or Website/App operations (such as email or customer service functions, data
                        processing, web analytics, maintenance, online advertising, and security execution and clearing
                        services), in which case, We may provide Your Personal Information to such third parties in connection
                        with the performance of such activities. Such third parties will only use Your Personal Information to
                        the extent necessary to perform their functions and will be contractually bound to process Your Personal
                        Information only on Our behalf and in compliance with Our requests.</p>
                    <p id="117b3446-9b2e-4e89-a183-ed40fd223737" class="">We will also share Your information with any member,
                        subsidiary, parent or affiliate of Our corporate group, to assist in the improvement and optimization of
                        the Website/App, in order to prevent illegal uses, increase user numbers, development, engineering and
                        analysis of information or for Our internal business purposes.</p>
                    <p id="3af9b88a-788b-4a45-a6ff-14e3f0125841" class="">In the unlikely event of Our bankruptcy, insolvency,
                        reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or
                        equitable principles affecting creditors’ rights generally, We may not be able to control how Your
                        Personal Information is treated, transferred, or used.</p>
                    <p id="cd66b686-9e4c-4021-87f3-c85c09638073" class="">
                    </p>
                    <p id="19cfbcb8-bda1-48ad-9a89-669672bde1fe" class=""><strong>YOUR RIGHTS</strong></p>
                    <p id="fc670e8c-181b-4a21-b343-5ab9c10650fa" class="">In certain circumstances, You have the right to access
                        the Personal Information that We hold about You and to correct, update, or request deletion Your
                        Personal Information. Prior to the fulfilment of Your request concerning Your Personal Information, We
                        will ask You to verify Your identity before We can act upon Your request. You have the following rights:
                    </p>
                    <p id="28414ff4-274d-481d-b365-1332d3bf0d95" class="">The right to request proper rectification, removal or
                        restriction of Your Personal Information;</p>
                    <p id="b38ccaf7-449a-4f46-b271-3d25a52e2014" class="">The right to require free of charge (1) confirmation
                        of whether We process Your Personal Information and (2) access to a copy of the Personal Information
                        retained;</p>
                    <p id="376b2d7c-0a48-4837-832c-75dfdc3c9b20" class="">The right to take legal actions in relation to any
                        breach of Your rights regarding the processing of the Personal Information, as well as to lodge
                        complaints before the competent data protection regulators.</p>
                    <p id="de18359b-c20c-4ea8-a0ba-082cd5bd3233" class="">The right not to be subject to any automatic
                        individual decisions which produces legal effects on You or similarly significantly affects You;</p>
                    <p id="ee22cb8a-4cdc-4d97-ab8e-bd32c9d1fb28" class="">Where the processing of Your Personal Information is
                        based on Your consent, the right to withdraw Your consent at any time without impact to data processing
                        activities that have taken place before such withdrawal or to any other existing legal justification of
                        the processing activity in question;</p>
                    <p id="c0a49f53-9557-4622-aa40-6c09867f7a78" class="">Where processing of Your Personal Information is
                        either based on Your consent or necessary for the performance of a contract with You and processing is
                        carried out by automated means, the right to receive the Personal Information concerning You in a
                        structured, commonly used and machine-readable format or to have Your Personal Information transmitted
                        directly to another company, where technically feasible (data portability);</p>
                    <p id="f6aac58a-6074-4106-9aa5-632406d8d402" class="">As far as We process Your Personal Information on the
                        basis of Our legitimate interests, You can object to processing at any time. You can find a detailed
                        description of Our processing activities and the legal basis in the sections above. If You object to
                        such processing, We ask You to state the grounds of Your objection in order for Us to examine the
                        processing of Your Personal Information and decide whether to adjust the processing accordingly.</p>
                    <p id="5730336d-45cd-41f8-b671-6e98358d4bc6" class="">
                    </p>
                    <p id="c47a2a32-cdc5-4fe6-afc2-014452075460" class=""><strong>SECURITY</strong></p>
                    <p id="1bc49cc3-5945-403f-a60e-14b70e43945f" class="">We follow generally accepted standards to protect the
                        Personal Information submitted to Us, both during transmission and once We receive it. Since no method
                        of transmission over the Internet, or method of electronic storage, is 100% secure, therefore, We cannot
                        guarantee its absolute security. If You have any questions about security on Our Website/App, You can
                        contact Us at support@whatstool.in.</p>
                    <p id="e03a7ead-9dad-4d14-bef5-a1f6ea9f0a2a" class="">We use a combination of firewalls, encryption
                        techniques and authentication procedures, among others, to maintain the security of Your online session
                        and to protect Our online accounts and systems from unauthorized access.</p>
                    <p id="1f910ff2-91f6-499e-8c81-dfe0c20a95ad" class="">When You register for the service, We require a
                        password from You for Your privacy. We transmit information such as Your login credentials for
                        Website/App or account credentials securely.</p>
                    <p id="fc12dd38-5a1d-40d3-acd8-012eb298fa31" class="">Our servers are in secure facilities where access
                        requires multiple levels of authentication, including an identity card and biometrics recognition
                        procedures. Security personnel monitor the facilities 7 days a week, 24 hours a day.</p>
                    <p id="5baad23d-a0fd-4112-8a13-6396c5269f3c" class="">Our databases are protected from general employee
                        access both physically and logically.</p>
                    <p id="8d404c99-7bad-4994-9dd7-992721a37243" class="">We enforce physical access controls to Our buildings.
                        No employee can put Personal Information on any insecure machine (i.e., nothing can be taken from the
                        database and put on an insecure laptop). We permit only authorized employees who are trained in the
                        proper handling of customer information, to have access to aforesaid Personal Information.</p>
                    <p id="d56aeea6-aa27-4744-a65b-bd23ed2b8669" class="">
                    </p>
                    <p id="5376a2b5-863b-43a0-8468-174f02dd9c55" class=""><strong>STORAGE AND TRANSFER OF INFORMATION</strong>
                    </p>
                    <p id="6adee055-2b8b-471e-8279-24bb735384e7" class="">Information collected by Us may be stored and
                        processed in India or any other country in which We or Our agents maintain facilities. By using the
                        Website/App, You expressly consent to any such transfer and storage of information outside of Your
                        country of residence, where data protection laws may be different and/or less stringent. However, We
                        will endeavor to take reasonable measures to keep up an adequate level of data protection also when
                        sharing Your Personal Information with such countries.</p>
                    <p id="f45a571b-eaae-4be5-8337-405c66b8098f" class="">We make no representation or warranty with respect to
                        any duty to permanently store any information You may provide or that We otherwise collect about You
                        except as required by the laws in India.</p>
                    <p id="483581db-b80b-42f3-8619-8d5799dab649" class="">We strive to keep Our processing activities with
                        respect to Your Personal Information as limited as possible. In the absence of specific retention
                        periods set out in this Policy, Your Personal Information will be retained only for as long as We need
                        it to fulfil the purpose for which We have collected it and, if applicable, as long as required by
                        statutory retention requirements. Typically, this means We retain Your Personal Information for as long
                        as Your Account is active and for up to 180 days following deletion of Your Account. Please note that
                        statutory storage obligations or the need for legal actions that may arise from misconduct within the
                        Website/App can lead to a longer retention of Your Personal Information. By using the Website/App and
                        providing Us with information (including Personal Information), You waive any claims that may arise
                        under Your own or any other local or national laws, rules or regulations or international treaties. We
                        may from time to time transfer or merge Your information collected off-line to Our online databases or
                        store off-line information in an electronic format. This Policy applies to Your information for as long
                        as Your information is in Our possession, even if You terminate or discontinue Your use of the
                        Website/App</p>
                    <p id="87d1eec4-b711-468e-aca4-51a128a30dab" class="">In the event that We undergo re-organization, are sold
                        to, or merged with a third party, or sell all or substantially all of Our assets, any Personal
                        Information We hold about You may be transferred to that re-organized entity or third party in
                        compliance with applicable law.</p>
                    <p id="4515d725-210c-4f18-b5d7-294642190b37" class="">In the unlikely event of Our bankruptcy, insolvency,
                        reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or
                        equitable principles affecting creditors’ rights generally, We may not be able to control how Your
                        Personal Information is treated, transferred, or used.</p>
                    <p id="9b1c894f-2729-4fd6-9888-0dbca1c7527e" class="">
                    </p>
                    <p id="f875c10f-fb50-4dcf-ae39-b8a96135b098" class=""><strong>LINK TO THIRD PARTY WEBSITES</strong></p>
                    <p id="a1284491-93c1-48c4-9a75-8674d0683c4b" class="">The Website/App may include links to other website,
                        mobile applications, or services (“Third Party Sites”), whose privacy practices may differ from those
                        set forth herein. Such links are not an endorsement by Us of those third-party Sites and/or the products
                        or services they offer. If You visit Third Party Sites or submit Information to any of those Third-Party
                        Sites, Your visit and Information is governed by their privacy policy. We encourage You to carefully
                        read the privacy policy of any Third-Party Site You visit, as it may differ substantially from that of
                        this Policy. We make no representations or warranties with respect to, nor are We responsible for the
                        privacy policies of, any Third-Party Sites. If You decide to click on any such links or access any
                        Third- Party Sites appearing on the Website/App, You do so at Your own risk.</p>
                    <p id="b23b39aa-5df6-471a-a067-5307624d8e50" class="">
                    </p>
                    <p id="7a9732a2-194b-49f0-bbab-b861f8ac9a7a" class=""><strong>INTIMATION BY YOU REGARDING CHANGE IN PERSONAL
                        INFORMATION</strong></p>
                    <p id="16efe35f-8c1a-4d15-8bec-7f991643c075" class="">If Your Personal Information provided to Us when You
                        had registered yourself in Our Website/App changes, You must update it as soon as possible. To review
                        and update Your Personal Information through the “Edit” option in Our Website/App. Note: We will retain
                        Your information for as long as Your account is active or as needed to provide You Services. If You wish
                        to cancel Your account or request that We no longer use Your information to provide You Services, You
                        can delete the Account through the “Delete” option in our Website/App. However, We will retain and Use
                        Your information as necessary to comply with Our legal obligations, resolve disputes, and to enforce Our
                        agreements.</p>
                    <p id="bc751907-ac1a-44c0-9ded-427636e4ce8c" class="">
                    </p>
                    <p id="a85d75a1-d985-4f95-b256-2782628112c2" class=""><strong>EMAIL NOTIFICATIONS AND OPT-OUT</strong></p>
                    <p id="ea95c1f1-84b7-44df-a268-f4db1344732b" class="">We maintain a strict “no-spam” policy. Unless You
                        requested otherwise in Your account settings at the time of Your initial registration or specifically
                        opt out as provided herein, by accepting the Terms of Use and this Policy, You expressly agree that We
                        may use Your information to contact You by email in order to deliver You information that is relevant to
                        Your use of the Website/App such as administrative notices, product/service enhancements or
                        “newsletters”, or that, in some cases, is targeted to Your interests, such as information about
                        Service/functionalities offerings that We believe You may be interested in learning more about. In the
                        event of an intellectual property claim, we may transfer your contact details with the claimant for
                        contacting You to resolve the infringement claim directly with You. You may choose to stop receiving
                        these email communications from Us by following the “unsubscribe” instructions included in such
                        communications. If a third-party vendor provides such newsletters, You may unsubscribe in accordance
                        with the instructions provided by such third party. If You are having problems unsubscribing, please
                        contact us at support@whatstool.in (and You will be removed within seven (7) business days. Please note
                        that We will not process any unsubscribe requests submitted as direct replies to any newsletter.</p>
                    <p id="73764419-56e5-4b56-9eeb-597dfc817c9f" class="">
                    </p>
                    <p id="889500d0-95ea-4c63-84e9-5e6b392879b2" class=""><strong>CHANGES TO POLICY</strong></p>
                    <p id="6ca9b912-f6ae-4fc5-9abf-f27f8cf5b4a2" class="">We may update this Policy from time to time. When We
                        update the Policy, We will notify You by updating the “Last Updated” date at the top of this Policy and
                        posting the new Policy and providing any other notice required by applicable law. We recommend that You
                        review the Policy each time You visit the Website/App to stay informed of Our privacy practices.</p>
                    <p id="60b2f5f4-b697-41a8-8d12-6d511b11106d" class="">
                    </p>
                    <p id="bb47b1b3-b2a2-4c08-b4f5-6ccecab9687a" class=""><strong>CONTACT US</strong></p>
                    <p id="2b6057fa-73ed-4529-a2ff-a95b6688fc44" class="">In the event that You wish to make a complaint or
                        request concerning Your Personal Information or Our privacy practices, or have questions, concerns, or
                        comments about this Policy, please contact Us in the first instance at   and We will endeavor to deal
                        with Your request as soon as possible. You can also write to Us at  support@whatstool.in.   This is
                        without prejudice to Your right to launch a claim with Your data protection authority or follow the
                        dispute process set forth in the Terms of Use.</p>
                </div>
            </article>
        </Container>
    )
}
export const PrivacyPolicy = () => {
    return (
        <Container className="mb-5 pb-5">
            <h4 className="text-center mt-5 mb-5" style={{ color: getColour.DescriptionTextColour }}>Privacy Policy</h4>
            <div>
                <h4>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h4>
                When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.<br />
                When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.<br />
                Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.<br />
                <br /><br />
                <h4>SECTION 2 - CONSENT</h4>
                How do you get my consent?<br />
                When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.<br />
                If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.<br />
                <br /><br />
                <h4>How do I withdraw my consent?</h4>
                If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at support@whatstool.in or mailing us at: C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India.<br />
                <br /><br />
                <h4>SECTION 3 - DISCLOSURE</h4>
                We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.<br />
                <br /><br />
                <h4>SECTION 4 - PAYMENT</h4>
                We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.<br />
                Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.<br />
                PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.<br />
                For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com<br />
                <br /><br />
                <h4>SECTION 5 - THIRD-PARTY SERVICES</h4>
                In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.<br />
                However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.<br />
                For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.<br />
                In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.<br />
                Once you leave our store's website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website's Terms of Service. <br />
                <br /><br />
                <h5>Links</h5>
                When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.<br />
                <br /><br />
                <h4>SECTION 6 - SECURITY</h4>
                To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.<br />
                <br /><br />
                <h4>SECTION 7 - COOKIES</h4>
                We use cookies to maintain session of your user. It is not used to personally identify you on other websites.<br />
                <br /><br />
                <h4>SECTION 8 - AGE OF CONSENT</h4>
                By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.<br />
                <br /><br />
                <h4>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h4>
                We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.<br />
                If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.<br />
                <br /><br />
                <h4>QUESTIONS AND CONTACT INFORMATION</h4>
                If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at whatstoolsapp@gmail.com or by mail at C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India.<br />
                <br /><br />
                [Re: Privacy Compliance Officer]<br />
                [C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India. 560017]<br />
                <br /><br />
            </div>
        </Container>
    )
}
export const Cancellation = () => {
    return (
        <Container className="mb-5 pb-5">
            <h4 className="text-center mt-5 mb-5" style={{ color: getColour.DescriptionTextColour }}>Cancellation &amp; Refund Policy</h4>
            <div>
                <strong>Full Refund</strong><br />
                ✺ Full refund if facing issues with automatic forwarding and reported within 3 days of purchase.<br /><br />
                <strong>80% refund </strong><br />
                ✺ 80% refund of the total amount if having minor issues with bulk message forwarding &amp; reported after 3 days of purchase.<br /><br />
                <strong>No refund</strong><br />
                ✺ No refund request will be accepted if reported after 7-10 days(or longer) of purchase. +<br />
                ✺ No refund request will be accepted if having no issues with bulk message forwarding. +<br />
                ✺ No other reasons would be accepted than these for refund request.
            </div>
        </Container>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const ContactUs = () => {

    const s5 = { width: '90%', height: '15mm', outline: 'none', marginTop: '24px', border: 'none', borderRadius: '5px', padding: '0 17px' }
    const s3 = { width: '90%', height: '25mm', outline: 'none', marginTop: '24px', border: 'none', borderRadius: '5px', padding: '19px 22px' }
    const s6 = { background: '#00a82d', marginTop: '24px' }

    const [openDialog, setOpenDialog] = useState(false);

    const handleClose = () => setOpenDialog(false)

    const [name, setName] = useState(undefined)
    const handleName = (e) => setName(e)

    const [mobilenumber, setMobileNumber] = useState('')
    const handleMobileNumber = (e) => {
        let checkedVal = e?.replace(/[^0-9]/ig, '')
        setMobileNumber(checkedVal)
    }

    const [email, setEmail] = useState(undefined)
    const handleEmail = (e) => setEmail(e)

    const [message, setMessage] = useState(undefined)
    const handleMessage = (e) => setMessage(e)

    const sendReq = async () => {
        try {
            let data = `https://script.google.com/macros/s/AKfycbwfSWGE0anR7ZkSZqHK58tN-eS6wUZq1xto03YnLWLd8zaJvbmaMjyY3bE9CTdThdUS/exec?name=${name}&mobile=${mobilenumber}&email=${email}&message=${message}`

            const response = await fetch(data)
            if (response) {
                handleName('')
                handleMobileNumber('')
                handleEmail('')
                handleMessage('')
            }
        } catch (error) { }
        setOpenDialog(true)
    }

    return (
        <section>
            <Container>
                <div className=' mb-5'>
                    <Row>
                        <div className="text-center mt-5 mb-5 fw-semibold">
                            <h2 style={{ color: '#00a82d' }}>Have Some Question </h2>
                            <p style={{ color: 'gray' }}>Thank you for your interest in our services. Please fill out the form below or e-mail us and we will get back to you <br />e-mail us and we will get back to you</p>
                        </div>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div>
                                <img src="https://user-images.githubusercontent.com/117907145/221084918-08c9066a-7a53-4051-a571-324496422138.png" alt="Wtb" className="img-fluid" />
                                <img src="https://user-images.githubusercontent.com/117907145/221132059-b5d8392a-a99c-4ac9-a863-fec2c80fc601.png" style={{ width: '375px', height: '' }} alt="Wtb" className="img-fluid" />
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div style={{ background: '#F0F2F8', borderRadius: '12px' }} className='ps-5 pe-5 pb-4 pt-4'>
                                <h6 style={{ color: '#616874' }} className='pb-3 pt-3'>Write to us</h6>

                                <input value={name} onChange={(e) => handleName(e.target.value)} style={s5} type='text' placeholder="Name" />

                                <div value={mobilenumber} onChange={(e) => handleMobileNumber(e.target.value)} style={{ height: '15mm', width: '90%', outline: 'none', marginTop: '24px', borderRadius: '5px', display: 'flex' }} >
                                    <span style={{ color: 'gray', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} className="ps-3 pe-2 pt-3 bg-white">+91&nbsp;|</span>
                                    <input style={{ border: 'none', outline: 'none', width: '86%', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }} value={mobilenumber} onChange={(e) => handleMobileNumber(e.target.value)} type="tel" placeholder="Mobile Number" />
                                </div>

                                <input value={email} onChange={(e) => handleEmail(e.target.value)} style={s5} type='text' placeholder="Email Address" />
                                <textarea value={message} onChange={(e) => handleMessage(e.target.value)} style={s3} type='text' placeholder="Message"></textarea>
                                <div>
                                    <button onClick={(e) => sendReq(e.target.value)} className="text-white btn btn-sm ps-4 pe-4 pt-2 pb-2 fw-semibold rounded-3" style={s6}>Submit</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='mb-5'>
                    <iframe title={getString.SiteName} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1649.2640180780243!2d77.57397464867323!3d12.886036126808257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1540e9ddb919%3A0x9e54dc31975ebb8e!2sPrisha%20Cowork!5e0!3m2!1sen!2sin!4v1676278606179!5m2!1sen!2sin" width="100%" height="450" style={{ border: '0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </Container>
            <Dialog open={openDialog} TransitionComponent={Transition} keepMounted onClose={handleClose}>
                <div>
                    <div style={{ background: getColour.BorderLineColour, padding: '8px 0px 8px 0px' }}>
                        <p style={{ color: getColour.DescriptionTextColour, margin: '0px', fontSize: '20px' }} className='text-center'>Submitted Successfully🚀</p>
                    </div>
                    <div style={{ padding: '20px' }}>
                        <div style={{ padding: '40px 40px 0px 40px' }}>
                            <p className='text-center' style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }}>Thank you for applying to Cowork!</p>
                        </div>
                    </div>
                </div>
            </Dialog>
        </section>
    )
}

export const FAQ = () => {

    const [expanded, setExpanded] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const FAQ_Data_01 = [
        { "t": "What are the USPs of the WhatsApp Business account?", "a": "WhatsApp API is a software interface by WhatsApp that can allow organizations receive and deliver messages to multiple users at scale. You may have heard the word API multiple times without fully understanding its meaning. For your convenience, we'll break everything down for you.<br />The Application Programming Interface (API) is software that allows two programs to communicate with one another. You're utilizing an API whenever you use an app to send a message, perform a search, or upload a photo to the internet. The Business API for WhatsApp is similar. It can assist your company in communicating with other users via a WhatsApp API account for the company. While this may appear hard at first, it's relatively simple once you realize how it might benefit your company.<br />As compared to Whatso API, here’s what WhatsApp API brings to the table; 100,000 messages per day Green tick for verification Uses official API" },
        { "t": "How can I get the WhatsApp Business Verified green tick?", "a": "WhatsApp API Verification is not the same as WhatsApp Green Tick Verification. We'll walk you through the Whatsapp business verification procedure and how to get verified on WhatsApp quickly in this video. Although a company may be approved to use WhatsApp APIs, it may not receive a Green Tick.<br />After you've been approved for WhatsApp Business APIs, you can apply for Green Tick. Only a small percentage of firms match WhatsApp's official business account requirements. WhatsApp normally only gives Green Tick or an Official Business account to a small number of well-known companies.<br />The steps to get a Green Tick on WhatsApp are as follows: You must first request API access from a WhatsApp Business Solution Provider (BSP), such as Whatsoever. You can contact your BSP to apply for Green Tick once your WhatsApp Business API access has been approved.<br />However, if your company is not well-known, your Green Tick application may be denied. Only roughly ten percent of enterprises, in our experience, are qualified for WhatsApp Green Tick. WhatsApp retains the authority to approve your Green Tick, and the BSP has no role to play in this." },
        { "t": "Can I use my existing WhatsApp number with the Official WhatsApp Business account?", "a": "Yes, you can. Your company must have a working phone number that matches the following requirements:<ul><li>Landline and cell phone numbers have a country and area code.</li><li>You are the owner of the property.</li><li>Able to receive voice or text messages.</li><li>This is not a shortcode.</li><li>The WhatsApp Business API had never been utilized before.</li></ul>" },
        { "t": "Can I send any kind of message through WhatsApp Business account?", "a": "Yes, you can send PDFs, images, text messages, documents, contacts, and any kind of messages through WhatsApp." },
        { "t": "What are template messages?", "a": "A WhatsApp template, also known as a Highly Structured Message (HSM), is a pre-designed message structure that businesses can use to deliver notifications to clients on a regular basis. Delivery updates, appointment reminders, and automatic replies are just a few kinds of messages." },
        { "t": "Can I only use the pre-approved templates to send messages to my customers via WhatsApp API?", "a": "Yes, there are only certain templates you can use as per WhatsApp's official guidelines. Your templates need to be approved by WhatsApp before you can begin using them. At Whatso, you can share your templates and we can get them approved for you." },
        { "t": "Can I send Broadcasts to people who haven't saved my number?", "a": "Yes, you can send broadcasts to anyone who has WhatsApp. All you need is their contact details, which are usually fetched through your website itself." },
        { "t": "What kind of the Analytics functionality is available?", "a": "In the Analytics Tab you'll get all the data about every campaign you run, number of messages sent and read, the number of clicks, orders, and sales generated, as well as the cost spent and the ROI." },
        { "t": "How do I send Broadcast messages?", "a": "Once you've onboarded with WhatsTool Business' WhatsApp Marketing, you can simply go to the 'Broadcast' section and click on the 'New Broadcast' button. You'll be redirected to a page where you can select your target audience and then a diverse list of templates will be curated for you. You can select any of these templates and hit send." },
        { "t": "What is WhatsApp Marketing? Why do I need it?", "a": "WhatsTool Business is a WhatsApp Marketing platform that enables you to engage with your customers & target them on WhatsApp to increase sales by driving more traffic to your stores & increasing retention" }
    ]

    // Search Handling

    const [searchLs, setSearchLs] = useState(FAQ_Data_01)
    const handleSearchLs = (sv) => {
        const filteredData = FAQ_Data_01?.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filteredData)
    }
    // End of Search Handling
    return (
        <section style={{ margin: '80px 0' }}>
            <Container>
                <div>
                    <div className="text-center">
                        <p className="fw-semibold" style={{ fontSize: '32px' }}>FAQs</p>
                        <p>Here are some frequently asked questions</p>
                    </div>
                    <center>
                        <div style={{ display: 'flex', border: '1px solid #B8BDCC', borderRadius: '5px', padding: '0 15px', width: isDesktop ? '65%' : 'auto', height: '12mm', margin: '55px 0' }}>
                            <span style={{ padding: '8px' }}><SearchIcon /></span>
                            <input type="search" onChange={(e) => handleSearchLs(e.target.value)} placeholder="Type your question here" style={{ border: 'none', outline: 'none', width: '100%' }} />
                        </div>
                    </center>
                    {
                        searchLs?.map((v, i) => {
                            return (
                                <Accordion style={{ border: "none", boxShadow: "none" }} expanded={expanded === 'panel-l-' + i} onChange={handleChange('panel-l-' + i)} key={i}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon className="bRound" style={{ color: getColour.BaseColor, background: getColour.BorderLineColour, }} />}>
                                        <Typography className="fw-bold" sx={{ fontSize: "19px", width: '90%' }}>{v.t}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography dangerouslySetInnerHTML={{ __html: v.a }} style={{ fontSize: '18px', color: getColour.DescriptionTextColour }}></Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </div>
            </Container>
        </section >
    )
}

export const Testimonial = () => {
    const testimonialSchema = [
        { feedback: "Kapila is in cattle feeding business from 25 years and we wanted some solid solution for our 2 Millions farmers. So we approached WhatsTool. <br/>We recently implemented WhatsTool Business's WhatsApp Marketing solution and have been very pleased with the results. We've seen an increase in customer engagement, response times, and overall satisfaction with our communications.", name: 'Saurabh Shivhare', designation: 'Chief Executive', planInfo: 'Plan: Enterprise', category: 'Cattle Feed Industry, India', banner: getUrl.imageBaseUrl + '/tes_ind_kapilagro_banner.png', logo: getUrl.imageBaseUrl + "/tes_ind_kapilagro.png" },
        { feedback: "Our company were operating all manually. We wanted to go with WhatsApp for automating our booking for our cleaning services. WhatsTool team not only made us automate they made our success by integrating with our existing system and increased our booking by 12%. We are very happy so far.", name: 'Nishant Prasad', designation: 'Founder', planInfo: 'Plan: Enterprise', category: 'Cleaning Service, India', banner: getUrl.imageBaseUrl + '/tes_ind_cleanfanatic_banner.png', logo: getUrl.imageBaseUrl + "/tes_ind_cleanfanatic.png" },
        { feedback: "Whatstool is definitely one of the most affordable & intuitive platform. We could go live within a day and the team helped us in building the chatbot ! I definitely recommend the solution to startups !", name: 'Tharun Sai E', designation: 'Founder', planInfo: 'Plan: Enterprise', category: 'Mental Health Support, India', banner: getUrl.imageBaseUrl + '/tes_ind_findhope_banner.png', logo: getUrl.imageBaseUrl + "/tes_ind_findhope.png" },
        { feedback: "We wanted solution for our kids who are learning through our app. We got amazed with WhatsApp API solution provided by WhatsTool. Their team support is amazing ,they always make sure to deliver best for us. We wanted chatbot to handle our learning segment and the flexibility in their chatbot functionality helps deliver what we needed. Highly recommended.", name: 'Gaurav Chowdhary', designation: 'Co-founder', planInfo: 'Plan: Growth', category: 'EdTech Company, India', banner: getUrl.imageBaseUrl + '/tes_ind_kico_banner.png', logo: getUrl.imageBaseUrl + "/tes_ind_kico.png" },
    ]
    return (
        <Container className="mt-5 mb-5 pb-5">
            <h3 className="theme_lite text-center mb-5">There's Word <span className="fw-bold" style={{ color: getColour.BaseColor }}>About Us</span> all Around</h3>
            <div className="testimonials">
                <Carousel variant="dark">
                    {
                        testimonialSchema?.map((v, i) => {
                            return (
                                <Carousel.Item key={i}>
                                    <Row className="bg-white">
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}><img className="mainSideImg shadow-lg" src={v.banner} style={{ height: "500px", width: '80%' }} alt={getString.SiteName} /></Col>
                                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                            <div className="tesBody ps-5 pt-4">
                                                <p style={{ width: "80%", fontSize: "16px", color: getColour.DescriptionTextColour }} dangerouslySetInnerHTML={{ __html: v.feedback }}></p>
                                                <div className="tes_info" style={{ display: 'flex' }}>
                                                    <div><img src={v.logo} style={{ width: 'auto', height: '60px' }} className="img-fluid logo" alt={getString.SiteName} /></div>
                                                    <div className="ms-3">
                                                        <h3 className="fw-bold" style={{ fontSize: "24px", color: getColour.HeadingTextColour }}>{v.name}</h3>
                                                        <p style={{ fontSize: "18px", color: getColour.HeadingTextColour }}>{v.designation}</p>
                                                        <p style={{ fontSize: "15px", color: getColour.HeadingTextColour, marginTop: '-11px' }}>{v.planInfo}</p>
                                                        <p style={{ fontSize: "15px", color: getColour.HeadingTextColour, marginTop: '-11px' }}>{v.category}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
        </Container>
    )
}

export const FeaturesNavbar = () => {
    return (
        <Container className="mb-5 pt-5 pb-5">
            <h3 className="text-center fw-bold" style={{ color: getColour.HeadingTextColour }}>The Most 'Complete' Solution Whatsapp Platform</h3>
            <p className="text-center text-muted" style={{ color: getColour.DescriptionTextColour, fontSize: "13px" }}>Everything you need to notify, chat &amp; engage your users<br />All in one place</p>
            <div className="tabUI mt-4">
                <Tab.Container defaultActiveKey="live_chat">
                    <Nav variant="pills" className="justify-content-center">
                        <Nav.Item>
                            <Nav.Link eventKey="live_chat" className="fw-bold hand">Live Chat</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="broadcast_messaging" className="fw-bold hand">Broadcast</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="ai_chatbots" className="fw-bold hand">A.I. Chatbots</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="in_built_crm" className="fw-bold hand">In-Built CRM</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="automate_notifications" className="fw-bold hand">Automate Notifications</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="mt-5">
                        <Tab.Pane eventKey="live_chat">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={getUrl.imageBaseUrl + "/home_chat_bg.webp"} alt={getString.SiteName} className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-4" style={{ marginLeft: isMobile ? '0' : '40px' }}>
                                            <h4 className="fw-bold" style={{ color: getColour.HeadingTextColour }}>Multiple Agent Live Chat</h4>
                                            <p className="text-muted mt-3">Have multiple team members drive Live Chat<br /> Support on the Same Whatsapp Business Number.</p>
                                            <p className="text-muted mt-3 mb-5">Filter Chats according to tags, campaigns and<br /> attributes for Smart Agent Chat Routing.</p>
                                            <p onClick={() => window.open(getUrl.wpMultiagentUrl, '_self')} className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 bg-white fw-bold text-center" style={{ color: getColour.BaseColor, border: "2px solid " + getColour.BaseColor, width: isMobile ? '45%' : '30%' }}>Learn More</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="broadcast_messaging">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={getUrl.imageBaseUrl + "/home_broadcast_bg.webp"} alt={getString.SiteName} className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-4" style={{ marginLeft: isMobile ? '0' : '40px' }}>
                                            <h4 className="fw-bold" style={{ color: getColour.HeadingTextColour }}>Import &amp; Broadcast Instantly</h4>
                                            <p className="text-muted mt-3">You can simply Import all your Contacts and<br /> Broadcast Approved Messages Instantly.</p>
                                            <p className="text-muted mt-3 mb-5">See real-time analytics on the WhatsTool Platform for<br /> delivered, read rates and more.</p>
                                            <p onClick={() => window.open(getUrl.wpBroadcastmsgUrl, '_self')} className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 bg-white fw-bold" style={{ color: getColour.BaseColor, border: "2px solid " + getColour.BaseColor, width: isMobile ? '45%' : '30%' }}>Learn More</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ai_chatbots">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={getUrl.imageBaseUrl + "/ai_chatbot.png"} alt={getString.SiteName} className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-4" style={{ marginLeft: isMobile ? '0' : '40px' }}>
                                            <h4 className="fw-bold" style={{ color: getColour.HeadingTextColour }}>Solve Customer Queries 24x7</h4>
                                            <p className="text-muted mt-3">Connect Smart A.I. Chatbots from Google Dialogflow<br />seamlessly &amp; monitor conversations from Live Chat<br />Dashboard</p>
                                            <p className="text-muted mt-3 mb-5">Solve Customer Queries, Send Images, Files &amp; Videos<br /> in a Smart Automated Manner.</p>
                                            <p onClick={() => window.open(getUrl.wpChatbotUrl, '_self')} className="btn-md ps-3 pe-3 pt-2 pb-2 rounded-2 bg-white fw-bold" style={{ color: getColour.BaseColor, border: "2px solid " + getColour.BaseColor, width: isMobile ? '45%' : '30%' }}>Learn More</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="in_built_crm">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={getUrl.imageBaseUrl + "/home_crm_bg.webp"} alt={getString.SiteName} className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-4" style={{ color: getColour.HeadingTextColour, marginLeft: isMobile ? '0' : '40px' }}>
                                            <h4 className="fw-bold">Manage Contacts, tags &amp; more</h4>
                                            <p className="text-muted mt-3">Our In-Built CRM enables you to add various tags and<br /> attributes to each customer.</p>
                                            <p className="text-muted mt-3 mb-5">WhatsTool provides you with an extensive User Profile<br /> to drive important Insights</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="automate_notifications">
                            <div>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-center"><img src={getUrl.imageBaseUrl + "/home_broadcast_bg.webp"} alt={getString.SiteName} className="img-fluid" /></p>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="pt-4" style={{ color: getColour.HeadingTextColour, marginLeft: isMobile ? '0' : '40px' }}>
                                            <h4 className="fw-bold">Connect your CRMs with APIs</h4>
                                            <p className="text-muted mt-3">Connect your CRM, Payment Portals, eCommerce<br /> Platforms like Shopify, WooCommerce, Hubspot.<br />Zapier via APIs.</p>
                                            <p className="text-muted mt-3 mb-5">Automate notifications for <b className="text-dark">Abandoned Cart,<br /> Application Drop-off, Order Confirmation, Event<br />Updates</b> &amp; more</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Container>
    )
}
