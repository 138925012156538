class WtColors {
    colourFunc() {
        this.BaseColor = '#00A82D'

        this.MainBgColour = '#EFFFED'
        this.BorderLineColour = '#F0F2F8'
        this.TabbackColour = '#f8fbf7'

        this.HeadingTextColour = '#3F4A5F'
        this.DescriptionTextColour = '#616874'
        this.LightTextColour = '#9EA4B5'

        this.ChatLsBgColour = '#fffff'
        this.ChatSelectionColour = '#F0F2F8'
        this.ChatBoxBgColour = '#f8f8f8'
        this.ChatEndUserMsgBgColour = '#efffed'
        this.ChatBusinessMsgBgColour = '#f0f2f8'
        this.ChatPrivateNoteBgColour = '#FFFCEC'


        this.ChatSelectionColor = '#F9F9F9'

        this.DraftYellow = '#F2B415'
        this.ProcessingBlue = '#0B86DF'
        this.CloudyGrey = '#B8BDCC'
        this.RedColor = '#F64545'
        this.BrownColor = '#AC590B'
        this.OrangeColor = '#F07A0C'
        this.PrivateNoteColor = "#FFFCEC"
        this.LiteGreen = "#40C900"
        this.DarkGreen = "#40C900"
        this.LiteBlue = "#36A4F3"
        this.DarkBlue = "#1C6CA7"
        this.LiteBrown = "#C78F00"
        this.BlackishBrown = "#856000"
        this.ChatbotTbHeaderBgColour = "#F0F2F8"
        this.headingTextColour = '#3F4A5F'


        this.InteractiveMsgColor = '#FD7900'
        this.SingleProductMsgColor = '#C78F00'
        this.MultiProductMsgColor = '#AC590B'
        this.MenuMsgColor = '#856000'
        this.TextMsgColor = '#79CF35'
        this.QuickReplyMsgColor = '#2F8D02'
        this.ActionMsgColor = '#1C5500'
        this.ImageMsgColor = '#36A4F3'
        this.VideoMsgColor = '#3654F3'
        this.AudioMsgColor = '#C236F3'
        this.DocumentMsgColor = '#EB156F'
        this.TemplateMsgColor = '#1C6CA7'

        this.BoxShadow = '0px 0px 8px rgba(0, 0, 0, 0.25)'

    }
}
export const getColour = new WtColors()
getColour.colourFunc()