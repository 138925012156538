import { getUrl } from '../constants/urls';
import { Env } from '../controller/constant'

export function removeUndefinedAndFalseProps(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            if (obj[prop] === undefined || obj[prop] === false) {
                delete obj[prop];
            }
        }
    }
}


export const getWabaCloudInfo = (req) => {
    if (localStorage.getItem('wabaCloud')) {
        let uInfo = JSON.parse(localStorage.getItem('wabaCloud'))
        let info = ''

        if (req === 'status') info = uInfo.status || 'disconnected'

        removeUndefinedAndFalseProps(info)

        return info
    } else {
        return false
    }
}

export const showConsole = (info) => {
    if (Env.env_type !== 'production') { return console.log(info) } else { return }
}

export const showConsoleV2 = (type, tag, payload) => {
    if (Env.env_type !== 'production') {
        if (type === 'log') return console.log('🟢 [' + tag + ']: 🛬 ', payload)
        if (type === 'error') return console.error('🔴 [' + tag + ']: 🚨 ', payload)
        if (type === 'warn') return console.warn('🟡 [' + tag + ']: 🤕 ', payload)
    }
}


export const getUiEnvUrl = () => {
    if (window.location.href?.includes(getUrl.testURL) || window.location.href?.includes('localhost')) {
        return getUrl.appTestLink
    } else {
        return getUrl.appProdLink
    }
}
export const isTestEnv = () => {
    if (window.location.href?.includes(getUrl.testURL) || window.location.href?.includes('localhost')) {
        return true
    } else {
        return false
    }
}
