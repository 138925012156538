class WtLoginKeys {
    constructor() {
        // User login related keys
        this.uInfo = 'tree'
        this.uInfoBup = 'treeBup'
        this.sessionTraceId = 'treeThread'

        this.userInfo = 'userInfo'
        this.userId = 'userId'
        this.userName = 'userName'
        this.userEmail = 'userEmail'
        this.userEmailVerified = 'userEmailVerified'
        this.userWhatsApp = 'userWhatsApp'
        this.userWhatsAppVerified = 'userWhatsAppVerified'
        this.userRole = 'userRole'
        this.businessId = 'businessId'
        this.businessName = 'businessName'
        this.businessEmail = 'businessEmail'
        this.businessWebsite = 'businessWebsite'
        this.businessOnBoardingStatus = 'businessOnBoardingStatus'
        this.channelObj = 'channelObj'
        this.authToken = 'authToken'

        this.headerInfo = 'headerInfo'

        this.logoLink = 'logoLink'
        this.themeColour = 'themeColour'
        // End of User login related keys
    }
}
export const getLoginKey = new WtLoginKeys()

