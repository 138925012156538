import { Env } from '../controller/constant'
import crypto from 'crypto-js'

class CustomStorage {
    secret
    constructor(secret) {
        this.secret = secret
    }

    setItem = (key, value) => {
        const val = crypto.AES.encrypt(JSON.stringify(value), this.secret).toString()
        localStorage.setItem(key, val)
    }

    getItem = (key) => {
        try {
            const bytes = crypto.AES.decrypt(localStorage.getItem(key), this.secret)
            const value = bytes.toString(crypto.enc.Utf8)
            return JSON.parse(value)
        } catch (error) {
            return undefined
        }
    }
}

export const getStorage = new CustomStorage(Env.localStorageKey)