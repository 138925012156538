/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import './index.css'
import { Container, Row, Col, Table, Form } from 'react-bootstrap'
import { Client } from '../home/ui'
import { Link } from 'react-router-dom'
import { Testimonial } from './ui'
import { getUrl } from '../../constants/urls'
import { getColour } from '../../constants/colours'
import { getString } from '../../constants/strings'
import { isMobile } from 'react-device-detect'
import { Dialog, DialogContent, ImageList, ImageListItem, Slide } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ShowSnackbar } from '../../controller/BaseTool'
import { LinkedIn } from '@mui/icons-material'
import { WtEvent } from '../../basetool/analytics'
import { getAnalyticsKey } from '../../constants/analyticsKeys'
import { ConversationalEngagementDialog, FeaturedChatBotDialog } from '../../basetool/dialogs'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';



import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import globalContext from '../../context/global/globalContext'
import { getUiEnvUrl, isTestEnv } from '../../basetool/methods'


// Start of Hotels Hospitality Sector UI

export const HotelsHospitalitySector = () => {
    return (
        <section>
            <HotelsHospitalitySectorOne />
        </section>
    )
}

export const HotelsHospitalitySectorOne = () => {

    const [state, setState] = useState({ right: false, });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
            <List>{['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (<ListItem key={text} disablePadding><ListItemButton>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
            </ListItemButton>
            </ListItem>
            ))}
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );



    // const templateListView = (anchor) => (
    //     <Box className="templateView" role="presentation" style={{ pointerEvents: "none" }} onClick={toggleTemplateDrawer(anchor, false)} onKeyDown={toggleTemplateDrawer(anchor, false, undefined, undefined, undefined)}>
    //         <div className="ms-4 me-4 mt-4"><p>Template view</p></div>
    //         <div className="ms-4 me-4"><p className="fw-bold" style={{ color: getColour.DescriptionTextColour }}>{templateNames}</p></div>
    //         {isClickJson && <div style={{ padding: '12px 24px' }}><CodeEditor id='jsonCodeEditor' value={isClickJson} style={{ background: 'black', color: 'white' }} /></div>}
    //         <div style={{ position: "fixed", bottom: "20px", right: "120px", pointerEvents: "auto" }}>
    //             <Button variant="contained" className="btn btn-sm text-white" style={{ background: getColour.PrimaryColour }} onClick={() => { copyToClipboards(isClickJson) }}>Copy template json</Button>
    //         </div>
    //     </Box >
    // )

    return (
        <section>
            <div>
                <h3>HotelsHospitality</h3>
                <p className='text-center'>Drawer</p>

                <div>
                    {['right'].map((anchor) => (
                        <section key={anchor}>
                            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                {list(anchor)}
                            </Drawer>
                        </section>
                    ))}
                </div>
            </div>
        </section>
    )
}

// End of Hotels Hospitality Sector UI


// Chatbot Page UI
export const Chatbots = () => {
    return (
        <div className="">
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h1>
                                Start your digital transformation journey with <b>AI chatbots</b> to deliver automated and personalised experience
                            </h1>
                            <p className="text-muted mt-5">
                                We at Whatstool help lead your business’s customer service<br />journey through all key touchpoints. Engage your customers with<br />24x7 Chatbot support and grow your business widely.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + "/wtb_05.png"} style={{ width: '70%' }} className="img-fluid" alt={getString.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients" fluid><Client /></Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h3 style={{ color: getColour.BaseColor }}>Generate leads 24x7 and Grow<br />your business with Chatbots</h3>
                            <p className="text-muted mt-5">
                                Be available to your customers around the clock, never miss out on<br />a lead again! Our dashboard ensures customer conversations are<br />accounted for through Assignee, @Mentions, and Tagging.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center p-5">
                                <img src={getUrl.imageBaseUrl + "/wtx_chatbot_02.png"} className="img-fluid" alt={getString.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients pe-3 ps-3" fluid>
                <Container className="mt-5 mb-5 pb-5">
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div>
                                <p className="text-center p-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_03.png"} className="img-fluid" alt={getString.SiteName} />
                                </p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className="mt-5">
                                <h3 style={{ color: getColour.BaseColor }}>Build your Bot within a matter of<br />minutes. No codes, just<br />conversational flow.</h3>
                                <p className="text-muted mt-5">
                                    Setup your Chatbot to interact with users just like any agent would.<br /> Create, test, and run your no-code Chatbot. Set a standard with<br />the quality of customer support, engagement, and interaction.
                                </p>
                                <p className="mt-5"><Link to={{ pathname: getUiEnvUrl() }} target='_blank' className="btn btn-md ps-5 pe-5 pt-2 pb-2 text-white fw-bold" style={{ background: "#128C7E" }}>Start Building</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <h5 className="fw-bold mt-5 mb-5 text-center">BENEFITS OF WHATSAPP CHATBOT</h5>
                <Container className="pe-5 ps-5">
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_01.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Effectively and instantly<br />interact with clients</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_02.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Expand the company’s<br />availability and reach<br />&nbsp;</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_03.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Support customers by<br />responding to queries in<br />real-time</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_04.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Send contexual<br />responses automatically</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_05.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Personalize customer’s<br />experience</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_06.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Free up personnel to<br />solve more complex requests</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_07.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Make customer communications secure<br />by end-to-end encryption</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_08.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Shorten the sales<br />cycle<br />&nbsp;</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/wtx_chatbot_benifits_09.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Achieve better brand<br />recognition<br />&nbsp;</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}
// End of Chatbot Page UI

// MultiAgent Page UI
export const MultiAgent = () => {
    return (
        <div className="">
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h1>WhatsApp Multi-agent : Shared workspace for teams to collaborate</h1>
                            <p className="text-muted mt-5">
                                Have multiple attendants in a single number. A handy tool for sales and customer support
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + "/wtb_03.png"} className="img-fluid shadow-lg rounded-3" alt={getString.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients" fluid><Client /></Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h3 style={{ color: getColour.BaseColor }}> Get close to your customers with WhatsApp Multi agent</h3>
                            <p className="text-muted mt-5">
                                Customers want only one thing: quick and very simple contact with you. Effectively serve your customers with online chat: Unlimited messaging and multimedia, chat history and unlimited staff.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center p-5">
                                <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_001.png"} className="img-fluid" alt={getString.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients pe-3 ps-3" fluid>
                <Container className="mt-5 mb-5 pb-5">
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div>
                                <p className="pt-5 pb-5 pe-5">
                                    <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_002.png"} className="img-fluid" alt={getString.SiteName} />
                                </p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className="mt-5">
                                <h3 style={{ color: getColour.BaseColor }}>Optimize your company's WhatsApp service</h3>
                                <p className="text-muted mt-5">
                                    Keep several conversations under control: open a separate tab for each conversation. Take total control of your customer interactions - share a single WhatsApp account across multiple devices at the same time
                                </p>
                                <p className="mt-5"><Link to={{ pathname: getUiEnvUrl() }} target='_blank' className="btn btn-md ps-5 pe-5 pt-2 pb-2 text-white fw-bold" style={{ background: "#128C7E" }}>Get Started</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <h5 className="fw-bold mt-5 mb-5 text-center">Benefits of WhatsApp multi-agent chat support</h5>
                <Container className="pe-5 ps-5">
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_Benefits_001.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">More<br />Conversation</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_Benefits_002.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Faster<br />Response</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_Benefits_003.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Cost<br />Effectiveness</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_Benefits_004.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Competitive<br />Advantage</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_Benefits_005.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Greater Customer<br />Retention</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_Benefits_006.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Increased<br />Sales</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WT_MultiAgent_Benefits_007.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Convenient for<br />Customers</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}
// End of MultiAgent Page UI

// BroadcastMessage Page UI
export const BroadcastMsg = () => {
    return (
        <div className="">
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row style={{ marginTop: "100px" }}>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="mt-5">
                            <h1>
                                Broadcast Promotional Messages (Officially)
                            </h1>
                            <p className="text-muted mt-5">
                                Well, WhatsApp recently allowed Businesses to Broadcast and Automate Promotional Messages via Whatsapp Business API<br />
                                You can Now Send Exciting Offers, Discount Coupon Code and Festival Wishes without getting your Number blocked!
                            </p>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <p className="float-end">
                                <img src={getUrl.imageBaseUrl + "/wtb_broadcast_001.png"} className="img-fluid" width="400px" alt={getString.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients" fluid><Client /></Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5">
                            <h3 style={{ color: getColour.BaseColor }}>Broadcast Messages in a Few Clicks</h3>
                            <p className="text-muted mt-5">
                                Import contacts with labels and additional fields to create segments, schedule broadcasting lists and view real-time analytics all with a few clicks.
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div>
                            <p className="text-center p-5">
                                <img src={getUrl.imageBaseUrl + "/WTX_Broadcast_002.png"} className="img-fluid" width="100%" alt={getString.SiteName} />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="clients pe-3 ps-3" fluid>
                <Container className="mt-5 mb-5 pb-5">
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div>
                                <p className="pt-5 pb-5 pe-5">
                                    <img src={getUrl.imageBaseUrl + "/WTX_Broadcast_003.png"} className="img-fluid" alt={getString.SiteName} />
                                </p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className="mt-5 pt-5">
                                <h3 style={{ color: getColour.BaseColor }}>Set up segmented campaigns based on custom fields.</h3>
                                <p className="text-muted mt-5">
                                    Leverage the power of WhatsApp Marketing by starting a WhatsApp Broadcast for up to 100k recipients in a single click.
                                </p>
                                <p className="mt-5 pt-5"><Link to={{ pathname: getUiEnvUrl() }} target='_blank' className="btn btn-md ps-5 pe-5 pt-2 pb-2 text-white fw-bold" style={{ background: "#128C7E" }}>Get Started</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="mt-5 mb-5 pe-3 ps-3">
                <h5 className="fw-bold mt-5 mb-5 text-center">BENEFITS OF BROADCAST MESSAGE</h5>
                <Container className="pe-5 ps-5">
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WTX_Broadcast_Benefits_001.png"} width="230px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Wider Target<br />Reach</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WTX_Broadcast_Benefits_002.png"} width="230px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Better Connect<br />with Customer</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WTX_Broadcast_Benefits_003.png"} width="230px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Cost<br />Effectiveness</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WTX_Broadcast_Benefits_004.png"} width="230px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Up-Sell and<br />Cross-sell products</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WTX_Broadcast_Benefits_005.png"} width="230px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3"><br />Personalized<br />Offers</h6>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="p-3">
                            <div className="benefits_box">
                                <p className="text-center pt-5 ps-3 pe-3 pb-5">
                                    <img src={getUrl.imageBaseUrl + "/WTX_Broadcast_Benefits_006.png"} width="240px" className="img-fluid" alt={getString.SiteName} />
                                </p>
                                <h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">Scheduled  New<br />Campaigns</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </Container>
        </div>
    )
}
// End of BroadcastMessage Page UI

// Quick Guide Page UI
export const QuickGuide = () => {
    const FeaturesData = [
        { "t": "Free Whatsapp Business API", "d": "Get Whatsapp Business API Approval within 2 days (Free with any plan)", "img": getUrl.imageBaseUrl + "/WBApi.svg" },
        { "t": "Multi Agent Live Chat", "d": "Enable Multiple Human-Agent Live Chat Support on the same Whatsapp Number", "img": getUrl.imageBaseUrl + "/MultiAgent.svg" },
        { "t": "Broadcast Unlimited Campaigns", "d": "Broadcast Messages up to 1,00,000 users in one day", "img": getUrl.imageBaseUrl + "/BroadcastIcon.svg" },
        { "t": "Connect Your APIs", "d": "Easily Connect with Shopify, Salesforce, WooCommerce, Zapier, Payment Portals, CRMs & more", "img": getUrl.imageBaseUrl + "/API.svg" },
        { "t": "Connect no Code A.I. Chatbots", "d": "Connect No-Code Chatbots from Google Dialogflow in a Single Click", "img": getUrl.imageBaseUrl + "/Chatbots.svg" },
        { "t": "Import and Export Contacts", "d": "Import Opted-In Contacts and Broadcast Messages seamlessly", "img": getUrl.imageBaseUrl + "/ImportExport.svg" },
        { "t": "Template Messages Approvals", "d": "Quick Fast Template Message Approval Process (2-3 Days) via Interactive Dashboard", "img": getUrl.imageBaseUrl + "/ApprovalMsg.svg" },
        { "t": "Send Personalised Messages", "d": "Use User attributes effectively to send personalised campaigns", "img": getUrl.imageBaseUrl + "/PersonalizeMessage.svg" },
        { "t": "Chat CRM Included", "d": "Manage Contacts & Create segregated audience using various tags, attributes & more", "img": getUrl.imageBaseUrl + "/CRM.svg" }
    ]
    return (
        <Container className="mt-5 mb-5 pe-3 ps-3">
            <p className="mt-5 fw-bold" style={{ color: getColour.HeadingTextColour, fontSize: "24px" }}>Powerful  Features that you need</p>
            <p className="fw-bold" style={{ color: getColour.DescriptionTextColour, fontSize: "12px" }}>All Plans of WhatsTool Business are Inclusive of these features</p>
            <div className="features mt-4 qg_one">
                <Row>
                    {
                        FeaturesData.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-2 p-3 hand" style={{ height: "auto" }} key={i}>
                                    <div className="rounded-3 p-4 quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                        <div style={{ display: "flex" }}>
                                            <p className="fw-bold" style={{ fontSize: "18px", width: "75%" }}>{v.t}</p>
                                            <p className="float-end"><img src={v.img} alt={getString.SiteName} /></p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "14px" }}>{v.d} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>

            <p className="mt-5 fw-bold" style={{ color: getColour.HeadingTextColour, fontSize: "24px" }}>Why Choose Whatsapp?</p>
            <p className="fw-bold" style={{ color: getColour.DescriptionTextColour, fontSize: "12px" }}>Whatsapp is the One Platform that brings together Actionable Notifications &amp; Customer Support!</p>
            <div className="pt-5 mt-5 pb-5 rounded-3 quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                <Row>
                    <Col>
                        <div>
                            <p className="text-center"><img src={getUrl.imageBaseUrl + "/OpenRate.svg"} alt={getString.SiteName} /></p>
                            <p className="text-center fw-bold" style={{ color: getColour.HeadingTextColour }}>95% Open Rates</p>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p className="text-center"><img src={getUrl.imageBaseUrl + "/ClickRate.svg"} alt={getString.SiteName} /></p>
                            <p className="text-center fw-bold" style={{ color: getColour.HeadingTextColour }}>45-60% Click Rates</p>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p className="text-center"><img src={getUrl.imageBaseUrl + "/2BnUser.svg"} alt={getString.SiteName} /></p>
                            <p className="text-center fw-bold" style={{ color: getColour.HeadingTextColour }}>2 Bn+ Users</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <p className="text-center mt-4 "><Link to={{ pathname: getUiEnvUrl() }} target='_blank'><button className="btn btn-sm  text-white mb-5 pt-3 pb-3 ps-5 pe-5 bRound" style={{ background: getColour.BaseColor, fontSize: "20px" }}>Get Started Now</button></Link></p>

            <p className="mt-5 fw-bold" style={{ color: getColour.HeadingTextColour, fontSize: "24px" }}>Experience Whatsapp Smart Notifications Now</p>
            <p className="fw-bold" style={{ color: getColour.DescriptionTextColour, fontSize: "12px" }}>Which Notification would you like to Experience? Choose one &amp; we will send it instantly on your Whatsapp</p>
            <div className="pb-5 mt-5 qg_two">
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div >
                                <div className="p-3">
                                    <p className="text-center"><img src={getUrl.imageBaseUrl + "/Cart.svg"} alt={getString.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px", background: getColour.BaseColor }} className="text-center text-white p-2">Abandoned Cart Recovery</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={getUrl.imageBaseUrl + "/OrderCart.svg"} alt={getString.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px", background: getColour.BaseColor }} className="text-center text-white p-2">Order Confirmation</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={getUrl.imageBaseUrl + "/Feedback.svg"} alt={getString.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px", background: getColour.BaseColor }} className="text-center text-white p-2">Feedback Offer Loop</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={getUrl.imageBaseUrl + "/Product.svg"} alt={getString.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px", background: getColour.BaseColor }} className="text-center text-white p-2">Product Details</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={getUrl.imageBaseUrl + "/Course.svg"} alt={getString.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px", background: getColour.BaseColor }} className="text-center text-white p-2">Course Application Reminder</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} >
                        <div className="quick_features" style={{ borderRadius: "6px", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div>
                                <div className="p-3">
                                    <p className="text-center"><img src={getUrl.imageBaseUrl + "/Event.svg"} alt={getString.SiteName} /></p>
                                </div>
                            </div>
                            <p style={{ borderRadius: "0px 0px 6px 6px", background: getColour.BaseColor }} className="text-center text-white p-2">Event Reminder</p>
                        </div>
                    </Col>
                </Row >
            </div>

            <p className="mt-5 fw-bold" style={{ color: getColour.HeadingTextColour, fontSize: "24px" }}>Get WhatsApp Business API in 10 Minutes</p>
            <div className="qg_three">
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="p-5 images">
                            <img src={getUrl.imageBaseUrl + "/GreenTick.png"} className="img-fluid" alt={getString.SiteName} />
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="p-5 text">
                            <p>WhatsTool Perform is powered by Official Whatsapp Business APIs and is in alignment with all Whatsapp Rules.</p>
                            <p>Get Verified Green Tick on your Whatsapp Broadcast upto 100,000 Notifications 24*7 Chat Support by WhatsTool Team</p>
                            <p className="mt-4 "><Link to={{ pathname: getUiEnvUrl() }} target='_blank'><button className="btn btn-sm text-white mb-5 pt-1 pb-1 ps-3 pe-3 bRound" style={{ background: getColour.BaseColor, fontSize: "15px" }}>Apply Now</button></Link></p>
                        </div>
                    </Col>
                </Row>
            </div>


            <p className="text-center fw-bold mt-5 pt-5" style={{ color: getColour.HeadingTextColour, fontSize: "24px" }}>Official WhatsApp API Key-Points</p>
            <div className="key-points mt-5 hand">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                <ul>
                                    <li>Pre-requisites for Official WhatsAPP</li>
                                    <li>Documents and other requirements to get an Official WhatsApp API account created.</li>
                                </ul>
                            </div>
                            <div className="bg-white p-4" style={{ lineHeight: "2.15" }}>
                                <p>You will need the following to get started:</p>
                                <ol>
                                    <li>Email,</li>
                                    <li>Live website URL,</li>
                                    <li>Facebook account,</li>
                                    <li>A mobile number that has never been previously used on WhatsApp,</li>
                                    <li>Company formation document available in your country,</li>
                                    <li>Company address proof document such as tax certificate etc.</li>
                                </ol>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div>
                            <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                    <ul>
                                        <li>Can I send any kind of message using official WhatsApp account?</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-4">
                                    <p>You can send any kind of image, video, document message with official WhatsApp account, but please do note that you need to get the message template approved from WhatsApp first using our panel. Template approval takes around 5 mins to up to 24 hours duration.</p>
                                </div>
                            </div>
                            <div className="mt-2 quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                    <ul>
                                        <li>Set-up Charge</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-4">
                                    <p>Completely free</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12}>
                        <div>
                            <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                    <ul>
                                        <li>Verified Green Tick</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-4">
                                    <p>Yes. But green-tick verification is subject to Facebook policies. Check your business eligibility for Green-tick verification here.</p>
                                </div>
                            </div>
                            <div className="mt-2 quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                    <ul>
                                        <li>Banning of Numbers</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-4">
                                    <p>No, the number will not get banned unless reported or blocked by a significantly large number of users for several consecutive days.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                <ul>
                                    <li>Number of WhatsApp messages per day</li>
                                </ul>
                            </div>
                            <div className="bg-white p-4" style={{ lineHeight: "2.15" }}>
                                <p className="text-center" >
                                    <img src={getUrl.imageBaseUrl + "/quickGuide_01.png"} alt={getString.SiteName} style={{ height: "150px" }} className="img-fluid w-75" />
                                </p>
                            </div>
                        </div>
                        <Row className="mt-3">
                            <Col>
                                <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                    <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                        <ul>
                                            <li>Set-up Time</li>
                                        </ul>
                                    </div>
                                    <div className="bg-white p-3">
                                        <p>Application is processed immediately. Approval from Facebook may take 1-5 days.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                                    <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                        <ul>
                                            <li>How it works</li>
                                        </ul>
                                    </div>
                                    <div className="bg-white p-3">
                                        <p>Use Contact Management and Campaign Management to send bulk messages.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <div className="quick_features" style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)" }}>
                            <div className="p-3 text-white fw-bold" style={{ background: getColour.BaseColor }}>
                                <ul>
                                    <li>Per message charge</li>
                                    <li>Meta, parent company of WhatsApp charges message cost and it needs to be paid to them.</li>
                                </ul>
                            </div>
                            <div className="bg-white p-4" style={{ lineHeight: "2.15" }}>
                                <Table className="fw-bold" responsive>
                                    <thead style={{ background: getColour.CloudyGrey, color: getColour.HeadingTextColour }}>
                                        <tr>
                                            <th>Country</th>
                                            <th>Marketing</th>
                                            <th>Utility</th>
                                            <th>Authentication</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>
                                        <tr>
                                            <td>India</td>
                                            <td>₹ 0.7265</td>
                                            <td>₹ 0.3082</td>
                                            <td>---</td>
                                        </tr>
                                        <tr>
                                            <td>Nigeria</td>
                                            <td>₹ 3.7851</td>
                                            <td>₹ 2.34</td>
                                            <td>₹ 2.106</td>
                                        </tr>
                                        <tr>
                                            <td>Indonesia</td>
                                            <td>₹ 3.0111</td>
                                            <td>₹ 1.4653</td>
                                            <td>₹ 2.1979</td>
                                        </tr>
                                        <tr>
                                            <td>Brazil</td>
                                            <td>₹ 4.5808</td>
                                            <td>₹ 2.5652</td>
                                            <td>₹ 2.3087</td>
                                        </tr>
                                        <tr>
                                            <td>Malaysia</td>
                                            <td>₹ 6.3037</td>
                                            <td>₹ 1.466</td>
                                            <td>₹ 1.3194</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='mt-5'>
                <h3 style={{ color: getColour.HeadingTextColour, marginTop: "100px" }}>360Dialog vs WhatsApp Cloud API</h3>
                <Table className="fw-bold w-75" responsive>
                    <thead style={{ background: getColour.BorderLineColour, color: getColour.LightTextColour }}>
                        <tr>
                            <th>360Dialog</th>
                            <th>WhatsApp Cloud API</th>
                        </tr>
                    </thead>
                    <tbody style={{ background: "#fff", fontSize: "14px" }}>
                        <tr>
                            <td>Mandatory to Recharge after 1000 message in all plan except in Free plan (after 500 message)</td>
                            <td>Mandatory to Recharge after 1000 message in all plan except in Free plan (after 500 message)</td>
                        </tr>
                        <tr>
                            <td>Security Deposit of Rs 2000 is mandatory at the start of plan. It will be refunded at the end if you inform about your removal of account before 60 days.</td>
                            <td>No security Deposit Required</td>
                        </tr>
                        <tr>
                            <td>Recharge will include WhatsApp charges. For  Example for Recharge of Rs1000 user will get 1800 BIC = 1700x0.49(BIC) + 0.10 (WTB platform free)x1700</td>
                            <td>Recharge will not include WhatsApp charges & will only be our platform fee.<br />For Example for Recharge of Rs1000 user will be able to send 10,000 messages because we will only charge platform free</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Container >
    )
}
// End of Quick Guide Page UI



// Integeration Page UI
export const Integeration = () => {
    return (
        <div>
            <IntegrationInfo />
            <ECommerceIntegrations />
            <CRMAgentIntegrations />
            <PaymentIntegrations />
            <Appintegrations />
        </div>
    )
}
export const IntegrationInfo = () => {
    const s1 = { color: '#273041', fontSize: '25px' }
    const s2 = { color: getColour.DescriptionTextColour, fontSize: '18px' }
    return (
        <div>
            <Container>
                <Row>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div className='mt-5'>
                            <p style={s1} className="fw-semibold him_fs">Reach many WhatsApp users by seamlessly integrating your existing systems with WhatsTool Business</p>
                            <p style={s2} className="him_fsx">Give your customers best experience and personalisation. By integrating your databases and third party tools with WhatsTool Business, you can provide a superior experience to both your customers and agents.</p>
                        </div>
                    </Col>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className='mt-5 float-end'>
                            <img src={getUrl.imageBaseUrl + '/inte_01.png'} style={{ width: '400px', height: '290px' }} alt='Whatstool Business' className='img-fluid' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export const ECommerceIntegrations = () => {
    const s1 = { color: getColour.BaseColor, fontSize: '25px' }
    const s2 = { color: getColour.DescriptionTextColour, fontSize: '18px' }

    return (
        <div style={{ background: "#F8FBF7" }}>
            <Container>
                <div className='mt-5 pt-5 pb-5'>
                    <Row>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <div >
                                <p style={s1} className="fw-semibold him_fs">E-Commerce Integrations</p>
                                <p style={s2} className="him_fsx">Integrate your favourite E -Commerce apps to reduce abandoned carts, increase revenues with one click integrations</p>
                            </div>
                        </Col>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div className='float-end'>
                                <img src={getUrl.imageBaseUrl + '/inte_02.png'} style={{ width: '560px' }} alt='Whatstool Business' className='img-fluid' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const CRMAgentIntegrations = () => {

    const s3 = { width: "560px" }
    const s4 = { color: getColour.BaseColor, fontSize: '25px' }
    const s5 = { color: getColour.DescriptionTextColour, fontSize: '18px' }

    return (
        <div>
            <Container fluid>
                <Container>
                    <div className='pb-5 pt-5'>
                        <Row>
                            <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                <div>
                                    <img src={getUrl.imageBaseUrl + '/inte_03.png'} alt='Whatstool Business' style={s3} className='img-fluid' />
                                </div>
                            </Col>
                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                                <div>
                                    <p style={s4} className="fw-semibold pt-5 him_fs">CRM & Agent Integrations</p>
                                    <p style={s5} className="him_fsx">Integrate your existing CRM & live Agent apps to engage with your customer better</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Container>
        </div>
    )
}
export const PaymentIntegrations = () => {
    const s1 = { color: getColour.BaseColor, fontSize: '25px' }
    const s2 = { color: getColour.DescriptionTextColour, fontSize: '18px' }
    const s3 = { width: "471px" }

    return (
        <div style={{ background: "#F8FBF7" }}>
            <Container>
                <div className='mt-5 pt-5 pb-5'>
                    <Row>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div>
                                <p style={s1} className="fw-semibold him_fs">Payment Integrations</p>
                                <p style={s2} className="him_fsx">Integrate with easily accessible payment gateways and enable easy payment for your customer without switching between the channels</p>
                            </div>
                        </Col>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >
                            <div className='float-end '>
                                <img src={getUrl.imageBaseUrl + '/inte_04.png'} alt='Whatstool Business' style={s3} className='img-fluid' />

                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const Appintegrations = () => {
    const s3 = { cursor: "pointer" }
    // const s8 = { background: "#92cc37" }
    const s9 = { color: getColour.LightTextColour }
    const s10 = { fontSize: "19px" }
    const s11 = { background: getColour.CloudyGrey, width: "100%", height: "40px", position: "absolute", bottom: "0" }
    const s1 = { fontSize: '25px' }
    const s2 = { color: getColour.LightTextColour, fontSize: "18px" }
    const appintegrationsSchema = [
        { image: getUrl.imageBaseUrl + '/inte_zoho.png', info: 'CRM Plateform', description: 'Integrate messages on the Zoho CRM', btnText: 'Coming Soon', bottambtntext: 'Connect' },
        { image: getUrl.imageBaseUrl + '/inte_shopify.png', info: 'E-commerce Platform', description: 'Chat Widget, Cart Abandonment Emails, etc.', btnText: 'Coming Soon', bottambtntext: 'Connect' },
        { image: getUrl.imageBaseUrl + '/inte_walink.png', info: 'Messaging Platform', description: 'Create shareable links & QR  for your WA business number', btnText: 'Coming Soon', bottambtntext: 'Connect' },
        { image: getUrl.imageBaseUrl + '/inte_pably.png', info: 'Online Marketing & Sales Plateform', description: 'Automate or link with 700+ apps throgh pabbly Connect', btnText: 'Coming Soon', bottambtntext: 'Connect' },
        { image: getUrl.imageBaseUrl + '/inte_zapier.png', info: 'Connect Platform', description: 'Send an approved template WhatsApp message', btnText: 'Coming Soon', bottambtntext: 'Connect' },
        { image: getUrl.imageBaseUrl + '/inte_razorpay.png', info: 'Payment Provider', description: 'Integrate messages on the Razorpay', btnText: 'Coming Soon', bottambtntext: 'Connect' },
        { image: getUrl.imageBaseUrl + '/inte_woocom.png', info: 'E-commerce Platform', description: 'Chat Widget, Cart Abandonment Emails, etc.', btnText: 'Coming Soon', bottambtntext: 'Connect' },
        { image: getUrl.imageBaseUrl + '/inte_hubspot.png', info: 'CRM Platform', description: 'Integrate messages on the Hubspot CRM', btnText: 'Coming Soon', bottambtntext: 'Connect' },
    ]
    return (
        <div>
            <Container className="mb-5">
                <Row>
                    <div className='mt-5 '>
                        <p className='fw-semibold him_fs' style={s1}>App Integration </p>
                        <p style={s2} className="him_fsx">Connect WhatsTool Business to your favorite services</p>
                    </div>
                    {
                        appintegrationsSchema?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className='him_boxshadow rounded-3 mt-5' style={s3}>
                                        <div className='p-4'>
                                            <img src={v.image} alt='whatstool business' classname='img-fluid' />
                                            {/* <button className="btn btn-sm me-2 text-white rounded-5 float-end" style={s8}>{v.btnText}</button> */}

                                            <p style={s9} className='fw-semibold pt-2'>{v.info}</p>
                                            <p className='pt-4 fw-semibold' style={s10}> {v.description}</p>
                                        </div>

                                        <button className="btn btn-sm mt-4 text-white fw-bold" onClick={() => window.open(getUiEnvUrl(), '_blank')} style={s11}>{v.bottambtntext}</button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}
// End of Integeration Page UI



// Educational Industry Sector UI by Himanshu
export const EducationalSector = () => {

    const setParams = useContext(globalContext)
    const urlSearch = window.location.href
    if (urlSearch.includes("?")) {
        const parmas = urlSearch.split("?")[1]
        setParams.setParams(parmas)

    }

    return (
        <>
            <EducationInfo />
            <Container className="clients" fluid><Client /></Container>
            <EducationsectionOne />
            <EducationsectionTwo />
            <EducationsectionThree />
            <EducationsectionFour />
            <EducationsectionFive />
            <EducationsectionSix />
            <EducationsectionSeven />
            <EducationChatbotTemplates />
            <Container style={{ background: getColour.TabbackColour }} className='pt-4' fluid><Container><Testimonial /></Container></Container>
            <EducationsectionFooter />
        </>
    )
}
export const EducationInfo = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>WhatsTool Business for Education Institutions</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>From Admit, Onboard, Engage, Give Updates, Solve Queries to Customer Support it helps Educational Institutions improve Admission Rates</span>
                                <div>
                                    {/* <button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" onClick={() => window.open(getUiEnvUrl(), '_blank')} style={{ background: getColour.BaseColor, borderRadius: '8px' }}>Get Started</button> */}
                                    <Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: getColour.BaseColor, borderRadius: '8px' }}>Get Started</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/edu_01.png'} style={{ height: '410px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const EducationsectionOne = () => {
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Take Admission on WhatsApp</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Allow your students to view the course details and choose the course and get admitted from WhatsApp only.</span>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/edu_02.png'} style={{ height: '470px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const EducationsectionTwo = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/edu_03.png'} style={{ height: '450px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Online Parent Teacher Meeting</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Move parents teachers meeting online. Share regular class updates with them, with progress reports and more.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const EducationsectionThree = () => {
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ', marginTop: isMobile ? '' : '50px' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Share Important Notification & Updates</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Inform students about upcoming events, class schedules, Exam Time Tables.</span>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/edu_04.png'} style={{ height: '410px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const EducationsectionFour = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/edu_05.png'} style={{ height: '294px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Collect Feedback from Students</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Use WhatsApp to collect feedback from students and parents. See higher response rate.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const EducationsectionFive = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Green Tick Mark</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Get green tick for your Education Institute in WhatsApp, make it official and allow people to trust you more.</p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/edu_06.png'} style={{ height: isMobile ? '' : '300px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const EducationsectionSix = () => {
    return (
        <Container style={{ background: getColour.TabbackColour }} fluid>
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="text-center">
                            <p className=" pt-5 pb-5 fw-semibold him_fontSIZE" style={{ fontSize: '26px', color: getColour.BaseColor }}>Use Team Chat to answer Student, Parent and Faculty queries by assigning people for that particular task</p>
                            <p className="pb-5">
                                <img src={getUrl.imageBaseUrl + '/edu_07.png'} style={{ height: isMobile ? '' : '535px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export const EducationsectionSeven = () => {
    return (
        <Container>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center">
                        <p className=" pt-5 pb-5 fw-semibold him_fontSIZE" style={{ fontSize: '26px', color: getColour.BaseColor }}>Use Broadcast to send messages to 1000+ people at a time</p>
                        <p className="pb-4">
                            <img src={getUrl.imageBaseUrl + '/edu_08.png'} style={{ height: isMobile ? '' : '625px' }} alt={getString.SiteName} className="img-fluid" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export const EducationChatbotTemplates = () => {
    const s1 = { border: '1px solid #ccc', borderRadius: '8px', minHeight: '445px' }
    const s3 = { color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '2px 20px 4px 15px' }
    const s4 = { fontSize: "16px" }

    const featureTemplateSchema = [
        { title: 'Lead Generation Bot', category: 'Lead Generation', description: 'Collect name, email, and other basic information from your visitors & generate leads using this chatbot template.', image: 'https://user-images.githubusercontent.com/117907145/224295991-e3351466-4a0d-45dd-96af-e99353300b2c.png', btnText: 'Try', btnActionUrl: 'i want to try lead generation bot' },
        { title: 'Tutor Selection Bot', category: 'Booking / Scheduling', description: 'Make the tutor selection process hassle-free by creating an automated chatbot that can guide students to make an informed', image: 'https://user-images.githubusercontent.com/117907145/224296304-27fbaa14-35b3-4253-a241-feb04eeaa6c7.png', btnText: 'Try', btnActionUrl: 'i want to try tutor selection bot' },
        { title: 'Parent Testimonial Bot', category: 'Support FAQ', description: 'Our parent testimonial chatbot is designed to help educational institutes collect feedback and engage with their students', image: 'https://user-images.githubusercontent.com/117907145/224296509-37f4fd95-82e9-4418-a78f-48d30c5ad92f.png', btnText: 'Try', btnActionUrl: 'i want to try site visit feedback bot' },
        { title: 'Course Fee Bot', category: 'Support FAQ', description: 'This Course Fee chatbot can help fully automate the fee payment process thereby helping students make hassle-free', image: 'https://user-images.githubusercontent.com/117907145/224296733-85b8e74c-97b4-449a-840b-79a8c073201b.png', btnText: 'Try', btnActionUrl: 'i want to try course fee bot' },
        { title: 'Student Registration Bot', category: 'Lead Generation', description: 'Create a custom enrollment form & streamline your current student enrollment process with our automated no-code', image: 'https://user-images.githubusercontent.com/117907145/224296978-eece952f-3e00-46d4-94b8-a3b7020b4858.png', btnText: 'Try', btnActionUrl: 'i want to try lead generation bot' },
        { title: 'Browse Course Bot or Course Discovery Bot', category: 'Discovery', description: 'Our Course Discovery chatbot helps universities & education institutes help students discover their preferred course in', image: 'https://user-images.githubusercontent.com/117907145/224297225-cd6fdee7-8899-4029-8cda-dc03ca8dbc78.png', btnText: 'Try', btnActionUrl: 'I want to try Browse Course Bot or Course Discovery Bot' },
    ]

    const [openChatBotDialogBox, setOpenChatBotDialogBox] = useState({ open: false, onClose: null })
    const openChatBotDialog = (v) => setOpenChatBotDialogBox({ open: true, v: v, onClose: () => setOpenChatBotDialogBox(false) })
    return (
        <div className='mt-5 mb-5'>
            <div className='mt-5 mb-5 text-center'>
                <p className='fw-semibold' style={{ fontSize: '22px' }}>Chatbot Templates for Education Industry</p>
                <p style={{ color: getColour.DescriptionTextColour, marginTop: '-12px' }}>Try it now</p>
            </div>
            <Container>
                <Row>
                    {
                        featureTemplateSchema?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className="mb-3" style={s1}>
                                        <img src={v.image} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '200px', width: '100%' }} alt="whatstool" className="img-fluid" />
                                        <p className="fw-bold mt-2 ms-2 me-2 mb-0" style={{ fontSize: '20px' }}>{v.title}</p>
                                        <small className="mt-0 ms-2 me-2 mb-2" style={{ background: '#cbd5e0', fontSize: '11px', borderRadius: '4px', paddingTop: '2px', paddingBottom: '4px', paddingLeft: '8px', paddingRight: '8px' }}>{v.category}</small>

                                        <p className="m-2" style={s4}>{v.description}<strong>&nbsp;...</strong></p>
                                        <button style={s3} onClick={() => openChatBotDialog(v)} className="btn btn-sm m-2">{v.btnText}</button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            <FeaturedChatBotDialog open={openChatBotDialogBox.open} v={openChatBotDialogBox.v} onClose={openChatBotDialogBox.onClose} />
        </div>
    )
}
export const EducationsectionFooter = () => {

    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }

    return (
        <Container className="pt-5 pb-4" fluid >
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="text-center">
                            <p className="fw-semibold pb-4 him_fontSIZE" style={{ fontSize: '26px' }}>Use WhatsTool Business as a solution for your Business Growth</p>
                            <p className="him_fontSIZE" style={{ fontSize: '18px', color: 'gray' }}>Solve students queries 24*7 and engage with the students throughout their journey without any human effort and increase your sales</p>
                            <p className="pt-4 fw-semibold him_fontSIZE" style={{ fontSize: '16px' }}>You can do it with zero cost</p>
                            <p>
                                <Link to={{ pathname: getUrls() }} target='_blank'><button style={{ background: getColour.BaseColor, borderRadius: "8px", border: getColour.BaseColor, marginTop: "20px" }} className="btn btn-sm ps-5 pe-5 pt-2 pb-2 text-white fw-semibold">Get Started</button></Link>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
// End of Educational Industry Sector UI by Himanshu



// ECommerce Industry Sector UI by Himanshu
export const ECommerceSector = () => {

    const setParams = useContext(globalContext)
    const urlSearch = window.location.href
    if (urlSearch.includes("?")) {
        const parmas = urlSearch.split("?")[1]
        setParams.setParams(parmas)
    }
    return (
        <section>
            <EcommerceInfo />
            <Container className="clients" fluid><Client /></Container>
            <EcommercesectionOne />
            <EcommercesectionTwo />
            <EcommercesectionThree />
            <EcommercesectionFour />
            <EcommercesectionFive />
            <EcommercesectionSix />
            <EcommercesectionSeven />
            <EcommercesectionEight />
            <EcommercesectionNine />
            <EcommerceChatbotTemplates />
            <Container style={{ background: getColour.TabbackColour }} className='pt-4' fluid><Container><Testimonial /></Container></Container>
            <EcommercesectionFooter />
        </section>
    )
}
export const EcommerceInfo = () => {

    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>WhatsTool Business for E-commerce</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Promote Your products, share catalogs, receive orders online and provide customer support 24/7</span>
                                <div>
                                    <Link to={{ pathname: getUrls() }} target='_blank'></Link><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" onClick={() => window.open(getUiEnvUrl(), '_blank')} style={{ background: getColour.BaseColor, borderRadius: '8px' }}>Get Started</button>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/ecom_01.png'} style={{ height: '410px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const EcommercesectionOne = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Take Orders on WhatsApp</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Allow your customers to view your shop catalog, place orders, track shipments, cancel and edit items all from WhatsApp only.</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/ecom_02.png'} style={{ height: '500px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const EcommercesectionTwo = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/media/brodcst_01.png'} style={{ height: '402px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Send Broadcast Messages to selected Audience</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>When you want to send personalised offer/discounts to selected users or when you want users to know about the sales happening send broadcast message to selected audience and increase your sales rate by 15%</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const EcommercesectionThree = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Secure payment with Integration Partner</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Many times user will have to switch between tabs for the payment process and also user will have doubt on the safe payment. But now with the help of integration partner user can have secure payment and also no need to switch between the tabs.</p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/media/brodcst_03.png'} style={{ height: '395px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const EcommercesectionFour = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/media/brodcst_02.png'} style={{ height: '347px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '50px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Reduce the rate of return orders</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Many industry will have return orders because of incorrect size or not much information about the product. You can avoid this by chatting more with the user about his comfort and then suggesting him the proper size, colour and giving more information and also solving his queries about the product even before the purchase. This will reduce the return product rate.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const EcommercesectionFive = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Customer Support on WhatsApp</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Provide 24*7 customer support on WhatsApp without any human dependancy and without any delay in the service.</p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/ecom_04.png'} style={{ height: '410px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const EcommercesectionSix = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/ecom_05.png'} style={{ height: '294px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '50px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Collect Ratings & Reviews on WhatsApp</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Use WhatsApp to collect reviews, ratings and feedback personally.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const EcommercesectionSeven = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Green Tick Mark</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Get green tick for your business in WhatsApp, make your business official and allow your customer to trust you more.</p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/ecom_06.png'} style={{ height: isMobile ? '' : '300px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const EcommercesectionEight = () => {
    return (
        <Container style={{ background: getColour.TabbackColour }} fluid>
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="text-center">
                            <p className=" pt-5 pb-5 fw-semibold him_fontSIZE" style={{ fontSize: '26px', color: getColour.BaseColor }}>Use Team Chat for Customer support by assigning people for that particular task </p>
                            <p className="pb-5">
                                <img src={getUrl.imageBaseUrl + '/ecom_07.png'} style={{ height: isMobile ? '' : '535px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export const EcommercesectionNine = () => {
    return (
        <Container>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center">
                        <p className=" pt-5 pb-5 fw-semibold him_fontSIZE" style={{ fontSize: '26px', color: getColour.BaseColor }}>Use Broadcast to send messages to 1000+ people at a time </p>
                        <p className="pb-4">
                            <img src={getUrl.imageBaseUrl + '/ecom_08.png'} style={{ height: isMobile ? '' : '625px' }} alt={getString.SiteName} className="img-fluid" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export const EcommerceChatbotTemplates = () => {
    const s1 = { border: '1px solid #ccc', borderRadius: '8px', minHeight: '445px' }
    const s3 = { color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '2px 20px 4px 15px' }
    const s4 = { fontSize: "16px" }

    const featureTemplateSchema = [
        { title: 'WooCommerce COD Confirmation Bot', category: 'Support FAQ', description: 'This bot would capture the COD Order confirmation or cancel request from the customer and update the status back to', image: 'https://user-images.githubusercontent.com/117907145/224294142-ebc70335-552f-4f18-bdd1-8e35a91fcdc5.png', btnText: 'Try', btnActionUrl: 'i want to try woocommerce cod confirmation bot' },
    ]

    const [openChatBotDialogBox, setOpenChatBotDialogBox] = useState({ open: false, onClose: null })
    const openChatBotDialog = (v) => setOpenChatBotDialogBox({ open: true, v: v, onClose: () => setOpenChatBotDialogBox(false) })
    return (
        <div className='mt-5 mb-5'>
            <div className='mt-5 mb-5 text-center'>
                <p className='fw-semibold' style={{ fontSize: '22px' }}>Chatbot Templates for E-Commerce Industry</p>
                <p style={{ color: getColour.DescriptionTextColour, marginTop: '-12px' }}>Try it now</p>
            </div>
            <Container>
                <Row>
                    {
                        featureTemplateSchema?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className="mb-3" style={s1}>
                                        <img src={v.image} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '200px', width: '100%' }} alt="whatstool" className="img-fluid" />
                                        <p className="fw-bold mt-2 ms-2 me-2 mb-0" style={{ fontSize: '20px' }}>{v.title}</p>
                                        <small className="mt-0 ms-2 me-2 mb-2" style={{ background: '#cbd5e0', fontSize: '11px', borderRadius: '4px', paddingTop: '2px', paddingBottom: '4px', paddingLeft: '8px', paddingRight: '8px' }}>{v.category}</small>

                                        <p className="m-2" style={s4}>{v.description}<strong>&nbsp;...</strong></p>
                                        <button style={s3} onClick={() => openChatBotDialog(v)} className="btn btn-sm m-2">{v.btnText}</button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            <FeaturedChatBotDialog open={openChatBotDialogBox.open} v={openChatBotDialogBox.v} onClose={openChatBotDialogBox.onClose} />
        </div>
    )
}
export const EcommercesectionFooter = () => {

    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <Container className="pt-5 pb-4" fluid >
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="text-center">
                            <p className="fw-semibold pb-4 him_fontSIZE" style={{ fontSize: '26px' }}>Use WhatsTool Business as a solution for your Business Growth</p>
                            <p className="him_fontSIZE" style={{ fontSize: '18px', color: 'gray' }}>Provide 24*7 customer support and engage with the customers throughout their journey without any human effort and increase your sales</p>
                            <p className="pt-4 fw-semibold him_fontSIZE" style={{ fontSize: '16px' }}>You can do it with zero cost</p>
                            <p>
                                <Link to={{ pathname: getUrls() }} target='_blank'><button style={{ background: getColour.BaseColor, borderRadius: "8px", border: getColour.BaseColor, marginTop: "20px" }} className="btn btn-sm ps-5 pe-5 pt-2 pb-2 text-white fw-semibold">Get Started</button></Link>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container >
    )
}
// End of ECommerce Industry Sector UI by Himanshu


// Health Industry Sector UI by Himanshu
export const HealthSector = () => {

    const setParams = useContext(globalContext)
    const urlSearch = window.location.href
    if (urlSearch.includes("?")) {
        const parmas = urlSearch.split("?")[1]
        setParams.setParams(parmas)
    }
    return (
        <>
            <HealthInfo />
            <Container className="clients" fluid><Client /></Container>
            <HealthsectionOne />
            <HealthsectionTwo />
            <HealthsectionThree />
            <HealthsectionFour />
            <HealthsectionFive />
            <HealthsectionSix />
            <HealthsectionSeven />
            <HealthsectionEight />
            <HealthChatbotTemplates />
            <Container style={{ background: getColour.TabbackColour }} className='pt-4' fluid><Container><Testimonial /></Container></Container>
            <HealthsectionFooter />
        </>
    )
}
export const HealthInfo = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>WhatsTool Business for Healthcare & Medicals</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Go the extra mile by providing your patients and customers the ability to reach out to you 24/7 and at every step of their journey</span>
                                <div>
                                    <Link to={{ pathname: getUrls() }} target='_blank'></Link><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" onClick={() => window.open(getUiEnvUrl(), '_blank')} style={{ background: getColour.BaseColor, borderRadius: '8px' }}>Get Started</button>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/heath_01.png'} style={{ height: '410px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const HealthsectionOne = () => {
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Take Appointment on WhatsApp</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Allow your patient to view the available doctor details and choose the timings and get appointment from WhatsApp only.</span>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/heath_02.png'} style={{ height: '410px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const HealthsectionTwo = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/heath_03.png'} style={{ height: '365px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Automated Appointment Reminders</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Send your customers timely reminders for their appointments to ensure that they never miss appointment.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const HealthsectionThree = () => {
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Sample & Report Collection Notifications</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Send notifications to remind patients to collect samples and reports from your clinic or hospital.</span>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/heath_04.png'} style={{ height: '348px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const HealthsectionFour = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/heath_05.png'} style={{ height: '440px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Post Consultation Follow Up</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>You can send post - consultation follow ups to check in on your patients. It helps you provide that personal touch.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const HealthsectionFive = () => {
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Collect Feedback from Patients</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Use WhatsApp to collect feedback from patients about the treatment and hospital. See higher response rate.</span>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/heath_06.png'} style={{ height: '272px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const HealthsectionSix = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/heath_07.png'} style={{ height: isMobile ? '' : '300px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Green Tick Mark</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Get green tick for your Hospital in WhatsApp, make it official and allow people to trust you more.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const HealthsectionSeven = () => {
    return (
        <Container>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center">
                        <p className=" pt-5 pb-5 fw-semibold him_fontSIZE" style={{ fontSize: '26px', color: getColour.BaseColor }}>Use Team Chat to answer Patient and Faculty queries by assigning people for that particular task</p>
                        <p className="pb-5">
                            <img src={getUrl.imageBaseUrl + '/heath_08.png'} style={{ height: isMobile ? '' : '535px' }} alt={getString.SiteName} className="img-fluid" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export const HealthsectionEight = () => {
    return (
        <Container>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center">
                        <p className=" pt-5 pb-5 fw-semibold him_fontSIZE" style={{ fontSize: '26px', color: getColour.BaseColor }}>Use Broadcast to send messages to 1000+ people at a time</p>
                        <p className="pb-4">
                            <img src={getUrl.imageBaseUrl + '/heath_09.png'} style={{ height: isMobile ? '' : '625px' }} alt={getString.SiteName} className="img-fluid" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export const HealthChatbotTemplates = () => {
    const s1 = { border: '1px solid #ccc', borderRadius: '8px', minHeight: '445px' }
    const s3 = { color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '2px 20px 4px 15px' }
    const s4 = { fontSize: "16px" }

    const featureTemplateSchema = [
        { title: 'Self Diagnosis Bot', category: 'Lead Generation', description: 'Self Diagnosis bot template is an ideal choice for healthcare businesses to let their patients/by-standers diagnose for any', image: 'https://user-images.githubusercontent.com/117907145/226508993-14c6242a-c6b3-415a-98d7-7460ea5f6908.png', btnText: 'Try', btnActionUrl: '' },
        { title: 'Health Package Booking Bot', category: 'Booking / Scheduling', description: 'Health package booking chatbot is designed for you to easily offer packages on various checkups. This chatbot template', image: 'https://user-images.githubusercontent.com/117907145/226508994-47fc976f-2ff5-45ca-b718-076876bb0384.png', btnText: 'Try', btnActionUrl: '' },
        { title: 'Blood Test Home Collection Bot', category: 'Booking / Scheduling', description: 'Blood Test Home Collection chatbot is an automated interactive bot template which lets your users book blood test collection', image: 'https://user-images.githubusercontent.com/117907145/226508996-3b4c0b21-aa13-4927-b5cd-4e34b4c49a9f.png', btnText: 'Try', btnActionUrl: 'i want to try blood test home collection bot' },
        { title: 'Doctor Appointment Booking Bot', category: 'Booking / Scheduling', description: 'The Doctor Appointment chatbot is designed for you to easily offer appointment bookings to people who are in', image: 'https://user-images.githubusercontent.com/117907145/226508987-a781c42d-83c8-47ba-8101-7acc2d15439a.png', btnText: 'Try', btnActionUrl: 'i want to try doctor appointment booking bot' }
    ]

    const [openChatBotDialogBox, setOpenChatBotDialogBox] = useState({ open: false, onClose: null })
    const openChatBotDialog = (v) => setOpenChatBotDialogBox({ open: true, v: v, onClose: () => setOpenChatBotDialogBox(false) })
    return (
        <div className='mt-5 mb-5'>
            <div className='mt-5 mb-5 text-center'>
                <p className='fw-semibold' style={{ fontSize: '22px' }}>Chatbot Templates for Health Industry</p>
                <p style={{ color: getColour.DescriptionTextColour, marginTop: '-12px' }}>Try it now</p>
            </div>
            <Container>
                <Row>
                    {
                        featureTemplateSchema?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className="mb-3" style={s1}>
                                        <img src={v.image} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '200px', width: '100%' }} alt="whatstool" className="img-fluid" />
                                        <p className="fw-bold mt-2 ms-2 me-2 mb-0" style={{ fontSize: '20px' }}>{v.title}</p>
                                        <small className="mt-0 ms-2 me-2 mb-2" style={{ background: '#cbd5e0', fontSize: '11px', borderRadius: '4px', paddingTop: '2px', paddingBottom: '4px', paddingLeft: '8px', paddingRight: '8px' }}>{v.category}</small>

                                        <p className="m-2" style={s4}>{v.description}<strong>&nbsp;...</strong></p>
                                        <button style={s3} onClick={() => openChatBotDialog(v)} className="btn btn-sm m-2">{v.btnText}</button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>

            <FeaturedChatBotDialog open={openChatBotDialogBox.open} v={openChatBotDialogBox.v} onClose={openChatBotDialogBox.onClose} />
        </div>
    )
}

export const HealthsectionFooter = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <Container className="pt-5 pb-4" fluid >
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="text-center">
                            <p className="fw-semibold pb-4 him_fontSIZE" style={{ fontSize: '26px' }}>Use WhatsTool Business as a solution for your Business Growth</p>
                            <p className="him_fontSIZE" style={{ fontSize: '18px', color: 'gray' }}>Solve students queries 24*7 and engage with the students throughout their journey without any human effort and increase your sales</p>
                            <p className="pt-4 fw-semibold him_fontSIZE" style={{ fontSize: '16px' }}>You can do it with zero cost</p>
                            <p>
                                <Link to={{ pathname: getUrls() }} target='_blank'><button style={{ background: getColour.BaseColor, borderRadius: "8px", border: getColour.BaseColor, marginTop: "20px" }} className="btn btn-sm ps-5 pe-5 pt-2 pb-2 text-white fw-semibold">Get Started</button></Link>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
// End of Health Industry Sector UI by Himanshu



// Tour Travel Industry Sector UI by Himanshu
export const TourTravelSector = () => {
    const setParams = useContext(globalContext)
    const urlSearch = window.location.href
    if (urlSearch.includes("?")) {
        const parmas = urlSearch.split("?")[1]
        setParams.setParams(parmas)
    }
    return (
        <>
            <TravelInfo />
            <Container className="clients" fluid><Client /></Container>
            <TravelsectionOne />
            <TravelsectionTwo />
            <TravelsectionThree />
            <TravelsectionFour />
            <TravelsectionFive />
            <TravelsectionSix />
            <TravelsectionSeven />
            <TravelsectionEight />
            <TravelsectionNine />
            <TravelChatbotTemplates />
            <Container style={{ background: getColour.TabbackColour }} className='pt-4' fluid><Container><Testimonial /></Container></Container>
            <TravelsFooter />
        </>
    )
}
export const TravelInfo = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>WhatsTool Business for Tours & Travels</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Assist, Coordinate, Recommend & Plan for your customers journey and make it a perfect trip by providing customer support 24/7</span>
                                <div>
                                    <Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: getColour.BaseColor, borderRadius: '8px' }}>Get Started</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/tt_01.png'} style={{ height: '410px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const TravelsectionOne = () => {
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Assist Bookings on WhatsApp</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Allow your customer to book, modify and cancel it from WhatsApp only.</span>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/tt_02.png'} style={{ height: '482px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const TravelsectionTwo = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/tt_03.png'} style={{ height: '334px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Automated Booking Reminders</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Send your customers timely reminders of their bookings to ensure that they never miss or be late for the trip.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const TravelsectionThree = () => {
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Make Repeat Customers</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Provide customers a memorable and delightful experiences so that they come back again.</span>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/tt_04.png'} style={{ height: '482px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const TravelsectionFour = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/Customer_Sop.png'} style={{ height: '396px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Provide Customer Support 24/7</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>You can solve your customers queries 24/7 without any human dependancy by using chatbot.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const TravelsectionFive = () => {
    return (
        <section>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Share Exciting Deals and Offers</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Send your customers about the current deals and offers with the personalised message.</span>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} col={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/130449582/233981954-2a35d793-86c3-4232-94e8-5e2090b21350.png" style={{ height: '426px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
export const TravelsectionSix = () => {
    return (
        <div style={{ background: getColour.TabbackColour }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src={getUrl.imageBaseUrl + '/tt_07.png'} style={{ height: '294px' }} alt={getString.SiteName} className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Collect Customer Reviews</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Use WhatsApp to collect reviews and feedback from your customers.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const TravelsectionSeven = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={6} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Green Tick Mark</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Get green tick for your Business in WhatsApp, make it official and allow people to trust you more.</p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12} >
                            <p className="text-center">
                                <img src={getUrl.imageBaseUrl + '/tt_08.png'} style={{ height: isMobile ? '' : '300px' }} alt={getString.SiteName} className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const TravelsectionEight = () => {
    return (
        <Container>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center">
                        <p className=" pt-5 pb-5 fw-semibold him_fontSIZE" style={{ fontSize: '26px', color: getColour.BaseColor }}>Use Team Chat for customer support by assigning people for that particular task</p>
                        <p className="pb-5">
                            <img src={getUrl.imageBaseUrl + '/tt_09.png'} style={{ height: isMobile ? '' : '535px' }} alt={getString.SiteName} className="img-fluid" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export const TravelsectionNine = () => {
    return (
        <Container>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="text-center">
                        <p className=" pt-5 pb-5 fw-semibold him_fontSIZE" style={{ fontSize: '26px', color: getColour.BaseColor }}>Use Broadcast to send messages to 1000+ people at a time</p>
                        <p className="pb-4">
                            <img src={getUrl.imageBaseUrl + '/tt_10.png'} style={{ height: isMobile ? '' : '625px' }} alt={getString.SiteName} className="img-fluid" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export const TravelChatbotTemplates = () => {
    const s1 = { border: '1px solid #ccc', borderRadius: '8px', minHeight: '445px' }
    const s3 = { color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '2px 20px 4px 15px' }
    const s4 = { fontSize: "16px" }

    const featureTemplateSchema = [
        { title: 'Bus Booking Bot', category: 'Booking / Scheduling', description: 'Our bus booking bot allows you to provide real-time bus ticket information & help them book their bus tickets & pay within the chatbot window.', image: 'https://user-images.githubusercontent.com/117907145/224294809-f989e67d-cfc0-40d3-9344-88b8e5d663dd.png', btnText: 'Try', btnActionUrl: 'i want to try bus booking bot' },
        { title: 'Flight Booking Bot', category: 'Booking / Scheduling', description: 'Help your customers find & book flight tickets on the go & create seamless, hassle-free flight booking conversations by', image: 'https://user-images.githubusercontent.com/117907145/224295170-d9a6720d-3b5f-439e-a4d6-c65152c1325e.png', btnText: 'Try', btnActionUrl: 'i want to try flight booking bot' },
        { title: 'Tour Package Booking Bot', category: 'Lead Generation', description: 'This chatbot template is designed to learn the travel needs of your customers and help them book the best package that suits', image: 'https://user-images.githubusercontent.com/117907145/224295689-c3e5086b-4d77-4abd-ab07-308eecc7bbd3.png', btnText: 'Try', btnActionUrl: 'we want to try flight web check in bot' },
        { title: 'Vehicle Service Booking Bot', category: 'Booking / Scheduling', description: 'This bot helps auto dealers streamline their vehicle service process & help your customers schedule a service instantly &', image: 'https://user-images.githubusercontent.com/117907145/224298149-b26c73e3-9cde-4092-935d-8bd78eda4f6d.png', btnText: 'Try', btnActionUrl: 'i want to try vehicle service booking bot' },
        { title: 'Vehicle Insurance Renewal Bot', category: 'Support FAQ', description: 'Help your customers save precious time by enabling them to renew their vehicle insurance instantly from their homes with', image: 'https://user-images.githubusercontent.com/117907145/224298476-c1ab4caf-81fd-4300-9c85-da7f76e5cb8b.png', btnText: 'Try', btnActionUrl: 'i want to try vehicle insurance renewal bot' },
        { title: 'Book a Test Drive Bot', category: 'Booking / Scheduling', description: 'Our Test Drive chatbot helps your customers find the nearest dealership & book a test drive in just a few minutes.', image: 'https://user-images.githubusercontent.com/117907145/226508992-24642a93-8326-4770-8cb5-a057b14bbca7.png', btnText: 'Try', btnActionUrl: 'i want to try book a test drive bot' },
    ]

    const [openChatBotDialogBox, setOpenChatBotDialogBox] = useState({ open: false, onClose: null })
    const openChatBotDialog = (v) => setOpenChatBotDialogBox({ open: true, v: v, onClose: () => setOpenChatBotDialogBox(false) })
    return (
        <div className='mt-5 mb-5'>
            <div className='mt-5 mb-5 text-center'>
                <p className='fw-semibold' style={{ fontSize: '22px' }}>Chatbot Templates for Tour & Travels Industry</p>
                <p style={{ color: getColour.DescriptionTextColour, marginTop: '-12px' }}>Try it now</p>
            </div>
            <Container>
                <Row>
                    {
                        featureTemplateSchema?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className="mb-3" style={s1}>
                                        <img src={v.image} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '200px', width: '100%' }} alt="whatstool" className="img-fluid" />
                                        <p className="fw-bold mt-2 ms-2 me-2 mb-0" style={{ fontSize: '20px' }}>{v.title}</p>
                                        <small className="mt-0 ms-2 me-2 mb-2" style={{ background: '#cbd5e0', fontSize: '11px', borderRadius: '4px', paddingTop: '2px', paddingBottom: '4px', paddingLeft: '8px', paddingRight: '8px' }}>{v.category}</small>

                                        <p className="m-2" style={s4}>{v.description}<strong>&nbsp;...</strong></p>
                                        <button style={s3} onClick={() => openChatBotDialog(v)} className="btn btn-sm m-2">{v.btnText}</button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>

            <FeaturedChatBotDialog open={openChatBotDialogBox.open} v={openChatBotDialogBox.v} onClose={openChatBotDialogBox.onClose} />
        </div>
    )
}
export const TravelsFooter = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <Container className="pt-5 pb-4" fluid >
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="text-center">
                            <p className="fw-semibold pb-4 him_fontSIZE" style={{ fontSize: '26px' }}>Use WhatsTool Business as a solution for your Business Growth</p>
                            <p className="him_fontSIZE" style={{ fontSize: '18px', color: 'gray' }}>Provide 24*7 customer support and engage with the customers throughout their journey without any human effort and increase your sales</p>
                            <p className="pt-4 fw-semibold him_fontSIZE" style={{ fontSize: '16px' }}>You can do it with zero cost</p>
                            <p>
                                <Link to={{ pathname: getUrls() }} target='_blank'><button style={{ background: getColour.BaseColor, borderRadius: "8px", border: getColour.BaseColor, marginTop: "20px" }} className="btn btn-sm ps-5 pe-5 pt-2 pb-2 text-white fw-semibold">Get Started</button></Link>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container >
    )
}
// End of Tour Travel Industry Sector UI by Himanshu



// Team Page by Himanshu
export const TeamPage = () => {
    return (
        <div className='mt-4 mb-5 pb-5'>
            <TeamPageHeader />
            <TeamPageMission />
            <OurTeams />
            <OurStory />
        </div>
    )
}
export const TeamPageHeader = () => {
    return (
        // <Container style={{ background: getColour.MainBgColour }} fluid>
        <Container className='linearGradient' fluid>


            <Row>
                <Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12} >
                    <div style={{ padding: isMobile ? '75px 30px' : "50px 140px" }} className=''>
                        <p style={{ fontSize: isMobile ? '25px' : "35px" }} className={isMobile ? '' : 'fw-semibold him_sizing'}>Empowering with the power of WhatsApp to Businesses </p>
                        <p style={{ fontSize: isMobile ? '20px' : "30px", color: "gray" }} className='pe-5'>for their growth in sales, marketing & customer support</p>
                    </div>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12} >
                    <LazyLoadImage src='https://user-images.githubusercontent.com/130449582/233082237-6b0c9254-1bbe-45a5-9d22-7df41a2754dd.png' style={{ width: '100%', flex: 1.45 }} alt='whatstool business' className='img-fluid' />
                </Col>
            </Row>
        </Container>
    )
}
export const TeamPageMission = () => {
    const OurAimbox = [
        { image: getUrl.imageBaseUrl + '/our_aim_01.webp', info: 'Customers First', title: 'Delivering customers what they need is our first priority' },
        { image: getUrl.imageBaseUrl + '/our_aim_02.webp', info: 'Providing Best', title: 'We always work hard to provide best for our customer in their growth' },
        { image: getUrl.imageBaseUrl + '/our_aim_03.webp', info: 'Being Loyal', title: 'We always wish to gain customer’s trust and loyal' },
        { image: getUrl.imageBaseUrl + '/our_aim_04.webp', info: 'Part of the Team', title: 'We always try to be the part of our customer’s team to solve the issue' }
    ]
    return (
        <div>
            <Container>
                <Row>
                    <div>
                        <p className='text-center fw-semibold mt-5 him_sizing' style={{ fontSize: "30px" }}>OUR MISSION</p>
                        <p style={{ fontSize: "20px", color: "gray" }} className='mt-5 him_Sizing'>We are in the mission to create the same impact for business that WhatsApp created for the users and businesses across the world. We want to empower business and teams with the tools for their growth in sales, support and marketing. Your growth is our mission.</p>
                        <p className='text-center fw-semibold him_sizing' style={{ fontSize: "30px", marginTop: '60px' }}>OUR AIM</p>
                    </div>
                    {
                        OurAimbox.map((v, i) => {
                            return (
                                <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} key={i}>
                                    <div className='mt-5 text-center mb-5 '>

                                        <LazyLoadImage src={v.image} alt='whatstool business' style={{ width: "100px", borderRadius: '2px' }} className='him_Boxsed ' />

                                        <p style={{ fontSize: "20px" }} className='fw-semibold him_sizedepen'>{v.info}</p>
                                        <p className='him_Sizing' style={{ color: "gray", fontSize: '15px' }}>{v.title}</p>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}
export const OurTeams = () => {
    const EmployeeIntro = [
        { empimage: getUrl.imageBaseUrl + '/ashish.webp', name: 'Ashish Kumar', profilerole: 'Software Developer', socialimg1: getUrl.imageBaseUrl + '/twitter.webp', socialimg2: getUrl.imageBaseUrl + '/insta.webp', socialimg3: getUrl.imageBaseUrl + '/in.webp', twitter: '', instagram: 'https://www.instagram.com/ak4344343/', linkedin: 'https://www.linkedin.com/in/ashish-kumar-6a7518186/' },

        { empimage: getUrl.imageBaseUrl + '/mayank.webp', name: 'Mayank Kumar', profilerole: 'Software Developer', socialimg1: getUrl.imageBaseUrl + '/twitter.webp', socialimg2: getUrl.imageBaseUrl + '/insta.webp', socialimg3: getUrl.imageBaseUrl + '/in.webp', instagram: '', twitter: '', linkedin: 'https://www.linkedin.com/in/mayank-uoi/' },
        { empimage: getUrl.imageBaseUrl + '/madhushree.webp', name: 'Madhushree BJ', profilerole: 'UI/UX Designer', socialimg1: getUrl.imageBaseUrl + '/twitter.webp', socialimg2: getUrl.imageBaseUrl + '/insta.webp', socialimg3: getUrl.imageBaseUrl + '/in.webp', twitter: '', instagram: 'https://www.instagram.com/madhushreebj/', linkedin: 'https://www.linkedin.com/in/madhushree-bj-680843180/' },
        { empimage: getUrl.imageBaseUrl + '/animesh.webp', name: 'Animesh Dey', profilerole: 'Software Developer', socialimg1: getUrl.imageBaseUrl + '/twitter.webp', socialimg2: getUrl.imageBaseUrl + '/insta.webp', socialimg3: getUrl.imageBaseUrl + '/in.webp', twitter: '', instagram: '', linkedin: 'https://www.linkedin.com/in/dey-animesh/' },
        { empimage: getUrl.imageBaseUrl + '/suryanshu.webp', name: 'Suryanshu Raj', profilerole: 'Customer Success Manager', socialimg1: getUrl.imageBaseUrl + '/twitter.webp', socialimg2: getUrl.imageBaseUrl + '/insta.webp', socialimg3: getUrl.imageBaseUrl + '/in.webp', twitter: 'https://twitter.com/SuryanshuRaj19', instagram: '', linkedin: 'https://www.linkedin.com/in/suryanshu-raj-18a31a21b/' },
        { empimage: getUrl.imageBaseUrl + '/purnima.webp', name: 'Purnima Kumari', profilerole: 'Customer Success Manager', socialimg1: getUrl.imageBaseUrl + '/twitter.webp', socialimg2: getUrl.imageBaseUrl + '/insta.webp', socialimg3: getUrl.imageBaseUrl + '/in.webp', twitter: 'https://twitter.com/SuryanshuRaj19', instagram: '', linkedin: 'https://www.linkedin.com/in/suryanshu-raj-18a31a21b/' },
    ]

    const s1 = { fontSize: "24px", color: getColour.BaseColor }
    const s2 = { fontSize: '18px', margin: '-20px 0px 0px 0px' }
    const s3 = { color: 'gray' }
    const s4 = { fontSize: '28px' }
    const s5 = { fontSize: '18px', color: 'gray', marginTop: '-15px' }
    return (
        <div className='mt-5 mb-5'>
            <Container>
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5'>
                        <div className={isMobile ? '' : "ms-4"}>
                            <LazyLoadImage src={getUrl.imageBaseUrl + '/sujeet.webp'} alt='Whatstool Business' className='img-fluid p-3 ms-2' style={{ width: '330px', height: '330px', borderRadius: '24px' }} />
                        </div>
                    </Col>

                    <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12} className='mb-5'>
                        <div className='ms-5 mt-5'>
                            <p className='fw-semibold mt-3 mb-5 him_sizing' style={{ fontSize: "25px" }}>OUR TEAM</p>
                            <p style={s1} className='fw-semibold him_sizing'>“We are a team working together to empower each other through our work, skills and support in the growth of our clients.”</p><br />
                            <p style={s2} className='fw-bold him_Sizing'>Sujeet Kumar Mehta - <span style={s3} className='fw-semibold'>CEO & Founder</span>&nbsp;&nbsp;<span><Link to={{ pathname: 'https://www.linkedin.com/in/sujeetkumarmehta/' }} target='_blank'><LinkedIn style={{ fontSize: '22px', marginTop: '-6px', color: getColour.LightTextColour }} /></Link></span></p>
                            <span role='button' onClick={() => window.open('mailto:sujeet@whatstool.in?subject=Subject&body=Body%20goes%20here')}><small style={{ color: getColour.LightTextColour }}><MailOutlineOutlinedIcon />&nbsp;sujeet@whatstool.in</small></span>
                        </div>
                    </Col>
                    {
                        EmployeeIntro.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className='mt-5 ms-5 me-5 mb-5'>
                                        <p className='text-center'>
                                            <LazyLoadImage src={v.empimage} alt="whatstool Business" className='img-fluid' style={{ height: '350px', borderRadius: '8px' }} />
                                        </p>
                                        <div className='text-center'>
                                            <p style={s4} className='fw-semibold him_sizing'>{v.name}</p>
                                            <p className='him_Sizing' style={s5}>{v.profilerole}</p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}
export const OurStory = () => {
    const TeamGallery = [
        { image: getUrl.imageBaseUrl + '/group_01.webp' },
        { image: getUrl.imageBaseUrl + '/group_02.webp' },
        { image: getUrl.imageBaseUrl + '/group_03.webp' },
        { image: getUrl.imageBaseUrl + '/group_04.webp' },
        { image: getUrl.imageBaseUrl + '/group_05.webp' },
        { image: getUrl.imageBaseUrl + '/group_06.webp' },
        { image: getUrl.imageBaseUrl + '/g7.webp' },
        { image: getUrl.imageBaseUrl + '/g8.webp' },
        { image: getUrl.imageBaseUrl + '/g10.webp' },
        { image: getUrl.imageBaseUrl + '/g13.webp' },
        { image: getUrl.imageBaseUrl + '/g15.webp' },
        { image: getUrl.imageBaseUrl + '/g16.webp' },
        { image: getUrl.imageBaseUrl + '/g17.webp' },
        { image: getUrl.imageBaseUrl + '/g18.webp' },
        { image: getUrl.imageBaseUrl + '/g19.webp' },
        { image: getUrl.imageBaseUrl + '/g20.webp' },
        { image: getUrl.imageBaseUrl + '/g9.webp' },
        { image: getUrl.imageBaseUrl + '/g12.webp' }
    ]
    const s1 = { fontSize: '32px' }
    return (
        <div>
            <Container>
                <div className='mt-5'>
                    <p style={s1} className='fw-semibold mb-5 text-center him_sizing'>GALLERY</p>
                    {/* <p className='him_sizedepen' style={s2}>WhatsTool Business started in the year of 2018 with the team of 2, later official office started in Haziribagh with the team of 4. Then later we started our new office branch in Bengaluru location. Then later our clients ratio increased along with our team. We started growing big by solving our customers problem in their field of industry with any size. Now we are a team of 12 Members each having their unique dedication towards work. Hard work and Team work has brought us here.</p> */}
                </div>
                <ImageList sx={{ width: 'auto', height: 'auto' }} variant="quilted" cols={isMobile ? 1 : 4} rowHeight={'auto'}>
                    {TeamGallery.map((item, i) => (
                        <ImageListItem key={i}>
                            <img
                                src={`${item.image}?w=300&h=300&fit=crop&auto=format`}
                                srcSet={`${item.image}?w=300&h=300&fit=crop&auto=format&dpr=2 2x`}
                                alt={getString.SiteName}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Container>
        </div>
    )
}
// End of Team Page by Himanshu



// Conversational Engagement by Himanshu
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})
export const ConversationalEngagement = () => {
    const reqRef = useRef(null)
    return (
        <div className='mt-4'>
            <ConversationalInfo reqRef={reqRef} />
            <div className='mt-5'><Client /></div>
            <ConversationalSection1 />
            <ConversationalSection2 />
            <ConversationalSection3 />
            <ConversationalSection4 />
            <ConversationalSection5 reqRef={reqRef} />
        </div>
    )
}
export const ConversationalInfo = ({ reqRef }) => {
    const s4 = { width: '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', color: 'gray' }
    const s6 = { background: getColour.BaseColor, marginTop: '17px' }
    const s5 = { width: '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 15px' }
    const s3 = { width: '80%', height: '24mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '10px 15px' }
    const s1 = { color: 'gray' }

    const [countryCode, setCountryCode] = useState('+91')
    const handleCountryCode = (code) => {
        setCountryCode(code)
        handleClose()
    }
    const [mobile, setMobile] = useState('')
    const handleMobile = (val) => {
        let checkedVal = val?.replace(/[^0-9]/ig, '')
        setMobile(checkedVal)
    }

    const [countryCodeLs, setCountryCodeLs] = useState(undefined)
    const getCountryCode = async () => {
        const countryCodeResponse = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/CountryCodes.json')
        setCountryCodeLs(await countryCodeResponse.json())
    }
    useEffect(() => { getCountryCode() }, [])

    const [openDialog, setOpenDialog] = useState(false);
    const openCountrySelectionDialogBox = () => {
        setOpenDialog(true)
    }
    const handleClose = () => setOpenDialog(false)

    const [searchLs, setSearchLs] = useState([])
    useEffect(() => { setSearchLs(countryCodeLs) }, [countryCodeLs])
    const searchItems = (searchValue) => {
        const filteredData = countryCodeLs.filter((item) => { return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase()) })
        setSearchLs(filteredData)
    }

    // Snackbar Code
    const [errorMsg, setErrorMsg] = useState("")
    const [errorMsgBg, setErrorMsgBg] = useState(getColour.RedColor)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const SnackbarShow = (errorMsg, errorMsgBg) => {
        setErrorMsg(errorMsg)
        setErrorMsgBg(errorMsgBg)
        setOpenSnackbar(true)
    }
    const SnackbarClose = () => setOpenSnackbar(false)
    // End of Snackbar Code


    const [name, setName] = useState('')
    const handleName = (val) => setName(val)
    const [companyName, setCompanyName] = useState('')
    const handleCompanyName = (val) => setCompanyName(val)
    const [emailId, setEmailId] = useState('')
    const handleEmailId = (val) => setEmailId(val)
    const [companySize, setCompanySize] = useState('')
    const handleCompanySize = (val) => setCompanySize(val)
    const [message, setMessage] = useState('')
    const handleMessage = (val) => setMessage(val)

    const [formStatus, setFormStatus] = useState(true)
    const validateForm = () => {
        if (name?.length !== 0 && companyName?.length !== 0 && emailId?.length !== 0 && companySize?.length !== 0 && companyName?.length !== 0 && mobile?.length !== 0) {
            setFormStatus(false)
        } else {
            setFormStatus(true)
        }
    }
    useEffect(() => {
        validateForm()
    }, [name, companyName, emailId, companyName, companySize, mobile])

    const [openReqDialog, setOpenReqDialog] = useState({ open: false, onClose: null })
    const openReqDialogBox = () => {
        setOpenReqDialog({
            open: true, onClose: () => {
                setOpenReqDialog(false)
            }
        })
    }

    const handleFormSubmit = async () => {
        try {
            let data = `https://script.google.com/macros/s/AKfycbwnJYBoGRgQfw0MDd6Hg9N1osaosxmtmPUhiHk-HeIijDbXZJo9nvq6pFWs5UzjuNrB/exec?name=${name}&companyName=${companyName}&mobile=${countryCode}${mobile}&email=${emailId}&companySize=${companySize}&message=${message}`
            const response = await fetch(data)
            if (response) {
                WtEvent(getAnalyticsKey.conversationalEngagement)
                handleName('')
                handleCompanyName('')
                handleEmailId('')
                handleCompanySize('')
                handleMessage('')
                setMobile('')
                openReqDialogBox()
                SnackbarShow('Request Submitted Successfully', getColour.BaseColor)
            }
        } catch (error) { }
    }
    return (
        <div ref={reqRef}>
            <Container>
                <Row>
                    <div className="text-center mt-5 mb-5">
                        <h3>Conversational engagement on WhatsApp Business is now made Automate </h3>
                        <p style={{ color: 'gray', fontSize: isMobile ? '18px' : '' }}>Engage customers across sales, marketing and customer support with WhatsApp Business API</p>
                    </div>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <div>
                            <LazyLoadImage src={getUrl.imageBaseUrl + '/con_01.webp'} alt="WTB" className="img-fluid" style={{ padding: isMobile ? '0' : '50px' }} />
                        </div>
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                        <div>
                            <input value={name} onChange={(e) => handleName(e.target.value)} style={s5} type='text' placeholder="Name" />
                            <input value={companyName} onChange={(e) => handleCompanyName(e.target.value)} style={s5} type='text' placeholder="Company Name" />

                            <div style={{ width: '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', display: 'flex' }} >
                                <span onClick={() => openCountrySelectionDialogBox()} style={s1} className="ps-3 pe-2 pt-2 hand">{countryCode}&nbsp;|</span>
                                <input style={{ border: 'none', outline: 'none', width: '85%' }} className='hand' value={mobile} onChange={(e) => handleMobile(e.target.value)} type="tel" placeholder="Mobile Number" />
                            </div>

                            <input value={emailId} onChange={(e) => handleEmailId(e.target.value)} style={s5} type='email' placeholder="Work Email Address" />

                            <Form.Select style={s4} value={companySize} onChange={(e) => handleCompanySize(e.target.value)}>
                                <option value=''>Company Size</option>
                                <option value="1 - 10 employees">1 - 10 employees</option>
                                <option value="11 - 50 employees">11 - 50 employees</option>
                                <option value="51 - 200 employees">51 - 200 employees</option>
                                <option value="201 - 500 employees">201-500 employees</option>
                            </Form.Select>

                            <textarea value={message} onChange={(e) => handleMessage(e.target.value)} style={s3} type='text' placeholder="Message (Optional)">{message}</textarea>

                            <button onClick={() => handleFormSubmit()} disabled={formStatus ? true : false} className="text-white btn btn-sm ps-4 pe-4 pt-2 pb-2 fw-semibold rounded-3" style={s6}>Request a Callback</button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Dialog open={openDialog} TransitionComponent={Transition} keepMounted onClose={handleClose}>
                <input onChange={(e) => searchItems(e.target.value)} placeholder="Select Country Code" required type={'search'} className='form-control'></input>
                <DialogContent>
                    {
                        searchLs?.map((v, i) => {
                            return (
                                <p key={i} className='hand' onClick={() => handleCountryCode(v.dial_code)}>{v.name}{v.dial_code}</p>
                            )
                        })
                    }
                </DialogContent>
            </Dialog>

            <ShowSnackbar open={openSnackbar} errorMsg={errorMsg} errorMsgBg={errorMsgBg} close={SnackbarClose} />
            {openReqDialog.open && <ConversationalEngagementDialog open={openReqDialog.open} onClose={openReqDialog.onClose} />}
        </div>
    )
}
export const ConversationalSection1 = () => {
    return (
        <div>
            <Container>
                <div style={{ marginTop: '140px' }}>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div>
                                <LazyLoadImage src={getUrl.imageBaseUrl + '/con_02.webp'} alt="Whatstool Business" className="img-fluid" style={{ padding: isMobile ? '0' : '50px' }} />
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div style={{ marginTop: isMobile ? '54px' : '175px' }}>
                                <h4 className="mb-3">Take Orders on WhatsApp</h4>
                                <h6 style={{ color: 'gray' }} className='mb-3'>Allow your customers to view your shop catalog and order through WhatsApp only</h6>
                                <LazyLoadImage src={getUrl.imageBaseUrl + '/con_03.webp'} alt="WTB" style={{ height: '80px' }} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const ConversationalSection2 = () => {
    return (
        <div>
            <Container>
                <div style={{ marginTop: '100px' }}>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div style={{ marginTop: isMobile ? '' : '175px' }} >
                                <h4 className="mb-3">Recover Abandoned Carts on WhatsApp</h4>
                                <h6 style={{ color: 'gray' }} className='mb-3'>Many times user will add the products to the cart and forget to order. Send a reminder on whatsApp in which through a single click it takes them to the checkout page.</h6>
                                <LazyLoadImage src={getUrl.imageBaseUrl + '/con_04.webp'} alt="WTB" style={{ height: '80px' }} />
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div>
                                <LazyLoadImage src={getUrl.imageBaseUrl + '/con_05.webp'} alt="Whatstool Business" className="img-fluid" style={{ padding: isMobile ? '0' : '50px' }} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const ConversationalSection3 = () => {
    return (
        <div>
            <Container>
                <div style={{ marginTop: '100px' }}>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div>
                                <LazyLoadImage src={getUrl.imageBaseUrl + '/con_06.webp'} alt="Whatstool Business" className="img-fluid" style={{ padding: isMobile ? '0' : '50px' }} />
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div style={{ marginTop: isMobile ? '54px' : '175px' }}>
                                <h4 className="mb-3">Sent Personalised Offer and Notification</h4>
                                <h6 style={{ color: 'gray' }} className='mb-3'>Send promotions, offers, and back-in-stock alerts. Set up campaigns, send re-buy reminders. Send booking confirmations, subscription reminders, delivery updates and more</h6>
                                <LazyLoadImage src={getUrl.imageBaseUrl + '/con_07.webp'} alt="WTB" style={{ height: '80px' }} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const ConversationalSection4 = () => {
    const s1 = { border: '1px solid #21cd5b', borderRadius: '12px', boxShadow: '0 0 4px 2px rgb(0 0 0 / 25%)', minHeight: '375px', maxHeight: '375px', marginBottom: '50px' }

    const BenefitBox = [
        { image: 'https://user-images.githubusercontent.com/117907145/230591068-38cdfd2f-8981-4ab4-aad8-6a63a69d86e2.png', title: 'Up-Sell and Cross-sell products' },
        { image: 'https://user-images.githubusercontent.com/117907145/230591220-da71be91-c8c5-43f5-bbe1-e385c7c3b072.png', title: 'Wider Target Reach' },
        { image: 'https://user-images.githubusercontent.com/117907145/230591474-d325f349-c675-4bbb-990b-0b90dd36b068.png', title: 'Better Connect with Customer' },
        { image: 'https://user-images.githubusercontent.com/117907145/230592910-ad18fa14-a9d1-4450-ad17-4998b884b0ef.png', title: 'Cost Effectiveness' },
        { image: 'https://user-images.githubusercontent.com/117907145/230592014-cf68d782-4b71-49c7-9ddd-4231f41e1a53.png', title: 'Personilsed Offers' },
        { image: 'https://user-images.githubusercontent.com/117907145/230592283-836598a1-4427-428c-bf44-b0715599f840.png', title: 'Schedule New Compaigns' },
        { image: 'https://user-images.githubusercontent.com/117907145/230592964-80d4717e-2e97-4643-98a1-fbd5bb6931bb.png', title: 'Achieve better brand recognition' },
        { image: 'https://user-images.githubusercontent.com/117907145/230593046-409bc082-9fb6-405b-a834-fc35abba3889.png', title: 'Greater Customer Retention' },
        { image: 'https://user-images.githubusercontent.com/117907145/230593105-80dd4049-8dd9-416e-9bac-83c4a9eab8d6.png', title: 'Increased Sales' },
        { image: 'https://user-images.githubusercontent.com/117907145/230593235-7d300b80-80b3-4214-acd7-8cd0229adb0b.png', title: 'Support customers by responding to queries in real-time' },
        { image: 'https://user-images.githubusercontent.com/117907145/230593262-a9c31301-cc84-41d0-acc2-7ee02ae43c4c.png', title: 'Effectively and instantly interact with clients' },
        { image: 'https://user-images.githubusercontent.com/117907145/230593312-54f6b8ec-8b1a-4178-8a67-7cffffb4383d.png', title: 'Send contexual responses automatically' }
    ]
    return (
        <div>
            <Container>
                <div style={{ marginTop: '80px' }} className='ps-5 pe-5'>
                    <Row>
                        <div>
                            <h5 className="text-center mb-5 fw-bold">Benefits of WhatsTool Business</h5>
                        </div>
                        {
                            BenefitBox.map((v, i) => {
                                return (
                                    <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} key={i} >
                                        <div className="p-3" style={s1}>
                                            <p className="text-center pt-5 pb-5 pe-3 ps-3">
                                                <LazyLoadImage src={v.image} alt="Wtb" style={{ width: '240px' }} className='img-fluid' />
                                            </p>
                                            <h6 className="text-center mb-5 mt-3 ps-3 pe-3 fw-bold">{v.title}</h6>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const ConversationalSection5 = ({ reqRef }) => {
    const PlatformManyIndustries = [
        { image: getUrl.imageBaseUrl + '/con_20.webp', info: 'E-commerce', title: 'Promote Your products, share catalogs, receive orders online and provide customer support 24/7' },
        { image: getUrl.imageBaseUrl + '/con_21.webp', info: 'Education Institutions', title: 'From Admit, Onboard, Engage, Give Updates, Solve Queries to Customer Support it helps Educational Institutions improve Admission Rates' },
        { image: getUrl.imageBaseUrl + '/con_22.webp', info: 'Healthcare & Medicals', title: 'Go the extra mile by providing your patients and customers the ability to reach out to you 24/7 and at every step of their journey' },
        { image: getUrl.imageBaseUrl + '/con_23.webp', info: 'Food and Restaurants', title: 'Revolutionize your restaurant operations with WhatsApp based ordering, meal and add-ons, payments and real-time delivery updates.' },
        { image: getUrl.imageBaseUrl + '/con_24.webp', info: 'Tours & Travels', title: 'Assist, Coordinate, Recommend & Plan for your customers journey and make it a perfect trip by providing customer support ' }
    ]
    return (
        <div>
            <Container fluid>
                <Container>
                    <div style={{ marginTop: '65px' }} className='ps-5 pe-5'>
                        <Row>
                            <div>
                                <h5 className="text-center mb-5 fw-bold">One Platform - Many Industries</h5>
                            </div>
                            {
                                PlatformManyIndustries.map((v, i) => {
                                    return (
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <div style={{ marginTop: isMobile ? '0' : '70px' }} key={i}>
                                                <p >
                                                    <LazyLoadImage src={v.image} alt="Break Language" style={{ width: 'auto', height: '150px' }} />
                                                </p>
                                                <div>
                                                    <p style={{ fontSize: '26px' }} className='fw-bold mb-4'>{v.info}</p>
                                                    <p style={{ fontSize: '18px', fontWeight: '400', fontStyle: 'normal' }} >{v.title}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Container>
            </Container>
            <div className='mt-5' style={{ background: getColour.BaseColor }}>
                <Container style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginTop: '85px' }} className='pt-3 pb-3 mb-5'>
                    <div style={{ flex: 1 }}>
                        <p className='text-white fw-bold' style={{ margin: '10px 0px 0px 0px' }}>You can also insist these features in your Business</p>
                    </div>
                    <div>
                        <button onClick={() => reqRef.current?.scrollIntoView()} className='fw-bold  btn btn-sm' style={{ border: 'none', fontSize: '18px', background: '#fff', color: getColour.DescriptionTextColour, padding: '10px 30px' }}>Request a Callback</button>
                    </div>
                </Container>
            </div>
        </div>
    )
}
// End of Conversational Engagement by Himanshu



// Featured Chatbot Template by Himanshu
export const FeaturedChatbotTemplates = () => {
    const s1 = { border: '1px solid #ccc', borderRadius: '8px', minHeight: '445px' }
    const s3 = { color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '2px 20px 4px 15px' }
    const s4 = { fontSize: "16px" }

    const featureTemplateSchema = [
        { title: 'Jewellery Retailers Bot', category: 'Booking / Scheduling', description: 'Welcome to JewelBot, your ultimate destination for exquisite jewelry and personalized service! Chat with us to discover stunning pieces!', image: 'https://storage.googleapis.com/wt_resources/wtb_media/jewellery_retailers.png', btnText: 'Try', btnActionUrl: 'im looking to purchase the jewellery' },
        { title: 'Furniture Retailers Bot', category: 'Booking / Scheduling', description: 'Say hello to FurniMate, your go-to guide for all things furniture! Chat with us on WhatsApp to explore a world of stylish and comfortable furniture!', image: 'https://storage.googleapis.com/wt_resources/wtb_media/Furniture_retailers.png', btnText: 'Try', btnActionUrl: 'im looking for new furniture' },
        { title: 'Flower Shop Bot', category: 'Booking / Scheduling', description: 'Welcome to BloomBuddy, your virtual florist on WhatsApp! Chat with us to explore a world of blooms and greenery.', image: 'https://storage.googleapis.com/wt_resources/wtb_media/Flower_shop.png', btnText: 'Try', btnActionUrl: 'i like to order flowers' },
        { title: 'Home Elegance Decor Bot', category: 'Booking / Scheduling', description: 'Introducing EleganceBot, your trusted advisor for all things home decor. Chat with us on WhatsApp to discover the secrets of turning your living spaces into works of art.', image: 'https://storage.googleapis.com/wt_resources/wtb_media/Home_elegance_decor.png', btnText: 'Try', btnActionUrl: 'im looking for new curtains' },
        { title: 'Toy Store Bot', category: 'Booking / Scheduling', description: 'Welcome to ToyLand Express, your ultimate destination for all things fun and play! Chat with us on WhatsApp to explore a world of toys and games.', image: 'https://storage.googleapis.com/wt_resources/wtb_media/Toy_store.png', btnText: 'Try', btnActionUrl: 'hi im looking for gifts' },
        { title: 'Ecommerce Bot', category: 'Booking / Scheduling', description: "Say hello to ShopBot, your one-stop shop for all your online shopping needs! Chat with us on WhatsApp to browse through a wide range of products!", image: 'https://storage.googleapis.com/wt_resources/wtb_media/Ecommerce_bot.png', btnText: 'Try', btnActionUrl: 'i want to do shopping' },
        { title: 'Travel Bot', category: 'Booking / Scheduling', description: "Welcome to WanderWise, your virtual guide to the world of travel! Chat with us on WhatsApp to plan your dream vacations, get travel tips, and find the best deals on flights and accommodations!", image: 'https://storage.googleapis.com/wt_resources/wtb_media/Travel_bot.png', btnText: 'Try', btnActionUrl: 'book a bus ticket' },
        { title: 'WooCommerce COD Confirmation Bot', category: 'Support FAQ', description: 'This bot would capture the COD Order confirmation or cancel request from the customer and update the status back to', image: 'https://user-images.githubusercontent.com/117907145/224294142-ebc70335-552f-4f18-bdd1-8e35a91fcdc5.png', btnText: 'Try', btnActionUrl: 'i want to try woocommerce cod confirmation bot' },
        { title: 'Bus Booking Bot', category: 'Booking / Scheduling', description: 'Our bus booking bot allows you to provide real-time bus ticket information & help them book their bus tickets & pay within the chatbot window.', image: 'https://user-images.githubusercontent.com/117907145/224294809-f989e67d-cfc0-40d3-9344-88b8e5d663dd.png', btnText: 'Try', btnActionUrl: 'i want to try bus booking bot' },
        { title: 'Flight Web-Check-in Bot', category: 'Booking / Scheduling', description: 'This chatbot template is designed to let Airline companies offer customers an option to check-in for their flights using', image: 'https://user-images.githubusercontent.com/117907145/224295466-d4750417-5995-469f-ac91-7004b9dc1c92.png', btnText: 'Try', btnActionUrl: 'we want to try flight web check in bot' },
        { title: 'Flight Booking Bot', category: 'Booking / Scheduling', description: 'Help your customers find & book flight tickets on the go & create seamless, hassle-free flight booking conversations by', image: 'https://user-images.githubusercontent.com/117907145/224295170-d9a6720d-3b5f-439e-a4d6-c65152c1325e.png', btnText: 'Try', btnActionUrl: 'i want to try flight booking bot' },
        { title: 'Tour Package Booking Bot', category: 'Lead Generation', description: 'This chatbot template is designed to learn the travel needs of your customers and help them book the best package that suits', image: 'https://user-images.githubusercontent.com/117907145/224295689-c3e5086b-4d77-4abd-ab07-308eecc7bbd3.png', btnText: 'Try', btnActionUrl: 'we want to try flight web check in bot' },
        { title: 'Lead Generation Bot', category: 'Lead Generation', description: 'Collect name, email, and other basic information from your visitors & generate leads using this chatbot template.', image: 'https://user-images.githubusercontent.com/117907145/224295991-e3351466-4a0d-45dd-96af-e99353300b2c.png', btnText: 'Try', btnActionUrl: 'i want to try lead generation bot' },
        { title: 'Tutor Selection Bot', category: 'Booking / Scheduling', description: 'Make the tutor selection process hassle-free by creating an automated chatbot that can guide students to make an informed', image: 'https://user-images.githubusercontent.com/117907145/224296304-27fbaa14-35b3-4253-a241-feb04eeaa6c7.png', btnText: 'Try', btnActionUrl: 'i want to try tutor selection bot' },
        { title: 'Parent Testimonial Bot', category: 'Support FAQ', description: 'Our parent testimonial chatbot is designed to help educational institutes collect feedback and engage with their students', image: 'https://user-images.githubusercontent.com/117907145/224296509-37f4fd95-82e9-4418-a78f-48d30c5ad92f.png', btnText: 'Try', btnActionUrl: 'i want to try site visit feedback bot' },
        { title: 'Course Fee Bot', category: 'Support FAQ', description: 'This Course Fee chatbot can help fully automate the fee payment process thereby helping students make hassle-free', image: 'https://user-images.githubusercontent.com/117907145/224296733-85b8e74c-97b4-449a-840b-79a8c073201b.png', btnText: 'Try', btnActionUrl: 'i want to try course fee bot' },
        { title: 'Student Registration Bot', category: 'Lead Generation', description: 'Create a custom enrollment form & streamline your current student enrollment process with our automated no-code', image: 'https://user-images.githubusercontent.com/117907145/224296978-eece952f-3e00-46d4-94b8-a3b7020b4858.png', btnText: 'Try', btnActionUrl: 'i want to try lead generation bot' },
        { title: 'Browse Course Bot or Course Discovery Bot', category: 'Discovery', description: 'Our Course Discovery chatbot helps universities & education institutes help students discover their preferred course in', image: 'https://user-images.githubusercontent.com/117907145/224297225-cd6fdee7-8899-4029-8cda-dc03ca8dbc78.png', btnText: 'Try', btnActionUrl: 'I want to try Browse Course Bot or Course Discovery Bot' },
        { title: 'Vehicle Comparison Bot', category: 'Support FAQ', description: 'Vehicle comparison bot helps your customers compare and take decision on vehicles you offer based on pricing and', image: 'https://user-images.githubusercontent.com/117907145/224299552-c5bac4eb-db4c-4a24-80a9-98d0c13b0c99.png', btnText: 'Try', btnActionUrl: '' },
        { title: 'Showroom Visitor KYC Bot', category: 'Booking / Scheduling', description: 'Automatically collect & verify the information of your showroom visitor instantly & save them from the hassle of', image: 'https://user-images.githubusercontent.com/117907145/224297665-597ef1e2-2734-4b9c-865e-07fb246c8899.png', btnText: 'Try', btnActionUrl: '' },
        { title: 'Vehicle Service Booking Bot', category: 'Booking / Scheduling', description: 'This bot helps auto dealers streamline their vehicle service process & help your customers schedule a service instantly &', image: 'https://user-images.githubusercontent.com/117907145/224298149-b26c73e3-9cde-4092-935d-8bd78eda4f6d.png', btnText: 'Try', btnActionUrl: 'i want to try vehicle service booking bot' },
        { title: 'Vehicle Insurance Renewal Bot', category: 'Support FAQ', description: 'Help your customers save precious time by enabling them to renew their vehicle insurance instantly from their homes with', image: 'https://user-images.githubusercontent.com/117907145/224298476-c1ab4caf-81fd-4300-9c85-da7f76e5cb8b.png', btnText: 'Try', btnActionUrl: 'i want to try vehicle insurance renewal bot' },
        { title: 'Book a Test Drive Bot', category: 'Booking / Scheduling', description: 'Our Test Drive chatbot helps your customers find the nearest dealership & book a test drive in just a few minutes.', image: 'https://user-images.githubusercontent.com/117907145/226508992-24642a93-8326-4770-8cb5-a057b14bbca7.png', btnText: 'Try', btnActionUrl: 'i want to try book a test drive bot' },
        { title: 'Self Diagnosis Bot', category: 'Lead Generation', description: 'Self Diagnosis bot template is an ideal choice for healthcare businesses to let their patients/by-standers diagnose for any', image: 'https://user-images.githubusercontent.com/117907145/226508993-14c6242a-c6b3-415a-98d7-7460ea5f6908.png', btnText: 'Try', btnActionUrl: '' },
        { title: 'Health Package Booking Bot', category: 'Booking / Scheduling', description: 'Health package booking chatbot is designed for you to easily offer packages on various checkups. This chatbot template', image: 'https://user-images.githubusercontent.com/117907145/226508994-47fc976f-2ff5-45ca-b718-076876bb0384.png', btnText: 'Try', btnActionUrl: '' },
        { title: 'Blood Test Home Collection Bot', category: 'Booking / Scheduling', description: 'Blood Test Home Collection chatbot is an automated interactive bot template which lets your users book blood test collection', image: 'https://user-images.githubusercontent.com/117907145/226508996-3b4c0b21-aa13-4927-b5cd-4e34b4c49a9f.png', btnText: 'Try', btnActionUrl: 'i want to try blood test home collection bot' },
        { title: 'Doctor Appointment Booking Bot', category: 'Booking / Scheduling', description: 'The Doctor Appointment chatbot is designed for you to easily offer appointment bookings to people who are in', image: 'https://user-images.githubusercontent.com/117907145/226508987-a781c42d-83c8-47ba-8101-7acc2d15439a.png', btnText: 'Try', btnActionUrl: 'i want to try doctor appointment booking bot' },
    ]

    const [openChatBotDialogBox, setOpenChatBotDialogBox] = useState({ open: false, onClose: null })
    const openChatBotDialog = (v) => setOpenChatBotDialogBox({ open: true, v: v, onClose: () => setOpenChatBotDialogBox(false) })
    return (
        <Container>
            <div className='mt-5 mb-5'>
                <div className='mt-5 mb-3'>
                    <p className='text-center fw-semibold' style={{ fontSize: '24px' }}>Whatsapp Chatbot Templates Library</p>
                </div>
                <div style={{ textAlign: 'end' }} className="mb-5">
                    <Link to={getUrl.WhatstoolTemplatesUrl}><button style={{ color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '8px 25px', fontSize: '14px' }} className="btn btn-sm">Featured WhatsApp Templates Gallery</button></Link>
                </div>
                <Container>
                    <Row>
                        {
                            featureTemplateSchema?.map((v, i) => {
                                return (
                                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                        <div className="mb-3" style={s1}>
                                            <img src={v.image} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '200px', width: '100%' }} alt="whatstool" className="img-fluid" />
                                            <p className="fw-bold mt-2 ms-2 me-2 mb-0" style={{ fontSize: '20px' }}>{v.title}</p>
                                            <small className="mt-0 ms-2 me-2 mb-2" style={{ background: '#cbd5e0', fontSize: '11px', borderRadius: '4px', paddingTop: '2px', paddingBottom: '4px', paddingLeft: '8px', paddingRight: '8px' }}>{v.category}</small>

                                            <p className="m-2" style={s4}>{v.description}<strong>&nbsp;...</strong></p>
                                            <button style={s3} onClick={() => openChatBotDialog(v)} className="btn btn-sm m-2">{v.btnText}</button>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>

                <FeaturedChatBotDialog open={openChatBotDialogBox.open} v={openChatBotDialogBox.v} onClose={openChatBotDialogBox.onClose} />
            </div>
        </Container>
    )
}
// End of Featured Chatbot Template by Himanshu



// Marketing Page by Himanshu
export const MarketingApp = () => {
    const setParams = useContext(globalContext)
    const urlSearch = window.location.href
    if (urlSearch.includes("?")) {
        const parmas = urlSearch.split("?")[1]
        setParams.setParams(parmas)
    }
    return (
        <div>
            <WtbMarketingInfo />
            <Container className="clients" fluid><Client /></Container>
            <WtbMarketingSection1 />
            <WtbMarketingSection2 />
            <WtbMarketingSection3 />
            <WtbMarketingSection4 />
            <WtbMarketingSection5 />
            <WtbMarketingChatbotTemplates />
            <Container style={{ background: getColour.TabbackColour }} className='pt-4' fluid><Container><Testimonial /></Container></Container>
            <WtbMarketingSection6 />
        </div >
    )
}
export const WtbMarketingInfo = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <div>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Meaningfully connect with your customers on their favourite App</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>With 95% open rates within 2 minutes of message delivery, WhatsApp is the best marketing channel out there.</span>
                                <div>
                                    <Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Get Started</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224145460-9360338e-ff6f-4c19-bd77-2c9f898d3f1a.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbMarketingSection1 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '80px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Send 10,000 of messages in a click, promote your major announcement</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>This has helped Ganesh to reach thousands of customer in a click on WhatsApp. He got amazing open rate of 95% along with increase in orders and sales.</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224146935-432f4e8a-b5aa-4243-af13-3e8796fb48f4.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbMarketingSection2 = () => {
    return (
        <div style={{ background: '#f8fbf7' }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src="https://user-images.githubusercontent.com/117907145/224148124-8811a257-6738-40d0-b747-c8ee5f95357d.png" alt="Whatstool Business" className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px ', marginTop: isMobile ? '' : '70px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Sending personalised message to users who likes and not likes is the main power</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Get the power to repeat the order with right targeting and increase the sale by 35% from the customer who were not interested also.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const WtbMarketingSection3 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '70px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Data is the oil to think for the strategies of Marketing and Right Targeting</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>With the right data to take decision on the marketing ROI one can able to make it super great with the every efforts he put in.</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224149517-54f56e4d-16d5-4b2a-93ba-2b4098f7eea9.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbMarketingSection4 = () => {
    return (
        <div style={{ background: '#f8fbf7' }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src="https://user-images.githubusercontent.com/117907145/224287273-0aa71de1-83bc-427b-a257-23212bb700ca.png" alt="Whatstool Business" className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Sell more, support more and run marketing on auto mode with chatbot auto flows</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Chatbot is great time saver and brings all the power of customer satisfaction for 24/7. This is a great power that one can use across their implementation.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const WtbMarketingSection5 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '75px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>When you wants to target right audience with the right information or content you need segmentation</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Segmentation helps to hit the bulls eye and when you do that many magic can happen. The best magic is the sale. Increase in sale is what segmentation can bring.</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224152016-2bc0cefe-91b3-412a-aa48-5833b7c04855.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbMarketingChatbotTemplates = () => {
    const s1 = { border: '1px solid #ccc', borderRadius: '8px', minHeight: '445px' }
    const s3 = { color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '2px 20px 4px 15px' }
    const s4 = { fontSize: "16px" }

    const featureTemplateSchema = [
        { title: 'Lead Generation Bot', category: 'Lead Generation', description: 'Collect name, email, and other basic information from your visitors & generate leads using this chatbot template.', image: 'https://user-images.githubusercontent.com/117907145/224295991-e3351466-4a0d-45dd-96af-e99353300b2c.png', btnText: 'Try', btnActionUrl: 'i want to try lead generation bot' },
        { title: 'Tutor Selection Bot', category: 'Booking / Scheduling', description: 'Make the tutor selection process hassle-free by creating an automated chatbot that can guide students to make an informed', image: 'https://user-images.githubusercontent.com/117907145/224296304-27fbaa14-35b3-4253-a241-feb04eeaa6c7.png', btnText: 'Try', btnActionUrl: 'i want to try tutor selection bot' },
        { title: 'Showroom Visitor KYC Bot', category: 'Booking / Scheduling', description: 'Automatically collect & verify the information of your showroom visitor instantly & save them from the hassle of', image: 'https://user-images.githubusercontent.com/117907145/224297665-597ef1e2-2734-4b9c-865e-07fb246c8899.png', btnText: 'Try', btnActionUrl: '' },
    ]

    const [openChatBotDialogBox, setOpenChatBotDialogBox] = useState({ open: false, onClose: null })
    const openChatBotDialog = (v) => setOpenChatBotDialogBox({ open: true, v: v, onClose: () => setOpenChatBotDialogBox(false) })
    return (
        <div className='mt-5 mb-5'>
            <div className='mt-5 mb-5 text-center'>
                <p className='fw-semibold' style={{ fontSize: '22px' }}>Chatbot Templates for Marketing Industry</p>
                <p style={{ color: getColour.DescriptionTextColour, marginTop: '-12px' }}>Try it now</p>
            </div>
            <Container>
                <Row>
                    {
                        featureTemplateSchema?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className="mb-3" style={s1}>
                                        <img src={v.image} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '200px', width: '100%' }} alt="whatstool" className="img-fluid" />
                                        <p className="fw-bold mt-2 ms-2 me-2 mb-0" style={{ fontSize: '20px' }}>{v.title}</p>
                                        <small className="mt-0 ms-2 me-2 mb-2" style={{ background: '#cbd5e0', fontSize: '11px', borderRadius: '4px', paddingTop: '2px', paddingBottom: '4px', paddingLeft: '8px', paddingRight: '8px' }}>{v.category}</small>

                                        <p className="m-2" style={s4}>{v.description}<strong>&nbsp;...</strong></p>
                                        <button style={s3} onClick={() => openChatBotDialog(v)} className="btn btn-sm m-2">{v.btnText}</button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            <FeaturedChatBotDialog open={openChatBotDialogBox.open} v={openChatBotDialogBox.v} onClose={openChatBotDialogBox.onClose} />
        </div>
    )
}
export const WtbMarketingSection6 = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <div>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                                    <div className="text-center">
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Use WhatsTool Business as a solution for your Business Growth</h3>
                                        <h3 style={{ fontSize: '18px', color: getColour.DescriptionTextColour }}>Provide 24*7 customer support and engage with the customers throughout their journey without any human effort and increase your sales</h3>
                                        <h3 className="pt-5" style={{ color: '#273041', fontSize: '16px' }}>You can do it with zero cost</h3>
                                        <p>
                                            <Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Get Started</button></Link>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
// End of Marketing Page by Himanshu


// Sales Page by Himanshu
export const SalesApp = () => {
    const setParams = useContext(globalContext)
    const urlSearch = window.location.href
    if (urlSearch.includes("?")) {
        const parmas = urlSearch.split("?")[1]
        setParams.setParams(parmas)
    }
    return (
        <div>
            <WtbSalesInfo />
            <Container className="clients" fluid><Client /></Container>
            <WtbSalesSection1 />
            <WtbSalesSection2 />
            <WtbSalesSection3 />
            <WtbSalesSection4 />
            <WtbSalesSection5 />
            <WtbSalesSection6 />
            <WtbSalesChatbotTemplates />
            <Container style={{ background: getColour.TabbackColour }} className='pt-4' fluid><Container><Testimonial /></Container></Container>
            <WtbSalesSection7 />
        </div >
    )
}
export const WtbSalesInfo = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <div>
            <Container>
                <div className={isMobile ? 'p-0 mt-5' : 'p-5 mt-2'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Empower shoppers to shop where they want and how they want.</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>53% of consumers want the ability to purchase directly through a messaging app and even more (59%) want the ability to customize products through chat functions</span>
                                <div>
                                    <Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Get Started</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/223957049-b6f86605-75a4-4a9f-a3bc-4b71811986d1.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbSalesSection1 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Sync your catalog from Website to WhatsApp</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>This enables your customer to browse and experience your product on WhatsApp and order while chatting.</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224068342-f0f99082-c7b5-41ce-85e1-4f6a926f7efb.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbSalesSection2 = () => {
    return (
        <div style={{ background: '#f8fbf7' }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src="https://user-images.githubusercontent.com/117907145/223972604-1462687b-2b07-4aff-bbc3-fd911d076c67.png" alt="Whatstool Business" className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '150px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Taking order while chatting and sending in cart Increased Sales</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Add to cart is all we love to do. Customers can do same on WhatsApp too with the help of WhatsTool Business. This helps to increase the sales.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const WtbSalesSection3 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <p className="mb-4">
                                    <img src="https://user-images.githubusercontent.com/117907145/224115274-b71ce2d0-0754-4d6a-beeb-426c35c0bb42.png" style={{ width: '70px', height: '57px' }} alt="Whatstool Business" className="img-fluid" />
                                </p>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Send Payment link automatically just after the product selection</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>This reduces the friction of users from switching tabs and extra hassle and increased the conversion of add to card to payment ration.</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224114066-2793d388-e30b-48ac-b582-770c79f5a617.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbSalesSection4 = () => {
    return (
        <div style={{ background: '#f8fbf7' }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src="https://user-images.githubusercontent.com/117907145/224117037-212c67e8-566c-44b7-bd5f-7d5226340945.png" alt="Whatstool Business" className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px ', marginTop: isMobile ? '' : '120px' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Customer love the notification of order they made</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>You can set the order notification which can be very satisfying to the customers who made his hard earned money. This improves customer experience to next level.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const WtbSalesSection5 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '70px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Convert the chat into cart</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>While you provide information on chat you can take their order and send them the cart and they can buy in click.</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224118683-b42716ac-2dd1-42dd-b214-1c32d33043c3.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbSalesSection6 = () => {
    return (
        <div style={{ background: '#f8fbf7' }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src="https://user-images.githubusercontent.com/117907145/224121062-d383f94c-87b2-43e8-acda-29015137ef70.png" alt="Whatstool Business" className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Auto pull all product data from shopify to WhatsApp Catalog</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>This takes the customer experience to next level. It pull the store also sets all the important notification customers wants on WhatsApp.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}

export const WtbSalesChatbotTemplates = () => {
    const s1 = { border: '1px solid #ccc', borderRadius: '8px', minHeight: '445px' }
    const s3 = { color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '2px 20px 4px 15px' }
    const s4 = { fontSize: "16px" }

    const featureTemplateSchema = [
        { title: 'Student Registration Bot', category: 'Lead Generation', description: 'Create a custom enrollment form & streamline your current student enrollment process with our automated no-code', image: 'https://user-images.githubusercontent.com/117907145/224296978-eece952f-3e00-46d4-94b8-a3b7020b4858.png', btnText: 'Try', btnActionUrl: 'i want to try lead generation bot' },
        { title: 'Browse Course Bot or Course Discovery Bot', category: 'Discovery', description: 'Our Course Discovery chatbot helps universities & education institutes help students discover their preferred course in', image: 'https://user-images.githubusercontent.com/117907145/224297225-cd6fdee7-8899-4029-8cda-dc03ca8dbc78.png', btnText: 'Try', btnActionUrl: 'I want to try Browse Course Bot or Course Discovery Bot' },
    ]

    const [openChatBotDialogBox, setOpenChatBotDialogBox] = useState({ open: false, onClose: null })
    const openChatBotDialog = (v) => setOpenChatBotDialogBox({ open: true, v: v, onClose: () => setOpenChatBotDialogBox(false) })
    return (
        <div className='mt-5 mb-5'>
            <div className='mt-5 mb-5 text-center'>
                <p className='fw-semibold' style={{ fontSize: '22px' }}>Chatbot Templates for sales Industry</p>
                <p style={{ color: getColour.DescriptionTextColour, marginTop: '-12px' }}>Try it now</p>
            </div>
            <Container>
                <Row>
                    {
                        featureTemplateSchema?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className="mb-3" style={s1}>
                                        <img src={v.image} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '200px', width: '100%' }} alt="whatstool" className="img-fluid" />
                                        <p className="fw-bold mt-2 ms-2 me-2 mb-0" style={{ fontSize: '20px' }}>{v.title}</p>
                                        <small className="mt-0 ms-2 me-2 mb-2" style={{ background: '#cbd5e0', fontSize: '11px', borderRadius: '4px', paddingTop: '2px', paddingBottom: '4px', paddingLeft: '8px', paddingRight: '8px' }}>{v.category}</small>

                                        <p className="m-2" style={s4}>{v.description}<strong>&nbsp;...</strong></p>
                                        <button style={s3} onClick={() => openChatBotDialog(v)} className="btn btn-sm m-2">{v.btnText}</button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            <FeaturedChatBotDialog open={openChatBotDialogBox.open} v={openChatBotDialogBox.v} onClose={openChatBotDialogBox.onClose} />
        </div>
    )
}
export const WtbSalesSection7 = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            console.log("objectParam: ", params.params)
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <div>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                                    <div className="text-center">
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Use WhatsTool Business as a solution for your Business Growth</h3>
                                        <h3 style={{ fontSize: '18px', color: getColour.DescriptionTextColour }}>Provide 24*7 customer support and engage with the customers throughout their journey without any human effort and increase your sales</h3>
                                        <h3 className="pt-5" style={{ color: '#273041', fontSize: '16px' }}>You can do it with zero cost</h3>
                                        <p>
                                            <Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Get Started</button></Link>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
// Sales Page by Himanshu


// Customer Support by Himanshu
export const CustomerSupportApp = () => {
    const setParams = useContext(globalContext)
    const urlSearch = window.location.href
    if (urlSearch.includes("?")) {
        const parmas = urlSearch.split("?")[1]
        setParams.setParams(parmas)
    }
    return (
        <div>
            <WtbCustomerSupportInfo />
            <Container className="clients" fluid><Client /></Container>
            <WtbCustomerSupportSection1 />
            <WtbCustomerSupportSection2 />
            <WtbCustomerSupportSection3 />
            <WtbCustomerSupportSection4 />
            <WtbCustomerSupportSection5 />
            <CustomerSupportChatbotTemplates />
            <Container style={{ background: getColour.TabbackColour }} className='pt-4' fluid><Container><Testimonial /></Container></Container>
            <WtbCustomerSupportSection6 />
        </div >
    )
}
export const WtbCustomerSupportInfo = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <div>
            <Container>
                <div className={isMobile ? 'mt-5' : 'mt-2'} style={{ padding: isMobile ? '' : '48px 48px 0 ' }}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} >
                            <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                <h3 className="mb-4" style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Empower your team to provide a collaborative and positive experience</h3>
                                <span style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>62% of social media users say they’d like to communicate with a customer service representative through a messaging app.</span>
                                <div>
                                    <Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Get Started</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224155127-71a438e3-842d-43f0-bf99-7d1a33b32c9f.png" style={{ width: '421px' }} alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbCustomerSupportSection1 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px', marginTop: isMobile ? '' : '75px' }}>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Now on WhatsApp, Its just like how people have been doing customer with unified team chat</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Multiple Agents of your team helping customer and as a manager you have full view of whats happening.</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224157391-cce6435d-badd-4371-8cf9-dd62fcdc9fc2.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbCustomerSupportSection2 = () => {
    return (
        <div style={{ background: '#f8fbf7' }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src="https://user-images.githubusercontent.com/117907145/224157780-181b06f8-8daa-404f-9425-27143566665a.png" alt="Whatstool Business" className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                        <p className="mb-5 text-center">
                                            <img src="https://user-images.githubusercontent.com/117907145/224158466-e6b54260-2daf-4919-bc9a-c912d71c717b.png" style={{ width: '58px', height: '59px' }} alt="Whatstool Business" className="img-fluid" />
                                        </p>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Don’t repeat. Just do quick reply</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>With text, media and multiple sequence of messages at one place, customer gets faster response than before. Agents support more people on less time.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const WtbCustomerSupportSection3 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <p className="mb-5 text-center">
                                    <img src="https://user-images.githubusercontent.com/117907145/224161516-4fca4369-e636-4bac-af26-57fd2bad4bca.png" style={{ width: '70px', height: '57px' }} alt="Whatstool Business" className="img-fluid" />
                                </p>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Every agent has unique expertise. So utilise it</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>You can handover the chat to any agent as you need. Help solve customer problem quickly</p>

                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224160868-61b4c726-474d-4ff0-a0d2-56128c9411a4.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WtbCustomerSupportSection4 = () => {
    return (
        <div style={{ background: '#f8fbf7' }}>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                                    <p className="text-center">
                                        <img src="https://user-images.githubusercontent.com/117907145/224162336-a9c7bd77-e35c-4d46-bcdd-ec7bd8354bd7.png" alt="Whatstool Business" className="img-fluid" />
                                    </p>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                    <div style={{ padding: isMobile ? '' : '0 55px ' }}>
                                        <p className="mb-5 text-center">
                                            <img src="https://user-images.githubusercontent.com/117907145/224162047-06e3abac-51ea-4145-b8b9-26f86b3e666b.png" style={{ width: '66px', height: '67px' }} alt="Whatstool Business" className="img-fluid" />
                                        </p>
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Right labels save lot of time of the entire team</h3>
                                        <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Assign right label helps increase the efficiency of team by 20%</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
export const WtbCustomerSupportSection5 = () => {
    return (
        <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
            <Container>
                <div className={isMobile ? 'p-0' : 'p-5'}>
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div style={{ padding: isMobile ? '' : '0 55px' }}>
                                <p className="mb-5 text-center">
                                    <img src="https://user-images.githubusercontent.com/117907145/224164194-e484c701-a5c0-4f9b-bae9-17f2a174b97e.png" alt="Whatstool Business" className="img-fluid" />
                                </p>
                                <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Use this to customise our tool as your business nee</h3>
                                <p style={{ fontSize: isMobile ? '14px' : '16px', color: getColour.DescriptionTextColour }} className='fw-semibold'>Amazing tool with amazing power. Once you know it your business requirements are shorted.</p>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
                            <p className="text-center">
                                <img src="https://user-images.githubusercontent.com/117907145/224163862-947b1478-f3b8-44d5-b23e-06924cfd0cba.png" alt="Whatstool Business" className="img-fluid" />
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export const CustomerSupportChatbotTemplates = () => {
    const s1 = { border: '1px solid #ccc', borderRadius: '8px', minHeight: '445px' }
    const s3 = { color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '2px 20px 4px 15px' }
    const s4 = { fontSize: "16px" }

    const featureTemplateSchema = [
        { title: 'Lead Generation Bot', category: 'Lead Generation', description: 'Collect name, email, and other basic information from your visitors & generate leads using this chatbot template.', image: 'https://user-images.githubusercontent.com/117907145/224295991-e3351466-4a0d-45dd-96af-e99353300b2c.png', btnText: 'Try', btnActionUrl: 'i want to try lead generation bot' },
    ]

    const [openChatBotDialogBox, setOpenChatBotDialogBox] = useState({ open: false, onClose: null })
    const openChatBotDialog = (v) => setOpenChatBotDialogBox({ open: true, v: v, onClose: () => setOpenChatBotDialogBox(false) })
    return (
        <div className='mt-5 mb-5'>
            <div className='mt-5 mb-5 text-center'>
                <p className='fw-semibold' style={{ fontSize: '22px' }}>Chatbot Templates for Customer Support Industry</p>
                <p style={{ color: getColour.DescriptionTextColour, marginTop: '-12px' }}>Try it now</p>
            </div>
            <Container>
                <Row>
                    {
                        featureTemplateSchema?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div className="mb-3" style={s1}>
                                        <img src={v.image} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '200px', width: '100%' }} alt="whatstool" className="img-fluid" />
                                        <p className="fw-bold mt-2 ms-2 me-2 mb-0" style={{ fontSize: '20px' }}>{v.title}</p>
                                        <small className="mt-0 ms-2 me-2 mb-2" style={{ background: '#cbd5e0', fontSize: '11px', borderRadius: '4px', paddingTop: '2px', paddingBottom: '4px', paddingLeft: '8px', paddingRight: '8px' }}>{v.category}</small>

                                        <p className="m-2" style={s4}>{v.description}<strong>&nbsp;...</strong></p>
                                        <button style={s3} onClick={() => openChatBotDialog(v)} className="btn btn-sm m-2">{v.btnText}</button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            <FeaturedChatBotDialog open={openChatBotDialogBox.open} v={openChatBotDialogBox.v} onClose={openChatBotDialogBox.onClose} />
        </div>
    )
}
export const WtbCustomerSupportSection6 = () => {
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    } return (
        <div>
            <Container fluid>
                <div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
                    <Container>
                        <div className={isMobile ? 'p-0' : 'p-5'}>
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
                                    <div className="text-center">
                                        <h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>Use WhatsTool Business as a solution for your Business Growth</h3>
                                        <h3 style={{ fontSize: '18px', color: getColour.DescriptionTextColour }}>Provide 24*7 customer support and engage with the customers throughout their journey without any human effort and increase your sales</h3>
                                        <h3 className="pt-5" style={{ color: '#273041', fontSize: '16px' }}>You can do it with zero cost</h3>
                                        <p>
                                            <Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Get Started</button></Link>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    )
}
// End of Customer Support by Himanshu



