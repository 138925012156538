class WtUrls {
  constructor() {
    // URLs
    this.imageBaseUrl = "https://storage.googleapis.com/wt_resources/images"
    this.templateimgBaseUrl = "https://storage.googleapis.com/wt_resources/templatesimg"
    this.logoUrl = 'https://storage.googleapis.com/wt_resources/images/wtb_green_round_images.png'

    this.blogUrl = 'https://blog.whatstool.business/'

    // App Urls
    this.whatstoolAppUrl = 'https://play.google.com/store/apps/details?id=com.whatstools.statussaver.directchat.trendingstatus.searchprofile'
    this.whatstoolBusinessAppUrl = 'https://play.google.com/store/apps/details?id=com.whatstool.business'
    this.whatsSaveAppUrl = 'https://play.google.com/store/apps/details?id=com.whatstool.whatssave.exportcontact.autosave'
    this.instaramAppUrl = 'https://play.google.com/store/apps/details?id=igtool.toolsforinstagram.instagramphotodownloader.instagramvideodownloader.repost'
    this.appDeveloperUrl = 'https://play.google.com/store/apps/developer?id=WhatsTool+Tech:+Toolkit+for+Business+%26+WhatsApp'
    this.wtDesktopAppUrl = 'https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/desktop/WhatsTool.zip'
    // End of URLs


    this.testURL = '934b863-testing-wtb-7b863.web.app'

    this.appProdLink = 'https://app.whatstool.business'
    this.appTestLink = 'https://934b863-testing-wtb-7b863.web.app'

    this.staticLink = 'https://934b863-testing-wtb-7b863.web.app/auth'
    this.productionLink = 'https://app.whatstool.business/auth'


    this.productionTemplateLink = 'https://app.whatstool.business/settings/templates-create?data='
    this.appTestingTemplateLink = 'https://934b863-testing-wtb-7b863.web.app/settings/templates-create?data='


    this.dashboardUrl = "/app"


    this.paymentPlanSubscriptionUrl = "/app/payment/plan-subscription"

    this.wtdPaymentUrl = "/wtd-payment"

    this.whastsappchatbuttonUrl = "/whatsapp-chat-button"
    this.whatsappLinkGeneratorUrl = "/whatsapp-link-generator"
    this.whatsappQRGeneratorUrl = "/whatsApp-QR-code-generator"
    this.WhatsToolPricingCalcultorUrl = "/pricing-calculator"

    this.featuredTemplatesUrl = '/whatsapp-chatbot-template'
    this.CompareWatiUrl = '/wati-compare'
    this.marketingPageUrl = '/marketing'
    this.salesPageUrl = '/sales'
    this.customerSupportPageUrl = '/customer-support'

    this.paymentUrl = "/app/payment"
    this.paymentCompletionUrl = "/app/completion"



    // Static Pages URls
    this.whatsappBusinessApiUrl = "/whatsApp-business-api"
    this.crmUrl = "/crm"
    this.intergrationUrl = "/integration"
    this.educationalSectorUrl = "/industry/educational-sector-industry"
    this.ecommerceSectorUrl = "/industry/ecommerce-sector-industry"
    this.healthSectorUrl = "/industry/health-sector-industry"
    this.tourTravelSectorUrl = "/industry/tour-travel-sector-industry"
    this.HotelsHospitalitySectorUrl = "/indutry/Hotels-Hospitality-Sector-industry"
    this.pricingUrl = "/whatsapp-api-pricing"

    this.teamPageUrl = '/team'
    this.conversationalEngagementUrl = '/conversational-engagement'

    this.powerBusinessUrl = '/power-business'
    this.PartnershipPageUrl = '/partnership'
    this.CaseStudyUrl = '/case-study'
    this.MaintenanceUrl = '/maintenance'
    this.WhatstoolTemplatesUrl = '/whatsapp-message-templates'

    this.UrlShortnerLink = '/tiny/:tiny'

    this.quickGuideUrl = "/quick-guide"
    this.chatbotsUrl = "/chatbots"
    this.multiAgentsUrl = "/whatsapp-multi-agent"
    this.multiagentUrl = "/multi-agents"  // Old URL
    this.broadcastMessageUrl = "/broadcast-message"
    this.clientsUrl = "/clients"

    this.supportUrl = "/support"
    this.contactUsUrl = "/contact-us"
    this.termsOfServiceUrl = "/terms-of-service"
    this.privacyPolicyUrl = "/privacy-policy"
    this.cancellationUrl = "/cancellation"







    // ----------------------

    this.wpHomeUrl = "https://whatstool.business/"
    this.wpEducationUrl = "https://whatstool.business/educational-sector-industry"
    this.wpEcommerceUrl = "https://whatstool.business/ecommerce-sector-industry"
    this.wpHealthUrl = "https://whatstool.business/health-sector-industry"
    this.wpTourtravelUrl = "https://whatstool.business/tour-travel-sector-industry"

    this.wpMarketingUrl = "https://whatstool.business/marketing"
    this.wpSalesUrl = "https://whatstool.business/sales"
    this.wpCustomersupportUrl = "https://whatstool.business/customer-support"
    this.wpIntegrationUrl = "https://whatstool.business/integration"
    this.wpQuickguideUrl = "https://whatstool.business/quick-guide"
    this.wpPowerbusinessUrl = "https://whatstool.business/power-business"
    this.wpMultiagentUrl = "https://whatstool.business/whatsapp-multi-agent"
    this.wpBroadcastmsgUrl = "https://whatstool.business/whatsapp-broadcast-message"
    this.wpAnalyticsUrl = "https://whatstool.business/analytics"
    this.wpChatbotUrl = "https://whatstool.business/chatbots"
    this.wpContactusUrl = "https://whatstool.business/contact-us"
    this.wpTermsconditionUrl = "https://whatstool.business/terms-of-service"
    this.wpPrivacypolicyUrl = "https://whatstool.business/privacy-policy"
    this.wpCancellationUrl = "https://whatstool.business/cancellation"
    this.wpTeampageUrl = "https://whatstool.business/team"
    this.wpClientpageUrl = "https://whatstool.business/clients"
    this.wpCasestudyUrl = "https://whatstool.business/case-study"
    this.wpPartnershipUrl = "https://whatstool.business/partnership"
    this.wpLearnUrl = "https://faq.whatstool.business/docs/"
    this.wpRequestCallUrl = "https://whatstool.business/request-call"
    this.wpWhatsappapiUrl = "https://whatstool.business/whatsapp-api"
    this.wpWhatsappcrmUrl = "https://whatstool.business/whatsapp-crm"
    this.wpdemobookingUrl = "https://whatstool.business/demo-booking"
    this.wpFestiveSaleUrl = "https://whatstool.business/whatsapp-api-festive-season-sale"

    this.wpChatwidgetUrl = "https://tools.whatstool.business/whatsapp-chat-button"
    this.wpQrgeneratorUrl = "https://tools.whatstool.business/whatsApp-QR-code-generator"
    this.wpLinkgeneratorUrl = "https://tools.whatstool.business/whatsapp-link-generator"
    this.wpchatbottemplateUrl = "https://tools.whatstool.business/whatsapp-chatbot-template"
    this.wpWhatsapptemplateUrl = "https://tools.whatstool.business/whatsapp-message-templates"
    this.wpPricingUrl = "https://tools.whatstool.business/whatsapp-api-pricing"
    this.wpPricingcalculatorUrl = "https://tools.whatstool.business/pricing-calculator"




    /**
       * usage:
       * const location = useLocation()
       * getUrlQueryParams(location)
       * @returns url query params
    */
    this.parseQueryParams = function (location) {
      return Array.from((new URLSearchParams(location.search)).entries()).reduce((e, f) => { e[`${f[0]}`] = f[1]; return e }, {})
    }
  }
}
export const getUrl = new WtUrls()