/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import { isMobile } from "react-device-detect"
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import Tabs from 'react-bootstrap/Tabs'
import { getColour } from "../../constants/colours"
import { WtEvent } from "../../basetool/analytics"
import { getAnalyticsKey } from "../../constants/analyticsKeys"
import { ApplyNowTemplate, CalendlyLiveDemo, WhatsAppTemplates } from "../../basetool/dialogs"
import { getUrl } from "../../constants/urls"
import whatstoolTemplates from '../../third-party/Templates.json'
import { Link } from "react-router-dom"
import { getString } from "../../constants/strings"
import { LazyLoadImage } from "react-lazy-load-image-component"
import globalContext from "../../context/global/globalContext"
import { getUiEnvUrl, isTestEnv } from "../../basetool/methods"
import { useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom"



export const WhatstoolPowerBusiness = () => {
	return (
		<div>
			<WtbPowerBusinessInfo />
			<WtbPowerBusinessSection2 />
			<WtbPowerBusinessSection3 />
			<WtbPowerBusinessSection4 />
			<WtbPowerBusinessSection5 />
			<WtbPowerBusinessSection6 />
			<WtbPowerBusinessSection7 />
			<WtbPowerBusinessSection8 />
			<WtbPowerBusinessSection9 />
			<WtbPowerBusinessSection10 />
			<WtbPowerBusinessSection11 />
			<WtbPowerBusinessSection12 />
			<WtbPowerBusinessSection13 />
			<WtbPowerBusinessSection14 />
			<WtbPowerBusinessSection15 />
			<WtbPowerBusinessSection16 />
			<WtbPowerBusinessSection17 />
			<WtbPowerBusinessSection18 />
			<WtbPowerBusinessSection19 />
			<WtbPowerBusinessSection20 />
		</div>
	)
}
export const WtbPowerBusinessInfo = () => {
	return (
		<div>
			<Container fluid>
				<div style={{ padding: '42px' }} className="mb-5 wt_padding">
					<Row>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
							<div className="fw-semibold mt-5">
								<p className="wt_fsize" style={{ fontSize: '32px' }}>Experience the Power of WhatsApp for your Business</p>
								<p className="mt-5 wt_margin" style={{ fontSize: '18px', color: '#707070' }}>Convert WhatsApp chat into sales, marketing and customer support channel</p>
							</div>
						</Col>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="float-end">
								<img src="https://user-images.githubusercontent.com/117907145/229052172-16893bc4-6c8d-45c7-b55e-6de510d09824.png" style={{ height: '310px' }} alt="HelloWtb" className="img-fluid wt_height" />
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection2 = () => {

	const CompletesolutionBusinessGrowth = [
		{ image: 'https://user-images.githubusercontent.com/117907145/229054404-05932d42-7485-4be6-bade-28d5ae828393.png', title: 'Sales', info: 'Empower shoppers to shop where they want and how they want. <br><br>53% of consumers want the ability to purchase directly through a messaging app and even more (59%) want the ability to customise products through chat functions' },
		{ image: 'https://user-images.githubusercontent.com/117907145/229061898-63ae278e-e1fc-42a3-a52c-4a7f89042349.png', title: 'Marketing', info: 'Meaningfully connect with your customers on their favourite app. <br><br>With 95% open rates within 2 minutes of message delivery, WhatsApp is the best marketing channel out there.' },
		{ image: 'https://user-images.githubusercontent.com/117907145/229061952-fcfbeebb-7117-409e-811a-86d034d19756.png', title: 'Customer Support', info: 'Empower your team to provide a collaborative and positive experience. <br><br>62% of social media users say they’d like to communicate with a customer service representative through a messaging app.' },
	]
	return (
		<div style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container >
					<div className="pb-5">
						<div className="text-center fw-semibold wt_fsize wt_margin" style={{ fontSize: '32px', padding: '50px 0 70px 0' }}>
							<p>WhatsTool Business provides complete solution <br />for your Business Growth</p>
						</div>
						<Row>
							{
								CompletesolutionBusinessGrowth?.map((v, i) => {
									return (
										<Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
											<div className="ms-3 me-3" style={{ background: '#F0F2F8', borderRadius: '6px', padding: '28px 32px', minHeight: '349px', marginTop: isMobile ? '40px' : '' }}>
												<div style={{ display: 'flex', alignItems: 'flex-end' }} >
													<img src={v.image} style={{ height: '64px' }} alt="Sales" className="img-fluid" />
													<p className="fw-semibold ms-4" style={{ fontSize: '24px' }}>{v.title}</p>
												</div>
												<p style={{ color: '#616874', marginTop: '45px' }} dangerouslySetInnerHTML={{ __html: v.info }}></p>
											</div>
										</Col>
									)
								})
							}
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection3 = () => {
	return (
		<Container>
			<div className="p-5 wt_padding">
				<div style={{ fontSize: '28px', margin: '35px 0 90px 0' }} className="text-center fw-semibold wt_fsize">
					<p>With WhatsTool Business, you can turn WhatsApp chat into sales portal</p>
				</div>
				<Row>
					<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
						<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '60px' }}>
							<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>You can sync your catalog from website to WhatsApp</p>
							<p style={{ fontSize: '18px', color: '#616874' }}>This enables your customer to browse and experience your product on WhatsApp and order while chatting.</p>
						</div>
					</Col>
					<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
						<p className="text-center wt_height">
							<img src="https://user-images.githubusercontent.com/117907145/229090101-d4f13672-a84d-4724-ad22-8d968ecd1444.png" alt="Catalog" className="img-fluid" />
						</p>
					</Col>
				</Row>
			</div>
		</Container>
	)
}
export const WtbPowerBusinessSection4 = () => {
	return (
		<div className="pt-4" style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container>
					<div className="p-5 wt_padding">
						<Row>
							<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
								<p className="text-center wt_height">
									<img src="https://user-images.githubusercontent.com/117907145/229092527-488468ac-0ceb-468e-a990-fc3c4d5c069c.png" style={{ height: '429px' }} alt="Catalog" className="img-fluid" />
								</p>
							</Col>
							<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
								<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 0 0 80px', marginTop: '115px' }}>
									<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Taking order while chatting and sending in cart Increased Sales</p>
									<p style={{ fontSize: '18px', color: '#616874' }}>Add to cart is all we love to do. Customers can do same on WhatsApp too with the help of WT Business. This enabled and increased the sales.</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection5 = () => {
	return (
		<div className="pt-4">
			<Container>
				<div className="p-5 wt_padding">
					<Row>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '60px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Send payment link automatically just after the product selection</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>This reduced the friction of users from switching tabs and extra hassle and increased the conversion of add to card to payment ration</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229105779-3c87e922-e7c3-45a4-91e0-2e51d5c2e9c4.png" style={{ height: '415px' }} alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection6 = () => {
	return (
		<div className="pt-4" style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container>
					<div className="p-5 wt_padding">
						<Row>
							<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
								<p className="text-center wt_height">
									<img src="https://user-images.githubusercontent.com/117907145/229106417-bf0ad54a-66a8-4e2a-b0a1-27cf9f14c598.png" style={{ height: '429px' }} alt="Catalog" className="img-fluid" />
								</p>
							</Col>
							<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
								<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 0 0 80px', marginTop: '115px' }}>
									<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Customer love the notification of order they made</p>
									<p style={{ fontSize: '18px', color: '#616874' }}>You can set the order notification which can be very satisfying to the customers who made his hard earned money. This improves customer experience to next level.</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection7 = () => {
	return (
		<div className="pt-4">
			<Container>
				<div className="p-5 wt_padding">
					<Row>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '60px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Convert the chat into cart.</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>While you provide info on chat you can take their order and send them the cart and they can buy in click</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229106944-b6515ea9-e5c3-408d-8b9b-71267f2c8d63.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection8 = () => {
	return (
		<div className="pt-4" style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container>
					<div className="p-5 wt_padding">
						<Row>
							<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
								<p className="text-center wt_height">
									<img src="https://user-images.githubusercontent.com/117907145/229107528-c76a4ce4-e3ec-4cc7-821b-076accd2b558.png" alt="Catalog" className="img-fluid" />
								</p>
							</Col>
							<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
								<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 0 0 80px', marginTop: '50px' }}>
									<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Auto pull all product data from shopify to WhatsApp Catalog</p>
									<p style={{ fontSize: '18px', color: '#616874' }}>This takes the customer experience to next level. It pull the store also sets all the important notification customers wants on WhatsApp.</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection9 = () => {
	return (
		<div className="pt-4">
			<Container>
				<div className="p-5 wt_padding">
					<Row>
						<div style={{ fontSize: '28px', marginBottom: '95px' }} className="text-center fw-semibold wt_fsize">
							<p>Marketing tools helps the Business to reach maximum</p>
						</div>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '10px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Send 10,000 of messages in a click, promote your major announcement</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>This has helped Businesses to reach thousands of customer in a click on WhatsApp. They got amazing open rate of 95% along with increase in orders and sales.</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229108353-5bd0efc0-41ed-404f-89b6-9e743ceaaaeb.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection10 = () => {
	return (
		<div className="pt-4" style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container>
					<div className="p-5 wt_padding">
						<Row>
							<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
								<p className="text-center wt_height">
									<img src="https://user-images.githubusercontent.com/117907145/229118417-2d3f2e1a-c06d-4121-a135-bcb1321d8873.png" alt="Catalog" className="img-fluid" />
								</p>
							</Col>
							<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
								<div className="fw-semibold wt_padding" style={{ padding: '0 0 0 80px' }}>
									<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Sending personalised message to users who likes and not likes is the main power</p>
									<p style={{ fontSize: '18px', color: '#616874' }}>When Business got the power to repeat the order with right targeting and Increase the order from the customer who were not Interested then he could Increase the sale by 35%</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection11 = () => {
	return (
		<div className="pt-4">
			<Container>
				<div className="p-5 wt_padding">
					<Row>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '10px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Data is the oil to think for the strategies of Marketing and Right Targeting</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>With the right data to take decision on the marketing ROI Business is able to make it super great with the every efforts they put in.</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229119397-2373ab9f-5cff-4add-83c5-c1ec8e1e1573.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection12 = () => {
	return (
		<div className="pt-4" style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container>
					<div className="p-5 wt_padding">
						<Row>
							<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
								<p className="text-center wt_height">
									<img src="https://user-images.githubusercontent.com/117907145/229120293-59c7e887-2b78-4674-ab2c-49c8f2ba683d.png" alt="Catalog" className="img-fluid" />
								</p>
							</Col>
							<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
								<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 0 0 80px', marginTop: '10px' }}>
									<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Sell more, support more and run marketing on auto mode with chatbot auto flows</p>
									<p style={{ fontSize: '18px', color: '#616874' }}>Chatbot is great time saver and brings all the power of customer satisfaction for 24/7. This is a great power that Business uses across their implementation</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection13 = () => {
	return (
		<div className="pt-4">
			<Container>
				<div className="p-5 wt_padding">
					<Row>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '30px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>When you wants to target right audience with the right information or content you need segmentation</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>Segmentation helps to hit the bulls eye and when you do that many magic can happen. The best magic is the sale. Increase in sale is what segmentation can bring</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229121081-936e4b54-6847-4df2-b351-b01585fc06d7.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
					<Row>
						<div style={{ fontSize: '28px', margin: '85px 0 75px 0' }} className="text-center fw-semibold wt_fsize">
							<p>Better Support, with features that optimize your teamwork</p>
						</div>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '30px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Now on WhatsApp, Its just like how people have been doing customer with unified team chat</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>Multiple Agents of your team helping customer and as a manager you have full view of whats happening.</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229121202-e51d086b-710c-454d-a0dd-c437053655f8.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection14 = () => {
	return (
		<div className="pt-4" style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container>
					<div className="p-5 wt_padding">
						<Row>
							<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
								<p className="text-center wt_height">
									<img src="https://user-images.githubusercontent.com/117907145/229123762-981601ec-329e-4711-b690-b1892e7b93aa.png" alt="Catalog" className="img-fluid" />
								</p>
							</Col>
							<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
								<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 0 0 80px', marginTop: '35px' }}>
									<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Don’t repeat. Just do quick reply</p>
									<p style={{ fontSize: '18px', color: '#616874' }}>With text, media and multiple sequence of messages at one place, customer gets faster response than before. Agents support more people on less time.</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection15 = () => {
	return (
		<div className="pt-4">
			<Container>
				<div className="p-5 wt_padding">
					<Row>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '60px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Every agent has unique expertise. So utilise it </p>
								<p style={{ fontSize: '18px', color: '#616874' }}>You can handover the chat to any agent as you need. Help solve customer problem quickly</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229124200-68c445e1-800b-4f37-b749-12a04681c6b3.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection16 = () => {
	return (
		<div className="pt-4" style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container>
					<div className="p-5 wt_padding">
						<Row>
							<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
								<p className="text-center wt_height">
									<img src="https://user-images.githubusercontent.com/117907145/229125129-ebf53724-d63a-413e-b983-c67b6288b721.png" alt="Catalog" className="img-fluid" />
								</p>
							</Col>
							<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
								<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 0 0 80px', marginTop: '115px' }}>
									<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Right labels save lot of time of the entire team</p>
									<p style={{ fontSize: '18px', color: '#616874' }}>Assign right label helps increase the efficiency of team by 20%</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection17 = () => {
	return (
		<div className="pt-4">
			<Container>
				<div className="p-5 wt_padding">
					<Row>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '30px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Use custom fields & CRM to customise our tool as your business need</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>Amazing tool with amazing power. Once you know it your business requirements are shorted.</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229126016-9e27083f-365d-4485-94f1-b5fb270301ca.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
					<Row>
						<div style={{ fontSize: '28px', margin: '85px 0 75px 0' }} className="text-center fw-semibold wt_fsize">
							<p>With your system via API, and to your need tools of the world</p>
						</div>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '40px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>E-Commerce Integration helps connect with all right tools for e commerce business</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>Integrate your favourite E -Commerce apps to reduce abandoned carts, increase revenues with one click integrations</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229126151-b748869b-b371-4354-b290-6c3bebb9cc77.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection18 = () => {
	return (
		<div className="pt-4" style={{ background: '#F8FBF7' }}>
			<Container fluid>
				<Container>
					<div className="p-5 wt_padding">
						<Row>
							<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
								<p className="text-center wt_height">
									<img src="https://user-images.githubusercontent.com/117907145/229126674-12e4a02f-4bec-4609-80e4-32fb0918695a.png" alt="Catalog" className="img-fluid" />
								</p>
							</Col>
							<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
								<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 0 0 80px', marginTop: '10px' }}>
									<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>CRM & Agent Integration helps to know how your customer are interacting in our system on your favourite tools of tracking</p>
									<p style={{ fontSize: '18px', color: '#616874' }}>Integrate your existing CRM & live Agent apps to engage with your customer better</p>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection19 = () => {
	return (
		<div className="pt-4">
			<Container>
				<div className="p-5 wt_padding">
					<Row>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className="fw-semibold wt_padding wt_margin" style={{ padding: '0 80px 0 0', marginTop: '60px' }}>
								<p className="wt_fsize" style={{ fontSize: '28px', color: '#00A82D' }}>Payment Integration helps your customer to do the payments without switching between the tabs</p>
								<p style={{ fontSize: '18px', color: '#616874' }}>Integrate with easily accessible payment gateways and enable easy payment for your customer without switching between the channels</p>
							</div>
						</Col>
						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} >
							<p className="text-center wt_height">
								<img src="https://user-images.githubusercontent.com/117907145/229127040-c420a9ec-6776-4e63-bd32-8ac6b2f6da54.png" alt="Catalog" className="img-fluid" />
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}
export const WtbPowerBusinessSection20 = () => {

	const OurAimbox = [
		{ image: 'https://user-images.githubusercontent.com/117907145/215436361-8cb0c58a-ac6c-42fb-a3c3-143825d29f15.png', info: 'Customers First', title: 'Delivering customers what they need is our first priority' },
		{ image: 'https://user-images.githubusercontent.com/117907145/215439272-a8434390-c9a1-48df-a15e-599c2d074f76.png', info: 'Providing Best', title: 'We always work hard to provide best for our customer in their growth' },
		{ image: 'https://user-images.githubusercontent.com/117907145/215435726-e6f4f529-fdf3-413f-b548-4c7d46bb9b19.png', info: 'Being Loyal', title: 'We always wish to gain customer’s trust and loyal' },
		{ image: 'https://user-images.githubusercontent.com/117907145/215436133-b700d435-8e0a-44ea-9278-3dfb54606aec.png', info: 'Part of the Team', title: 'We always try to be the part of our customer’s team to solve the issue' }
	]
	return (
		<div className="mt-5 mb-5">
			<Container>
				<Row>
					<div>
						<p className='text-center fw-semibold wt_fsize' style={{ fontSize: "28px" }}>OUR AIM</p>
					</div>
					{
						OurAimbox.map((v, i) => {
							return (
								<Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} key={i}>
									<div className='mt-5 text-center mb-5 '>

										<img src={v.image} alt='whatstool business' style={{ width: "100px" }} className='him_Boxsed' />

										<p style={{ fontSize: "20px" }} className='fw-semibold him_sizedepen'>{v.info}</p>
										<p className='him_Sizing' style={{ color: "#616874", fontSize: '16px', marginTop: '-12px' }}>{v.title}</p>
									</div>
								</Col>
							)
						})
					}
				</Row>
			</Container>
		</div>
	)
}

// End of Webpage in WhatstoolPowerBusiness

// Start of WTbTestimonials design

export const WtbTestimonials = () => {
	return (
		<section className="mt-5 mb-5 test_padding" style={{ padding: '0 150px' }}>
			<Container>
				<div className="text-center mb-5" style={{ color: '#29bc64' }}>
					<h3>There's Word <span style={{ color: ' #00a82d' }} className="fw-bold">About Us</span> all Around</h3>
				</div>
				<div>
					<Tab.Container id="left-tabs-example" defaultActiveKey="first">
						<Row>
							<Col sm={3}>
								<Nav variant="pills" className="flex-column">
									<Nav.Item>
										<Nav.Link eventKey="first">
											<div style={{ display: 'flex', alignItems: 'flex-start' }}>
												<div>
													<img src="https://user-images.githubusercontent.com/117907145/229427517-fbb0b827-acad-41f5-a93e-4c0dee7066fd.png" style={{ width: 'auto', height: '60px' }} alt="kapilapashu" className="img-fluid" />
												</div>
												<div className="ms-4 pt-1">
													<h3 className="fw-semibold" style={{ color: '#273041', fontSize: '20px' }}>Kapila</h3>
													<p style={{ fontSize: '14px', color: '#616874' }}>Cattle Feed Industry, India</p>
												</div>
											</div>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item style={{ paddingTop: '37px' }}>
										<Nav.Link eventKey="second">
											<div style={{ display: 'flex', alignItems: 'flex-start' }}>
												<div>
													<img src="https://user-images.githubusercontent.com/117907145/229442902-cefeee12-3635-4484-9ab2-3003b3d6432f.png" style={{ width: 'auto', height: '60px' }} alt="Cleanfenatic" className="img-fluid" />
												</div>
												<div className="ms-4 pt-1">
													<h3 className="fw-semibold" style={{ color: '#273041', fontSize: '20px' }}>Clean Fanatic</h3>
													<p style={{ fontSize: '14px', color: '#616874' }}>Cleaning Service, India</p>
												</div>
											</div>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item style={{ paddingTop: '37px' }}>
										<Nav.Link eventKey="Third">
											<div style={{ display: 'flex', alignItems: 'flex-start' }}>
												<div>
													<img src="https://user-images.githubusercontent.com/117907145/229443278-43555a3d-ce3e-4880-bab8-6fb77c49cd63.png" style={{ width: 'auto', height: '60px' }} alt="Cleanfenatic" className="img-fluid" />
												</div>
												<div className="ms-4 pt-1">
													<h3 className="fw-semibold" style={{ color: '#273041', fontSize: '20px' }}>Hope</h3>
													<p style={{ fontSize: '14px', color: '#616874' }}>Mental Health Support, India</p>
												</div>
											</div>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item style={{ paddingTop: '37px' }}>
										<Nav.Link eventKey="Fourth">
											<div style={{ display: 'flex', alignItems: 'flex-start' }}>
												<div>
													<img src="https://user-images.githubusercontent.com/117907145/229443929-c43046cc-568d-40f9-9066-d0927ea38664.png" style={{ width: 'auto', height: '60px' }} alt="Kico" className="img-fluid" />
												</div>
												<div className="ms-4 pt-1">
													<h3 className="fw-semibold" style={{ color: '#273041', fontSize: '20px' }}>KiCo</h3>
													<p style={{ fontSize: '14px', color: '#616874' }}>EdTech Company, India</p>
												</div>
											</div>
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
							<Col sm={9}>
								<Tab.Content>
									<Tab.Pane eventKey="first">
										<div style={{ margin: '110px' }} className="test_margin">
											<div style={{ display: 'flex' }}>
												<div style={{ flex: "1" }}>
													<h3 style={{ fontSize: '24px', color: '#273041' }} className="fw-semibold">Saurabh Shivhare</h3>
													<p style={{ color: '#616874', marginTop: '-2px' }}>Chief Executive</p>
												</div>
												<div><StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /><StarHalfIcon style={{ color: '#F2B415' }} /></div>
											</div>
											<div style={{ fontSize: '18px', color: '#616874', marginTop: '25px' }}>
												<p>Kapila is in the cattle feeding business for 25 years and we wanted some solid solutions for our 2 Million farmers. So we approached WhatsTool. <br />We recently implemented WhatsTool Business's WhatsApp Marketing solution and have been very pleased with the results. We've seen an increase in customer engagement, response times, and overall satisfaction with our communications.</p>
											</div>
											<p style={{ fontSize: '20px', color: '#616874', textAlign: 'end', fontWeight: '500' }} className="mt-5">Plan: Enterprise</p>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="second">
										<div style={{ margin: '110px' }} className="test_margin">
											<div style={{ display: 'flex' }}>
												<div style={{ flex: "1" }}>
													<h3 style={{ fontSize: '24px', color: '#273041' }} className="fw-semibold">Nishant Prasad</h3>
													<p style={{ color: '#616874', marginTop: '-2px' }}>Founder</p>
												</div>
												<div><StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /><StarHalfIcon style={{ color: '#F2B415' }} /></div>
											</div>
											<div style={{ fontSize: '18px', color: '#616874', marginTop: '25px' }}>
												<p>Our company were operating all manually. We wanted to go with WhatsApp for automating our booking for our cleaning services. WhatsTool team not only made us automate they made our success by integrating with our existing system and increased our booking by 12%. We are very happy so far.</p>
											</div>
											<p style={{ fontSize: '20px', color: '#616874', textAlign: 'end', fontWeight: '500' }} className="mt-5">Plan: Enterprise</p>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="Third">
										<div style={{ margin: '110px' }} className="test_margin">
											<div style={{ display: 'flex' }}>
												<div style={{ flex: "1" }}>
													<h3 style={{ fontSize: '24px', color: '#273041' }} className="fw-semibold">Tharun Sai E</h3>
													<p style={{ color: '#616874', marginTop: '-2px' }}>Founder</p>
												</div>
												<div><StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /><StarHalfIcon style={{ color: '#F2B415' }} /></div>
											</div>
											<div style={{ fontSize: '18px', color: '#616874', marginTop: '25px' }}>
												<p>Whatstool is definitely one of the most affordable & intuitive platforms. We could go live within a day and the team helped us in building the chatbot! I definitely recommend the solution to startups!</p>
											</div>
											<p style={{ fontSize: '20px', color: '#616874', textAlign: 'end', fontWeight: '500' }} className="mt-5">Plan: Enterprise</p>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="Fourth">
										<div style={{ margin: '110px' }} className="test_margin">
											<div style={{ display: 'flex' }}>
												<div style={{ flex: "1" }}>
													<h3 style={{ fontSize: '24px', color: '#273041' }} className="fw-semibold">Gaurav Chowdhary</h3>
													<p style={{ color: '#616874', marginTop: '-2px' }}>Co-founder</p>
												</div>
												<div><StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /><StarHalfIcon style={{ color: '#F2B415' }} /></div>
											</div>
											<div style={{ fontSize: '18px', color: '#616874', marginTop: '25px' }}>
												<p>We wanted a solution for our kids who are learning through our app. We got amazed by the WhatsApp API solution provided by WhatsTool. Their team support is amazing, they always make sure to deliver the best for us. We wanted chatbot to handle our learning segment and the flexibility in their chatbot functionality helps deliver what we needed. Highly recommended.</p>
											</div>
											<p style={{ fontSize: '20px', color: '#616874', textAlign: 'end', fontWeight: '500' }} className="mt-5">Plan: Growth</p>
										</div>
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</div>
			</Container>
		</section>
	)
}

// End of WTbTestimonials design

// Start of partnership WebPage

export const PartnershipPage = () => {
	const uref = useRef(null);
	const handleClick = () => {
		uref?.current?.scrollIntoView({ bottom: 0, left: 0, behavior: 'smooth' });
	};

	useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

	const [selecttab, setSelecttab] = useState('')
	const handleSelecttab = (e) => setSelecttab(e)

	return (
		<div>
			<PartnershipPageInfo handleClick={handleClick} />
			<PartnershipPageSection1 selecttab={selecttab} handleSelecttab={handleSelecttab} />
			<PartnershipPageSection2 uref={uref} selecttab={selecttab} />
		</div>
	)
}

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })

export const PartnershipPageInfo = ({ handleClick }) => {
	return (
		<Container fluid>
			<div className="mt-5">
				<Row>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
						<div style={{ padding: isMobile ? '' : '0 130px ' }}>
							<h3 className="mb-4" style={{ fontSize: isMobile ? '' : '30px' }}>Grow your business as a <span style={{ color: '#00a82d' }}>WhatsTool Business</span> partner</h3>
							<span style={{ fontSize: isMobile ? '14px' : '17px', color: '#616874' }} className='fw-semibold'>Let’s work together. Join our community of partners to grow your business.</span>
							<div>
								<button onClick={() => handleClick()} className="text-white btn btn-sm ps-2 pe-2 pt-2 pb-2 mt-5 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Apply Now</button>
							</div>
						</div>
					</Col>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
						<p className={isMobile ? 'mt-5' : 'float-end mt-0'}>
							<img src="https://user-images.githubusercontent.com/117907145/224919613-36d906c7-77f1-41dc-bf34-f546fa1c30ff.png" style={{ width: '421px' }} alt="Whatstool Business" className="img-fluid" />
						</p>
					</Col>
				</Row>
			</div>
		</Container>
	)
}
export const PartnershipPageSection1 = ({ selecttab, handleSelecttab }) => {
	return (
		<Container>
			<div className="mt-5 mb-5 pt-5 pb-5">
				<div className="text-center">
					<h3>Connect with WhatsTool Business and exceed your business goals</h3>
				</div>
				<Tabs
					defaultActiveKey="digital_marketing" value={selecttab} onSelect={(e) => handleSelecttab(e)} style={{ justifyContent: 'space-evenly', fontWeight: '600', cursor: 'pointer', borderBottom: '1px dashed #ccc' }} id="uncontrolled-tab-example" className="mb-3 mt-4">

					<Tab eventKey="digital_marketing" title="Digital marketing">
						<div className="mt-5">
							<Row>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div style={{ padding: isMobile ? '' : '0 50px ', marginTop: isMobile ? '' : '75px' }}>
										<h3 className="mb-4 text-center">Digital marketing agency </h3>
										<span style={{ fontSize: isMobile ? '14px' : '16px', color: '#616874' }} className='fw-semibold'>If you are running a digital marketing agency you can be our partner by using a range of digital - focused marketing tactics to increase our’s digital engagement and discoverability on the internet and help to make more sales.</span>
									</div>
								</Col>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div className="text-center">
										<img src="https://user-images.githubusercontent.com/117907145/224961654-d5c0ce31-3ef9-48e0-81b5-aee927b3aaab.png" alt="" className="img-fluid" />
									</div>
								</Col>
							</Row>
						</div>
					</Tab>
					<Tab eventKey="social_media" title="Social media">
						<div className="mt-5">
							<Row>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div style={{ padding: isMobile ? '' : '0 50px ', marginTop: isMobile ? '' : '75px' }}>
										<h3 className="mb-4 text-center">Social Media Influencer</h3>
										<span style={{ fontSize: isMobile ? '14px' : '16px', color: '#616874' }} className='fw-semibold'>If you are a social media influencer, you can be our partner by influencing people about our product and help in increase of sales. </span>
									</div>
								</Col>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div className="text-center">
										<img src="https://user-images.githubusercontent.com/117907145/225840289-1b8512e7-75da-4533-8910-ae2d153db4ae.png" style={{ height: isMobile ? '260px' : '400px' }} alt="" className="img-fluid" />

									</div>
								</Col>
							</Row>
						</div>
					</Tab>
					<Tab eventKey="consultant" title="Consultant">
						<div className="mt-5">
							<Row>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div style={{ padding: isMobile ? '' : '0 50px ', marginTop: isMobile ? '' : '75px' }}>
										<h3 className="mb-4 text-center">Consultant</h3>
										<span style={{ fontSize: isMobile ? '14px' : '16px', color: '#616874' }} className='fw-semibold'>If you are a consultant, you can be our partner and offer advice and expertise to organisations to help us improve our business performance and help in increase of sales. </span>
									</div>
								</Col>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div className="text-center">
										<img src="https://user-images.githubusercontent.com/117907145/225840283-dee6b3ea-9a81-4597-a7c6-02188bc995b9.png" style={{ height: isMobile ? '' : '300px' }} alt="" className="img-fluid" />

									</div>
								</Col>
							</Row>
						</div>
					</Tab>
					<Tab eventKey="web_development" title="Web development">
						<div className="mt-5">
							<Row>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div style={{ padding: isMobile ? '' : '0 50px ', marginTop: isMobile ? '' : '75px' }}>
										<h3 className="mb-4 text-center">Web development agency</h3>
										<span style={{ fontSize: isMobile ? '14px' : '16px', color: '#616874' }} className='fw-semibold'>If you are a Web development agency owner, you can be our partner helping our business set up our online recognition and help in increase of sales.</span>
									</div>
								</Col>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div className="text-center">
										<img src="https://user-images.githubusercontent.com/117907145/225840280-e295f9e2-c051-4d39-a569-6ec61448b80a.png" style={{ height: isMobile ? '' : '233px' }} alt="" className="img-fluid" />

									</div>
								</Col>
							</Row>
						</div>
					</Tab>
					<Tab eventKey="looking_to_earn" title="Looking to earn">
						<div className="mt-5">
							<Row>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div style={{ padding: isMobile ? '' : '0 50px ', marginTop: isMobile ? '' : '75px' }}>
										<h3 className="mb-4 text-center">Looking to earn</h3>
										<span style={{ fontSize: isMobile ? '14px' : '16px', color: '#616874' }} className='fw-semibold'>If you are Looking to earn income through referrals you can be our partner by referring our website to the people for their business need and help in increase of sales.</span>
									</div>
								</Col>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
									<div className="text-center">
										<img src="https://user-images.githubusercontent.com/117907145/225840268-4f9cb3e8-277a-478a-b4c6-7d510a8c2dfc.png" style={{ height: isMobile ? '220px' : '267px' }} alt="" className="img-fluid" />
									</div>
								</Col>
							</Row>
						</div>
					</Tab>
					<Tab eventKey="product_integration" title="Product Integration">
						<div className="mt-5">
							<Row>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
									<div style={{ padding: isMobile ? '' : '0 50px ', marginTop: isMobile ? '' : '75px' }}>
										<h3 className="mb-4 text-center">Looking to earn</h3>
										<span style={{ fontSize: isMobile ? '14px' : '16px', color: '#616874' }} className='fw-semibold'>If you are having a product and want to integrate it with WhatsTool Business, then you can be our partner and increase your sales and growth.</span>
									</div>
								</Col>
								<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
									<div className="text-center">
										<img src={getUrl.imageBaseUrl + '/media/appintegrationimg.png'} style={{ height: isMobile ? '220px' : '267px' }} alt="" className="img-fluid" />
									</div>
								</Col>
							</Row>
						</div>
					</Tab>
				</Tabs>
			</div>
		</Container>
	)
}
export const PartnershipPageSection2 = ({ uref, selecttab }) => {

	const [countryCode, setCountryCode] = useState('+91')
	const handleCountryCode = (code) => {
		setCountryCode(code)
		handleClose()
	}

	const [countryCodeLs, setCountryCodeLs] = useState(undefined)
	const getCountryCode = async () => {
		const countryCodeResponse = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/CountryCodes.json')
		setCountryCodeLs(await countryCodeResponse.json())
	}
	useEffect(() => {
		getCountryCode()
	}, [])

	const [openDialog, setOpenDialog] = useState(false);
	const openCountrySelectionDialogBox = () => {
		setOpenDialog(true)
	}

	const handleClose = () => setOpenDialog(false)

	const [searchLs, setSearchLs] = useState([])
	useEffect(() => { setSearchLs(countryCodeLs) }, [countryCodeLs])
	const searchItems = (searchValue) => {
		const filteredData = countryCodeLs.filter((item) => { return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase()) })
		setSearchLs(filteredData)
	}

	const [name, setName] = useState('')
	const handleName = (e) => setName(e)

	const [companyname, setcompanyName] = useState('')
	const handlecompanyName = (e) => setcompanyName(e)

	const [mobilenumber, setMobileNumber] = useState('')
	const handleMobileNumber = (e) => {
		let checkedVal = e?.replace(/[^0-9]/ig, '')
		setMobileNumber(checkedVal)
	}

	const [email, setEmail] = useState('')
	const handleEmail = (e) => setEmail(e)

	const [companysize, setCompanysize] = useState('')
	const handleCompanysize = (e) => setCompanysize(e)

	const [typeofcompany, setTypeofcompany] = useState('')
	const handleTypeofcompany = (e) => setTypeofcompany(e)

	const [message, setMessage] = useState('')
	const handleMessage = (e) => setMessage(e)

	const [openDialogg, setOpenDialogg] = useState({ open: false, onClose: null })

	const sendResponse = async () => {
		try {
			let data = `https://script.google.com/macros/s/AKfycbxipdNH08H7On0G-UAK1WlbeAb0KF6vyBzPao6cbCDCg_93-HPAE4-bOllWtJBeLkU/exec?name=${name}&company_name=${companyname}&select_tab=${selecttab}&mobile_number=${mobilenumber}&email=${email}&company_size=${companysize}&type_of_company=${typeofcompany}&messages=${message}`

			const response = await fetch(data)
			if (response) {
				WtEvent(getAnalyticsKey.CalendlyLiveDemo)
				handleName('')
				handlecompanyName('')
				handleMobileNumber('')
				handleEmail('')
				handleCompanysize('')
				handleTypeofcompany('')
				handleMessage('')
			}
		} catch (error) { }
		setOpenDialogg({ open: true, onClose: () => setOpenDialogg(false) })
	}

	return (
		<div>
			<Container>
				<div className="text-center">
					<h3 ref={uref}>Become a WhatsTool Business Authorised Partner</h3>
				</div>
				<div className="mt-5 mb-5">
					<Row>
						<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
							<div style={{ marginTop: isMobile ? '0' : '80px' }} className='text-center'>
								<img src="https://user-images.githubusercontent.com/117907145/224970738-d0bc8133-17ec-4c05-93e3-c38ba9587abe.png" alt="partnership" className="img-fluid" />
							</div>
						</Col>
						<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>

							<div autoComplete="on" style={{ background: '#fafafa', padding: isMobile ? '18px 15px' : '18px 75px', margin: isMobile ? '0' : '0 20px' }} className='rounded-3'>

								<input style={{ width: '100%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 15px', background: '#fafafa' }} value={name} onChange={(e) => handleName(e.target.value)} autoFocus type='text' placeholder="Name" />

								<input style={{ width: '100%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 15px', background: '#fafafa' }} value={companyname} onChange={(e) => handlecompanyName(e.target.value)} type='text' placeholder="Company Name" />

								<div style={{ width: '100%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', display: 'flex', color: 'gray' }} >
									<span onClick={() => openCountrySelectionDialogBox()} className="ps-3 pe-2 pt-2 hand">{countryCode}&nbsp;|</span>
									<input style={{ border: 'none', outline: 'none', width: isMobile ? '79%' : '86%', background: '#fafafa' }} value={mobilenumber} onChange={(e) => handleMobileNumber(e.target.value)} type="tel" maxLength="12" placeholder="Mobile Number" />
								</div>

								<input value={email} onChange={(e) => handleEmail(e.target.value)} style={{ width: '100%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 15px', background: '#fafafa' }} type='email' placeholder="Email Address" />

								<select value={companysize} onChange={(e) => handleCompanysize(e.target.value)} style={{ width: '100%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 11px', background: '#fafafa' }}>
									<option>Company Size</option>
									<option value="1-15">1-15</option>
									<option value="15-50">15-50</option>
									<option value="50-200">50-200</option>
									<option value="200+">200+</option>
								</select>

								<select value={typeofcompany} onChange={(e) => handleTypeofcompany(e.target.value)} style={{ width: '100%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 11px', background: '#fafafa' }}>
									<option>Select Industry</option>
									<option value="automotive">Automotive</option>
									<option value="beauty, spa and salon">Beauty, spa and salon</option>
									<option value="clothing">Clothing</option>
									<option value="education">Education</option>
									<option value="entertainment">Entertainment</option>
									<option value="event Planning and Service">Event planning and service"</option>
									<option value="finance and Banking">Finance and banking</option>
									<option value="food and Groceries">Food and groceries</option>
									<option value="public Service">Public service</option>
									<option value="hotel and Lodging">Hotel and lodging</option>
									<option value="medical and Health">Medical and health</option>
									<option value="charity">Charity</option>
									<option value="Professional Services">Professional services</option>
									<option value="other">Other</option>
								</select>
								<textarea value={message} onChange={(e) => handleMessage(e.target.value)} style={{ width: '100%', height: '24mm', outline: 'none', marginTop: '60px', border: '1px solid gray', borderRadius: '5px', padding: '10px 15px', background: '#fafafa' }} type='submit' placeholder="Message (Optional)" />
								<div>
									<button onClick={(e) => sendResponse(e.target.value)} className="text-white btn btn-sm ps-3 pe-3 pt-2 pb-2 mt-5 mb-3 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Submit</button>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Container >
			{openDialogg.open && <CalendlyLiveDemo open={openDialogg.open} onClose={openDialogg.onClose} />}
			<Dialog open={openDialog} TransitionComponent={Transition} keepMounted onClose={handleClose}>
				<input onChange={(e) => searchItems(e.target.value)} placeholder="Select Country Code" required type={'search'} className='form-control mt-1'></input>
				<DialogContent>
					{
						searchLs?.map((v, i) => {
							return (
								<p className="hand" key={i} onClick={() => handleCountryCode(v.dial_code)}>{v.name}{v.dial_code}</p>
							)
						})
					}
				</DialogContent>
			</Dialog>
		</div>
	)
}
// End of partnership WebPage

// Start of caseStudy WebPage

export const CaseStudy = () => {
	return (
		<div>
			<CaseStudyHeader />
			<CaseStudySection1 />
			<CaseStudySection2 />
		</div>
	)
}

export const CaseStudyHeader = () => {
	return (
		<div style={{ display: isMobile ? 'grid' : 'flex', background: '#EFFFED' }} className="mt-4">
			<div style={{ flex: 2 }}>
				<p style={{ padding: isMobile ? '25px 50px' : '90px 135px' }}>
					<h1 className="mb-3">Case Study</h1>
					<h6 style={{ color: 'gray' }}>See how WhatsTool Business helped in customer’s Journey</h6>
				</p>
			</div>
			<div style={{ flex: 1 }}>
				<img src="https://user-images.githubusercontent.com/117907145/220552868-b7b81a6e-8510-4ae6-b157-215d55c34b67.png" alt="caseStudylogo" style={{ height: isMobile ? '240px' : '300px' }} />
			</div>
		</div>
	)
}

export const CaseStudySection1 = () => {
	const s1 = { borderRadius: '12px', boxShadow: '0 0 4px 2px rgb(0 0 0 / 25%)', minHeight: isMobile ? '470px' : '408px', maxHeight: isMobile ? '450px' : '408px', marginRight: isMobile ? '0' : '18px' }
	const ClientBrand = [
		{ image: 'https://user-images.githubusercontent.com/117907145/220558385-00b6c9c8-c54f-43a6-96fd-24c033920598.png', title: 'Clean Fanatic', info: 'Clean Fanatic recovered 40% of their Abandoned Cart and it helped in their growth and sales. Read more to know how they achieved it.', Readmore: 'Read their story' },
		{ image: 'https://user-images.githubusercontent.com/117907145/220558382-9774f072-d173-4c24-9d0b-2db03f0384a3.png', title: 'KiCo', info: 'Kico was facing problem in doing customer support. Now they are doing it very easily that to 24/7 automatically without any human dependancy. Read more to know how they achieved it.', Readmore: 'Read their story' },
		{ image: 'https://user-images.githubusercontent.com/117907145/220558380-76e865cd-f85d-4389-ad97-c712a010a192.png', title: 'Zomato', info: 'Zomato is getting 50% of the order by sending the personalised offer, important notification etc. Read more to know how they achieved it.', Readmore: 'Read their story' },
	]
	return (
		<div>
			<Container>
				<div style={{ marginTop: '80px' }} className='ps-5 pe-5'>
					<Row>
						{
							ClientBrand.map((v, i) => {
								return (
									<Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
										<div style={s1} className='pt-5 pb-5 pe-4 ps-4 mb-5'>
											<p className="text-center ">
												<img src={v.image} alt="wtb" style={{ width: '80px' }} />
											</p>
											<h3 className="text-center pb-4">{v.title}</h3>
											<p className="fw-semibold pb-3" style={{ color: 'gray' }}>{v.info}</p>
											<small className="fw-bold hand" style={{ color: getColour.BaseColor }}>{v.Readmore}</small>
										</div>
									</Col>
								)
							})
						}
					</Row>
					<div style={{ display: isMobile ? 'grid' : 'flex' }} className='mt-5 mb-5'>
						<div style={{ flex: 1 }}>
							<img src="https://user-images.githubusercontent.com/117907145/220583826-9be33667-db82-4b86-be02-01de1647eddf.png" alt="Kapila" className="img-fluid" />
						</div>

						<div style={{ flex: 1, padding: isMobile ? '0' : '40px 30px' }}>

							<p className="fw-semibold" style={{ color: 'gray' }}>Kapila Business wanted to engage with their customer 24/7 but it was not possible due to their busy schedule. They couldn’t give much time for their customer but then they used WhatsTool Business for their problem. They could easily solve the customer queries 24/7 and also could solve their other problem.</p>

							<p className="fw-semibold pb-5" style={{ color: 'gray' }}>Read their complete story to know how they did it.</p>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
								<p>
									<img src="https://user-images.githubusercontent.com/117907145/220589054-a8eda2a5-b292-40a4-988e-9cada794ca71.png" alt="Kapila" style={{ width: '75px' }} />
								</p>
								<small className="fw-bold hand float-end" style={{ color: getColour.BaseColor }}>Read their story</small>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export const CaseStudySection2 = () => {
	return (
		<div style={{ background: 'rgb(245, 245, 245)' }}>
			<Container fluid>
				<div className={isMobile ? 'pb-5 pt-5' : 'pb-0 pt-5'}>
					<Container>
						<div className={isMobile ? 'p-0' : 'p-5'}>
							<Row>
								<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
									<div className="text-center">
										<h3 className="mb-4 " style={{ fontSize: isMobile ? '' : '26px', color: '#273041' }}>WhatsTool Business solutions can grow your business with WhatsApp</h3>
										<h3 style={{ color: '#273041', fontSize: '18px' }}>You can do it with zero cost</h3>
										<p>
											<button onClick={() => window.open(getUiEnvUrl(), '_blank')} className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 mt-5 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Get Started</button>
										</p>
									</div>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</Container>
		</div>
	)
}

// End of CaseStudy WebPage

// Start of WhatsApp Templates Webpage

export const WhatstoolTemplates = () => {
	const location = useLocation()
	const history = useHistory()

	const [templates, setTemplates] = useState(whatstoolTemplates) // Search Handling

	const templateGalleryTypeList = ['All', 'Republic Day Sale', 'Empower Festival Sale', 'Education', 'E-commerce', 'Entertainment', 'Internet Services', 'Fashion and Sales', 'General', 'Sports', 'Transactional', 'Webinar', 'Art Work']

	const [cateFilter, setCateFilter] = useState('All') // Filter handling
	const handleCateFilter = (val) => {
		setCateFilter(val)
		history.push(`${getUrl.WhatstoolTemplatesUrl}?filter=${val}`)
	}

	
	const params = getUrl.parseQueryParams(location)
	useEffect(() => {
		if (params['filter'] === 'All') setCateFilter('All')
		if (params['filter'] === 'Republic Day Sale') setCateFilter('Republic Day Sale')
		if (params['filter'] === 'Empower Festival Sale') setCateFilter('Empower Festival Sale')
		if (params['filter'] === 'Education') setCateFilter('Education')
		if (params['filter'] === 'E-commerce') setCateFilter('E-commerce')
		if (params['filter'] === 'Entertainment') setCateFilter('Entertainment')
		if (params['filter'] === 'Internet Services') setCateFilter('Internet Services')
		if (params['filter'] === 'Fashion and Sales') setCateFilter('Fashion and Sales')
		if (params['filter'] === 'General') setCateFilter('General')
		if (params['filter'] === 'Sports') setCateFilter('Sports')
		if (params['filter'] === 'Transactional') setCateFilter('Transactional')
		if (params['filter'] === 'Webinar') setCateFilter('Webinar')
		if (params['filter'] === 'Art Work') setCateFilter('Art Work')
	}, [params['filter']])

	const [templateDialog, setTemplateDialog] = useState({ open: false, onClose: null })// Full Img Dialog Box Handle..
	const openTemplateDialog = (v) => setTemplateDialog({ open: true, v: v, onClose: () => setTemplateDialog(false) })

	const [applytempDialog, setApplytempDialog] = useState({ open: false, onClose: null })//  Apply Now Template Dialog Box Handle...
	const handlesetApplytempDialog = (v) => setApplytempDialog({ open: true, v: v, onClose: () => setApplytempDialog(false) })

	return (
		<section>
			<Container className="mt-5 mb-5">
				<div className="text-center">
					<h4 style={{ color: getColour.HeadingTextColour }}>WhatsApp Message Templates Gallery</h4>
					<p style={{ color: getColour.HeadingTextColour, fontSize: '16px' }}>This template is created to be displayed as a sample to the customer</p>
				</div>
				<div style={{ textAlign: 'end' }} className="mb-5">
					<Link to={getUrl.featuredTemplatesUrl}><button style={{ color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '8px 25px', fontSize: '14px' }} className="btn btn-sm">Featured Chatbot Templates</button></Link>
				</div>
				<Tab.Container id="left-tabs-example">
					<Row>
						<Col sm={3} className='pb-3' style={{ borderRight: '1px solid rgb(240, 242, 248)' }}>
							<Nav variant="pills" className="flex-column" defaultActiveKey={`${cateFilter}`} activeKey={`${cateFilter}`} onSelect={handleCateFilter}>
								{
									templateGalleryTypeList.map((v, i) => {
										return (
											<Nav.Item style={{ paddingTop: '9px' }} key={i}>
												<Nav.Link eventKey={`${v}`}>
													<div>
														<option value={v} key={i}>{v}</option>
													</div>
												</Nav.Link>
											</Nav.Item>
										)
									})
								}
							</Nav>
						</Col>
						<Col sm={9}>
							<Row>
								{
									templates?.filter((v) => {
										if (cateFilter === 'All') return v
										return cateFilter === v.item_category
									})
										?.map((v, i) => {
											return (
												<Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12} key={i} className="mb-3">
													<section>
														<div style={{ border: '1px solid #ccc', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', minHeight: isMobile ? '480px' : '400px', maxHeight: '550px', position: 'relative' }}>
															<div onClick={() => openTemplateDialog(v)} className="hand ">
																<img src={getUrl.templateimgBaseUrl + v.ImageHalf} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: 'auto', width: '100%', borderBottom: '1px solid #9FF2A7' }} alt="WhatsTool Templates" className="img-fluid pb-1" />
															</div>

															<TemplatestitleComp v={v} key={i} />
															<p className="mt-2 ms-2 me-2 mb-2" style={{ fontSize: '16px' }}>{v?.Describe}</p>

															<div style={{ paddingTop: '20px', display: 'flex' }}>
																<div style={{ flex: 1, position: 'absolute', left: '2%', bottom: '4%' }}>
																	<button style={{ color: getColour.DescriptionTextColour, background: getColour.BorderLineColour, borderRadius: '7px', padding: isMobile ? '8px 28px' : '6px 20px', whiteSpace: 'nowrap' }} onClick={() => openTemplateDialog(v)} className='btn btn-sm shadow-lg'>Preview</button>
																</div>
																<button style={{ background: getColour.BaseColor, borderRadius: '7px', padding: isMobile ? '8px 28px' : '6px 20px', whiteSpace: 'nowrap', position: 'absolute', right: '2%', bottom: '4%' }} onClick={() => handlesetApplytempDialog(v)} className='btn btn-sm text-white shadow-lg'>Apply Now</button>
															</div>
														</div>
													</section>
												</Col>
											)
										})
								}
							</Row>
						</Col>
					</Row>
				</Tab.Container>
			</Container>

			{templateDialog.open && <WhatsAppTemplates open={templateDialog.open} v={templateDialog.v} onClose={templateDialog.onClose} />}
			{applytempDialog.open && <ApplyNowTemplate open={applytempDialog.open} v={applytempDialog.v} onClose={applytempDialog.onClose} />}
		</section >
	)
}

export const TemplatestitleComp = ({ v }) => {

	const [readMore, setReadMore] = useState(false)
	const handleReadMore = () => setReadMore(!readMore)

	return (
		<section>
			<div>
				{
					v.title?.length > 30 &&
					<p className="ms-2 me-2 fw-semibold">
						{!readMore && <span>{`${v.title?.substring(0, 30)} ...`}</span>}
						{readMore && <span>{`${v.title}`}</span>}
						<span className='hand' style={{ color: getColour.ProcessingBlue }} onClick={() => handleReadMore()}>{readMore ? 'Read less' : 'Read more'}</span>
					</p>
				}
				{v.title?.length < 30 && <p style={{ color: getColour.HeadingTextColour }}>{`${v.title}`}</p>}
			</div>
		</section>
	)
}

// End of WhatsApp Templates Webpage

// Start of Compare WhatsTool Vs WATI Webpage

export const CompareWati = () => {
	const setParams = useContext(globalContext)
	const urlSearch = window.location.href
	if (urlSearch.includes("?")) {
		const parmas = urlSearch.split("?")[1]
		setParams.setParams(parmas)
	}
	return (
		<div>
			<ComparisionPageinfo />
			<Whatstoolgif />
			<CompareTable />
			<Comparewativswhatstool />
			<WhyWhatstoolbusiness />
		</div>
	)
}

export const ComparisionPageinfo = () => {
	const params = useContext(globalContext)
	function getUrls() {
		if (params.params) {
			return `${getUrl.staticLink}?${params.params}`
		}
		return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
	}
	return (
		<div className='him_backgroundurl'>
			<Container fluid>
				<Container>
					<Row>
						<Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
							<div className='mt-5' style={{ display: "flex" }}>
								<img src='https://user-images.githubusercontent.com/117907145/214569112-dcd47ddc-0e78-4dc1-a0b4-ae9029dbb59e.png' alt='whatstool Business' style={{ width: "40px", height: "40px" }} />&nbsp;
								<img src='https://user-images.githubusercontent.com/117907145/214570172-9fc86996-a455-44f8-b27e-7677859556db.png' alt='whatstool Business' style={{ width: "auto", height: "40px" }} className='mt-1' />
								<p className='ms-5 me-5 fw-bold' style={{ color: "gray", fontSize: "22px" }}>VS</p>
								<img src='https://user-images.githubusercontent.com/117907145/214573014-760f29cf-3517-4728-b00b-563479ee7d8b.png' alt='whatstool Business' style={{ width: "40px", height: "40px" }} />
								<p className='fw-bold' style={{ fontSize: "27px" }} >&nbsp;WATI</p>
							</div>

							<p style={{ fontSize: "34px" }} className='fw-semibold mt-4'>WhatsTool Business - <span style={{ fontSize: "30px", color: "gray" }}>Wati’s Best Alternative</span></p>
							<p style={{ color: "#616874", fontSize: "18px" }} className='mt-3'>WhatsTool Business will solve your Business problems through its features and helps to grow your sales</p>
							<div style={{ display: 'flex' }} className="mt-5">
								<Link to={{ pathname: getUrls() }} target='_blank'><button className="text-white btn btn-sm ps-5 pe-5 pt-2 pb-2 fw-semibold" style={{ background: '#00a82d', borderRadius: '8px' }}>Get Started</button></Link>
								<Link to={{ pathname: getUrls() }} target='_blank'><button style={{ color: getColour.BaseColor, border: "1px solid " + getColour.BaseColor, background: '#fff', borderRadius: '8px', padding: '8px 25px', fontSize: '14px' }} className="btn btn-sm ms-3">Try Demo</button></Link>
							</div>
						</Col>

						<Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
							<div className='mt-5'>
								<img src='https://user-images.githubusercontent.com/117907145/214836543-38a48b94-70fa-4c3e-8b4e-2cef97ab5ce0.png' alt='whatstool business' className='float-end him_imgwidth' style={{ width: "420px" }} />
							</div>
						</Col>
					</Row>
				</Container>
			</Container>

		</div>
	)
}

export const Whatstoolgif = () => {
	return (
		<Container>
			<Row>
				<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
					<div>
						<LazyLoadImage src="./templatesimg/wwg.png" alt='imgwt' className="img-fluid" />
					</div>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
					<div className='mt-5'>
						<p style={{ color: "#616874" }}>WhatsTool Business is a one solution for all your Business problems. WhatsTool Business has many features that reduces your efforts and helps to makes your work easy in simple steps. Many business has trusted us in their growth. We have solution for all kind of industries.</p>

					</div>
				</Col>
			</Row>
		</Container>
	)
}

export const CompareTable = () => {
	const UI = ({ type1, type2, img, img1, alt, h1, h2, h3, h4 }) => {
		return (
			<div className='text-center mt-5'>
				{/* {
                    type === 'heading' && ''
                }

                {
                    type !== 'heading' &&
                    <img src={img1} alt='whatstool' style={{ width: "42px" }} />
                }
                {
                    type !== 'heading' &&
                    <img src={img2} alt='whatstool' style={{ width: "42px" }} />
                }
                {
                    type !== 'heading' &&
                    <img src={img3} alt='whatstool' style={{ width: "42px" }} />
                } */}

				{/* <p style={{ fontSize: "28px" }} className='fw-bold'>COMPARISON</p> */}

				<div style={{ height: '60px' }}>

					<img src={img} alt={alt} style={{ width: "42px" }} />
					<img src={img1} alt={alt} style={{ width: "30%" }} />

					<span style={{ fontSize: "27px" }} className='fw-semibold'>
						{type1}
					</span>

					<span className='fw-bold' style={{ color: "gray", fontSize: "22px" }}>&nbsp;
						{type2}
					</span>
				</div>


				<div style={{ background: "#F0F2F8" }}>
					<p className='fw-semibold' style={{ fontSize: "21px", padding: '15px 0' }}>{h1}</p>
				</div>
				<div style={{ background: "#F0F2F8" }}>
					<p className='fw-semibold ' style={{ fontSize: "21px", padding: '15px 0' }}>{h2}</p>
				</div>
				<div style={{ background: "#F0F2F8" }}>
					<p className='fw-semibold' style={{ fontSize: "21px", padding: '15px 0' }}>{h3}</p>
				</div>
				<div style={{ background: "#F0F2F8" }}>
					<p className='fw-semibold' style={{ fontSize: "21px", padding: '15px 0' }}>{h4}</p>
				</div>

			</div>
		)
	}
	return (
		<Container>
			<Row>
				<p className='fw-semibold mt-5 text-center him_fontSize' style={{ fontSize: "32px" }}>A Comparative Table of WhatsTool Business vs Wati</p>


				<Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}><UI type1={'COMPARISION'} type2={''} img={''} img1={''} h1={'Price '} h2={'Subscription'} h3={'Green tick verification'} h4={'Chatbot builder'} /></Col>
				<Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}><UI type1={''} type2={'WATI'} img={'https://user-images.githubusercontent.com/117907145/214573014-760f29cf-3517-4728-b00b-563479ee7d8b.png'} img1={''} h1={'Rs. 2900 '} h2={'Pre-paid'} h3={'$100 for verification'} h4={'Basic Chatbot'} /></Col>
				<Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}><UI type1={''} type2={''} img={'https://user-images.githubusercontent.com/117907145/214569112-dcd47ddc-0e78-4dc1-a0b4-ae9029dbb59e.png'} img1={'https://user-images.githubusercontent.com/117907145/214570172-9fc86996-a455-44f8-b27e-7677859556db.png'} h1={'Rs. 1000'} h2={'Pre-paid & Post-paid'} h3={'Free green-tick'} h4={'Advanced Chatbot'} /></Col>
			</Row>
		</Container>
	)
}

export const Comparewativswhatstool = () => {
	return (
		<Container>
			<Row className='him_mt'>
				<div style={{ display: "flex", cursor: "pointer" }} className='him_disflexroot'>
					<div style={{ background: "#f0faf2", flex: 1, marginTop: "145px" }} className='p-4  '>
						<p className='text-center'>
							<img src='https://user-images.githubusercontent.com/117907145/214573014-760f29cf-3517-4728-b00b-563479ee7d8b.png' alt='whatstool Business' style={{ width: "42px", height: "42px" }} />
						</p>
						<p className='text-center fw-semibold mt-4 mb-5' style={{ fontSize: "24px" }}>What WATI Don’t Have?</p>
						<ul style={{ fontSize: "18px" }}>
							<li>WATI is yet to release a shop/catalogue feature for businesses to showcase their product offerings on WhatsApp.</li>
							<li className='pt-3'>In Free Trial you cannot use Chatbot.</li>
							<li className='pt-3'>In Free Trial you cannot use your own whatsApp number. </li>
						</ul>

					</div>

					<div style={{ background: "#f0faf2", flex: 1, marginTop: "145px" }} className='ms-5 p-4 him_margintop'>
						<p className='text-center'>
							<img src='https://user-images.githubusercontent.com/117907145/214569112-dcd47ddc-0e78-4dc1-a0b4-ae9029dbb59e.png' alt='whatstool Business' style={{ width: "42px", height: "42px" }} />
						</p>
						<p className='text-center fw-semibold mt-4 mb-5' style={{ fontSize: "24px" }}>What you will get in WhatsTool Business?</p>
						<ul style={{ fontSize: "18px" }}>
							<li>WhatsTool Business provide demo account where you can use the features to know how it works.</li>
							<li className='pt-3'>WhatsTool Business has a Free Plan in which you can use your own WhatsApp number to use the features.</li>
							<li className='pt-3'>We have Advanced Chatbot Builder. </li>
						</ul>

					</div>
				</div>

			</Row>
		</Container>
	)
}

export const WhyWhatstoolbusiness = () => {
	const whatstoolbusiness = [
		{ image: "https://user-images.githubusercontent.com/117907145/215031325-5af841ea-87e7-48ac-a359-3c71c883c58f.png", title: 'ChatBot', info: "To make your work easy and effortless" },
		{ image: "https://user-images.githubusercontent.com/117907145/215031890-c8fcb82d-867d-4379-9186-1c9d59493b73.png", title: 'Multi Agent Chat', info: "To work with your team and assign agent, add labels" },
		{ image: "https://user-images.githubusercontent.com/117907145/215032099-4422796f-1e5f-4729-b912-81d4f0817a72.png", title: 'Broadcast Message', info: "To send n number of messages at a time" },
		{ image: "https://user-images.githubusercontent.com/117907145/215032179-d5203051-9335-4326-a7eb-3a89fb950bf9.png", title: 'Integration', info: "To connect with your favourite app without switching between the channels" }
	]
	return (
		<section>
			<Container className="mt-5 mb-5 pe-3 ps-3">
				<h5 className="fw-bold mt-5 mb-5 text-center">Why WhatsTool Business</h5>
				<Container className="pe-5 ps-5">
					<Row>
						{
							whatstoolbusiness?.map((v, i) => {
								return (
									<Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} key={i} className="p-3">
										<div className="benefits_box">
											<p className="text-center pt-5 ps-3 pe-3 pb-5">
												<img src={v.image} width="240px" className="img-fluid" alt={getString.SiteName} />
											</p>
											<h6 className="text-center mt-3 mb-5 fw-bold ps-3 pe-3">{v.title}<br />{v.info}</h6>
										</div>
									</Col>
								)
							})
						}
						<p style={{ color: '#616874' }} className='mt-5 fw-semibold'>We have so many solutions for the Business to improve the sales and customer support service and reduces the effort of the company with the features we provide</p>
						<ul className='ms-3'>
							<li>Have multiple team members to drive Live Chat Support on the Same Whatsapp Business Number.</li>
							<li>You can simply Import all your Contacts and Broadcast Approved Messages Instantly.</li>
							<li>Solve Customer Queries 24x7 using Chat Bot</li>
							<li>Our In-Built CRM enables you to add various tags and attributes to each customer.</li>
							<li>Connect your CRM, Payment Portals, eCommerce Platforms</li>
						</ul>
					</Row>
				</Container>
			</Container>
		</section>
	)
}



// End of Compare WhatsTool Vs WATI Webpage


