/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import './App.css'
import { ShowSnackbar } from './controller/BaseTool'
import { Env } from './controller/constant'
import axios from 'axios'
import './App.css'
import { Buffer } from 'buffer'
import { isMobile } from 'react-device-detect'
import 'react-toastify/dist/ReactToastify.css'
import { getColour } from './constants/colours'
import { showConsole } from './basetool/methods'
import { getLoginKey } from './constants/loginKeys'
import { RouteController } from './routes/routes'
import globalContext from './context/global/globalContext'
import { getStorage } from './basetool/CustomStorage'


// Axios Work
let reqLs = {}
axios.interceptors.request.use((request) => {
	let reqId = request.url.replace(Env.baseApiCallURL, '') + JSON.stringify({ method: request.method, payload: request.data }).split('').sort().join('');
	if (reqLs[`${reqId}`] === true) { return undefined }
	reqLs[`${reqId}`] = true

	let token_gen = String(Number(Date.now()))
	token_gen = Buffer.from(token_gen).toString("base64").slice(0, -2)
	token_gen = token_gen.split("").reverse().join("")

	showConsole('📣 Request: ' + request.method + ' ' + request.url.replace(Env.baseApiCallURL, '') + ' ' + JSON.stringify(request.data))

	request.headers["token"] = token_gen

	request.headers['devicetype'] = isMobile ? 'mobile_web' : 'desktop_web'
	request.reqId = reqId

	return request
}, (error) => { return Promise.reject(error) })

axios.interceptors.response.use(
	(response) => {
		delete reqLs[`${response.config.reqId}`]

		if (!getStorage.getItem(getLoginKey.sessionTraceId) || getStorage.getItem(getLoginKey.sessionTraceId) === undefined) getStorage.setItem(getLoginKey.sessionTraceId, response?.headers['session-trace-id'])

		showConsole('🟢 Success: ' + response.config.method + ' ' + response.config.url.replace(Env.baseApiCallURL, '') + ' ' + response.status)
		return response;
	},
	(error) => {
		const response = error.response;
		delete reqLs[`${response.config.reqId}`]

		showConsole('🔴 Failure: ' + response.config.method + ' ' + response.config.url.replace(Env.baseApiCallURL, '') + ' ' + response.status)

		return Promise.reject(error)
	}
)
// End of Axios Work


function App() {
	// Snackbar Code
	const openSnackbar = useContext(globalContext)
	const snackbarShow = useContext(globalContext)
	const snackbarClose = useContext(globalContext)

	

	// Axios Work
	axios.interceptors.response.use(
		(response) => {
			let status = response?.status;
			if (status === 200 && response.data.message !== "Success" && response.config.url !== 'http://ip-api.com/json') snackbarShow.snackbarShow(response.data.message, getColour.BaseColor)
			if (status === 201) snackbarShow.snackbarShow(response.data.message, getColour.BaseColor)
			if (status === 202) snackbarShow.snackbarShow(response.data.message, getColour.BaseColor)
			if (status === 204) snackbarShow.snackbarShow("Delete Successfully", getColour.BaseColor)
			return response;
		},
		(error) => {
			if (error?.response?.status === 500) snackbarShow.snackbarShow("Internal Server Error", getColour.RedColor)
			if (error?.response?.status === 501) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			if (error?.response?.status === 510) {
				if (Env.env_type !== 'production') {
					showConsole(error.response.data.message)
					snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
				} else {
					snackbarShow.snackbarShow("Something went wrong!!!", getColour.RedColor)
				}
			}
			if (error?.response?.status === 400) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			if (error?.response?.status === 401) {
				if (!window.location.href?.includes('/auth/login')) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			}
			if (error?.response?.status === 403) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			if (error?.response?.status === 404) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			if (error?.response?.status === 405) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			if (error?.response?.status === 406) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			if (error?.response?.status === 409) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			if (error?.response?.status === 410) snackbarShow.snackbarShow(error.response.data.message, getColour.RedColor)
			if (error?.response?.status === 424) localStorage.setItem('productLsError', error.response.data.message)


			return Promise.reject(error)
		}
	)
	// End of Axios Work
	return (
		<>
			<RouteController />
			{openSnackbar.openSnackbar.open && <ShowSnackbar open={openSnackbar.openSnackbar.open} errorMsg={openSnackbar.openSnackbar.errorMsg} errorMsgBg={openSnackbar.openSnackbar.errorMsgBg} close={snackbarClose.snackbarClose} />}
		</>
	)
}

export default App