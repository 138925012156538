class WtSeo {
    constructor() {
        // SEO Data
        this.pricingTitle = 'Pricing'
        this.quickGuideTitle = 'Quick Guide'
        this.chatbotTitle = 'Chatbots'
        this.multiAgentTitle = 'Multi Agent Support'
        this.broadcastMsgTitle = 'Broadcast Messaging'
        this.clientsTitle = 'Clients'
        this.supportTitle = 'Customer Support'
        this.termsOfServiceTitle = 'Terms of Services'
        this.privacyPolicyTitle = 'Privacy Policy'
        this.cancellationTitle = 'Cancellation & Refund Policy'

        this.commonKeywords = 'whatsapp multiagent, whatsapp multi agent, whatsapp business multi agent, whatsapp multi agent chat, whatsapp multi agent platform, whatsapp multi agent chat'
        // End of SEO Data


        this.teamPageTitle = 'Team'
        this.conversationalEngagementTitle = 'Conversational Engagement on WhatsApp Business'
        this.featuredChatbotTemplateTitle = 'Chatbot Templates'


        this.homePageTitle = 'WhatsTool Business'
        this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        this.pricingPageTitle = 'Pricing - WhatsTool Business'
        this.pricingPageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        this.pricingPageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '

        // this.homePageTitle = 'WhatsTool Business'
        // this.homePageDescription = 'Powered by WhatsApp Official Business API. WT Business solutions can grow your business with WhatsApp'
        // this.homePageKeywords = 'whatstool, whatstool business, business, whatsapp api, whatsapp api solution, '


    }
}
export const getSeo = new WtSeo()