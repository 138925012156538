/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import './index.css'
import { Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { getColour } from '../../constants/colours'
import { getUrl } from '../../constants/urls'
import { getDimen } from '../../constants/dimens'
import { getString } from '../../constants/strings'
import { isMobile } from 'react-device-detect'


export const WTBTools = () => {
    const wtbToolsSchema = [
        { hText: 'Chat Widget', bText: 'Create Chat Widget for your website', btnText: 'Create', btnAction: getUrl.whastsappchatbuttonUrl, imgLink: getUrl.imageBaseUrl + '/WA_Chat_widget.svg' },
        { hText: 'WhatsApp QR Generator', bText: 'Create Shareable QR for your WA Business number', btnText: 'Generate', btnAction: getUrl.whatsappQRGeneratorUrl, imgLink: getUrl.imageBaseUrl + '/WA_QR.svg' },
        { hText: 'WhatsApp Link Generator', bText: 'Create Shareable links for your WA Business number', btnText: 'Generate', btnAction: getUrl.whatsappLinkGeneratorUrl, imgLink: getUrl.imageBaseUrl + '/WA_Link_QR_Icon.svg' }
    ]

    const history = useHistory()
    const openURL = (url) => history.push(url)
    return (
        <section style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', background: '#fafafa' }}>
            {
                wtbToolsSchema?.map((v, i) => {
                    return (
                        <div style={{ flex: 1, border: '1px solid ' + getColour.BorderLineColour, borderRadius: getDimen.borderRadius, padding: '6px', margin: '0px 20px 65px 20px', background: '#fff' }} key={i}>
                            <p className='text-center mt-5'>
                                <img src={v.imgLink} style={{ width: '200px', height: '200px' }} className='img-fluid' alt={getString.SiteName} />
                            </p>
                            <p className='text-center fw-bold' style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }}>{v.hText}</p>
                            <p className='text-center' style={{ color: getColour.LightTextColour, fontSize: '12px' }}>{v.bText}</p>
                            <p className='text-center mt-5 mb-3'><button onClick={() => openURL(v.btnAction)} className='btn btn-sm secondaryBtn' style={{ color: getColour.DescriptionTextColour, background: getColour.BorderLineColour, padding: '4px 20px', fontSize: '18px' }}>{v.btnText}</button></p>
                        </div>
                    )
                })
            }
        </section>
    )
}

export const VideoIframe = () => {
    const [playVideo, setPlayVideo] = useState(false)
    const handlePlayVideoBtn = () => {
        setPlayVideo(!playVideo)
    }
    return (
        <div className="mt-5 mb-5 pt-5 pb-5">
            <p className="text-center">
                {
                    (playVideo === false) ? (
                        <img onClick={handlePlayVideoBtn} src={getUrl.imageBaseUrl + "/business_video_01.webp"} className="img-fluid rounded-3 hand" width="850px" alt={getString.SiteName} />
                    ) : (
                        <iframe className="rounded-3 iframeVideo" width="850" height="450" src="https://www.youtube.com/embed/7W78gzxie2w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    )
                }
            </p>
        </div>
    )
}

export const BlogViewHome = () => {

    var blogJSON_two = [
        { "blogName": "Why choose WhatsApp Broadcast Message instead of Email/SMS", "blogstitle": "WhatsApp Broadcast Messages, Email, and SMS are all communication tools that businesses and individuals can use to reach their target audience", "blogThumbnail": "https://storage.googleapis.com/wt_resources/wtb_media/business_blog.png", "blogUrl": "https://blog.whatstool.business/why-choose-whatsapp-broadcast-message-instead-of-email-sms/" },
        { "blogName": "How to send Interactive WhatsApp Messages with Action Button", "blogstitle": "Template Messages are pre-approved transactional messages that allow you to initiate conversations with your customers, or continue a user-initiated conversation", "blogThumbnail": "https://storage.googleapis.com/wt_resources/wtb_media/Blog_02.png", "blogUrl": "https://blog.whatstool.business/how-to-send-interactive-whatsapp-messages-with-action-button/" },
        { "blogName": "Why All Direct to Consumer D2C Businesses Should Have a WhatsApp Number.", "blogstitle": "The D2C market has seen a revival in the current age, especially during the COVID pandemic", "blogThumbnail": "https://storage.googleapis.com/wt_resources/wtb_media/Blogs_01.png", "blogUrl": "https://blog.whatstool.business/why-all-direct-to-consumer-d2c-businesses-should-have-a-whatsapp-number/" }
    ]
    return (
        <div className="blogView p-5 mb-5 text-center">
            <p style={{ color: getColour.DescriptionTextColour, fontSize: "20px", fontWeight: "500" }} className='pb-4'>Read blogs to know more about Whatstool Business</p>
            <div className="blogList">
                <Row>
                    {
                        blogJSON_two.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={12} key={i}>
                                    <div className="p-2">
                                        <Link to={{ pathname: v.blogUrl }} target="_blank">
                                            <div style={{ border: '1px solid #ccc', borderRadius: '8px', minHeight: '500px' }}>
                                                <img src={v.blogThumbnail} style={{ width: "100%", height: "225px", borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} alt={getString.SiteName} className="img-fluid" />
                                                <div className='p-3' style={{ textAlign: 'initial' }}>
                                                    <p className="mt-3" style={{ color: '#273041', fontSize: "18px", fontWeight: "500" }}>{v.blogName}</p>
                                                    <p className="mt-3" style={{ color: '#616874', fontSize: "16px", fontWeight: "500" }}>{v.blogstitle}<b>...</b></p>
                                                    <div style={{ display: 'flex' }} className='mt-3'>
                                                        <p className="mt-2" style={{ color: '#B8BDCC', fontSize: "14px", fontWeight: "500", flex: '1' }}>2 min read</p>
                                                        <p className="mt-2" style={{ color: getColour.BaseColor, fontSize: "14px", fontWeight: "500", flex: '1', textAlign: 'end' }}>Read More</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row >
            </div >
        </div >
    )
}

