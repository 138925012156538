class Variables {
    constructor() {
        this.env_type = process.env.REACT_APP_ENV
        this.baseApiCallURL = process.env.REACT_APP_ENDPOINT
        this.baseUI_URL = process.env.REACT_APP_UI_BASE
        this.authKey = process.env.REACT_APP_AUTH_KEY
        this.stripePublishableKey = process.env.REACT_APP_STRIPE_KEY
        this.razorpayKey = process.env.REACT_APP_RAZORPAY_KEY
        this.razorpayPaymentVerify = '/payments/razorpay/verify'
        this.dialog360ApiKey = process.env.REACT_APP_D360_KEY
        this.firebaseConfig = {
            apiKey: process.env.REACT_APP_FB_KEY,
            authDomain: process.env.REACT_APP_FB_DOMAIN,
            databaseURL: process.env.REACT_APP_FB_DB_URL,
            projectId: process.env.REACT_APP_FB_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FB_BUCKET,
            messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
            appId: process.env.REACT_APP_FB_APP_ID,
            measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
        }
        this.mqttHostname = process.env.REACT_APP_MQTT_HOST_NAME
        this.mqttPass = process.env.REACT_APP_MQTT_PASS
        this.mqttUsername = process.env.REACT_APP_MQTT_USER
        this.mqttPort = process.env.REACT_APP_MQTT_PORT
        this.vapid = process.env.REACT_APP_VAPID_KEY
        this.analyticsTrackID = process.env.REACT_APP_ANALYTICS_ID
        this.mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN
        this.fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID
        this.googleAuthClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
        
        this.localStorageKey = process.env.REACT_APP_LOCAL_STOAGE_KEY
    }
}

export const Env = new Variables()