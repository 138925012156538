/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext, useRef } from 'react'
import './index.css'
import { Add, AddCircleOutlineOutlined, Bolt, Bookmarks, Business, Search, ExpandMore, ChatBubbleOutlineOutlined, ChatOutlined, Check, CheckSharp, Close, ContactEmergency, Diversity3, Groups2, HowToReg, Key, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, PersonOutlineOutlined, Phone, Power, Quickreply, SmartToy, Person, AddShoppingCart, BorderVertical, WhatsApp, LibraryAdd, AppsOutlined, CampaignOutlined, ShoppingCartOutlined, QuestionAnswerOutlined, LockOutlined } from '@mui/icons-material'
import { Container, Row, Col, Table, Carousel, Accordion as RBAccordion } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { FormControlLabel, Radio, RadioGroup, Slider } from '@mui/material'
import { fetchPlanListOpen } from '../../apis/APIs'
import { WtLoader } from '../../controller/BaseTool'
import { getUrl } from '../../constants/urls'
import { getColour } from '../../constants/colours'
import { getDimen } from '../../constants/dimens'
import { BuyNowDialog, JoinLIVEDemoDialog } from '../../basetool/dialogs'
import { Testimonial } from './ui'
import globalContext from '../../context/global/globalContext'
import { isDesktop, isMobile, isMobileOnly } from 'react-device-detect'
import { getString } from '../../constants/strings'
import { WtEvent } from '../../basetool/analytics'
import { getAnalyticsKey } from '../../constants/analyticsKeys'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { isTestEnv } from '../../basetool/methods'
import useGeoLocation from "react-ipgeolocation"


export const Pricing = () => {
    const isLoading = useContext(globalContext)
    const handleWTLoader = useContext(globalContext)

    const selectRef = useRef(null)

    const [planType, setPlanType] = useState('yearly') // monthly | yearly
    const handlePlanType = (val) => {
        setPlanType(val)
        if (val === 'yearly') setValidity(12)
        if (val === 'monthly') setValidity(1)
    }

    const [validity, setValidity] = useState(12)
    const handleValidity = (val) => {
        setValidity(val)
        console.log(val)
        if (val === '12' || val === 12) {
            setPlanType('yearly')
        } else {
            setPlanType('monthly')
        }
    }



    const [planList, setPlanList] = useState([])


    const [currencyVal, setCurrencyVal] = useState(false)


    const [planPrice, setPlanPrice] = useState({ 'growth': 0, 'pro': 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePlanPrice = () => {
        let growthDiscountValue = 0
        let proDiscountValue = 0
        if (validity < 3) {
            growthDiscountValue = 0
            proDiscountValue = 0
        } else if (validity >= 3 && validity < 6) {
            growthDiscountValue = planList[0]?.base_discount
            proDiscountValue = planList[1]?.base_discount
        } else if (validity >= 6 && validity < 9) {
            growthDiscountValue = planList[0]?.base_discount * 2
            proDiscountValue = planList[1]?.base_discount * 2
        } else if (validity >= 9 && validity < 12) {
            growthDiscountValue = planList[0]?.base_discount * 3
            proDiscountValue = planList[1]?.base_discount * 3
        } else if (validity >= 12 && validity < 15) {
            growthDiscountValue = planList[0]?.base_discount * 4
            proDiscountValue = planList[1]?.base_discount * 4
        } else if (validity >= 15 && validity < 18) {
            growthDiscountValue = planList[0]?.base_discount * 5
            proDiscountValue = planList[1]?.base_discount * 5
        } else if (validity >= 18 && validity < 21) {
            growthDiscountValue = planList[0]?.base_discount * 6
            proDiscountValue = planList[1]?.base_discount * 6
        } else if (validity >= 21 && validity < 24) {
            growthDiscountValue = planList[0]?.base_discount * 7
            proDiscountValue = planList[1]?.base_discount * 7
        } else if (validity === 24) {
            growthDiscountValue = planList[0]?.base_discount * 8
            proDiscountValue = planList[1]?.base_discount * 8
        }

        let growthBasePrice = !currencyVal ? planList[0]?.price_inr : planList[0]?.price_usd
        let growthSeasonalDiscount = planList[0]?.seasonal_discount
        let growthTotalDiscount = (growthDiscountValue + growthSeasonalDiscount)

        let proBasePrice = !currencyVal ? planList[1]?.price_inr : planList[1]?.price_usd
        let proSeasonalDiscount = planList[1]?.seasonal_discount
        let proTotalDiscount = (proDiscountValue + proSeasonalDiscount)

        let finalGrowthPrice = ((growthBasePrice * validity) - ((growthBasePrice * validity * growthTotalDiscount) / 100))
        let finalProPrice = (proBasePrice * validity) - ((proBasePrice * validity * proTotalDiscount) / 100)

        setPlanPrice({ 'growth': finalGrowthPrice, 'pro': finalProPrice })
    }

    useEffect(() => { handlePlanPrice() }, [validity, currencyVal, isLoading.isLoading])

    const getPlanList = async () => {
        try {
            handleWTLoader.handleWTLoader(true)
            const response = await fetchPlanListOpen()
            if (response.status === 200) {
                response.data.data?.map((v) => {
                    if (v._id === "Primary") {
                        setPlanList(v.plans)
                    }
                })
                handleWTLoader.handleWTLoader(false)
            }
        } catch (error) { handleWTLoader.handleWTLoader(false) }
    }
    useEffect(() => { getPlanList() }, [])

    function valuetext(value) {
        return `${value} month`;
    }
    const getPercentageVal = () => {
        let floorVal = 2.5
        let percentage = 0
        if (validity < 3) {
            percentage = 0
        } else if (validity >= 3 && validity < 6) {
            percentage = floorVal
        } else if (validity >= 6 && validity < 9) {
            percentage = floorVal * 2
        } else if (validity >= 9 && validity < 12) {
            percentage = floorVal * 3
        } else if (validity >= 12 && validity < 15) {
            percentage = floorVal * 4
        } else if (validity >= 15 && validity < 18) {
            percentage = floorVal * 5
        } else if (validity >= 18 && validity < 21) {
            percentage = floorVal * 6
        } else if (validity >= 21 && validity < 24) {
            percentage = floorVal * 7
        } else if (validity === 24) {
            percentage = floorVal * 8
        }
        return `${percentage}`
    }
    const sliderStyle = {
        color: getColour.BaseColor,
        '& .MuiSlider-valueLabel': {
            backgroundColor: getColour.BaseColor,
            color: '#fff'
        },
        '& .MuiSlider-markLabel': {
            color: getColour.BaseColor
        }
    }
    const radioBtnColor = {
        color: getColour.BaseColor,
        '&.Mui-checked': {
            color: getColour.BaseColor,
        },
        '& .MuiSvgIcon-root': {
            fontSize: isDesktop ? 15 : 13,
        },
    }


    const location = useGeoLocation();

    useEffect(() => {
        if (location.country && location.country !== 'IN') {
            if (location.country !== 'IN') setCurrencyVal(true)
        }
    }, [location])


    const [buyNowDialog, setBuyNowDialog] = useState({ open: false, onClose: null })
    const openBuyNowDialog = () => {
        setBuyNowDialog({
            open: true, onClose: () => {
                setBuyNowDialog(false)
            }
        })
    }
    return (
        <Container className="mt-3 mb-5 pt-4 pricing">
            {isLoading.isLoading && <WtLoader open={isLoading.isLoading} />}
            {buyNowDialog.open && <BuyNowDialog open={buyNowDialog.open} onClose={buyNowDialog.onClose} />}
            <div className="pt-4 pb-0 top-bar">
                <h1 className="text-center fw-bold" style={{ color: getColour.HeadingTextColour, fontSize: "45px" }}>Grow your business with Us,<br />Start Now</h1>
                <h5 className="text-center" style={{ color: getColour.DescriptionTextColour }}>With the most affordable pricing, we are highly preferred by startups</h5>
            </div>

            {/* Monthly - - Yearly - Currency Section */}
            <Container className='planTypeUI' fluid>
                <Row className="mt-4 ">
                    <Col xxl={2} xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className="hand">
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0px 0px 0px' }}>
                            <div style={{ display: 'flex', fontSize: isDesktop ? '16px' : '14px', border: '1px solid ' + getColour.HeadingTextColour, borderRadius: '50px', width: isDesktop ? '30vw' : '95vw', padding: isDesktop ? '10px 10px 10px 15px' : '10px 20px' }}>
                                <div>
                                    <p className='fw-semibold' style={{ margin: 0, color: getColour.HeadingTextColour }}>Plan duration &nbsp;&nbsp;</p>
                                </div>
                                <div>
                                    <select ref={selectRef} defaultValue={validity} value={validity} onChange={(e) => handleValidity(e.target.value)} style={{ color: getColour.DescriptionTextColour, border: 'none', outline: 'none', borderTopRightRadius: '50px', borderBottomRightRadius: '50px', padding: isDesktop && '2px 15px 2px 10px' }}>
                                        {
                                            [...Array(24)]?.map((_v, i) => {
                                                return <option value={i + 1} key={i}>{i + 1} {i + 1 > 1 ? 'months' : 'month'}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div style={{ flex: 1 }}></div>
                                <div>
                                    <RadioGroup defaultValue={planType} row name="row-radio-buttons-group" onChange={(e) => handlePlanType(e.target.value)} style={{ margin: '-4px 0px -6px 0px' }} size="small">
                                        <FormControlLabel value='monthly' control={<Radio sx={radioBtnColor} />} label='Monthly' />
                                        <FormControlLabel value='yearly' control={<Radio sx={radioBtnColor} />} label='Yearly' />
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0px 0px 0px' }}>
                            <div style={{ display: 'flex', border: '1px solid ' + getColour.HeadingTextColour, borderRadius: '50px', padding: isDesktop ? '10px 15px 10px 15px' : '10px 20px', width: isDesktop ? '30vw' : '95vw' }}>
                                <div style={{ width: isDesktop ? '4vw' : '14vw' }}>
                                    <p className='text-center fw-semibold' style={{ margin: 0, color: getColour.HeadingTextColour }}>Discount</p>
                                </div>
                                <div style={{ display: 'flex', width: isDesktop ? '15vw' : '50vw', padding: isDesktop ? '0px 30px' : '0px 10px' }}>
                                    <div style={{ margin: '0px 15px 0px 0px' }}>0%</div>
                                    <Slider sx={sliderStyle} defaultValue={validity} value={validity} onChange={(e) => handleValidity(e.target.value)} getAriaValueText={valuetext} valueLabelFormat={valuetext} step={1} valueLabelDisplay='auto' min={1} max={24} />
                                    <div style={{ margin: '0px 0px 0px 15px' }}>20%</div>
                                </div>
                                {isDesktop && <div style={{ flex: 1 }}></div>}
                                <div style={{ width: '4vw' }}>
                                    <small className='' style={{ color: '#fff', background: getColour.BaseColor, padding: '4px 20px', borderRadius: '30px' }}>{getPercentageVal()}%</small>
                                </div>
                            </div>
                        </div>
                        {/* {
                            location.country === 'IN' &&
                            <div>
                                <p className="text-center mt-4" style={{ display: 'block' }}>
                                    <span>INR (₹)</span>&nbsp;<Switch defaultChecked={currencyVal} onChange={handleCurrencyVal} />&nbsp;<span>USD ($)</span>
                                </p>
                            </div>
                        } */}
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={12} sm={12} xs={12}></Col>
                </Row>
            </Container>
            {/* End of Monthly - Yearly - Currency Section */}


            {/* Main Plan Section */}
            <section className='mt-5 mb-4'>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                    <div className='shadow-lg growth-plan mb-3' style={{ flex: 1, border: '1px solid ' + getColour.BorderLineColour, margin: '0px 10px 0px 0px', borderRadius: '10px' }}>
                        <div style={{ borderRadius: '10px' }}>
                            <p className='text-center' style={{ color: getColour.RedColor, margin: '0px', padding: '10px', fontWeight: 'bold' }}>&nbsp;</p>
                        </div>
                        <div style={{ padding: '0px 20px 30px 20px' }}>
                            <div style={{ marginTop: '20px' }}>
                                <p className='text-center' style={{ fontSize: '30px', color: getColour.HeadingTextColour, fontWeight: 'bold' }}>Growth</p>
                            </div>
                            <div>
                                <p className='text-center' style={{ fontSize: '30px', color: getColour.HeadingTextColour, margin: '0px' }}>
                                    {currencyVal ? "$" : "₹"}
                                    {Math.floor(planPrice?.growth)}
                                </p>
                                <p className='text-center' style={{ fontSize: '15px', color: getColour.DescriptionTextColour }}>{validity} month billed</p>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    {planList[0]?.seasonal_discount > 0 && <small className='text-center' style={{ fontSize: '15px', paddingBottom: '3px', background: '#F64545', color: '#fff', borderRadius: '30px', paddingLeft: '10px', paddingRight: '10px' }}>+ {planList[0]?.seasonal_discount}% Seasonal offer included</small>}
                                </div>
                            </div>
                            <div>
                                <p className='text-center'>
                                    <button onClick={() => openBuyNowDialog()} className='btn btn-sm w-75' style={{ color: getColour.BaseColor, background: '#fff', border: '1px solid ' + getColour.BaseColor, fontWeight: 'bold', padding: '10px 20px', fontSize: '18px' }}>Buy Now</button>
                                </p>
                            </div>
                            <div className='hand'>
                                <p className='text-center' style={{ color: getColour.HeadingTextColour, fontWeight: 'bold' }}>1,000 free service conversation per month</p>
                            </div>
                            <div><hr /></div>
                            <div style={{ padding: '0px 10px' }}>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Groups2 style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 10 agents</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Bookmarks style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 25 labels</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><ContactEmergency style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 25 custom field</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Quickreply style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 25 quick reply</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><SmartToy style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 10 chatbots</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}> &nbsp;</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}> &nbsp;</p>
                            </div>
                            <div><hr style={{ borderTop: '1px dashed ' + getColour.HeadingTextColour }} /></div>
                            <div style={{ padding: '0px 10px' }}>
                                <p><LibraryAdd style={{ fontSize: '20px' }} /> <span style={{ colour: getColour.HeadingTextColour, fontWeight: 'bold' }}>Add-ons</span></p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><AddShoppingCart style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Shopify</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><BorderVertical style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Google Sheet</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Groups2 style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Additional agents</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><WhatsApp style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Additional channels</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Person style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Dedicated account manager</p>
                            </div>
                            <div><hr style={{ borderTop: '1px dashed ' + getColour.HeadingTextColour }} /></div>
                            <div style={{ padding: '0px 10px' }}>
                                <p><Phone style={{ fontSize: '20px' }} /> <span style={{ colour: getColour.HeadingTextColour, fontWeight: 'bold' }}>Support</span></p>
                                <p style={{ margin: '0px', color: getColour.DescriptionTextColour }}><CheckSharp style={{ fontSize: '18px', color: getColour.BaseColor }} /> Docs, Videos and FAQs</p>
                                <p style={{ margin: '0px', color: getColour.DescriptionTextColour }}><CheckSharp style={{ fontSize: '18px', color: getColour.BaseColor }} /> Priority Email & WhatsApp Support</p>
                                <p style={{ margin: '0px', color: getColour.DescriptionTextColour }}><CheckSharp style={{ fontSize: '18px', color: getColour.BaseColor }} /> Onboarding Support</p>
                            </div>
                        </div>
                    </div>
                    <div className='shadow-lg pro-plan mb-3' style={{ flex: 1, border: '1px solid ' + getColour.BorderLineColour, margin: '0px 10px 0px 10px', borderRadius: '10px' }}>
                        <div style={{ background: getColour.MainBgColour, borderRadius: '10px' }}>
                            <p className='text-center' style={{ color: getColour.RedColor, margin: '0px', padding: '10px', fontWeight: 'bold' }}>Recommended for all & you</p>
                        </div>
                        <div style={{ padding: '0px 20px 30px 20px' }}>
                            <div style={{ marginTop: '20px' }}>
                                <p className='text-center' style={{ fontSize: '30px', color: getColour.HeadingTextColour, fontWeight: 'bold' }}>Pro</p>
                            </div>
                            <div>
                                <p className='text-center' style={{ fontSize: '30px', color: getColour.HeadingTextColour, margin: '0px' }}>
                                    {currencyVal ? "$" : "₹"}
                                    {Math.floor(planPrice?.pro)}
                                </p>
                                <p className='text-center' style={{ fontSize: '15px', color: getColour.DescriptionTextColour }}>{validity} month billed</p>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    {planList[1]?.seasonal_discount > 0 && <small className='text-center' style={{ fontSize: '15px', paddingBottom: '3px', background: '#F64545', color: '#fff', borderRadius: '30px', paddingLeft: '10px', paddingRight: '10px' }}>+ {planList[1]?.seasonal_discount}% Seasonal offer included</small>}
                                </div>
                            </div>
                            <div>
                                <p className='text-center'>
                                    <button onClick={() => openBuyNowDialog()} className='btn btn-sm w-75' style={{ color: getColour.BaseColor, background: '#fff', border: '1px solid ' + getColour.BaseColor, fontWeight: 'bold', padding: '10px 20px', fontSize: '18px' }}>Buy Now</button>
                                </p>
                            </div>
                            <div className='hand' onClick={() => { }}>
                                <p className='text-center' style={{ color: getColour.HeadingTextColour, fontWeight: 'bold' }}>1,000 free service conversation per month</p>
                            </div>
                            <div><hr /></div>
                            <div style={{ padding: '0px 10px' }}>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Groups2 style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 20 agents</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Bookmarks style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 50 labels</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><ContactEmergency style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 50 custom field</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Quickreply style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 50 quick reply</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><SmartToy style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Up to 20 chatbots</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Power style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Advance Integration</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Key style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Developer API Access</p>
                            </div>
                            <div><hr style={{ borderTop: '1px dashed ' + getColour.HeadingTextColour }} /></div>
                            <div style={{ padding: '0px 10px' }}>
                                <p><LibraryAdd style={{ fontSize: '20px' }} /> <span style={{ colour: getColour.HeadingTextColour, fontWeight: 'bold' }}>Add-ons</span></p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><AddShoppingCart style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Shopify</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><BorderVertical style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Google Sheet</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Groups2 style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Additional agents</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><WhatsApp style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Additional channels</p>
                                <p style={{ margin: '0px 0px 10px 0px', color: getColour.DescriptionTextColour }}><Person style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Dedicated account manager</p>
                            </div>
                            <div><hr style={{ borderTop: '1px dashed ' + getColour.HeadingTextColour }} /></div>
                            <div style={{ padding: '0px 10px' }}>
                                <p><Phone style={{ fontSize: '20px' }} /> <span style={{ colour: getColour.HeadingTextColour, fontWeight: 'bold' }}>Support</span></p>
                                <p style={{ margin: '0px', color: getColour.DescriptionTextColour }}><CheckSharp style={{ fontSize: '18px', color: getColour.BaseColor }} /> Docs, Videos and FAQs</p>
                                <p style={{ margin: '0px', color: getColour.DescriptionTextColour }}><CheckSharp style={{ fontSize: '18px', color: getColour.BaseColor }} /> Priority Email & WhatsApp Support</p>
                                <p style={{ margin: '0px', color: getColour.DescriptionTextColour }}><CheckSharp style={{ fontSize: '18px', color: getColour.BaseColor }} /> Onboarding Support</p>
                                <p style={{ margin: '0px', color: getColour.DescriptionTextColour }}><CheckSharp style={{ fontSize: '18px', color: getColour.BaseColor }} /> Conversation Expert Assistance</p>
                                <p style={{ margin: '0px', color: getColour.DescriptionTextColour }}><CheckSharp style={{ fontSize: '18px', color: getColour.BaseColor }} /> Bot Building Assistance</p>
                            </div>
                        </div>
                    </div>
                    <div className='shadow-lg custom-plan' style={{ flex: 1, border: '1px solid ' + getColour.BorderLineColour, margin: '0px 0px 0px 10px', borderRadius: '10px' }}>
                        <div style={{ borderRadius: '10px' }}>
                            <p className='text-center' style={{ color: getColour.RedColor, margin: '0px', padding: '10px', fontWeight: 'bold' }}>&nbsp;</p>
                        </div>
                        <div style={{ padding: '0px 20px 30px 20px' }}>
                            <div style={{ marginTop: '20px' }}>
                                <p className='text-center' style={{ fontSize: '30px', color: getColour.HeadingTextColour, fontWeight: 'bold' }}>Custom</p>
                            </div>
                            <div>
                                <p className='text-center' style={{ fontSize: '16px', color: getColour.HeadingTextColour, margin: '0px 0px 33px 0px' }}>For companies seeking best-in-class conversation automation to modernise all customer touch-points</p>
                            </div>
                            <div>
                                <p className='text-center'>
                                    <button onClick={() => window.open(getUrl.wpdemobookingUrl, '_self')} className='btn btn-sm w-75' style={{ color: getColour.BaseColor, background: '#fff', border: '1px solid ' + getColour.BaseColor, fontWeight: 'bold', padding: '10px 20px', fontSize: '18px' }}>Talk to us</button>
                                </p>
                            </div>
                            <div className='hand' onClick={() => { }}>
                                <p className='text-center' style={{ color: getColour.HeadingTextColour, fontWeight: 'bold' }}>1,000 free service conversation per month</p>
                            </div>
                            <div><hr /></div>
                            <div style={{ margin: '20px 0px 0px 0px', padding: '0px 10px' }}>
                                <p style={{ margin: '0px 0px 15px 0px', color: getColour.DescriptionTextColour }}><AppsOutlined style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> All features of Pro included</p>
                                <p style={{ margin: '0px 0px 15px 0px', color: getColour.DescriptionTextColour }}><HowToReg style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Dedicated Key Account Manager</p>
                                <p style={{ margin: '0px 0px 15px 0px', color: getColour.DescriptionTextColour }}><Bolt style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> High speed messaging</p>
                                <p style={{ margin: '0px 0px 15px 0px', color: getColour.DescriptionTextColour }}><Power style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> Custom Integrations</p>
                                <p style={{ margin: '0px 0px 15px 0px', color: getColour.DescriptionTextColour }}><Diversity3 style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }} /> More than 20+ agent</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End of Main Plan Section */}


            <DetailedPlanUI planList={planList} currencyVal={currencyVal} planPrice={planPrice} />
            <div style={{ padding: '60px 0px' }}>
                <ZeroMarkupCost />
            </div>

            <PricingFaqUI />
            <PricingCTA />
            <Testimonial />
        </Container >
    )
}

export const ZeroMarkupCost = () => {
    return (
        <section style={{ margin: '0px 0px 60px 0px' }}>
            <div style={{ marginTop: '60px' }}>
                <p className='text-center fw-semibold' style={{ color: getColour.HeadingTextColour, fontSize: '24px', margin: '0px 0px 10px 0px' }}>Engage in Endless Conversations, No Worries About Message Count 😇</p>
                <p className='text-center' style={{ color: getColour.DescriptionTextColour, fontSize: '18px' }}>With WhatsApp Pay Per Conversation approach, you’ll have a 24-hour window for every interaction, enabling you to send unlimited messages without incurring extra charges.</p>
                <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', marginTop: '40px' }}>
                    <div style={{ flex: 1, padding: '0px 10px' }}>
                        <p className='text-center'><CampaignOutlined style={{ fontSize: '40px' }} /></p>
                        <p className='text-center fw-semibold' style={{ color: getColour.HeadingTextColour, fontSize: '18px', margin: '0px 0px 6px 0px' }}>Marketing Conversations</p>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour }}>Product updates, promotional and offer messages</p>
                        <p className='text-center fw-semibold'><small style={{ background: getColour.BorderLineColour, color: getColour.HeadingTextColour, padding: '6px 10px', borderRadius: '4px', fontSize: '16px' }}>#Business Initiated</small></p>
                    </div>
                    <div style={{ flex: 1, padding: '0px 10px' }}>
                        <p className='text-center'><ShoppingCartOutlined style={{ fontSize: '40px' }} /></p>
                        <p className='text-center fw-semibold' style={{ color: getColour.HeadingTextColour, fontSize: '18px', margin: '0px 0px 6px 0px' }}>Utility Conversations</p>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour }}>Transactional and post-purchase notifications</p>
                        <p className='text-center fw-semibold'><small style={{ background: getColour.BorderLineColour, color: getColour.HeadingTextColour, padding: '6px 10px', borderRadius: '4px', fontSize: '16px' }}>#Business Initiated</small></p>
                    </div>
                    <div style={{ flex: 1, padding: '0px 10px' }}>
                        <p className='text-center'><LockOutlined style={{ fontSize: '40px' }} /></p>
                        <p className='text-center fw-semibold' style={{ color: getColour.HeadingTextColour, fontSize: '18px', margin: '0px 0px 6px 0px' }}>Authentication Conversation</p>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour }}>OTPs for account verification and account recovery</p>
                        <p className='text-center fw-semibold'><small style={{ background: getColour.BorderLineColour, color: getColour.HeadingTextColour, padding: '6px 10px', borderRadius: '4px', fontSize: '16px' }}>#Business Initiated</small></p>
                    </div>
                    <div style={{ flex: 1, padding: '0px 10px' }}>
                        <p className='text-center'><QuestionAnswerOutlined style={{ fontSize: '40px' }} /></p>
                        <p className='text-center fw-semibold' style={{ color: getColour.HeadingTextColour, fontSize: '18px', margin: '0px 0px 6px 0px' }}>Service Conversations</p>
                        <p className='text-center' style={{ color: getColour.DescriptionTextColour }}>All user-initiated conversations with free-form messages</p>
                        <p className='text-center fw-semibold'><small style={{ background: getColour.BorderLineColour, color: getColour.HeadingTextColour, padding: '6px 10px', borderRadius: '4px', fontSize: '16px' }}>#User Initiated</small></p>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '40px' }}>
                <p className='text-center'>
                    <img src={getUrl.imageBaseUrl + '/template-pricing.png'} className='img-fluid' style={{ width: isDesktop ? '600px' : '300px', height: 'auto' }} alt={getString.SiteName} />
                </p>
            </div>
            <div style={{ marginTop: '40px' }}>
                <p className='text-center'><button className='btn btn-sm' onClick={() => window.open('https://whatstool.notion.site/WhatsApp-Conversational-pricing-c3e0c895fef44ab68eaba072a8dd3acd?pvs=4', '_blank')} style={{ color: '#fff', background: getColour.ProcessingBlue, padding: '10px 40px', borderRadius: '4px' }}>+ WhatsApp Conversation Cost </button></p>
            </div>
            {/* {openDialog.open && <FacebookMetaPricing open={openDialog.open} onClose={openDialog.onClose} />} */}
        </section>
    )
}

export const DetailedPlanUI = ({ planList, currencyVal, planPrice }) => {
    const [detailedPriceStatus, setDetailedPriceStatus] = useState(false)
    const handleDetailedPriceStatus = () => setDetailedPriceStatus(!detailedPriceStatus)
    return (
        <section style={{ margin: '0px 0px 60px 0px' }}>
            <p className='text-center hand' style={{ color: getColour.ProcessingBlue, fontSize: '18px' }} onClick={() => handleDetailedPriceStatus()}>Check detailed plan comparison {!detailedPriceStatus ? <KeyboardArrowDownOutlined style={{ color: getColour.ProcessingBlue }} /> : <KeyboardArrowUpOutlined style={{ color: getColour.ProcessingBlue }} />}</p>
            {
                detailedPriceStatus &&
                <div className="main-pricing" style={{ border: '1px solid ' + getColour.BorderLineColour, borderRadius: '6px' }}>
                    <div>
                        <Row className="pt-2 pb-2 price-topbar" >
                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                <div className="text-center pt-4 pb-5">
                                    <br />
                                    <span className="name0" style={{ color: getColour.DescriptionTextColour, fontSize: "15px", fontWeight: "bold" }}>Features</span>
                                </div>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                <div className="text-center pt-4 pb-5 ps-2 pe-3">
                                    <br />
                                    <span className="name2 text-uppercase" style={{ color: getColour.DescriptionTextColour, fontSize: "15px", fontWeight: "bold" }} >{planList[0]?.name}</span><br /><br />
                                    <span className="price" style={{ color: getColour.HeadingTextColour, fontSize: "30px", fontWeight: "bolder" }}>{currencyVal ? "$" : "₹"}{planPrice.growth}</span><br />
                                </div>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                <div className="text-center pt-0 pb-5 ps-2 pe-3">
                                    <span className="pop" style={{ background: "#F64545", color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "30px", paddingRight: "30px", fontSize: "15px", borderRadius: "20px" }}>Popular</span><br /><br />
                                    <span className="name3 text-uppercase" style={{ color: getColour.DescriptionTextColour, fontSize: "15px", fontWeight: "bold" }} >{planList[1]?.name}</span><br /><br />
                                    <span className="price3" style={{ color: getColour.HeadingTextColour, fontSize: "30px", fontWeight: "bolder" }}>{currencyVal ? "$" : "₹"}{planPrice.pro}</span><br />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='pricing-home'>
                        <RBAccordion defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8']} alwaysOpen>
                            <RBAccordion.Item eventKey="0">
                                <RBAccordion.Header>MULTI AGENT TEAM CHAT SUPPORT</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Chats</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>Unlimited*</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>Unlimited*</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Agents</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>10</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>20</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Agents Assigned</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Agent Analytics</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Assign Rules</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Quick Reply</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Multiple Quick Reply</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Canned Response</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2">
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Advance Canned Response</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                            <RBAccordion.Item eventKey="1">
                                <RBAccordion.Header>CRM</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Labels</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>25</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>50</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Custom Fields</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>25</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>50</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                            <RBAccordion.Item eventKey="2">
                                <RBAccordion.Header>CAMPAIGN</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Broadcast</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>Unlimited</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>Unlimited</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Broadcast Segmentation</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Advance Broadcast Analytics</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Rebroadcast</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                            <RBAccordion.Item eventKey="3">
                                <RBAccordion.Header>WHATSAPP COMMERCE</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>No. of Product</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>30</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>100</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Sharing Catalog & Cart</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Product in Chatbot Flow</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Purchase, Order, Delivery Flow</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                            <RBAccordion.Item eventKey="4">
                                <RBAccordion.Header>AUTOMATION (CHATBOT + FLOWBUILDER)</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Basic Chatbot</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>10</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>20</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Advance Chatbot</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Chatbot Analytics</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                            <RBAccordion.Item eventKey="5">
                                <RBAccordion.Header>ANALYTICS</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Analytics</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                            <RBAccordion.Item eventKey="6">
                                <RBAccordion.Header>INTEGRATION</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Intergration</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Developer API Access</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Apps</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                            <RBAccordion.Item eventKey="7">
                                <RBAccordion.Header>WTB SERVICE</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Template Resources</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Chatbot Resources</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Email Support</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>WhatsApp Support</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Advance WTB Training</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Immediate Support Available</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Close style={{ color: getColour.RedColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                            <RBAccordion.Item eventKey="8">
                                <RBAccordion.Header>ADD-ONS</RBAccordion.Header>
                                <RBAccordion.Body>
                                    <div>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Shopify</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Google Sheet</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <Check style={{ color: getColour.BaseColor }} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Additional agents</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>₹500 agent/month</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>₹1200 agent/month</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Additional channels</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>10% discount</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>20% discount</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2" style={{ borderBottom: "1px solid " + getColour.BorderLineColour }}>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <div>
                                                    <span style={{ color: getColour.DescriptionTextColour, fontSize: "18px" }}>Dedicated account manager</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>₹5,000 per month</span>
                                                </div>
                                            </Col>
                                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ borderLeft: "1px solid " + getColour.BorderLineColour }}>
                                                <div className="text-center">
                                                    <span>₹10,000 per month</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </RBAccordion.Body>
                            </RBAccordion.Item>
                        </RBAccordion>
                    </div>
                </div>
            }
            <div className='text-center' style={{ marginTop: '80px' }}>
                <img src="https://storage.googleapis.com/wt_resources/wta_offers/pricing_offer.png" className='img-fluid' alt={getString.SiteName} style={{ height: isMobile ? "" : '360px', borderRadius: '12px', boxShadow: "0px 1px 3px 0px #ddd" }} />
            </div>
        </section>
    )
}

export const PricingCamparisonUI = ({ currencyVal, usdVal }) => {
    return (
        <>
            <div className='price_comparsion ps-3 pe-3 mt-5'>
                <Row>
                    <Col className='colOne' xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} style={{ color: getColour.HeadingTextColour, fontWeight: "bold" }}>
                        <div className='p-4'>
                            <p style={{ marginBottom: "28px" }}>&nbsp;</p>
                            <p><Business /> Company</p>
                            <hr />
                            <p><ChatOutlined /> 10,000 Chats/month</p>
                            <hr />
                            <p><PersonOutlineOutlined /> Agent<br />&nbsp;</p>

                            <hr />
                            <p><ChatBubbleOutlineOutlined /> Free Chats</p>
                            <hr />
                            <p><AddCircleOutlineOutlined /> Add-ons Chats<br />&nbsp;</p>
                        </div>
                    </Col>
                    <Col className='colTow mb-3' xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ color: getColour.BaseColor, fontWeight: "bold" }}>
                        <div className='p-4' style={{ background: getColour.MainBgColour }}>
                            <p className='text-center'>
                                <img src='https://business.whatstool.in/favicon.ico' width="40px" className='img-fluid' alt={getString.SiteName} />
                            </p>
                            <p className='text-center'>WT Business</p>
                            <hr />
                            <p>{currencyVal ? "$" + Math.floor(1938 / usdVal) : "₹" + 1938} &nbsp;&nbsp;<span style={{ background: getColour.BaseColor, color: "#fff", paddingTop: "3px", paddingBottom: "3px", paddingLeft: "6px", paddingRight: "6px", fontSize: "11px", borderRadius: "20px" }}>Save 60%</span></p>
                            <hr />
                            <p>5 Agents Extra - 300/Agent</p>
                            <hr />
                            <p>1000</p>
                            <hr />
                            <p>₹0.1<br />&nbsp;</p>
                        </div>
                    </Col>
                    <Col className='colThree mb-3' xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ color: getColour.HeadingTextColour, fontWeight: "bold" }}>
                        <div className='p-4' style={{ background: getColour.BorderLineColour }}>
                            <p className='text-center'>
                                <img src='https://www.wati.io/wp-content/uploads/2020/08/cropped-logo-192x192.png' width="40px" className='img-fluid' alt={getString.SiteName} />
                            </p>
                            <p className='text-center'>Wati</p>
                            <hr />
                            <p>{currencyVal ? "$" + Math.floor(4250 / usdVal) : "₹" + 4250}</p>
                            <hr />
                            <p>5 Agents Extra - ₹1500/Agent</p>
                            <hr />
                            <p>1000</p>
                            <hr />
                            <p>₹0.17<br />&nbsp;</p>
                        </div>
                    </Col>
                    <Col className='colFour mb-3' xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ color: getColour.HeadingTextColour, fontWeight: "bold" }}>
                        <div className='p-4' style={{ background: getColour.BorderLineColour }}>
                            <p className='text-center'>
                                <img src='https://gallabox.com/favicon-32x32.png' width="40px" className='img-fluid' alt={getString.SiteName} />
                            </p>
                            <p className='text-center'>Gallabox</p>
                            <hr />
                            <p>{currencyVal ? "$" + Math.floor(6300 / usdVal) : "₹" + 6300}</p>
                            <hr />
                            <p>5 Agents Extra - ₹500/Agent</p>
                            <hr />
                            <p>1000</p>
                            <hr />
                            <p>₹0.50<br />&nbsp;</p>
                        </div>
                    </Col>
                    <Col className='colFive mb-3' xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ color: getColour.HeadingTextColour, fontWeight: "bold" }}>
                        <div className='p-4' style={{ background: getColour.BorderLineColour }}>
                            <p className='text-center'>
                                <img src='https://ucarecdn.com/92f70b8f-59b7-465a-91e2-aaf641536e92/' width="40px" className='img-fluid' alt={getString.SiteName} />
                            </p>
                            <p className='text-center'>AiSensy</p>
                            <hr />
                            <p>{currencyVal ? "$" + Math.floor(5950 / usdVal) : "₹" + 5950}</p>
                            <hr />
                            <p>Unlimited Agents<br />&nbsp;</p>
                            <hr />
                            <p>1000</p>
                            <hr />
                            <p>Included with WhatsApp pricing</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export const PricingbreakdownUI = () => {
    const history = useHistory()
    const s1 = { fontSize: '22px', color: '#273041' }
    const s2 = { fontSize: '16px', color: 'gray' }
    const s3 = { fontSize: '22px', color: 'gray' }
    const s4 = { fontSize: isMobile ? '24px' : '22px', color: getColour.BaseColor }
    const s5 = { fontSize: '16px', color: 'gray', marginTop: '-12px' }
    const s6 = { border: '1px solid ' + getColour.BaseColor, background: '#EFFFEA', width: isMobile ? '100%' : '31%' }
    const s7 = { border: '1px solid ' + getColour.BaseColor, background: '#EFFFEA', width: isMobile ? '100%' : '52%' }
    return (
        <div>
            <Container>
                <Row>
                    <div>
                        <div className="fw-semibold mt-5 mb-4">
                            <p style={s1}>Plan & Pricing Breakdown</p>
                            <p style={s2}>Monthly Charged</p>
                        </div>
                        <div style={{ border: '1px solid #273041', width: isMobile ? '100%' : '62vw' }} className={isMobile ? 'rounded-3 ps-2 pe-2 ' : 'rounded-4 ps-5 pe-5'}>
                            <p style={s3} className='fw-semibold pt-5 pb-4'>Charged by WhatsTool Business (Prepaid)&nbsp;&nbsp;<img src='https://user-images.githubusercontent.com/117907145/214569112-dcd47ddc-0e78-4dc1-a0b4-ae9029dbb59e.png' alt='whatstool Business' style={{ width: "32px", height: "32px" }} /></p>

                            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }} className='pb-5'>
                                <div style={s6} className='fw-semibold rounded-3 ps-4 pt-3 pb-2'>
                                    <p style={s4} >Monthly Plan Fee</p>
                                    <p style={s5}>Growth, Pro, Enterprise</p>

                                </div>
                                <div><Add style={{ fontSize: '28px', color: 'gray', margin: '40px', marginLeft: isMobile ? '135px' : '40px' }} /></div>

                                <div style={s7} className='fw-semibold rounded-3 ps-4 pt-3 pb-2'>
                                    <p style={s4} >Conversation Charges <span style={{ fontSize: '20px' }}>(Monthly Unique)</span></p>
                                    <p style={s5}>1000 Free/month</p>

                                </div>
                            </div>
                            <p style={s3} className='fw-semibold pt-4 pb-4'>Charged by WhatsApp (Postpaid)&nbsp;&nbsp;<img src="https://user-images.githubusercontent.com/117907145/217474503-305f00aa-f8a2-427a-909c-999b83d61a8a.svg" alt="whatstool Business" className="img-fluid" /></p>
                            <div style={{ border: '1px solid #0C79C8', background: '#c6e7ff', width: isMobile ? '100%' : '54%' }} className='fw-semibold rounded-3 mb-5 ps-4 pt-3 pb-2'>
                                <p style={{ fontSize: isMobile ? '24px' : '22px', color: '#0C79C8' }} >Conversation charges </p>
                                <p style={{ fontSize: '16px', color: 'gray', marginTop: '-12px' }}>24 hr - BIC/UIC (business/user initiated)<br />1000 Free/month</p>
                            </div>

                            <div style={{ margin: '0px 0px 30px 0px' }}>
                                <Link to={{ pathname: 'https://developers.facebook.com/docs/whatsapp/pricing/' }} target='_blank' style={{ color: getColour.ProcessingBlue, fontSize: '16px' }}>Meta Charges</Link>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <p className='text-center'>
                                <button onClick={() => history.push(getUrl.WhatsToolPricingCalcultorUrl)} className='btn btn-sm' style={{ color: '#fff', background: getColour.BaseColor, borderRadius: getDimen.borderRadius, fontSize: '24px', padding: '6px 24px' }}>WTB Plan &amp; Pricing Calculator</button>
                            </p>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export const SamplePricingUI = ({ currencyVal, usdVal }) => {
    return (
        <div className='sample-pricing mt-5'>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Sample Pricing</Accordion.Header>
                    <Accordion.Body>
                        <p style={{ color: getColour.DescriptionTextColour }}>Let's see the price of send unlimited messages to 10K unique users in a month. <br /><span className="fw-bold">Below is the WTB platform pricing as per plan (excluding WhatsApp charges)</span></p>
                        <div className=''>
                            <Table className="fw-bold" responsive>
                                <thead style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>
                                    <tr>
                                        <th>Sample Plan<br />&nbsp;</th>
                                        {/* <th className="text-center">Base Price</th> */}
                                        <th className="text-center">Monthly Subscription Cost<br />&nbsp;</th><th className="text-center">Messsage Add-on Cost (10k messages)<br />&nbsp;</th><th className="text-center" style={{ background: getColour.BorderLineColour, color: getColour.LightTextColour }} >Total Cost<br /><span style={{ fontSize: "10px" }}>(excluding whatsapp charges)</span></th>
                                    </tr>
                                </thead>
                                <tbody style={{ background: "#fff", fontSize: "14px" }}>
                                    <tr className="hand">
                                        <td>Free Plan</td>
                                        {/* <td className="text-center">{currencyVal ? "$" + 0 : "₹" + 0}</td> */}
                                        <td className="text-center">{currencyVal ? "$" + 0 : "₹" + 0}</td>
                                        <td className="text-center">{currencyVal ? "$" + Math.floor(5000 / usdVal) : "₹" + 5000}</td>
                                        <td className="text-center" style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>{currencyVal ? "$" + Math.floor(5000 / usdVal) : "₹" + 5000}</td>
                                    </tr>
                                    <tr className="hand">
                                        <td>Growth Plan</td>
                                        {/* <td className="text-center">{currencyVal ? "$" + Math.floor(1249 / usdVal) : "₹" + 1249}</td> */}
                                        <td className="text-center">{currencyVal ? "$" + Math.floor(999 / usdVal) : "₹" + 999}</td>
                                        <td className="text-center">{currencyVal ? "$" + Math.floor(1000 / usdVal) : "₹" + 1000}</td>
                                        <td className="text-center" style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>{currencyVal ? "$" + Math.floor(1999 / usdVal) : "₹" + 1999}</td>
                                    </tr>
                                    <tr className="hand">
                                        <td>Pro Plan</td>
                                        {/* <td className="text-center">{currencyVal ? "$" + Math.floor(2199 / usdVal) : "₹" + 2199}</td> */}
                                        <td className="text-center">{currencyVal ? "$" + Math.floor(2199 / usdVal) : "₹" + 2199}</td>
                                        <td className="text-center">{currencyVal ? "$" + Math.floor(1000 / usdVal) : "₹" + 1000}</td>
                                        <td className="text-center" style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>{currencyVal ? "$" + Math.floor(3199 / usdVal) : "₹" + 3199}</td>
                                    </tr>
                                    <tr className="hand">
                                        <td>Enterprise Plan</td>
                                        {/* <td className="text-center">{currencyVal ? "$" + Math.floor(3999 / usdVal) : "₹" + 3999}</td> */}
                                        <td className="text-center">{currencyVal ? "$" + Math.floor(3999 / usdVal) : "₹" + 3999}</td>
                                        <td className="text-center">{currencyVal ? "$" + Math.floor(1000 / usdVal) : "₹" + 1000}</td>
                                        <td className="text-center" style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>{currencyVal ? "$" + Math.floor(4999 / usdVal) : "₹" + 4999}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export const AddOnPlanUI = () => {
    return (
        <div className='mt-5 pt-5 mb-5 pb-5'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Add-on in Each Plan case</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <div>
                                <p style={{ fontSize: '20px', color: getColour.HeadingTextColour, fontWeight: 'bold', margin: '0px' }}>Shopify <span style={{ background: getColour.BaseColor, color: '#fff', padding: '2px 10px 4px 10px', borderRadius: '3px', fontSize: '15px' }}>Free</span></p>
                                <p style={{ fontSize: '18px', color: getColour.HeadingTextColour }}>Enhance your customer experience by sharing order updates and tracking information via WhatsApp.</p>
                            </div>
                            <div>
                                <p style={{ fontSize: '20px', color: getColour.HeadingTextColour, fontWeight: 'bold', margin: '0px' }}>Google Sheet <span style={{ background: getColour.BaseColor, color: '#fff', padding: '2px 10px 4px 10px', borderRadius: '3px', fontSize: '15px' }}>Free</span></p>
                                <p style={{ fontSize: '18px', color: getColour.HeadingTextColour }}>Maximize sales by converting leads to deals through our WhatsApp Business Solution.</p>
                            </div>
                            <div>
                                <p style={{ fontSize: '20px', color: getColour.HeadingTextColour, fontWeight: 'bold', margin: '0px' }}>Aditional users <span style={{ background: getColour.BaseColor, color: '#fff', padding: '2px 10px 4px 10px', borderRadius: '3px', fontSize: '15px' }}>₹1,000/ month</span></p>
                                <p style={{ fontSize: '18px', color: getColour.HeadingTextColour }}>Add an additional team member to your account for just ₹1,000/ month</p>
                            </div>
                            <div>
                                <p style={{ fontSize: '20px', color: getColour.HeadingTextColour, fontWeight: 'bold', margin: '0px' }}>Additional channels <span style={{ background: getColour.BaseColor, color: '#fff', padding: '2px 10px 4px 10px', borderRadius: '3px', fontSize: '15px' }}>₹1,200/ month</span></p>
                                <p style={{ fontSize: '18px', color: getColour.HeadingTextColour }}>Add an additional WhatsApp API number to your account for just ₹1,200/ month</p>
                            </div>
                            <div>
                                <p style={{ fontSize: '20px', color: getColour.HeadingTextColour, fontWeight: 'bold', margin: '0px' }}>Dedicated Account Manager <span style={{ background: getColour.BaseColor, color: '#fff', padding: '2px 10px 4px 10px', borderRadius: '3px', fontSize: '15px' }}>₹15,000/ month</span></p>
                                <p style={{ fontSize: '18px', color: getColour.HeadingTextColour }}>Get a dedicated account manager for ₹15,000/ month</p>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export const PricingFaqUI = () => {

    const [expanded, setExpanded] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const pricingAccordian = [
        { t: 'When I will be charged for monthly billing?', d: 'Our billing is Monthly subscription. It starts and end with the month. You should pay before the start of the month to use for the next month. If you do recharge on mid of the month then your plan validity will increase.' },
        { t: 'How I will be charged if I start in mid of the month?', d: "Let's say you start on 10th of this month then you will be charged for remaining days of this month and the next full month. Same with all the plan." },
        { t: 'What is the validity of my message recharge?', d: "Message recharge is valid for lifetime." },
        { t: 'How is the pricing of monthly messaging calculated?', d: "You will be charged for unique conversation you had in a month." },
        { t: 'What is the unique conversation per month?', d: "Let's say you are chatting with Mayank today and you chat with him again on 7th of the month and again on 10th and so on then it will be only 1 unique conversation which will be charged only 0.10 Paisa." },
        { t: 'I want to upgrade in the mid of the month. How to do it? When I will be charged?', d: "You will be charged for the add-on money you will be required to pay for upgrading for the remaining days of the month." },
        { t: 'I want to downgrade in the mid of the month?How to do it? When I will be charged?', d: "You cannot downgrade in the mid of the month. You can request and we will downgrade in the next month" }
    ]

    // Search Handling

    const [searchLs, setSearchLs] = useState(pricingAccordian)
    const handleSearchLs = (sv) => {
        const filteredData = pricingAccordian?.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filteredData)
    }
    // End of Search Handling
    return (
        <section style={{ margin: '80px 0' }}>
            <Container>
                <div>
                    <div className="text-center">
                        <p className="fw-semibold" style={{ fontSize: '32px', color: getColour.HeadingTextColour }}>FAQs</p>
                        <p>Here are some frequently asked questions</p>
                    </div>
                    <center>
                        <div style={{ display: 'flex', border: '1px solid #B8BDCC', borderRadius: '5px', padding: '0 15px', width: isDesktop ? '65%' : 'auto', height: '12mm', margin: '55px 0' }}>
                            <span style={{ padding: '8px' }}><Search /></span>
                            <input type="search" onChange={(e) => handleSearchLs(e.target.value)} placeholder="Type your question here" style={{ border: 'none', outline: 'none', width: '100%' }} />
                        </div>
                    </center>
                    {
                        searchLs?.map((v, i) => {
                            return (
                                <Accordion style={{ border: "none", boxShadow: "none" }} expanded={expanded === 'panel-l-' + i} onChange={handleChange('panel-l-' + i)} key={i}>
                                    <AccordionSummary expandIcon={<ExpandMore className="bRound" style={{ color: getColour.BaseColor, background: getColour.BorderLineColour, }} />}>
                                        <Typography className="fw-bold" sx={{ fontSize: "17px", width: '90%' }}>{v.t}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography dangerouslySetInnerHTML={{ __html: v.d }} style={{ fontSize: '18px', color: getColour.DescriptionTextColour }}></Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </div>
            </Container>
        </section >
    )
}

export const PricingCTA = () => {
    const [openDialog, setOpenDialog] = useState({ open: false, onClose: null })
    const openDialogBox = () => {
        WtEvent(getAnalyticsKey.joinLiveDemoClicked)
        setOpenDialog({ open: true, onClose: () => setOpenDialog(false) })
    }
    const params = useContext(globalContext)
    function getUrls() {
        if (params.params) {
            console.log("objectParam: ", params.params)
            return `${getUrl.staticLink}?${params.params}`
        }
        return isTestEnv() ? getUrl.staticLink : getUrl.productionLink
    }
    return (
        <div className="mt-5 mb-5 pb-5" style={{ background: '#fafafa', borderRadius: "10px" }}>
            <p className='text-center fw-bold pt-5 ps-2 pe-2' style={{ fontSize: "30px", color: getColour.HeadingTextColour }}>Many have utlised WhatsApp and growing with it</p>
            <p className="text-center ps-5 pe-5  mt-4"><img src={getUrl.imageBaseUrl + "/clients.webp"} className="img-fluid w-75" alt={getString.SiteName} /></p>
            <p className='text-center fw-bold mt-5' style={{ color: getColour.BaseColor, fontSize: "20px" }}>You can do it too with zero cost</p>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                <div style={{ flex: 1, display: 'flex' }}>
                    {isMobile ? '' : <p style={{ flex: 1, margin: '0px' }}></p>}
                    <div>
                        <Link to={{ pathname: getUrls() }} target='_blank'><button style={{ background: getColour.BaseColor, color: "#fff", padding: '10px 30px 12px 30px', margin: '10px 10px 0px 0px', whiteSpace: 'nowrap', fontSize: "20px", fontWeight: "bold" }} className="btn btn-sm rounded-3">Start Now</button></Link>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex' }}>
                    <button onClick={() => openDialogBox()} style={{ display: 'flex', color: getColour.HeadingTextColour, background: '#fff', border: '2px solid ' + getColour.HeadingTextColour, padding: '6px 30px 6px 30px', fontSize: '20px', fontWeight: '500', margin: '10px 0px 0px 0px' }} className='btn btn-sm'>
                        <div><p style={{ margin: '4px 0px 0px 0px' }}>Join Live Demo</p></div> &nbsp;&nbsp;&nbsp;
                        <div>
                            <Carousel style={{ width: '40px' }}>
                                <Carousel.Item interval={500}>
                                    <img src={getUrl.imageBaseUrl + '/demo1.jpg'} style={{ width: '40px', height: '40px', borderRadius: '30px' }} alt={getString.SiteName} />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img src={getUrl.imageBaseUrl + '/demo2.jpg'} style={{ width: '40px', height: '40px', borderRadius: '30px' }} alt={getString.SiteName} />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img src={getUrl.imageBaseUrl + '/demo3.jpg'} style={{ width: '40px', height: '40px', borderRadius: '30px' }} alt={getString.SiteName} />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img src={getUrl.imageBaseUrl + '/demo4.jpg'} style={{ width: '40px', height: '40px', borderRadius: '30px' }} alt={getString.SiteName} />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </button>
                </div>
            </div>
            {openDialog.open && <JoinLIVEDemoDialog open={openDialog.open} onClose={openDialog.onClose} />}
        </div>
    )
}

